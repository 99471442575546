import {
  KeyboardArrowLeft,
  SettingsAccessibilityOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import { useState, useEffect } from "react";
import { CalendarMobileHeader } from "../calendar/containercalendar.component";
import theme from "../../theme/theme";
import { Link, useSearchParams } from "react-router-dom";
import { SupportTicket } from "./support-ticket/support-ticket.component";
import { Glossary } from "./glossary/glossary.component";
import { GoalsBlog } from "./how-to/goals/goals.component";
import { OnboardingFAQ } from "./how-to/onboarding/onboarding.component";
import { InsightsBlog } from "./how-to/insights/insights.component";
import { CustomCategoryBlog } from "./how-to/custom-category/custom-category.component";
import { HowTo } from "./how-to/how-to.component";
import HowToTab from "./how-to/how-to-new.component";
import UnderstandingTab from "./understanding-mytiro/understanding-tab.component";
import { SupportLandingPageBlog } from "./landing-page/support-landing-page.component";

export const SupportTabContent = {
  contactSupport: {
    id: "contact-support",
    title: "Contact Support",
    description: "Create support tickets",
  },
  glossary: {
    id: "glossary",
    title: "Glossary",
    description: "Find out more about the terms used in myTiro",
  },
  goals: {
    id: "goals",
    title: " myTiro Goals",
    description: "Learn how to set goals to improve productivity using myTiro",
  },
  onboarding: {
    id: "onboarding",
    title: "myTiro Onboarding",
    description: "Follow these steps to get started with myTiro",
  },
  insights: {
    id: "insights",
    title: "myTiro Insights",
    description: "Get daily insights on your digital devices' usage",
  },
  customCategory: {
    id: "customCategory",
    title: "myTiro Custom Category",
    description: "Set up custom categories to create goals that matter to you.",
  },
  howTo: {
    id: "how-to",
    title: "Understanding myTiro",
    description: "Instructions and tips on how to use myTiro",
  },
  understanding: {
    id: "understanding",
    title: "Getting Started",
    description: "Get the most from your myTiro subscription",
  },
  steps: {
    id: "steps",
    title: "Steps for setup",
    description:
      "Set up myTiro in 5 easy steps and start building healthy digital time habits.",
  },
  register: {
    id: "register",
    title: "Registration of Activities",
    description: "How does myTiro register activities?",
  },
  results: {
    id: "results",
    title: "Results of myTiro",
    description: "Understanding your results on myTiro",
  },
  howTomyTiro: {
    id: "howTomyTiro",
    title: "How to use myTiro",
    description: "Detailed how-to for myTiro",
  },
  supportLandingPage: {
    id: "supportLandingPage",
    title: "Support",
    description: "",
  },
};

const landingpage = {
  icon: "/assets/support-tab/how_to_unselected.svg",
  icon_selected: "/assets/support-tab/how_to_selected.svg",
  title: SupportTabContent.supportLandingPage.title,
  description: SupportTabContent.supportLandingPage.description,
  display: <SupportLandingPageBlog />,
  id: SupportTabContent.supportLandingPage.id,
};

const supportButtons = [
  // {
  //   icon: "/assets/support-tab/how_to_unselected.svg",
  //   icon_selected: "/assets/support-tab/how_to_selected.svg",
  //   title: SupportTabContent.customCategory.title,
  //   description: SupportTabContent.customCategory.description,
  //   display: <CustomCategoryBlog />,
  //   id: SupportTabContent.customCategory.id,
  // },
  // {
  //   icon: "/assets/support-tab/how_to_unselected.svg",
  //   icon_selected: "/assets/support-tab/how_to_selected.svg",
  //   title: SupportTabContent.insights.title,
  //   description: SupportTabContent.insights.description,
  //   display: <InsightsBlog />,
  //   id: SupportTabContent.insights.id,
  // },
  // {
  //   icon: "/assets/support-tab/how_to_unselected.svg",
  //   icon_selected: "/assets/support-tab/how_to_selected.svg",
  //   title: SupportTabContent.goals.title,
  //   description: SupportTabContent.goals.description,
  //   display: <GoalsBlog />,
  //   id: SupportTabContent.goals.id,
  // },
  // {
  //   icon: "/assets/support-tab/how_to_unselected.svg",
  //   icon_selected: "/assets/support-tab/how_to_selected.svg",
  //   title: SupportTabContent.onboarding.title,
  //   description: SupportTabContent.onboarding.description,
  //   display: <OnboardingFAQ />,
  //   id: SupportTabContent.onboarding.id,
  // },
  {
    icon: "/assets/support-tab/how_to_unselected.svg",
    icon_selected: "/assets/support-tab/how_to_selected.svg",
    title: SupportTabContent.understanding.title,
    description: SupportTabContent.understanding.description,
    display: <UnderstandingTab />,
    id: SupportTabContent.understanding.id,
  },
  {
    icon: "/assets/support-tab/how_to_unselected.svg",
    icon_selected: "/assets/support-tab/how_to_selected.svg",
    title: SupportTabContent.howTo.title,
    description: SupportTabContent.howTo.description,
    display: <HowToTab />,
    id: SupportTabContent.howTo.id,
  },
  {
    icon: "/assets/support-tab/glossary_unselected.svg",
    icon_selected: "/assets/support-tab/glossary_selected.svg",
    title: SupportTabContent.glossary.title,
    description: SupportTabContent.glossary.description,
    display: <Glossary />,
    id: SupportTabContent.glossary.id,
  },
  {
    icon: "/assets/support-tab/contact_support_unselected.svg",
    icon_selected: "/assets/support-tab/contact_support_selected.svg",
    title: SupportTabContent.contactSupport.title,
    description: SupportTabContent.contactSupport.description,
    display: <SupportTicket />,
    id: SupportTabContent.contactSupport.id,
  },
];

interface SupportProps {}

const SupportNavigation = (props: {
  icon: string;
  icon_selected: string;
  title: string;
  description: string;
  isSelected: boolean;
  handleClick: (title: string) => void;
}) => {
  const { icon, icon_selected, title, description, isSelected } = props;
  const handleClick = () => {
    props.handleClick(title);
  };

  return (
    <Button
      onClick={handleClick}
      variant="settings"
      disabled={isSelected}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        marginBottom: "2%",
      }}
    >
      <Grid container>
        <Grid item xs={2} sx={{ paddingTop: "5px", textAlign: "center" }}>
          <img
            src={isSelected ? icon_selected : icon}
            height="70%"
            style={{ maxHeight: "36px" }}
          />
        </Grid>
        <Grid item xs={10} sx={{ textAlign: "left", paddingLeft: "4%" }}>
          <Box sx={{ margin: "auto" }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "black" }}
            >
              {title}
            </Typography>
            <Typography variant="body2">{description}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Button>
  );
};

const SupportPanel: React.FunctionComponent<SupportProps> = (props: any) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "4%",
        height: "85vh",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          display: "block",
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#E0E4EF",
          outline: 0,
          borderRadius: "40px",
          height: "40%",
        },
      }}
    >
      <Box sx={{ width: "100%" }}>{props.children}</Box>
    </Box>
  );
};

const SupportMobilePanel = (props: any) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "4%",
        height: "85vh",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          display: "block",
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#E0E4EF",
          outline: 0,
          borderRadius: "40px",
          height: "40%",
        },
      }}
    >
      <Button
        variant="onlytext"
        onClick={props.handleClick}
        startIcon={<KeyboardArrowLeft />}
      >
        Back
      </Button>
      <Box sx={{ width: "100%", margin: "auto" }}>{props.children}</Box>
    </Box>
  );
};

const SupportOptions = (props: {
  handleSelect: (id: string) => void;
  currentSetting: string;
}) => {
  const { handleSelect, currentSetting } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "4%",
        height: "85vh",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          display: "block",
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#E0E4EF",
          outline: 0,
          borderRadius: "40px",
          height: "40%",
        },
      }}
    >
      <Box>
        {supportButtons.map((setting, index) => (
          <SupportNavigation
            key={index}
            isSelected={setting.id === currentSetting}
            handleClick={() => handleSelect(setting.id)}
            {...setting}
          />
        ))}
      </Box>
      <Box>
        <Link to="/terms-of-service" target={"_blank"}>
          Terms of Service
        </Link>{" "}
        |{" "}
        <Link to="/privacy" target={"_blank"}>
          Privacy Policy
        </Link>
      </Box>
    </Box>
  );
};

const SupportTab: React.FunctionComponent<SupportProps> = () => {
  const findSettingFromID = (id: string) => {
    const setting = supportButtons.find((setting) => setting.id === id);
    if (setting) {
      return setting.display;
    } else {
      return landingpage.display;
    }
  };

  const matchesMedium = useMediaQuery(theme.breakpoints.down("md"));
  const [searchParams, setsearchParams] = useSearchParams();
  const [currentPanel, setCurrentPanel] = useState(landingpage.display);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [showMenu, setshowMenu] = useState(true);
  const [currentSetting, setcurrentSetting] = useState(landingpage.id);

  const handleSelect = (id: string) => {
    const setting = supportButtons.find((setting) => setting.id === id);
    if (setting !== undefined) {
      setcurrentSetting(setting?.id);
      setCurrentPanel(setting.display);
      if (matchesMedium) {
        setshowMenu(false);
      }
      const option = setting.id;
      setsearchParams({ option });
    }
    return;
  };

  const showOptions = () => {
    setcurrentSetting("none");
    setshowMenu(true);
  };

  useEffect(() => {
    if (showMenu) {
      setcurrentSetting("none");
    }
  }, [showMenu]);

  useEffect(() => {
    setCurrentPanel(findSettingFromID(currentSetting));
  }, [currentSetting]);

  useEffect(() => {
    const option = searchParams.get("option");
    if (option === null) {
      setcurrentSetting(landingpage.id);
    } else {
      setcurrentSetting(option);
    }
  }, []);

  useEffect(() => {
    const option = searchParams.get("option");
    console.log("Got Options change", option);
    if (option === null) {
      setcurrentSetting(landingpage.id);
    } else {
      setcurrentSetting(option);
    }
  }, [searchParams]);

  return (
    <ThemeProvider theme={theme}>
      <Box display="block" width="100%">
        {matches ? <CalendarMobileHeader /> : ""}
        <Box
          sx={{
            display: "block",
            width: "100%",
            padding: "2%",
            height: "100vh",
          }}
        >
          <Typography variant="h5" sx={{ marginBottom: "1.5%" }}>
            Support
          </Typography>
          {matchesMedium ? (
            <Box
              display="block"
              width="100%"
              sx={{ backgroundColor: "white", borderRadius: "8px" }}
            >
              {showMenu ? (
                <SupportOptions
                  currentSetting={currentSetting}
                  handleSelect={handleSelect}
                />
              ) : (
                <SupportMobilePanel handleClick={showOptions}>
                  {currentPanel}
                </SupportMobilePanel>
              )}
            </Box>
          ) : (
            <Grid
              container
              sx={{ background: "white", height: "85vh", borderRadius: "8px" }}
            >
              <Grid
                item
                lg={3}
                md={5}
                xs={12}
                sx={{ borderRight: "1px solid #D6DAE480" }}
              >
                <SupportOptions
                  currentSetting={currentSetting}
                  handleSelect={handleSelect}
                />
              </Grid>
              <Grid item lg={9} md={7} xs={12}>
                <SupportPanel>{currentPanel}</SupportPanel>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default SupportTab;
