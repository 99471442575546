import { Box, Tooltip } from "@mui/material";
import * as React from "react";
import { FocusSession } from "../models/calendar_main.model";

interface FocusIndicatorProps {
  focusSession: FocusSession;
  oneMinute: number;
  isOverlap: Boolean;
}

interface FocusIndicatorState {
  start: number;
  duration: number;
  oneMinute: number;
  isOverlap: Boolean;
  target: string | undefined;
}

class FocusIndicator extends React.Component<
  FocusIndicatorProps,
  FocusIndicatorState
> {
  constructor(props: FocusIndicatorProps) {
    super(props);
    this.state = {
      start: new Date(props.focusSession.time.start * 1000).getMinutes(),
      duration: props.focusSession.time.duration,
      oneMinute: props.oneMinute,
      isOverlap: props.isOverlap,
      target: props.focusSession.target,
    };
  }

  static getDerivedStateFromProps(
    nextProps: FocusIndicatorProps,
    prevState: FocusIndicatorState
  ) {
    if (nextProps.oneMinute !== prevState.oneMinute) {
      return {
        oneMinute: nextProps.oneMinute,
      };
    }
    if(nextProps.isOverlap !== prevState.isOverlap){
      return {
        isOverlap: nextProps.isOverlap,
      }
    }
    return null;
  }

  render() {
    const { start, duration, oneMinute, isOverlap, target } = this.state;
    let startTime = new Date(this.props.focusSession.time.start * 1000);
    let endTime = new Date(this.props.focusSession.time.end * 1000);
    return (
      <Tooltip
        key={startTime.getTime()}
        placement="right"
        title={
          "Focus Session" +
          ":\n" +
          startTime.getHours().toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          ":" +
          startTime.getMinutes().toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          " to " +
          endTime.getHours().toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          ":" +
          endTime.getMinutes().toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })
        }
        arrow
        followCursor
      >
        <Box
          sx={{
            position: "absolute",
            top: startTime.getMinutes() * oneMinute + "px",
            left: 0,
            height: (duration / 60) * oneMinute + "px",
            width: isOverlap ? "80%" : "100%",
            backgroundColor: "transparent",
            boxSizing: "border-box",
            border: "1px solid black",
            zIndex: 3,
            //pointerEvents: 'none',
          }}
        >
          {/* <Box sx={{position: 'relative', top: (start*oneMinute)+'px', height: '100%', width: '8px', background: "repeating-linear-gradient(45deg,#AAAFBBBB,#AAAFBBBB 10px,#5D626FBB 10px,#5D626FBB 20px);", zIndex: 10}} />
          <Box sx={{position: 'relative', top: (start*oneMinute)+'px', height: '100%', width: '8px', background: "repeating-linear-gradient(45deg,#AAAFBBBB,#AAAFBBBB 10px,#5D626FBB 10px,#5D626FBB 20px);", marginLeft: 'auto', zIndex: 10,}} />       */}
        </Box>
      </Tooltip>
    );
  }
}

export default FocusIndicator;
