import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const GoogleCallbackPage = () => {
  const search = useLocation().search;

  useEffect(() => {
    console.log("Google Callback Page");
    const code = new URLSearchParams(search).get("code");
    const state = new URLSearchParams(search).get("state");
    let mess = {
      code: code,
      state: state,
    };
    window.opener.postMessage(mess);
    setTimeout(() => {
      window.close();
    }, 1000);
  }, []);
  return (
    <div>
      <h2>Successfully Connected</h2>
      <p>This page will automatically close</p>
    </div>
  );
};
