import {
  Devices,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { Box, Collapse, Grid, IconButton, Typography } from "@mui/material";
import * as React from "react";
import { useState, useEffect } from "react";
import { formatEpoch, timeFromNow } from "../../settings/utils/settings.utils";
import { DeviceInfo, UserData } from "./userinfo.component";
import "./userinfo.styles.css";

interface UserInfoTableProps {
  userData: UserData[];
}

interface UserInfoTableRowProps {
  user: UserData;
}

interface DeviceRowProps {
  device: DeviceInfo;
  accountId: string;
  userName: string;
  lastRunTask?: {
    timestamp: number;
    dataProcessed: string;
  };
}

const DeviceRow = (props: DeviceRowProps) => {
  const { device, accountId, userName, lastRunTask } = props;
  const [expand, setexpand] = useState(false);
  return (
    <>
      <Grid
        container
        sx={{
          padding: "2% 0",
          alignItems: "center",
          borderBottom: "1px solid lightgray",
        }}
      >
        <Grid item xs={2} className="userinfo-table-cell">
          {accountId}
        </Grid>
        <Grid item xs={2} className="userinfo-table-cell">
          {userName}
        </Grid>
        <Grid item xs={2} className="userinfo-table-cell">
          {device.deviceId}
        </Grid>
        <Grid item xs={2} className="userinfo-table-cell">
          {device.name}
        </Grid>
        <Grid item xs={1} className="userinfo-table-cell">
          {device.os}
        </Grid>
        <Grid item xs={2} className="userinfo-table-cell">
          Last ran at{" "}
          {lastRunTask
            ? new Date(1000 * lastRunTask.timestamp).toLocaleString()
            : "NOT RUN YET"}
          {"\n"}
          <br />
          Data Processed - {lastRunTask ? lastRunTask?.dataProcessed : "N/A"}
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={() => setexpand(!expand)}>
            {expand ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={expand && device.agents?.length > 0} sx={{ width: "100%" }}>
        <Box display="block" width="100%">
          <Grid
            container
            width="100%"
            borderBottom={"1px solid lightgray"}
            sx={{ fontWeight: "bold", padding: "1% 0" }}
          >
            <Grid item xs={3} className="userinfo-table-cell">
              Agent ID
            </Grid>
            <Grid item xs={3} className="userinfo-table-cell">
              Agent Type
            </Grid>
            <Grid item xs={3} className="userinfo-table-cell">
              Agent Name
            </Grid>
            <Grid item xs={3} className="userinfo-table-cell">
              Last Synced
            </Grid>
          </Grid>
          {device.agents?.map((agent) => (
            <Grid container width="100%" borderBottom={"1px solid lightgray"}>
              <Grid item xs={3} className="agentinfo-table-cell">
                {agent.agentId}
              </Grid>
              <Grid item xs={3} className="agentinfo-table-cell">
                {agent.type}
              </Grid>
              <Grid item xs={3} className="agentinfo-table-cell">
                {agent.name}
              </Grid>
              <Grid item xs={3} className="agentinfo-table-cell">
                {new Date(1000 * agent.lastSyncTime).toLocaleString()}({timeFromNow(agent.lastSyncTime)} ago)
              </Grid>
            </Grid>
          ))}
        </Box>
      </Collapse>
    </>
  );
};

const UserInfoTableRow = (props: UserInfoTableRowProps) => {
  const { user } = props;
  return (
    <Grid container sx={{ padding: "2% 0", alignItems: "center" }}>
      {user.devices.map((device: DeviceInfo, index) => (
        <DeviceRow
          device={device}
          accountId={user.accountId}
          userName={user.name}
          lastRunTask={user.lastRunTask}
          key={index}
        />
      ))}
    </Grid>
  );
};

const UserInfoTableHeader = () => {
  return (
    <Grid
      container
      sx={{ backgroundColor: "#F3F4F8", padding: "2% 0", alignItems: "center" }}
    >
      <Grid item xs={2} className="userinfo-table-cell">
        Account ID
      </Grid>
      <Grid item xs={2} className="userinfo-table-cell">
        Name
      </Grid>
      <Grid item xs={2} className="userinfo-table-cell">
        Device ID
      </Grid>
      <Grid item xs={2} className="userinfo-table-cell">
        Device Name
      </Grid>
      <Grid item xs={1} className="userinfo-table-cell">
        Device OS
      </Grid>
      <Grid item xs={2} className="userinfo-table-cell">
        Last Run Task
      </Grid>
      <Grid item xs={1} className="userinfo-table-cell"></Grid>
    </Grid>
  );
};

const UserInfoTable = (props: UserInfoTableProps) => {
  const [userData, setuserData] = useState([] as UserData[]);
  useEffect(() => {
    console.log("PUD", props.userData);
    setuserData(props.userData);
  }, [props.userData]);
  useEffect(() => {
    console.log("UD", userData);
  }, [userData]);
  return (
    <Box
      width="100%"
      borderRadius="8px"
      overflow="hidden"
      borderBottom="1px solid lightgray"
    >
      <UserInfoTableHeader />
      {userData?.map((user, index) => (
        <UserInfoTableRow user={user} key={index} />
      ))}
    </Box>
  );
};

export default UserInfoTable;
