import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const TOSIntro = (): JSX.Element => {
  return (
    <>
      <Typography variant={"body1"}>
        <p>
          <Typography variant="body1">Last Modified: August 9, 2022</Typography>
        </p>
        <p>
          <Typography variant={"body1"}>
            Yappx Labs Inc., A Delaware Company, owns and controls myTiro.ai.
          </Typography>
        </p>
        <p>
          <Typography variant={"body1"}>
            These terms of service (the “Agreement”) establishes the terms and
            conditions that apply to all users (each and collectively, “You”,
            “Your”, or “User”) of the “Site” (myTiro's website located at
            myTiro.ai and yappxlabs.com, plug-ins, extensions, downloadable
            software at github.com/mytiro, mobile applications, and tablet
            computer applications ) and/or “Service” (use of the Internet web
            pages and mobile application pages, plug-ins, extensions, data,
            analyses, screens, emails, reports, documentation and other
            information of any kind that are accessible through the Site, as
            well as all updates, enhancements and modifications thereto, and all
            intellectual property contained therein) collectively referred to as
            the “Offerings”.
          </Typography>
        </p>

        <p>
          <Typography variant={"body1"}>
            “You”, “Your, or “User” means any individual or entity using our
            Offerings. If you use our Offerings on behalf of another person or
            entity, you represent and warrant that you’re authorized to accept
            the Agreement on that person’s or entity’s behalf, that by using our
            Offerings you’re accepting the Agreement on behalf of that person or
            entity, and that if you, or that person or entity, violates the
            Agreement, you and that person or entity agree to be responsible to
            us.
          </Typography>
        </p>
        <p>
          <Typography variant={"body1"} fontWeight={"bold"}>
            By using our Offerings, you are accepting this Agreement in full and
            your willingness to be bound by all the terms and conditions of this
            Agreement, as well as all Applicable Laws and Regulations. Do not
            use our Offerings if you do not agree to all of the terms and
            conditions of this Agreement.
          </Typography>
        </p>
        <p>
          <Typography variant={"body1"}>
            This Agreement can be changed, modified, supplemented, and/or
            updated by Yappx Labs Inc. ("YAPPX LABS INC", “YAPPX”, “MYTIRO.AI”,
            “MYTIRO”, "WE", "OUR", OR "US") at any time.
          </Typography>
        </p>
        <p>
          <Typography variant={"body1"} fontWeight={"bold"}>
            Your continued use of the Offerings after the modification of this
            Agreement means that you accept all such changes.
          </Typography>
        </p>
        <p>
          <Typography variant={"body1"}>
            Accordingly, you are advised to consult this Agreement each time you
            access our Offerings in order to view any changes to this Agreement.
          </Typography>
        </p>
        <p>
          <Typography variant={"body1"}>
            This Agreement was last modified as of the date indicated above.
          </Typography>
        </p>
      </Typography>
    </>
  );
};

export const TOSOfferings = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">Use of our Offerings</Typography>
      <p>
        <Typography variant="body1">
          You understand that myTiro uses third party vendors and hosting
          partners to provide the necessary hardware, software, networking,
          storage, and related technology required to provide the Offerings.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          Subject to the terms and conditions of this Agreement, myTiro.ai
          hereby grants to each User a limited revocable, non-exclusive,
          non-transferable license to access and use the Offerings.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          MyTiro.ai reserves the right at all times and without notice to: (i)
          restrict and/or terminate your access to the Offerings (or any portion
          thereof); and (ii) modify or discontinue providing the Offerings (or
          any portion thereof).
        </Typography>
      </p>
    </>
  );
};

export const TOSmyTiroAccount = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">Your myTiro Account</Typography>
      <p>
        <Typography variant="body1">
          Using our Offerings requires you to create an account on myTiro. You
          must be a human to create an account on myTiro. Accounts registered by
          "bots" or other automated methods are not permitted. You agree to
          provide us with complete and accurate information and to keep the
          information current so that we can communicate with you about your
          account.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          We may need to send you emails about your usage, reports, notable
          updates (like changes to our Terms of Service or Privacy Policy), or
          to let you know about legal inquiries or complaints we receive about
          the ways you use our Offerings so you can make informed choices in
          response.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          We may limit your access to our Offerings until we’re able to verify
          your account information, like your email address.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          When you create a myTiro.ai account or submit your email to join the
          waitlist, we consider that to be an inquiry about our Offerings, which
          means that we may also contact you to share more details about what we
          have to offer (i.e., marketing). You can opt out of the marketing
          communications, whether it’s an email, phone call, or text message.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          You are entirely responsible for maintaining the security and
          confidentiality of your account and password. You agree to notify
          myTiro immediately of any unauthorized use of your account or any
          other breach of security. To notify us, contact us at
          support@mytiro.ai.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          You are responsible for all use of the Offerings occurring under your
          account.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          myTiro will not be liable for any loss that you may incur as a result
          of you or someone else using your account, either with or without your
          knowledge. We’re not liable for any acts or omissions by you,
          including any damages of any kind incurred as a result of your acts or
          omissions.
        </Typography>
      </p>
    </>
  );
};

export const TOSMinAgeReq = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">Minimum Age Requiremnts</Typography>
      <p>
        <Typography variant="body1">
          Our Offerings are not directed to children. You’re not allowed to
          access or use our Offerings if you’re under the age of 18.
        </Typography>
      </p>
    </>
  );
};

export const TOSIntellectualProperty = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">Intellectual Property</Typography>
      <p>
        <Typography variant="body1">
          This Agreement does not transfer from myTiro to you any myTiro or
          third party intellectual property, and all right, title and interest
          in and to such property will remain (as between the parties) solely
          with myTiro.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          myTiro, the myTiro logo, and all other trademarks, service marks,
          graphics and logos used in connection with myTiro, the Offerings are
          trademarks or registered trademarks of myTiro.
        </Typography>
      </p>

      <p>
        <Typography variant="body1">
          The look and the feel of the Offerings is copyright© 2022 Yappx Labs
          Inc. All rights reserved. You may not duplicate, copy, or reuse any
          portion of the HTML/CSS or visual design elements without express
          written permission from myTiro.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          Other trademarks, service marks, graphics and logos used in connection
          with the Offerings may be the trademarks of other third parties. Your
          use of the Offerings grants you no right or license to reproduce or
          otherwise use any myTiro or third-party trademarks.
        </Typography>
      </p>

      <p>
        <Typography variant="body1"></Typography>
      </p>
    </>
  );
};

export const TOSResponsibilities = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">Your Responsibilities</Typography>
      <p>
        <Typography variant="body1">
          You may use the Offerings solely for lawful purposes.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          You may not use the Offerings in any manner that could damage,
          disable, overburden, or impair our servers or networks, or interfere
          with any other party's use and enjoyment of the Offerings.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          We want to keep myTiro safe for everyone. You agree to notify myTiro
          immediately if you gain unauthorized access to the Offerings, user
          accounts, or computer systems or networks, through hacking, password
          mining or any other means.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          We appreciate your help in responsibly disclosing security
          vulnerabilities to us.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          Without limiting any of the foregoing, you expressly agree that you
          shall not (and you agree not to allow any third party to):
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          <ul>
            <li>
              use, copy, install, transfer, reproduce, modify, hack, sell,
              resell, create derivative works, publicly display, duplicate, or
              distribute the Offerings or any portion thereof, use of the
              Offerings, or access to the Offerings except as specifically
              permitted by this Agreement;
            </li>
            <li>
              modify, adapt, or hack the Offerings or modify another website so
              as to falsely imply that it is associated with the Offerings,
              myTiro, or any other myTiro service.
            </li>
            <li>
              use any robot, spider, or other automatic device, process, or
              means to access the Offerings for any purpose, including
              monitoring or copying any of the material on the Offerings.
            </li>
            <li>
              install, transfer or distribute the Offerings to a third party
              without their knowledge, for example: installing the Offerings on
              an employee computer for the purpose of employee monitoring
              without their knowledge or consent;
            </li>
            <li>
              remove or alter any copyright, trademark or other proprietary
              rights notices contained in or on the Offerings or in or on any
              content or other material obtained through the Offerings;
            </li>
            <li>
              infringe or misappropriate the intellectual property rights of
              myTiro or any third party;
            </li>
            <li>
              create user accounts by automated means or under false or
              fraudulent pretences;
            </li>
            <li>
              create, distribute, or enable material that is, facilitates, or
              operates in conjunction with, malware, spyware, adware, or other
              malicious programs or code;
            </li>
            <li>
              reverse engineer, decompile, disassemble, decipher, or otherwise
              attempt to derive the source code for the Offerings or any related
              technology that is not open source;
            </li>
            <li>
              express or imply that any statements you make are endorsed by us,
              without our prior written consent in each instance;
            </li>
            <li>
              transmit (i) any content or information that is unlawful,
              fraudulent, threatening, abusive, libelous, defamatory, obscene or
              otherwise objectionable, or infringes on our or any third party's
              intellectual property or other rights, (ii) any material,
              non-public information about individuals or companies without the
              authorization to do so, (iii) any trade secret of any third party;
            </li>
            <li>
              use the Offerings for any illegal, inappropriate and/or
              unauthorized conduct, including without limitation, using the
              Offerings in violation of our or any third party's intellectual
              property or other proprietary or legal rights.
            </li>
          </ul>
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          We may take any legal action and implement any technical remedies to
          prevent the violation of these provisions and to enforce this
          Agreement. Our policy with respect to the collection and use of your
          personally identifiable information is set in our Privacy Policy. By
          accepting this Agreement, you acknowledge your agreement with our
          Privacy Policy.
        </Typography>
      </p>
    </>
  );
};

export const TOSWarranties = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">Disclaimer of Warranties</Typography>
      <p>
        <Typography variant="body1">
          The Offerings are provided on an "as is" and “as available” basis.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          myTiro and it's suppliers and licensors hereby disclaim all warranties
          of any kind, expressed or implied, including, without limitation, the
          warranties of merchantability, fitness for a particular purpose and
          non-infringement.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          Neither myTiro nor its suppliers and licensors, makes any warranty
          that the Offerings will be error free or that access thereto will be
          continuous or uninterrupted.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          Any material or data downloaded or otherwise obtained through the use
          of the Offerings is done at your own discretion and risk and you will
          be solely responsible of any damages or loss of data or email that
          results from the use of the Offerings.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          No advice or information whether oral or written, obtained by you from
          myTiro or through or from the Offerings will create any warranty not
          expressly stated in this Agreement.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          You understand that you download from, or otherwise obtain content or
          services through, the Offerings at your own discretion and risk.
        </Typography>
      </p>
    </>
  );
};

export const TOSLiability = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">Limitation of Liability</Typography>
      <p>
        <Typography variant="body1">
          In no event will myTiro, or its suppliers or licensors, be liable with
          respect to any subject matter of this Agreement under any contract,
          negligence, strict liability or other legal or equitable theory for:
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          <ol type="i">
            <li>any special, incidental or consequential damages;</li>
            <li>
              the cost of procurement for substitute products or services;
            </li>
            <li>for interruption of use or loss or corruption of data; or</li>
            <li>
              for any amounts that exceed $50 or the fees paid by you to myTiro
              under this Agreement during the twelve (12) month period prior to
              the cause of action, whichever is greater.
            </li>
          </ol>
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          myTiro shall have no liability for any failure or delay due to matters
          beyond their reasonable control.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          The foregoing shall not apply to the extent prohibited by applicable
          law.
        </Typography>
      </p>
    </>
  );
};

export const TOSIndemnification = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">Indemnification</Typography>
      <p>
        <Typography variant="body1">
          You agree to indemnify and hold harmless myTiro, its subsidiaries,
          affiliates, officers, agents, employees, contractors, and it's
          licensors, and their respective directors, officers, employees and
          agents from and against any and all demands, claims and expenses,
          including attorneys' fees, arising out of your use of the Offerings,
          including but not limited to your violation of this Agreement or your
          violation of any rights of a third party.
        </Typography>
      </p>
    </>
  );
};

export const TOSTermination = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">Termination</Typography>
      <p>
        <Typography variant="body1">
          myTiro may terminate your access to all or any part of our Offerings
          at any time, with or without cause, with or without notice, effective
          immediately.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          If you wish to terminate this Agreement or your myTiro.ai account (if
          you have one), you may simply discontinue using our Offerings.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          All of your data/content will immediately be non-accessible to you
          from the Offerings upon termination. This information cannot be
          recovered once your account is terminated.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          Following termination of the Offerings, only aggregated and anonymised
          data will be kept by myTiro for the purpose of machine learning and
          statistics.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          All provisions of this Agreement which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </Typography>
      </p>
    </>
  );
};

export const TOSPayment = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">Fees, Payment, and Renewal</Typography>
      <p>
        <Typography variant="body1">
          Currently, myTiro does not charge for the use of it’s Offerings.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          In the future, myTiro intends to implement a subscription based
          pricing for all it’s users.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          myTiro reserves the right to change it pricing policy at an
          appropriate time. Existing users will be notified in advance of any
          upcoming changes.
        </Typography>
      </p>
    </>
  );
};

export const TOSFeedback = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">Feedback</Typography>
      <p>
        <Typography variant="body1">
          We love hearing from you and are always looking to improve our
          Offerings. Please share your feedback at{" "}
          <a href={"mailto:support@mytiro.ai"} target="_blank">
            support@mytiro.ai
          </a>
          .
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          When you share comments, ideas, or feedback with us, you agree that
          we’re free to use them without any restriction or compensation to you.
        </Typography>
      </p>
    </>
  );
};

export const TOSSanctions = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">US Economic Sanctions</Typography>
      <p>
        <Typography variant="body1">
          You expressly represent and warrant that your use of our Offerings is
          not contrary to applicable U.S. Sanctions. Such use is prohibited, and
          myTiro reserves the right to terminate accounts or access of those in
          the event of a breach of this condition.
        </Typography>
      </p>
    </>
  );
};

export const TOSMisc = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">Miscellaneous</Typography>
      <p>
        <Typography variant="body1">
          This Agreement constitutes the entire agreement between myTiro and you
          concerning the subject matter hereof, and they may only be modified by
          a written amendment signed by an authorized executive of myTiro, or by
          the posting by myTiro of a revised version.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          The failure of myTiro to exercise or enforce any right or provision of
          the Agreement shall not constitute a waiver of such right or
          provision. This Agreement constitutes the entire agreement between you
          and myTiro and govern your use of the Offerings, superseding any prior
          agreements between you and myTiro (including, but not limited to, any
          prior versions of the Agreement).
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          If any provision of this Agreement is found to be invalid, the other
          provisions of this Agreement will remain in full force and effect. Any
          claim or cause of action arising out of or related to use of the
          Offerings or this Agreement must be filed within six months after the
          claim or cause of action arose or be forever barred.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          myTiro has the right to cooperate fully with any law enforcement
          authorities or court order requesting or directing us to disclose the
          identity or other information of anyone using the Offerings. YOU WAIVE
          AND HOLD HARMLESS MYTIRO AND ITS AFFILIATES, LICENSEES, AND SERVICE
          PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF
          THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF,
          INVESTIGATIONS BY SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          This Agreement and any action related thereto will be governed,
          controlled, interpreted, and defined by and under the laws of the
          State of Delaware, without giving effect to the principles of conflict
          of laws. Delaware has been chosen by the parties as the governing law
          as Delaware is the jurisdiction of incorporation of Yappx Labs Inc.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          This Terms of Service was created from{" "}
          <a href={"https://wordpress.com/tos/"} target="_blank">
            WordPress.com's TOS
          </a>{" "}
          which is available under a Creative Commons Sharealike license.
        </Typography>
      </p>
    </>
  );
};
