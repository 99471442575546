import {
  Box,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState, FunctionComponent, useContext } from "react";
import { KeyboardArrowUp } from "@mui/icons-material";
import { AccountContext } from "../../accountcontext/account";
import { useNavigate } from "react-router";
import NotificationsMenu from "./notifications/notificationsmenu.component";
import { useName } from "../../core/username.hook";


interface ProfileTopRightProps {}

const ProfileTopRight: FunctionComponent<ProfileTopRightProps> = () => {
  const [showDropDown, setshowDropDown] = useState(false);
  const {displayName} = useName();
  //const [displayPicture, setdisplayPicture] = useState("");
  const { logout } = useContext(AccountContext);
  const navigate = useNavigate();

  const handleDropDown = (event: any) => {
    setshowDropDown(!showDropDown);
  };

  const handleLogout = (event: any) => {
    logout();
    navigate("/");
  }

  return (
    <Box
      sx={{
        height: "10vh",
        backgroundColor: "white",
        width: "100%",
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: 'space-between',
        paddingRight: "5%",
      }}
    >
      <ListItem
        sx={{
          width: "70%",
          minWidth: "max-content",
          alignItems: "right",
          position: 'relative',
        }}
        secondaryAction={
          <IconButton edge="end" aria-label="expand" onClick={handleDropDown}>
            {showDropDown ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
          </IconButton>
        }
      >
        {/* <ListItemAvatar>
          <Avatar src={displayPicture}>
          </Avatar>
        </ListItemAvatar> */}
        <NotificationsMenu />
        <ListItemText primary={displayName} sx={{whiteSpace: 'nowrap', maxWidth: '15vw', overflow: 'ellipsis'}} />
        <Box
          sx={{
            display: showDropDown ? "block" : "none",
            backgroundColor: "white",
            border: "1px lightgray solid",
            position: "absolute",
            bottom: "-20px",
            right: 0,
          }}
        >
          <Button onClick={handleLogout}>
            <Typography variant="body2">Logout</Typography>
          </Button>
        </Box>
      </ListItem>
    </Box>
  );
};

export default ProfileTopRight;
