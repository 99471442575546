import {
  Box,
  Button,
  FormControl,
  ThemeProvider,
  Typography,
} from "@mui/material";
import * as React from "react";
import "../../onboarding/components/onboarding.styles.css";
import theme from "../../../theme/theme";
import { useEffect, useRef, useState } from "react";

interface LoginProps {
  credentials: { email: string | null; password: string | null };
  onEmailChange: (email: string | null) => void;
  onPasswordChange: (password: string | null) => void;
  onForgetPassword: () => void;
  initLogin: () => void;
  errorMessage: string;
  showErrorMessage: Boolean;
}

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function Login(props: LoginProps) {
  const handleEmail = (event: React.FormEvent<HTMLInputElement>) => {
    props.onEmailChange(event.currentTarget.value);
  };

  const handlePassword = (event: React.FormEvent<HTMLInputElement>) => {
    props.onPasswordChange(event.currentTarget.value);
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    props.initLogin();
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignSelf: "center",
          backgroundColor: "white",
          padding: "2% 6%",
          borderRadius: "20px",
          width: "100%",
        }}
      >
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Log in to <span style={{ color: "#7E4BB7" }}>myTiro</span>
        </Typography>
        {/* <Typography variant="body1" sx={{ color: "#7F8493" }}>
          Use your registered email ID to log in
        </Typography> */}
        <FormControl
          component={"form"}
          variant="standard"
          sx={{
            width: "100%",
            padding: 0,
            justifyContent: "center",
            marginTop: "4rem",
          }}
          onSubmit={onSubmit}
        >
          <label>
            Email
            <br />
            <input
              type="text"
              placeholder="Enter email"
              value={props.credentials.email ?? ""}
              onChange={handleEmail}
            />
          </label>
          <label>
            Password
            <br />
            <input
              type="password"
              className="password"
              placeholder="Enter password"
              value={props.credentials.password ?? ""}
              onChange={handlePassword}
            />
            {/* <label className="forgotpassword" onClick={props.onForgetPassword}>Forgot your password?</label> */}
          </label>
          <Typography
            variant="body2"
            className="forgotpassword"
            color="primary"
          >
            <Button
              className="forgot-button"
              onClick={() => props.onForgetPassword()}
            >
              Forgot password?
            </Button>
          </Typography>
          <Box
            sx={{
              display: props.showErrorMessage ? "block" : "none",
              height: "2.4em",
              lineHeight: "1.6em",
            }}
          >
            <Typography
              variant="body2"
              sx={{ color: "red", textAlign: "center", marginTop: "6%" }}
            >
              {props.errorMessage}
            </Typography>
          </Box>
          <Button
            type="submit"
            variant="contained"
            className="myButton"
            sx={{
              margin: "15px 0",
              marginTop: "45px",
              backgroundColor: "#643992",
            }}
          >
            Log In
          </Button>
          {/* <p className="text">
                Don't have an account? <a>Sign Up</a>
              </p> */}
        </FormControl>
      </Box>
    </ThemeProvider>
  );
}
