export interface CreateGoalDto {
  startTime: number;
  endTime: number;
  name: string;
  description: string;
  userDefined: boolean;
  templateId?: string;
  templateInstanceId?: number;
  templateSortIndex?: number;
  referenceData?: GoalTemplateReferenceData;
  targets: CreateTargetDto[];
  dimensions: CreateDimensionDto[];
}

export interface CreateTargetDto {
  type: TargetType;
  quantum: number;
  targetFunction: TargetFunction;
}

export interface CreateDimensionDto {
  type: DimensionType;
  value: string;
}

export enum TargetType {
  TIME = "time",
  FOCUS = "focus",
  ACTIVITIES = "activities",
}

export enum DimensionType {
  DEVICE = "device",
  CATEGORY = "category",
  APPLICATION = "application",
  ALL = "all",
  USER_CATEGORY = "user_category",
}

export enum QuantumType {
  TIME = "time",
  COUNT = "count",
}

export enum TargetFunction {
  MIN = "min",
  MAX = "max",
}

export interface ViewGoalDto {
  name: string;
  description: string;
  editable: boolean;
  endTime: number;
  id: number;
  importance: number;
  startTime: number;
  goalMet: boolean;
  pinned: boolean;
  tags: string[];
  targets: ViewTargetDto[];
  dimensions: ViewDimensionDto[];
  templateId: string;
  templateInstanceId: number;
  userDefined: boolean;
}

enum GoalOutcomeComparator {
  gte = "gte",
  lte = "lte",
  eq = "eq",
}

export interface ViewTargetDto {
  target: TargetType;
  targetQuantum: number;
  actualQuantum: number;
  quantumType: QuantumType;
  targetFunction: TargetFunction;
  targetMet: boolean;
}

export interface ViewDimensionDto {
  dimension: DimensionType;
  value: string;
}

export interface ViewCustomTemplateOptionDto {
  [dimenstion: string]: {
    [dimensionValue: string]: {
      time: {
        target: TargetType;
        value: number;
        targetFunction: TargetFunction;
      };
      focus: {
        target: TargetType;
        value: number;
        targetFunction: TargetFunction;
      };
      activities: {
        target: TargetType;
        value: number;
        targetFunction: TargetFunction;
      };
    };
  };
}

export interface CustomTemplateOptions {
  dimensionType: DimensionType;
  dimensionValue: string;
  targets: {
    [target: string]: {
      target: TargetType;
      value: number;
      targetFunction: TargetFunction;
    };
  };
}

export interface GoalTemplate {
  accountId: string;
  templateId: string;
  templateName: string;
  templateDescription: string;
  templateType: TemplateTypes;
  templateSortIndex: number;
  enabled: boolean;
  goalCreated?: boolean;
  targets: {
    target: TargetType;
    value: number;
    previousValue: number;
    enabled: boolean;
    targetFunction: TargetFunction;
  }[];
  dimensions: { dimension: DimensionType; value: string }[];
  referenceData: GoalTemplateReferenceData;
  dayTimestamp: number;
  id: number;
}

export interface ProcessedTemplate {
  id: number;
  templateId: string;
  templateName: string;
  templateDescription: string;
  targets: {
    [target: string]: {
      value: number;
      prevValue: number;
      enabled: boolean;
      targetFunction: TargetFunction;
    };
  };
  templateSortIndex: number;
  enabled: boolean;
  goalCreated: boolean;
  templateType: TemplateTypes;
}

export interface GoalTemplateReferenceData {
  targets: {
    target: TargetType;
    value: number;
    targetFunction: TargetFunction;
  }[];
}

export enum TemplateTypes {
  USER_DEFINED = "user_defined",
  MYTIRO_DEFINED = "mytiro_defined",
  AUTO_GENERATED = "auto_generated",
}
