import { CognitoUserSession } from "amazon-cognito-identity-js";
import { getSession, handleSessionNotFound } from "../accountcontext/account";
export interface Account {
  id: string;
  name?: string;
  email: string;
  profile?: Profile;
}

export interface Profile {
  id?: string;
  firstName: string;
  lastName: string;
  timezone: string;
  firstLogin?: boolean;
  nativeAgentConnected?: boolean;
  browserAgentConnected?: boolean;
  googleMailConnected?: boolean;
  googleCalendarConnected?: boolean;
  onboardingComplete?: boolean;
  onboardedAtTimestamp?: number;
  created?: string;
  updated?: string;
  customizations?: {};
}

const BASE_URL = "/api/user";

export const getAccount = (id: string, noCache = false): Promise<Account> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        const headers: any = {
          Authorization: `Bearer ${idToken}`,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        };
        if (noCache) {
          headers["Cache-Control"] = "no-cache";
          headers["Pragma"] = "no-cache";
        }
        const requestOptions: RequestInit = {
          method: "GET",
          headers: headers,
        };
        const url = `${BASE_URL}/account/${id}`;
        fetch(url, requestOptions)
          .then((response) => {
            console.log(response);
            if (response.ok) return response;
            else if (response.status === 404) {
              throw new Error("NOT_FOUND");
            } else {
              throw new Error("Something went wrong");
            }
          })
          .then((response) => response.text())
          .then((response) => JSON.parse(response))
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      })
      .catch((err) => {
        handleSessionNotFound(err);
        reject(err);
      });
  });
};

export const createNewAccount = (account: Account): Promise<Account> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        const requestOptions: RequestInit = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify(account),
        };
        const url = `${BASE_URL}/account`;
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((response) => JSON.parse(response))
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

export const createProfile = (
  profile: Profile,
  id: string
): Promise<Profile> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const requestOptions: RequestInit = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify(profile),
        };
        const url = `${BASE_URL}/account/${id}/profile`;
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((response) => JSON.parse(response))
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

export const getProfile = () => {
  return new Promise<Profile>((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        const url = `${BASE_URL}/account/${username}/profile`;
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      })
      .catch(handleSessionNotFound);
  });
};

export const markOnboarded = (id: string): Promise<Account> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const requestOptions: RequestInit = {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        const url = `${BASE_URL}/account/${id}/onboarded`;
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((response) => JSON.parse(response))
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};
