import { CognitoUserSession } from "amazon-cognito-identity-js";
import {
  getSession,
  handleSessionNotFound,
} from "../../accountcontext/account";
import { ScoringValuesDto } from "./scores.model";

const BASE_USAGE_URL = `/api/usage`;

// export const loadScores = async (
//     startTime: number,
//     endTime: number) => {
//     const scoresData: ScoringValuesDto[] = await getScores(startTime, endTime)
//     return
// }

export const getScores = async (
  startTime: number,
  endTime: number
): Promise<ScoringValuesDto[]> => {
  var params = [
    ["startTime", startTime.toString()],
    ["endTime", endTime.toString()],
  ];
  const searchParams = new URLSearchParams(params).toString();
  const url = `/scoring/scores?${searchParams}`;
  try {
    const ret = (await callApi(url, {
      method: "GET",
    })) as ScoringValuesDto[];
    return ret;
  } catch {
    return [];
  }
};

const session = async (): Promise<{ token: string; username: string }> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        resolve({ token: idToken, username: username });
      })
      .catch((err) => {
        console.log(err);
        handleSessionNotFound(err);
      });
  });
};

const callApi = async (api: string, reqOpts: RequestInit) => {
  const { token, username } = await session();
  reqOpts.headers = { ...reqOpts.headers, Authorization: `Bearer ${token}` };
  return new Promise((resolve, reject) => {
    const url = `${BASE_USAGE_URL}${api}`;
    fetch(url, reqOpts)
      .then((response) => response.text())
      .then((result) => JSON.parse(result))
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export const useScores = () => {};
