import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogTitle,
  Divider,
  Stack,
  TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  CategoryType,
  ItemType,
  SaveUpdateCategoryDto,
  UserItemDto,
} from "../models/custom-category.model";
import {
  getUserItems,
  saveUpdateUserCategory,
} from "../util/custom-category.service";
import { CustomTemplateOptions } from "../../../../services/goals/goals.model";
import { LogoLoader } from "../../../loader/loader.component";
import CloseIcon from "@mui/icons-material/Close";
export interface CustomCategoryDialogProps {
  open: boolean;
  selectedValue?: SaveUpdateCategoryDto | null;
  onClose: (value: SaveUpdateCategoryDto | null) => void;
  template: CustomCategoryDialogTemplate;
}

export interface CustomCategoryDialogTemplate {
  type: string;
  title: string;
  buttonText: string;
}

export const CustomCategoryDialogTemplateTypes = {
  create: {
    type: "create",
    title: "Create Custom Category",
    buttonText: "Create",
  },
  edit: {
    type: "edit",
    title: "Edit Custom Category",
    buttonText: "Update",
  },
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const CustomCategoryDialog = (props: CustomCategoryDialogProps) => {
  const { onClose, selectedValue, open } = props;
  const [loading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState<string>("");
  const [userItems, setUserItems] = useState<UserItemDto[]>([]);

  const [appDomainList, setAppDomainList] = useState<UserItemDto[]>([]);

  // useEffect(() => {
  //   fetchAppDomainList();
  // }, []);

  useEffect(() => {
    if (props.open) {
      fetchAppDomainList();
    }
  }, [props.open]);

  useEffect(() => {
    setUserItems(props.selectedValue?.items ?? []);
    setCategoryName(props.selectedValue?.categoryName ?? "");
  }, [props.selectedValue]);

  const handleClose = (value?: SaveUpdateCategoryDto) => {
    // console.log("here2");
    setCategoryName("");
    setUserItems([]);
    onClose(value ?? null);
  };

  const fetchAppDomainList = async () => {
    const value = await getUserItems();
    setAppDomainList(value);
  };

  const handleOnChangeCategoryName = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCategoryName(event.target.value);
  };

  const onSaveUpdateCategory = async () => {
    setLoading(true);
    const categoryDto: SaveUpdateCategoryDto = {
      id: props.selectedValue?.id,
      categoryName: categoryName,
      categoryType: CategoryType.USER_CATEGORY,
      items: userItems,
    };
    const value = (await saveUpdateUserCategory(
      categoryDto
    )) as SaveUpdateCategoryDto;
    handleClose(value);
    // console.log("here");
    await fetchAppDomainList();
    setLoading(false);
  };

  return (
    <Dialog
      onClose={() => handleClose()}
      open={open}
      fullWidth
      maxWidth="md"
      sx={
        {
          // width: "75%",
          // paddingBottom: "200",
        }
      }
    >
      <DialogTitle>
        <Box sx={{ position: "relative" }}>
          {props.template.title}
          <CloseIcon
            onClick={() => handleClose()}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              ":hover": { cursor: "pointer" },
            }}
          ></CloseIcon>
        </Box>
      </DialogTitle>

      <Stack spacing={3} sx={{}} alignItems={"center"} padding="20px">
        <LogoLoader loading={loading} />
        <TextField
          id="custom-category-name"
          label="Name"
          variant="outlined"
          // required
          value={categoryName}
          disabled={props.template === CustomCategoryDialogTemplateTypes.edit}
          autoFocus={
            props.template === CustomCategoryDialogTemplateTypes.create
          }
          onChange={handleOnChangeCategoryName}
          style={{
            width: "75%",
          }}
        />
        <Autocomplete
          multiple
          disableClearable={true}
          // limitTags={2}
          id="checkboxes-tags-demo"
          options={appDomainList.sort((a, b) =>
            a.itemType.localeCompare(b.itemType)
          )}
          groupBy={(appDomain) => {
            const value =
              appDomain.itemType === ItemType.DESKTOP_APP
                ? "Desktop App"
                : appDomain.itemType === ItemType.DOMAIN
                ? "Domain"
                : "Mobile App";
            return value;
          }}
          getOptionLabel={(appDomainList) => appDomainList.itemIdentifier}
          disableCloseOnSelect
          filterSelectedOptions
          // getOptionLabel={(option) => option.appName}
          value={userItems}
          onChange={(event, value) => setUserItems(value)}
          renderTags={(value: readonly UserItemDto[], getTagProps) =>
            value.map((option: UserItemDto, index: number) => (
              <Chip
                variant="filled"
                label={option.itemIdentifier}
                // onDelete={handleChipDelete}
                {...getTagProps({ index })}
              />
            ))
          }
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.itemIdentifier}
              </li>
            );
          }}
          style={{ width: "75%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus={
                props.template === CustomCategoryDialogTemplateTypes.edit
              }
              label={
                props.template === CustomCategoryDialogTemplateTypes.create
                  ? "Add apps/domains"
                  : "Update apps/domains"
              }
              sx={{
                caretColor: "black",
              }}
            />
          )}
        />
        <Button
          variant="contained"
          style={{
            width: "20%",
          }}
          onClick={onSaveUpdateCategory}
        >
          {props.template.buttonText}
        </Button>
      </Stack>
    </Dialog>
  );
};
