import {
  Box,
  Collapse,
  Grid,
  IconButton,
  IconButtonProps,
  styled,
  Typography,
} from "@mui/material";
import "../../landingpage.styles.css";
import { MiniGoalCard } from "./mini-goalcard.component";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { ViewGoalDto } from "../../../../services/goals/goals.model";
import { appState } from "../../../../services/app.service";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface GoalSummaryProps {
  goalData: ViewGoalDto[];
  deleteGoal?: (goalId: number) => void;
  pinGoal?: (goalId: number, pinned: boolean) => void;
  date: DateTime;
  expanded: boolean;
  currentDay: boolean;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const GoalSummary = (props: GoalSummaryProps) => {
  const [todaysGoals, setTodaysGoals] = useState<ViewGoalDto[]>([]);
  const [expanded, setExpanded] = useState(props.expanded);

  const today = props.date;

  const firstName = `${appState.get()?.account.profile?.firstName}'s`;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    // console.log("Goal Data: ", props.goalData);
    setTodaysGoals(props.goalData);
  }, [props.goalData]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={"#FAF2DC"}
      sx={{ padding: "2%", borderRadius: "12px" }}
      // padding="1%"
      width="100%"
    >
      <Box
        sx={{
          ":hover": {
            cursor: "pointer",
          },
        }}
        display="flex"
        flexDirection="row"
        alignItems={"center"}
        marginBottom="2%"
        onClick={handleExpandClick}
      >
        {/* <img src="/assets/Tips.png" height="24px" /> */}
        <Typography variant="body1Bold" marginLeft={"2%"}>
          {firstName} myTiro Goals for{" "}
          {`${today.monthLong} ${today.day}, ${today.year}`}
        </Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
        <Typography variant="body2">
          Click to {expanded ? "collapse" : "expand"}
        </Typography>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box sx={{ width: "100%" }}>
          {todaysGoals.length > 0 ? (
            <Grid container spacing={2}>
              {todaysGoals.map((goal: ViewGoalDto, index: number) => (
                <Grid
                  item
                  lg={4}
                  md={6}
                  xs={12}
                  key={goal.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <MiniGoalCard
                    goal={goal}
                    deleteGoal={props.deleteGoal}
                    pinGoal={props.pinGoal}
                    currentDay={props.currentDay}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box
              sx={{
                margin: "auto",
                height: "200px",
                borderRadius: "8px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
                border: "1px dashed #AAAFBB",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "light", color: "#AAAFBB", margin: "auto" }}
              >
                No Goals set for the day
              </Typography>
            </Box>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};
