export const glossaryContent: GlossaryContent[] = [
    {
        term: "Digital Devices",
        definition: "Windows or macOS computers, Android phones, Chrome, Firefox, Edge, and Brave browsers are digital devices.",
        examples: [

        ]
    },
    {
        term: "Using digital devices from hh:mm to hh:mm",
        definition: "myTiro logs the time when you start and stop using any of the defined digital devices.  The day starts at 00:01 AM and ends at 11:59 PM.",
        examples: [

        ]
    },
    {
        term: "Breaks",
        definition: "Anytime you are not using digital devices that are syncing with myTiro is categorized as a break.  The length and number of breaks vary for each individual.",
        examples: [
            
        ]
    },
    {
        term: "Activity",
        definition: "Active attention of the user on application/webpage/device at any given time as calculated by myTiro.",
        examples: [
           
        ]
    },
    {
        term: "Switch/Switching",
        definition: "Anytime users change their activity across applications on any synced digital devices, it is counted as a switch.  For example, switching from Word to Excel is counted as a switch but accessing different emails on www.gmail.com is not a switch.  “Switching between applications/devices/webpages” and “accessing an application/devices/webpages” are used interchangeably. ",
        examples: [

        ]
    },
    {
        term: "Uninterrupted time",
        definition: "Time spent by the user on any computer application, mobile application, or browser tab without switching out of that specific activity.",
        examples: [

        ]
    },
    {
        term: "Average switching time",
        definition: "Total time on digital devices divided by the number of times the user switched.",
        examples: [

        ]
    },
    {
        term: "Focus session",
        definition: "Uninterrupted time of at least 20 minutes (calculated based on a proprietary algorithm) within an activity is classified as a focus session.  The 20-min threshold is based on available scientific papers.",
        examples: [

        ]
    },
    {
        term: "Email",
        definition: "Accessing Gmail, Outlook, Protonmail, Hotmail, Yahoo, etc., via a native application on Windows or Mac, a browser, or a mobile device is tagged as Email.",
        examples: [

        ]
    },
    {
        term: "Office Apps",
        definition: "Accessing Microsoft Office or Google Docs via a native application on Windows or Mac, a browser, or a mobile device is tagged as Office Apps.",
        examples: [

        ]
    },
    {
        term: "Meeting",
        definition: "Accessing Meet, Zoom, Teams, and similar meeting apps via a native application on Windows or Mac, a browser, or a mobile device is tagged as Meetings.",
        examples: [

        ]
    },
    {
        term: "Browsing",
        definition: "Accessing URLs that are neither Email, Office Apps, or Meetings on any device is tagged under Browsing.",
        examples: [

        ]
    },
    {
        term: "Desktop Apps",
        definition: "Any Windows or Mac applications that are neither Email, Office Apps, or Meetings are tagged under Desktop Apps.",
        examples: [

        ]
    },
    {
        term: "Mobile Apps",
        definition: "Any mobile apps that are neither Email, Office Apps, or Meetings are tagged under Mobile Apps.",
        examples: [

        ]
    },

]

export interface GlossaryContent {
    term: string,
    definition: string,
    examples: string[],
}