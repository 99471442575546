import { Box, Grid, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import theme from "../../theme/theme";

import { useNavigate } from "react-router-dom";
import { getUsername } from "../../accountcontext/account";
import { createProfile, markOnboarded } from "../../components/profile.service";
import { LogoLoader } from "../../components/loader/loader.component";
import { UserDetailsForm } from "./userdetailsform/userdetailsform.component";

const yellowpattern = "/assets/forms/Sign_in_Sign_up_Pattern_3.svg";
const greenpattern = "/assets/forms/Sign_in_Sign_up_Pattern_2.svg";
const graypattern = "/assets/forms/Sign_in_Sign_up_Pattern_1.svg";

export const WelcomeOnboard = (props: {}) => {
  const [loading, setloading] = useState(false);
  const [fullname, setFullname] = useState("");
  const [errorMsg, seterrorMsg] = useState({
    message: "",
    showError: false as boolean,
  });
  const navigate = useNavigate();

  const splitFullName = (
    fullname: string
  ): { firstName: string; lastName: string } => {
    let names: string[] = fullname.split(" ");
    let firstName = names[0];
    let lastName = "";
    if (names.length > 1) {
      if (firstName.length === 1) {
        let firstNameDone = false;
        for (const [index, name] of names.entries()) {
          if (index === 0) continue;
          if (!firstNameDone && name.length === 1) {
            firstName = firstName + " " + name;
          } else if (!firstNameDone && name.length > 1) {
            firstName = firstName + " " + name;
            firstNameDone = true;
          } else {
            lastName = lastName + name + (index === names.length ? "" : " ");
          }
        }
      } else {
        for (let i = 1; i < names.length; i++)
          lastName = lastName + names[i] + (i === names.length - 1 ? "" : " ");
      }
    }
    return { firstName, lastName };
  };
  const callCreateProfile = (id: string, fullname: string) => {
    const { firstName, lastName } = splitFullName(fullname);
    const timezoneOffset = "" + new Date().getTimezoneOffset() * -1;
    return createProfile(
      { firstName: firstName, lastName: lastName, timezone: timezoneOffset },
      id
    );
  };

  const onFullnameChange = (fullname: string): void => {
    setFullname(fullname);
  };

  useEffect(() => {
    if (fullname.length > 0) {
      seterrorMsg({
        message: "",
        showError: false,
      });
    }
  }, [fullname]);

  const submitUserDetails = async () => {
    if (fullname.length === 0) {
      seterrorMsg({
        message: "Please enter your full name",
        showError: true,
      });
    } else {
      try {
        const username = await getUsername();
        await callCreateProfile(username, fullname);
        await markOnboarded(username);
        navigate("/");
      } catch (err: any) {
        console.log(err);
        seterrorMsg({
          message: err,
          showError: true,
        });
      } finally {
        setloading(false);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <LogoLoader loading={loading} />
      <div
        style={{
          backgroundColor: "#fff",
          width: "100vw",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box
          sx={{
            height: "10vh",
            width: "100%",
            padding: "1% 4%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img src="/assets/Logo_login.svg" height="80%" />
        </Box>
        <Box
          sx={{ position: "absolute", top: "74vh", left: "-200px", zIndex: 0 }}
        >
          <img src={yellowpattern} />
        </Box>
        <Box
          sx={{ position: "absolute", top: "25vh", right: "-200px", zIndex: 0 }}
        >
          <img src={greenpattern} />
        </Box>
        <Box
          sx={{ position: "absolute", top: "20vh", left: "30vw", zIndex: 0 }}
        >
          <img src={graypattern} style={{ zIndex: 0 }} />
        </Box>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "100vw",
            position: "relative",
            zIndex: 1,
            height: "90vh",
            overflow: "auto",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            xl={4}
            sx={{
              backgroundColor: "white",
              padding: 0,
              boxShadow: " 0px 10px 80px 0px #282C320F",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <UserDetailsForm
              onSubmit={submitUserDetails}
              errorMsg={errorMsg}
              onFullnameChange={onFullnameChange}
              fullName={fullname}
            />
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
};
