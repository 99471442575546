import {
  Box,
  Container,
  Divider,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import theme from "../../theme/theme";
import {
  PrivacyInformation,
  PrivacyInfoShare,
  PrivacyIntroduction,
  PrivacyProtection,
  PrivacyTldr,
} from "./privacy-sections.component";
const yellowpattern = "/assets/forms/Sign_in_Sign_up_Pattern_3.svg";
const greenpattern = "/assets/forms/Sign_in_Sign_up_Pattern_2.svg";
const graypattern = "/assets/forms/Sign_in_Sign_up_Pattern_1.svg";

export const PrivacyPage = (): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        bgcolor={theme.palette.secondary.main}
        display="block"
        height="100vh"
        width="100vw"
        position="relative"
        padding="2%"
        overflow="hidden"
      >
        <Box
          sx={{
            position: "absolute",
            top: "74vh",
            left: "-200px",
            zIndex: 0,
          }}
        >
          <img src={yellowpattern} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "25vh",
            right: "-200px",
            // zIndex: 0,
          }}
        >
          <img src={greenpattern} />
        </Box>
        {/* <Box sx={{ position: "absolute", top: "20vh", left: "30vw" }}>
          <img src={graypattern} style={{ zIndex: 0 }} />
        </Box> */}
        <Container>
          <Box
            sx={{
              overflow: "auto",
              "::-webkit-scrollbar": {
                width: "0px",
                background: "transparent",
              },
            }}
          >
            <Typography variant="h4">Data Privacy Policy</Typography>
            <Box height="85vh">{<PrivacyPolicyText></PrivacyPolicyText>}</Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

const PrivacyPolicyText = () => {
  return (
    <Stack>
      {/* <PrivacyTldr />
      <Divider sx={{ width: "100%", margin: "50px" }} /> */}
      <PrivacyIntroduction />
      <Divider sx={{ width: "100%", margin: "50px" }} />
      <PrivacyInformation />
      <Divider sx={{ width: "100%", margin: "50px" }} />
      <PrivacyInfoShare />
      <Divider sx={{ width: "100%", margin: "50px" }} />
      <PrivacyProtection />
    </Stack>
  );
};
