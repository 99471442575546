import * as React from 'react';
import { Component } from 'react';

interface ErrorBoundaryProps {
    
}
 
interface ErrorBoundaryState {
    hasError: boolean
}
 
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps){
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error: any){
        return {hasError: true};
    }

    render() { 
        if(this.state.hasError){
            return <h1>Something went wrong</h1>
        }
        return this.props.children;
    }
}
 
export default ErrorBoundary;
