import { Dialog, DialogContent, Box, Tabs, Tab, IconButton, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import ErrorBoundary from '../../../errorboundary.component';
import { NotificationType } from './model/notifications.model';
import NotificationComponent from './notification.component';

interface AllNotificationsProps {
    onClose: (event: any, reason: string)=> void;
    open: boolean;
    notifications: NotificationType[];
    onActionClick: (link: string, id: number) => void;
}
 
const AllNotifications: React.FunctionComponent<AllNotificationsProps> = (props: AllNotificationsProps) => {
    const { onClose, open, notifications } = props;
    return ( 
        <Dialog open={open} onClose={onClose}>
        <DialogContent sx={{ height: "70vh", width: "100vw", maxWidth: '568px', borderRadius: '12px', padding: '5%'}}>
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Typography variant="h6">Your notifications</Typography>
              <IconButton onClick={(event: any)=>onClose(event, "Button clicked")}>
                  <img src="/assets/dashboardcards/Close.svg" height='90%'/>
              </IconButton>
          </Box>
          <ErrorBoundary>
            <Box
              display="block"
              borderTop="1px solid lightgray"
            >
              {notifications.map((notification, index) =>
              <NotificationComponent isAll onDismiss={(id)=>{}} notification={notification} key={index} onActionClick={props.onActionClick} />
              )}
            </Box>
          </ErrorBoundary>
        </DialogContent>
      </Dialog>
     );
}
 
export default AllNotifications;