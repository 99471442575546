// root service that all components use

import { DateTime } from "luxon";
import { Account } from "../components/profile.service";

export interface AppState {
  account: Account;
  username: string;
  isOnboarded: boolean;
  timestamp?: number;
  cognitoGroups: string[];
}

export const isAppInitialized = (): boolean => {
  const initState: string | null = localStorage.getItem("initState");
  if (initState === null || initState === undefined) {
    return false;
  }
  const prsedState = JSON.parse(initState);
  return prsedState;
};

export const isUserAdmin = (): boolean => {
  const cogGrounps = appState.get()?.cognitoGroups;
  if (cogGrounps) {
    return cogGrounps.indexOf("ADMIN") > -1;
  } else {
    return false;
  }
};

export const getAppState = (): AppState | undefined => {
  if (isAppInitialized()) {
    const appState = localStorage.getItem("appState");
    if (appState === null || appState === undefined) {
      return undefined;
    }
    const prsedState = JSON.parse(appState);
    return prsedState;
  } else {
    return undefined;
  }
};

export const setAppState = (appState: AppState): void => {
  console.log("App State Updated", appState);
  appState.timestamp = Date.now();
  localStorage.setItem("appState", JSON.stringify(appState));
};

export const setAppInitialized = (): void => {
  const initState: boolean = true;
  localStorage.setItem("initState", JSON.stringify(initState));
};

export const clearAppState = (): void => {
  localStorage.removeItem("appState");
  localStorage.removeItem("initState");
};

export const previousAppUrl = {
  get: (): string | null => {
    return localStorage.getItem("previousUrl");
  },
  set: (url: string): void => {
    localStorage.setItem("previousUrl", url);
  },
  clear: (): void => {
    localStorage.removeItem("previousUrl");
  },
};

export const appState = {
  get: (): AppState | undefined => {
    return getAppState();
  },
  set: (appState: AppState): void => {
    setAppState(appState);
  },
  clear: (): void => {
    clearAppState();
  },
};

export const OnboardingComputationInProgress = {
  get: (): { status: boolean; completedDateTime?: DateTime } => {
    const profile = appState.get()?.account?.profile;
    if (profile && profile.onboardingComplete && profile.onboardedAtTimestamp) {
      const onboardedAt = DateTime.fromSeconds(
        profile.onboardedAtTimestamp
      ).startOf("day");
      const today = DateTime.now().startOf("day");
      today.diff(onboardedAt, "days").toObject();
      const completedDateTime = onboardedAt.plus({ days: 1 });
      return {
        status: today.diff(onboardedAt, "days").days < 1,
        completedDateTime: completedDateTime,
      };
    }
    return { status: false, completedDateTime: undefined };
  },
};

export const onboardingRequired = (): boolean => {
  const appState = getAppState();
  if (appState === undefined) {
    return true;
  }
  return (
    (!appState.account.profile?.nativeAgentConnected &&
      !appState.account.profile?.browserAgentConnected &&
      !appState.account.profile?.googleMailConnected &&
      !appState.account.profile?.googleCalendarConnected) ??
    true
  );
};
