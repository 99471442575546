interface EnvironmentValues {
  production: boolean;
  cognitoUserPoolId: {
    UserPoolId: string;
    ClientId: string;
  };
  cognitoAppClientId: string;
  nativeAppLinks: { [platform: string]: string };
  browserExtLinks: { [platform: string]: string };
  mobileAppLinks: { [platform: string]: string };
}

const currentEnvironment = process.env.REACT_APP_ENV ?? "DEVELOPMENT";

//development
let environmentVariables: EnvironmentValues = {
  production: false,
  cognitoUserPoolId: {
    UserPoolId: "us-east-1_soBHaOthP",
    ClientId: "6huubc7hmnpbkvt5kmfohrptan",
  },
  cognitoAppClientId: "6huubc7hmnpbkvt5kmfohrptan",
  nativeAppLinks: {
    windows: "https://mytiro-dev.s3.amazonaws.com/dev/myTiro.dev.msi",
    macos: "https://mytiro-dev.s3.amazonaws.com/dev/mytiro_dev_v0.0.3.pkg",
  },
  browserExtLinks: {
    chrome:
      "https://chrome.google.com/webstore/detail/mytiro-dev/lfagbjkmiiiabajkhbgfeaagodapmjim?hl=en-GB",
    firefox: "https://mytiro-dev.s3.amazonaws.com/dev/mytiro_dev-0.0.8.xpi",
  },
  mobileAppLinks: {
    android:
      "https://play.google.com/store/apps/details?id=ai.mytiro.android.agent",
  },
};

if (currentEnvironment === "ALPHA") {
  environmentVariables = {
    production: false,
    cognitoUserPoolId: {
      UserPoolId: "us-east-1_soBHaOthP",
      ClientId: "6huubc7hmnpbkvt5kmfohrptan",
    },
    cognitoAppClientId: "6huubc7hmnpbkvt5kmfohrptan",
    nativeAppLinks: {
      windows: "https://mytiro-dev.s3.amazonaws.com/alpha/myTiro.alpha.msi",
      macos: "https://mytiro-dev.s3.amazonaws.com/alpha/mytiro_alpha_osx.pkg",
    },
    browserExtLinks: {
      chrome:
        "https://chrome.google.com/webstore/detail/mytiro-alpha/mlmolbloionkjebadegkipbmlfhibgdc?hl=en-GB",
      firefox:
        "https://mytiro-dev.s3.amazonaws.com/alpha/mytiro_alpha-0.0.8.xpi",
    },
    mobileAppLinks: {
      android:
        "https://play.google.com/store/apps/details?id=ai.mytiro.android.agent",
    },
  };
} else if (currentEnvironment === "BETA") {
  environmentVariables = {
    production: true,
    cognitoUserPoolId: {
      UserPoolId: "us-east-1_i2essy1hG",
      ClientId: "ghm3sqm0ifp0a6jbsca6ts076",
    },
    cognitoAppClientId: "ghm3sqm0ifp0a6jbsca6ts076",
    nativeAppLinks: {
      windows: "https://mytiro-dev.s3.amazonaws.com/beta/myTiro.msi",
      macos: "https://mytiro-dev.s3.amazonaws.com/beta/mytiro_beta_osx.pkg",
    },
    browserExtLinks: {
      chrome:
        "https://chrome.google.com/webstore/detail/mytiro/lhlmgglaaabocaikjlebjdnandcolhde?hl=en-GB",
      firefox: "https://mytiro-dev.s3.amazonaws.com/beta/mytiro_firefox.xpi",
    },
    mobileAppLinks: {
      android:
        "https://play.google.com/store/apps/details?id=ai.mytiro.android.agent",
    },
  };
}

export const environment = environmentVariables;

//alpha
// export const environment: EnvironmentValues = {
//   production: false,
//   cognitoUserPoolId: {
//     UserPoolId: "us-east-1_soBHaOthP",
//     ClientId: "6huubc7hmnpbkvt5kmfohrptan",
//   },
//   cognitoAppClientId: "6huubc7hmnpbkvt5kmfohrptan",
//   nativeAppLinks: {
//     windows: "https://mytiro-dev.s3.amazonaws.com/alpha/myTiro.alpha.msi",
//     macos: "https://mytiro-dev.s3.amazonaws.com/alpha/mytiro_alpha_osx.pkg",
//   },
//   browserExtLinks: {
//     chrome:
//       "https://chrome.google.com/webstore/detail/mytiro-alpha/mlmolbloionkjebadegkipbmlfhibgdc?hl=en-GB",
//     firefox: "https://mytiro-dev.s3.amazonaws.com/alpha/mytiro_alpha-0.0.8.xpi",
//   },
//   mobileAppLinks: {
//     android:
//       "https://play.google.com/store/apps/details?id=ai.mytiro.android.agent",
//   },
// };

//beta
// export const environment: EnvironmentValues = {
//   production: true,
//   cognitoUserPoolId: {
//     UserPoolId: "us-east-1_i2essy1hG",
//     ClientId: "ghm3sqm0ifp0a6jbsca6ts076",
//   },
//   cognitoAppClientId: "ghm3sqm0ifp0a6jbsca6ts076",
//   nativeAppLinks: {
//     windows: "https://mytiro-dev.s3.amazonaws.com/beta/myTiro.msi",
//     macos: "https://mytiro-dev.s3.amazonaws.com/beta/mytiro_beta_osx.pkg",
//   },
//   browserExtLinks: {
//     chrome:
//       "https://chrome.google.com/webstore/detail/mytiro/lhlmgglaaabocaikjlebjdnandcolhde?hl=en-GB",
//     firefox: "https://mytiro-dev.s3.amazonaws.com/beta/mytiro_firefox.xpi",
//   },
//   mobileAppLinks: {
//     android:
//       "https://play.google.com/store/apps/details?id=ai.mytiro.android.agent",
//   },
// };
