import { Box, ThemeProvider, Typography } from "@mui/material";
import theme from "../../theme/theme";
const yellowpattern = "/assets/forms/Sign_in_Sign_up_Pattern_3.svg";
const greenpattern = "/assets/forms/Sign_in_Sign_up_Pattern_2.svg";
const graypattern = "/assets/forms/Sign_in_Sign_up_Pattern_1.svg";

export const UnderMaintenance = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100vw"
        height="100vh"
        bgcolor={theme.palette.secondary.main}
        position="relative"
      >
        <Box
          sx={{ position: "absolute", top: "74vh", left: "-200px", zIndex: 0 }}
        >
          <img src={yellowpattern} />
        </Box>
        <Box
          sx={{ position: "absolute", top: "25vh", right: "-200px", zIndex: 0 }}
        >
          <img src={greenpattern} />
        </Box>
        <Box
          sx={{ position: "absolute", top: "20vh", left: "30vw", zIndex: 0 }}
        >
          <img src={graypattern} style={{ zIndex: 0 }} />
        </Box>
        <img src="/assets/logo_only.svg" height="120px" />
        <Typography variant="h4" sx={{ marginTop: "2%", fontWeight: "bold" }}>
          <span style={{ color: theme.palette.primary.main }}>
            myTiro
          </span> is under maintenance
        </Typography>
        <Typography variant="h6" sx={{marginTop: '2%'}}>We apologize for the inconvenience</Typography>
        <Typography variant="body1">
            you data is being tracked and is secure, while we add new feature and squash some bugs
        </Typography>
        <Typography variant="body2Bold" sx={{marginTop: '4%'}}>
            Estimated time: <span style={{color: theme.palette.primary.main}}>10 minutes</span>
        </Typography>
      </Box>
    </ThemeProvider>
  );
};
