import { Box, Typography } from "@mui/material";
import { LogoLoader } from "../loader/loader.component";
import * as React from "react";
import { useState, useEffect } from "react";

interface SettingsPageProps {
  title: string;
  subtitle: string;
  children?: React.ReactChild | React.ReactChild[];
  loading?: boolean;
}

export const SettingsPage = (props: SettingsPageProps) => {
  const [loading, setloading] = useState(false);
  useEffect(() => {
    if (props.loading !== undefined) {
      setloading(props.loading);
    }
  }, [props.loading]);

  return (
    <Box sx={{ display: "block", width: "100%" }}>
      <LogoLoader loading={loading} />
      <Box>
        <Typography variant="h6">{props.title}</Typography>
        <Typography variant="body2">{props.subtitle}</Typography>
      </Box>
      <div
        style={{
          height: "1px",
          borderTop: "1px dashed #D6DAE480",
          margin: "4% 0",
        }}
      />
      {props.children}
    </Box>
  );
};
