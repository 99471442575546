export interface GoalsRings {
  text: string;
  imageUrl: string;
}

export const goalsRings: GoalsRings[] = [
  {
    text: "Time: Choose the minimum or maximum time you want to spend in an application or a category. Its default type is minimum and is measured in minutes.",
    imageUrl: "assets/how-to/goals-howto/timefocusring.png",
  },
  {
    text: "Focus: Choose the minimum or maximum focus you want to achieve. Its default type is minimum and is measured in minutes.",
    imageUrl: "assets/how-to/goals-howto/focusring.png",
  },
  {
    text: "Switch: Choose the minimum or maximum times you want to open a specific application or category. Its default type is maximum and is measured as a number.",
    imageUrl: "assets/how-to/goals-howto/switchfocusring.png",
  },
];
