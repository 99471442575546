import { Link, Stack, Typography } from "@mui/material";
import { SupportTabContent } from "../../support-tab.component";
import { SupportsPage } from "../../supportpage.component";
// import { SupportTabContent } from "../understanding-tab.component";

interface RegisterBlogProps {}

export const RegisterBlog = (props: RegisterBlogProps) => {
  return (
    <>
      <Typography
        variant="h6"
        display="block"
        id={SupportTabContent.register.id}
      ></Typography>
      <SupportsPage
        title={SupportTabContent.register.title}
        subtitle={SupportTabContent.register.description}
        loading={false}
      >
        <RegisterPage></RegisterPage>
      </SupportsPage>
    </>
  );
};

interface RegisterProps {}

const RegisterPage = (props: RegisterProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="body1" display="block">
        myTiro follows a four-step process to register and catalog all of your
        activities across all your devices.
      </Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block">
        <b>Step 1:</b> Using a proprietary algorithm, myTiro periodically
        records what application or website you are active on that particular
        device. It is important to note that you must have myTiro installed
        across all of your devices to get the most accurate data and insights
        into your working habits. myTiro does not access content within a
        specific file, application, or webpage.
      </Typography>
      <Typography variant="body1" display="block">
        <b>Step 2:</b> On each individual device, myTiro tokenizes (a level of
        anonymization) the collected information and securely sends the data to
        myTiro servers hosted on AWS.
      </Typography>
      <Typography variant="body1" display="block">
        <b>Step 3:</b> Then, myTiro processes all received information from each
        of your devices. The processed data is anonymized and specific to each
        individual user. myTiro’s proprietary algorithm then digests the data
        and develops the activity timeline by understanding where and when the
        user was active, contextual information (e.g., category,
        focus/fragmented, etc.) and other key trends from the previous workday.
      </Typography>
      <Typography variant="body1" display="block">
        <b>Step 4:</b> User activity, including time spent on applications,
        goals, focused/fragmented time, when and where time was spent, and more
        is then shared with the user via the personalized myTiro dashboard.
      </Typography>
      <Typography variant="body1" display="block">
        <b>Remember:</b> myTiro must be installed and running on all of your
        devices, browsers, and cell phones, and a user must be logged into
        myTiro on all said devices to get the most accurate and up-to-date read
        on their activity and habits.
      </Typography>
      <Typography variant="body1" display="block">
        <Link href="/support?option=results">
          Next: Understand your myTiro output
        </Link>
      </Typography>
      <Typography variant="body1" display="block"></Typography>
    </Stack>
  );
};
