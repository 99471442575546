interface ReqBodyBase {
  integrationMethod: string;
  integrationProvider: string | null;
  email?: string;
}

const BASE_URL = "/api/integration";

let currentProvider = "";

export const getCurrentProvider = (): string => {
  return currentProvider;
};

export const setCurrentProvider = (provider: string) => {
  currentProvider = provider;
};

export const startIntegration = async (
  provider: string | null,
  access_token: string,
  accountId: string,
  onComplete: (status: boolean | undefined, message: string) => void,
  emailAddress: string | undefined = undefined
) => {
  const reqBodyBase: ReqBodyBase = {
    integrationMethod: "OAuth2",
    integrationProvider: provider,
    email: emailAddress,
  };

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${access_token}`);
  const requestOptions: RequestInit = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(reqBodyBase),
    redirect: "follow",
  };
  return fetch(
    `${BASE_URL}/account/${accountId}/integration/start`,
    requestOptions
  )
    .then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          openSigninWindow(data.url, onComplete);
        });
      } else {
        return response.json().then((error) => {
          console.log(error);
          onComplete(false, "Error retrieving client, please try again later");
          throw new Error("Error retrieving client, please try again later");
        });
      }
    })
    .catch((error) => {
      console.log(error);
      onComplete(false, "Error retrieving client, please try again later");
      throw error;
    });
};

const openSigninWindow = (
  url: string,
  onComplete: (status: boolean | undefined, message: string) => void
) => {
  let windowObjectReference: Window | null = null;
  const strWindowFeatures =
    "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";
  windowObjectReference = window.open(
    url,
    "myTiro Integration",
    strWindowFeatures
  );
  var closeCheckTimer = setInterval(function () {
    if (windowObjectReference && windowObjectReference.closed) {
      clearInterval(closeCheckTimer);
      onComplete(undefined, "");
    }
  }, 1000);
};

export const processIntegrationResponse = async (
  event: MessageEvent,
  accountId: string,
  access_token: string,
  intergation_provider: string
) => {
  const reqBodyBase: any = {
    integrationMethod: "OAuth2",
    integrationProvider: intergation_provider,
  };
  if ("code" in event.data) {
    let code = event.data.code;
    let state = event.data.state;
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let body = reqBodyBase;
    body.code = code;
    body.state = state;
    let raw = JSON.stringify(body);
    myHeaders.append("Authorization", "Bearer " + access_token);
    let requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    return fetch(
      `${BASE_URL}/account/${accountId}/integration/complete/`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            console.log("Integration complete:", data);
          });
        } else {
          return response.json().then((error) => {
            console.log(error);
            throw new Error(
              "Error completing integration, please try again later"
            );
          });
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  } else {
    throw new Error("Not an integration Message");
  }
};
