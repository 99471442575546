import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CreateGoalDto } from "../../../services/goals/goals.model";
import { InsightDto } from "../../../services/insight-v2/insights-v2.model";

interface InsightGoalProps {
  focusIncreaseInsight: InsightDto | undefined;
  timeSavingInsight: InsightDto | undefined;
  handleClick: (insight: InsightDto | undefined) => void;
}

export const InsightGoal = (props: InsightGoalProps) => {
  const [insightDto, setInsightDto] = useState<InsightDto>();
  const [goalSet, setGoalSet] = useState<boolean>(false);

  useEffect(() => {
    if (
      props.timeSavingInsight?.goalDtos &&
      props.focusIncreaseInsight?.goalDtos
    ) {
      setInsightDto(
        selectGoal(props.timeSavingInsight, props.focusIncreaseInsight)
      );
      setGoalSet(false);
    } else if (
      props.timeSavingInsight?.goalCreated ||
      props.focusIncreaseInsight?.goalCreated
    ) {
      setGoalSet(true);
    } else {
      const insight = props.timeSavingInsight?.goalDtos
        ? props.timeSavingInsight
        : props.focusIncreaseInsight;
      setInsightDto(insight);
      setGoalSet(false);
    }
  }, [props.timeSavingInsight, props.focusIncreaseInsight]);

  const selectGoal = (
    timeSavingInsight: InsightDto,
    focusIncreaseInsight: InsightDto
  ) => {
    const timeSavingTargets =
      timeSavingInsight?.goalDtos?.targets.reduce((acc, cur) => {
        return (acc += cur.quantum);
      }, 0) ?? 0;
    const focusIncreaseTargets =
      focusIncreaseInsight?.goalDtos?.targets.reduce((acc, cur) => {
        return (acc += cur.quantum);
      }, 0) ?? 0;

    if (timeSavingTargets > focusIncreaseTargets) {
      return timeSavingInsight;
    } else {
      return focusIncreaseInsight;
    }
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        paddingX={"10px"}
      >
        {/* <Box>
                <Typography variant="body1Bold">
                  Need help with the challenge?
                </Typography>
              </Box> */}
        <Box sx={{ mb: 1 }}>
          <Typography variant="body1">
            {goalSet
              ? "Challenge Goal has been set for today"
              : insightDto?.insight
              ? insightDto?.insight
              : "Goal Unavailable"}
          </Typography>
        </Box>
        <Box sx={{ empty: 1 }}>
          {!goalSet ? (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                props.handleClick(insightDto);
              }}
              disabled={!insightDto}
            >
              Set Goal
            </Button>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </>
  );
};
