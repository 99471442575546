import { Box, Grid, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { ErrorItem } from './errorlog.service';
import '../habits/habitstable.styles.css';
import theme from '../../../theme/theme';

const ErrorTableRow = (props: { error: ErrorItem }) => {
    const { id, accountId, error, timestamp } = props.error;
    const date = new Date(timestamp * 1000);
    return (
      <Grid container className="admin-tablerow">
        <Grid item xs={1} className="admin-tablecell">
          <Typography variant="body1">{id}</Typography>
        </Grid>
        <Grid item xs={3} className="admin-tablecell">
          <Typography variant="body1">{date.toLocaleTimeString()+", "+date.toDateString()+", "+date.getTimezoneOffset()}</Typography>
        </Grid>
        <Grid item xs={3} className="admin-tablecell">
          <Typography variant="body1">{accountId}</Typography>
        </Grid>
        <Grid item xs={5}>
            <Typography variant="body1">{error}</Typography>
        </Grid>
      </Grid>
    );
  };
  
  const ErrorTableHeader = () => {
    
      return (
        <Grid container className="admin-tableheader">
          <Grid item xs={1} className="admin-tableheadercell">
            <Typography variant="body1Bold">ID</Typography>
          </Grid>
          <Grid item xs={3} className="admin-tableheadercell">
            <Typography variant="body1Bold">Timestamp</Typography>
          </Grid>
          <Grid item xs={3} className="admin-tableheadercell">
            <Typography variant="body1Bold">Account ID</Typography>
          </Grid>
          <Grid item xs={5}>
              <Typography variant="body1Bold">Message</Typography>
          </Grid>
        </Grid>
      );
    };

export const ErrorLogTable = (props: {errors: ErrorItem[]}) => {
    const [errors, seterrors] = useState(props.errors);
    useEffect(() => {
        if(errors !== props.errors){
            seterrors(props.errors);
        }
    }, [props.errors]);

    return(
        <Box className="admin-table" marginTop="1%" sx={{backgroundColor: theme.palette.secondary.main}}>
            <ErrorTableHeader />
            {errors.map((error, index) => <ErrorTableRow key={index} error={error} />)}
        </Box>
    )
}
