export const formatDate = (date: string | undefined) => {
  if (date === undefined) return "";
  const newDate = new Date(date);
  return newDate.toLocaleString("en-us", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const formatEpoch = (date: number | undefined) => {
  if (date === undefined) return "";
  const newDate = new Date(date * 1000);
  return newDate.toLocaleString("en-us", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const timeFromNow = (d: number): string => {
  if (d) {
    const now = Math.floor(Date.now() / 1000);
    const diff = now - d;
    const years = Math.floor(diff / 31556926);
    const months = Math.floor(diff/3629743);
    const weeks = Math.floor(diff / 604800);
    const days = Math.floor(diff / 86400);
    const h = Math.floor(diff / 3600);
    const m = Math.floor((diff % 3600) / 60);
    const s = Math.floor((diff % 3600) % 60);

    const yearDisplay = years > 0 ? years + (years == 1 ? " year" : ' years') : undefined;
    const monthDisplay = months > 0 ? months + (months == 1 ? " month" : " months") : undefined; 
    const weekDisplay = weeks > 0 ? weeks + (weeks == 1 ? " week" : " weeks") : undefined;
    const dayDisplay = days > 0 ? days + (days == 1 ? " day" : " days" ) : undefined;
    const hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : undefined;
    const mDisplay =
      m > 0 ? m + (m == 1 ? " minute " : " minutes ") : undefined;
    const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : undefined;
    return yearDisplay ?? monthDisplay?? weekDisplay?? dayDisplay?? hDisplay ?? mDisplay ?? sDisplay ?? "0 seconds";
  } else {
    return " - ";
  }
};
