import { TimelineDataStatus } from "../../../services/timeline/timeline.model";

export const timeLineDummyData = [
  {
    time: { startTime: 1661711400, endTime: 1661711537, duration: 137 },
    hour: 1,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661711537, endTime: 1661712319, duration: 782 },
    hour: 1,
    offline: false,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.FOCUS,
  },
  {
    time: { startTime: 1661712319, endTime: 1661712320, duration: 1 },
    hour: 1,
    offline: true,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661712320, endTime: 1661712670, duration: 350 },
    hour: 1,
    offline: false,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.FOCUS,
  },
  {
    time: { startTime: 1661712670, endTime: 1661712671, duration: 1 },
    hour: 1,
    offline: true,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661712671, endTime: 1661713186, duration: 515 },
    hour: 1,
    offline: false,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.FOCUS,
  },
  {
    time: { startTime: 1661713186, endTime: 1661713187, duration: 1 },
    hour: 1,
    offline: true,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661713187, endTime: 1661713667, duration: 480 },
    hour: 1,
    offline: false,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.FOCUS,
  },
  {
    time: { startTime: 1661713667, endTime: 1661713668, duration: 1 },
    hour: 1,
    offline: true,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661713668, endTime: 1661714860, duration: 1192 },
    hour: 1,
    offline: false,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.FOCUS,
  },
  {
    time: { startTime: 1661714860, endTime: 1661714861, duration: 1 },
    hour: 1,
    offline: true,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661714861, endTime: 1661715000, duration: 139 },
    hour: 1,
    offline: false,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.FOCUS,
  },
  {
    time: { startTime: 1661715000, endTime: 1661716218, duration: 1218 },
    hour: 2,
    offline: false,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.FOCUS,
  },
  {
    time: { startTime: 1661716218, endTime: 1661716219, duration: 1 },
    hour: 2,
    offline: true,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661716219, endTime: 1661716996, duration: 777 },
    hour: 2,
    offline: false,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.FOCUS,
  },
  {
    time: { startTime: 1661716996, endTime: 1661717572, duration: 576 },
    hour: 2,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661717572, endTime: 1661717573, duration: 1 },
    hour: 2,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661717573, endTime: 1661718600, duration: 1027 },
    hour: 2,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661718600, endTime: 1661718895, duration: 295 },
    hour: 3,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661718895, endTime: 1661718896, duration: 1 },
    hour: 3,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661718896, endTime: 1661719346, duration: 450 },
    hour: 3,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661719346, endTime: 1661719347, duration: 1 },
    hour: 3,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661719347, endTime: 1661720068, duration: 721 },
    hour: 3,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661720068, endTime: 1661720069, duration: 1 },
    hour: 3,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661720069, endTime: 1661720434, duration: 365 },
    hour: 3,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661720434, endTime: 1661720435, duration: 1 },
    hour: 3,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661720435, endTime: 1661720685, duration: 250 },
    hour: 3,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661720685, endTime: 1661720686, duration: 1 },
    hour: 3,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661720686, endTime: 1661721242, duration: 556 },
    hour: 3,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661721242, endTime: 1661721243, duration: 1 },
    hour: 3,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661721243, endTime: 1661721288, duration: 45 },
    hour: 3,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661721288, endTime: 1661721663, duration: 375 },
    hour: 3,
    offline: false,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.FOCUS,
  },
  {
    time: { startTime: 1661721663, endTime: 1661721664, duration: 1 },
    hour: 3,
    offline: true,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661721664, endTime: 1661722084, duration: 420 },
    hour: 3,
    offline: false,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.FOCUS,
  },
  {
    time: { startTime: 1661722084, endTime: 1661722085, duration: 1 },
    hour: 3,
    offline: true,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661722085, endTime: 1661722200, duration: 115 },
    hour: 3,
    offline: false,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.FOCUS,
  },
  {
    time: { startTime: 1661722200, endTime: 1661722386, duration: 186 },
    hour: 4,
    offline: false,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.FOCUS,
  },
  {
    time: { startTime: 1661722386, endTime: 1661722387, duration: 1 },
    hour: 4,
    offline: true,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661722387, endTime: 1661722717, duration: 330 },
    hour: 4,
    offline: false,
    focus: true,
    focusBreak: false,
    status: TimelineDataStatus.FOCUS,
  },
  {
    time: { startTime: 1661722717, endTime: 1661722837, duration: 120 },
    hour: 4,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661722837, endTime: 1661722838, duration: 1 },
    hour: 4,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661722838, endTime: 1661723258, duration: 420 },
    hour: 4,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661723258, endTime: 1661723259, duration: 1 },
    hour: 4,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661723259, endTime: 1661723689, duration: 430 },
    hour: 4,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661723689, endTime: 1661723690, duration: 1 },
    hour: 4,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661723690, endTime: 1661723985, duration: 295 },
    hour: 4,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661723985, endTime: 1661723986, duration: 1 },
    hour: 4,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661723986, endTime: 1661725028, duration: 1042 },
    hour: 4,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661725028, endTime: 1661725029, duration: 1 },
    hour: 4,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661725029, endTime: 1661725449, duration: 420 },
    hour: 4,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661725449, endTime: 1661725450, duration: 1 },
    hour: 4,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661725450, endTime: 1661725800, duration: 350 },
    hour: 4,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661725800, endTime: 1661725870, duration: 70 },
    hour: 5,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661725870, endTime: 1661725871, duration: 1 },
    hour: 5,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661725871, endTime: 1661726597, duration: 726 },
    hour: 5,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661726597, endTime: 1661726598, duration: 1 },
    hour: 5,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661726598, endTime: 1661726958, duration: 360 },
    hour: 5,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661726958, endTime: 1661726959, duration: 1 },
    hour: 5,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661726959, endTime: 1661727354, duration: 395 },
    hour: 5,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661727354, endTime: 1661727355, duration: 1 },
    hour: 5,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661727355, endTime: 1661727620, duration: 265 },
    hour: 5,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661727620, endTime: 1661729400, duration: 1780 },
    hour: 5,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661729400, endTime: 1661733000, duration: 3600 },
    hour: 6,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661733000, endTime: 1661736600, duration: 3600 },
    hour: 7,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661736600, endTime: 1661740200, duration: 3600 },
    hour: 8,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661740200, endTime: 1661743800, duration: 3600 },
    hour: 9,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661743800, endTime: 1661747400, duration: 3600 },
    hour: 10,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661747400, endTime: 1661751000, duration: 3600 },
    hour: 11,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661751000, endTime: 1661751022, duration: 22 },
    hour: 12,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661751022, endTime: 1661751032, duration: 10 },
    hour: 12,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661751032, endTime: 1661751033, duration: 1 },
    hour: 12,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661751033, endTime: 1661752514, duration: 1481 },
    hour: 12,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661752514, endTime: 1661752515, duration: 1 },
    hour: 12,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661752515, endTime: 1661753457, duration: 942 },
    hour: 12,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661753457, endTime: 1661753458, duration: 1 },
    hour: 12,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661753458, endTime: 1661754600, duration: 1142 },
    hour: 12,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661754600, endTime: 1661754771, duration: 171 },
    hour: 13,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661754771, endTime: 1661754772, duration: 1 },
    hour: 13,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661754772, endTime: 1661758200, duration: 3428 },
    hour: 13,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661758200, endTime: 1661758941, duration: 741 },
    hour: 14,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661758941, endTime: 1661758942, duration: 1 },
    hour: 14,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661758942, endTime: 1661759157, duration: 215 },
    hour: 14,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661759157, endTime: 1661759162, duration: 5 },
    hour: 14,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661759162, endTime: 1661759167, duration: 5 },
    hour: 14,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661759167, endTime: 1661761800, duration: 2633 },
    hour: 14,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661761800, endTime: 1661764807, duration: 3007 },
    hour: 15,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661764807, endTime: 1661765400, duration: 593 },
    hour: 15,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661765400, endTime: 1661766795, duration: 1395 },
    hour: 16,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661766795, endTime: 1661766796, duration: 1 },
    hour: 16,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661766796, endTime: 1661767241, duration: 445 },
    hour: 16,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661767241, endTime: 1661767242, duration: 1 },
    hour: 16,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661767242, endTime: 1661767562, duration: 320 },
    hour: 16,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661767562, endTime: 1661767563, duration: 1 },
    hour: 16,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661767563, endTime: 1661768024, duration: 461 },
    hour: 16,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661768024, endTime: 1661768025, duration: 1 },
    hour: 16,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661768025, endTime: 1661768611, duration: 586 },
    hour: 16,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661768611, endTime: 1661768612, duration: 1 },
    hour: 16,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661768612, endTime: 1661768843, duration: 231 },
    hour: 16,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661768843, endTime: 1661768844, duration: 1 },
    hour: 16,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661768844, endTime: 1661769000, duration: 156 },
    hour: 16,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661769000, endTime: 1661769270, duration: 270 },
    hour: 17,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661769270, endTime: 1661769271, duration: 1 },
    hour: 17,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661769271, endTime: 1661769606, duration: 335 },
    hour: 17,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661769606, endTime: 1661769607, duration: 1 },
    hour: 17,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661769607, endTime: 1661769832, duration: 225 },
    hour: 17,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661769832, endTime: 1661769833, duration: 1 },
    hour: 17,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661769833, endTime: 1661770204, duration: 371 },
    hour: 17,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661770204, endTime: 1661770205, duration: 1 },
    hour: 17,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661770205, endTime: 1661770536, duration: 331 },
    hour: 17,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661770536, endTime: 1661770537, duration: 1 },
    hour: 17,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661770537, endTime: 1661771419, duration: 882 },
    hour: 17,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661771419, endTime: 1661771420, duration: 1 },
    hour: 17,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661771420, endTime: 1661771826, duration: 406 },
    hour: 17,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661771826, endTime: 1661771827, duration: 1 },
    hour: 17,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661771827, endTime: 1661772037, duration: 210 },
    hour: 17,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661772037, endTime: 1661772038, duration: 1 },
    hour: 17,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661772038, endTime: 1661772600, duration: 562 },
    hour: 17,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661772600, endTime: 1661773610, duration: 1010 },
    hour: 18,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661773610, endTime: 1661773611, duration: 1 },
    hour: 18,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661773611, endTime: 1661774121, duration: 510 },
    hour: 18,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661774121, endTime: 1661774122, duration: 1 },
    hour: 18,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661774122, endTime: 1661774152, duration: 30 },
    hour: 18,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661774152, endTime: 1661774153, duration: 1 },
    hour: 18,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661774153, endTime: 1661774293, duration: 140 },
    hour: 18,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661774293, endTime: 1661774294, duration: 1 },
    hour: 18,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661774294, endTime: 1661775646, duration: 1352 },
    hour: 18,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661775646, endTime: 1661775647, duration: 1 },
    hour: 18,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661775647, endTime: 1661775804, duration: 157 },
    hour: 18,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661775804, endTime: 1661775805, duration: 1 },
    hour: 18,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661775805, endTime: 1661776200, duration: 395 },
    hour: 18,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661776200, endTime: 1661777634, duration: 1434 },
    hour: 19,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661777634, endTime: 1661777635, duration: 1 },
    hour: 19,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661777635, endTime: 1661779394, duration: 1759 },
    hour: 19,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661779394, endTime: 1661779800, duration: 406 },
    hour: 19,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661779800, endTime: 1661783400, duration: 3600 },
    hour: 20,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661783400, endTime: 1661784475, duration: 1075 },
    hour: 21,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661784475, endTime: 1661784490, duration: 15 },
    hour: 21,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661784490, endTime: 1661784491, duration: 1 },
    hour: 21,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661784491, endTime: 1661784521, duration: 30 },
    hour: 21,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661784521, endTime: 1661784522, duration: 1 },
    hour: 21,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661784522, endTime: 1661784822, duration: 300 },
    hour: 21,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661784822, endTime: 1661784823, duration: 1 },
    hour: 21,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661784823, endTime: 1661785910, duration: 1087 },
    hour: 21,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661785910, endTime: 1661786798, duration: 888 },
    hour: 21,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661786798, endTime: 1661787000, duration: 202 },
    hour: 21,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661787000, endTime: 1661788292, duration: 1292 },
    hour: 22,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661788292, endTime: 1661788819, duration: 527 },
    hour: 22,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661788819, endTime: 1661788939, duration: 120 },
    hour: 22,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661788939, endTime: 1661788940, duration: 1 },
    hour: 22,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661788940, endTime: 1661790047, duration: 1107 },
    hour: 22,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661790047, endTime: 1661790048, duration: 1 },
    hour: 22,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661790048, endTime: 1661790498, duration: 450 },
    hour: 22,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661790498, endTime: 1661790499, duration: 1 },
    hour: 22,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661790499, endTime: 1661790600, duration: 101 },
    hour: 22,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661790600, endTime: 1661790954, duration: 354 },
    hour: 23,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661790954, endTime: 1661790955, duration: 1 },
    hour: 23,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661790955, endTime: 1661792894, duration: 1939 },
    hour: 23,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661792894, endTime: 1661792895, duration: 1 },
    hour: 23,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661792895, endTime: 1661793350, duration: 455 },
    hour: 23,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661793350, endTime: 1661793351, duration: 1 },
    hour: 23,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661793351, endTime: 1661793721, duration: 370 },
    hour: 23,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661793721, endTime: 1661793722, duration: 1 },
    hour: 23,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661793722, endTime: 1661794002, duration: 280 },
    hour: 23,
    offline: false,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.ONLINE,
  },
  {
    time: { startTime: 1661794002, endTime: 1661794200, duration: 198 },
    hour: 23,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
  {
    time: { startTime: 1661794200, endTime: 1661797800, duration: 3600 },
    hour: 24,
    offline: true,
    focus: false,
    focusBreak: false,
    status: TimelineDataStatus.OFFLINE,
  },
];
