import * as dummyUsage from "./dashboard/usage/dummy_usage";
import * as dummyInfo from "./dashboard/information/dummy_info";
import * as dummyGoals from "./dashboard/goals/dummy_goals";
import * as dummyBenefits from "./dashboard/benefit/dummy_benefits";
import * as dummyCalendar from "./calendar/dummy_calendar";
import * as dummySidebar from "./sidebar/dummy_sidebar";

const dashboardFiles = {
  today: {
    usage: dummyUsage.today,
    information: dummyInfo.today,
    goals: dummyGoals.today,
    benefits: dummyBenefits.today,
  },
  yesterday: {
    usage: dummyUsage.yesterday,
    information: dummyInfo.yesterday,
    goals: dummyGoals.yesterday,
    benefits: dummyBenefits.yesterday,
  },
  week: {
    usage: dummyUsage.week,
    information: dummyInfo.week,
    goals: dummyGoals.week,
    benefits: dummyBenefits.week,
  },
  month: {
    usage: dummyUsage.month,
    information: dummyInfo.month,
    goals: dummyGoals.month,
    benefits: dummyBenefits.month,
  },
};

export const getUsageData = (startTime: number, endTime: number) => {
  const timeLabel = getTimeLabel(startTime, endTime);
  const usageData = dashboardFiles[timeLabel].usage;
  return usageData;
};

export const getInformationData = (startTime: number, endTime: number) => {
  const timeLabel = getTimeLabel(startTime, endTime);
  const informationData = dashboardFiles[timeLabel].information;
  return informationData;
};

export const getGoalsData = (startTime: number, endTime: number) => {
  const timeLabel = getTimeLabel(startTime, endTime);
  const goalsData = dashboardFiles[timeLabel].goals;
  return goalsData;
};

export const getBenefitsData = (startTime: number, endTime: number) => {
  const timeLabel = getTimeLabel(startTime, endTime);
  const benefitsData = dashboardFiles[timeLabel].benefits;
  return benefitsData;
};

export const getDashboardData = (startTime: number, endTime: number) => {
  const usageData = getUsageData(startTime, endTime);
  const informationData = getInformationData(startTime, endTime);
  const goalsData = getGoalsData(startTime, endTime);
  const benefitsData = getBenefitsData(startTime, endTime);

  return {
    usage: usageData,
    information: informationData,
    goals: goalsData,
    benefits: benefitsData,
  };
};

const getTimeLabel = (
  startTime: number,
  endTime: number
): "today" | "yesterday" | "week" | "month" => {
  const startDate = new Date(startTime * 1000);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  const week = new Date();
  week.setDate(today.getDate() - 7);
  const month = new Date();
  month.setDate(today.getDate() - 30);

  if (
    startDate.getDate() === new Date().getDate() &&
    startDate.getMonth() === new Date().getMonth() &&
    startDate.getFullYear() === new Date().getFullYear()
  ) {
    return "today";
  } else if (
    startDate.getDate() === yesterday.getDate() &&
    startDate.getMonth() === yesterday.getMonth() &&
    startDate.getFullYear() === yesterday.getFullYear()
  ) {
    return "yesterday";
  } else if (
    startDate.getDate() === week.getDate() &&
    startDate.getMonth() === week.getMonth() &&
    startDate.getFullYear() === week.getFullYear()
  ) {
    return "week";
  } else if (
    startDate.getDate() === month.getDate() &&
    startDate.getMonth() === month.getMonth() &&
    startDate.getFullYear() === month.getFullYear()
  ) {
    return "month";
  } else {
    return "today";
  }
};

export const getCalendarData = (startTime: number, endTime: number) => {
  return dummyCalendar.dummyCalendarData;
};

export const getInsightsData = (startTime: number, endTime: number) => {
  return dummySidebar.dummyHabits;
};

export const getTipsData = (startTime: number, endTime: number) => {
  return dummySidebar.dummyTips;
};

export const getSidebarData = (startTime: number, endTime: number) => {
  return {
    insights: dummySidebar.dummyHabits,
    suggestions: dummySidebar.dummyTips,
  };
};
