import { Link, Stack, Typography } from "@mui/material";
import { SupportTabContent } from "../../support-tab.component";
import { SupportsPage } from "../../supportpage.component";
// import { SupportTabContent } from "../understanding-tab.component";

interface StepsBlogProps {}

export const StepsBlog = (props: StepsBlogProps) => {
  return (
    <>
      <Typography
        variant="h6"
        display="block"
        id={SupportTabContent.steps.id}
      ></Typography>
      <SupportsPage
        title={SupportTabContent.steps.title}
        subtitle={SupportTabContent.steps.description}
        loading={false}
      >
        <StepsPage></StepsPage>
      </SupportsPage>
    </>
  );
};

interface StepsProps {}

const StepsPage = (props: StepsProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="body1" display="block">
        myTiro helps you understand where, when and how you spend your time
        across all of your digital devices. Your personalized myTiro dashboard
        contains a number of features that can help you be more efficient with
        your time and evolve your working habits as your priorities change;
        learn about your digital patterns with myTiro Insights and start to
        build healthy time habits with myTiro Goals.
      </Typography>
      <Typography variant="body1" display="block" fontWeight={"bold"}>
        Get started with myTiro in 5 easy steps.
      </Typography>
      <Typography variant="body1" display="block">
        <b>Step 1:</b> Join the myTiro Beta program. You can be invited to join
        the myTiro beta program by an existing myTiro user or you can sign up
        for the beta waitlist. Those who refer others will be a higher priority
        to join the beta program.
      </Typography>
      <Typography variant="body1" display="block">
        <b>Step 2:</b> Once you receive your email invitation to join the myTiro
        beta program, follow the simple onboarding instructions to set up your
        myTiro account.
      </Typography>
      <Typography variant="body1" display="block">
        <b>Step 3:</b> Once you have created your myTiro account and set a
        password, next you need to download and install myTiro on your
        computer(s), browser(s), and cell phone(s). Once the installation is
        complete, sign into all of the myTiro applications across your devices
        using your credentials.
      </Typography>
      <Typography variant="body1" display="block">
        <b>Step 4:</b> You have the option to connect your Google email and
        calendar to your myTiro account. Don’t worry, myTiro does not access any
        content within emails. You can read more about our privacy policy here.
      </Typography>
      <Typography variant="body1" display="block">
        <b>Step 5:</b> That’s it! Once you have completed these steps, you are
        now ready to use myTiro. Once you are logged into myTiro on your
        computer, browser, and cell phone, you will start to see your data
        within just a few hours of setting up myTiro. You will also receive
        daily and weekly email recaps on how you spent your time.
      </Typography>
      <Typography variant="body1" display="block">
        <Link href="/support?option=register">
          Next: How do I install myTiro
        </Link>
      </Typography>
      <Typography variant="body1" display="block"></Typography>
    </Stack>
  );
};
