import { DateTime } from "luxon";

export const useTimeService = () => {
  const isDayOfWeekEnabled = (date: DateTime): boolean => {
    return !(date.weekday === 6 || date.weekday === 7);
  };

  const getPreviousEnabledDay = (date: DateTime): DateTime => {
    let prevDay = date.minus({ days: 1 });
    if (prevDay.weekday === 7) {
      prevDay = prevDay.minus({ days: 2 });
    } else if (prevDay.weekday === 6) {
      prevDay = prevDay.minus({ days: 1 });
    }
    return prevDay;
  };

  return {
    isDayOfWeekEnabled,
    getPreviousEnabledDay,
  };
};
