import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DaySummaryDto } from "../../../../services/timeline/timeline.model";
import { convertSecondsToHHMM } from "../../../admin/useroverview/useroverview.service";
import {
  AppContributionScore,
  AppStats,
  SessionMetrics,
} from "../../dashboard.models";
// import { AppScoreChartComponent } from "./app-usage-graphic.component";

interface AppSessionDistributionProps {
  appContribution: AppContributionScore;
  maxSes: SessionMetrics;
  minSes: SessionMetrics;
  summaryData: DaySummaryDto;
  appStats: AppStats;
  appName: string;
}

export const AppSessionDistribution = (props: AppSessionDistributionProps) => {
  const [appContribution, setAppContribution] = useState<AppContributionScore>(
    {}
  );
  const [maxSes, setMaxSes] = useState<SessionMetrics>({});
  const [appName, setAppName] = useState<string>("");
  const [minSes, setMinSes] = useState<SessionMetrics>({});
  const [summaryData, setSummaryData] = useState<DaySummaryDto>();
  const [appStats, setAppStats] = useState<AppStats>();

  useEffect(() => {
    setSummaryData(props.summaryData);
  }, [props.summaryData]);

  useEffect(() => {
    setMinSes(props.minSes);
    console.log(props.minSes);
  }, [props.minSes]);

  useEffect(() => {
    setAppName(props.appName);
  }, [props.appName]);

  useEffect(() => {
    setMaxSes(props.maxSes);
    // console.log(props.maxSes);
  }, [props.maxSes]);
  useEffect(() => {
    setAppStats(props.appStats);
  }, [props.appStats]);

  useEffect(() => {
    setAppContribution(props.appContribution);
  }, [props.appContribution]);

  return (
    <Box
      sx={{
        bgcolor: "white",
        width: "100%",
        padding: "1.5%",
        borderRadius: "12px",
        paddingBottom: "2%",
        height: "100%",
      }}
    >
      <Typography variant="h6">
        <span style={{ fontWeight: 400 }}>
          How did you use{" "}
          <span style={{ color: "#7E4BB7", fontWeight: 700 }}>{appName}</span>?
        </span>
      </Typography>
      <Stack spacing={1} sx={{ ml: 2, mt: 2 }}>
        <AppContributionLine
          scoreType={"Focused"}
          score={
            appStats?.sessionData?.focusedData.reduce(
              (acc, cur) => acc + (cur.focusSession?.duration ?? 0),
              0
            ) ?? 0
            // appStats?.sessionData?.focusedData[0]?.focusSession?.duration ?? 0
          }
          numberOfSessions={appStats?.sessionData?.focusedData?.length ?? 0}
        ></AppContributionLine>
        <AppContributionLine
          scoreType={"Nearly Focused"}
          score={
            appStats?.sessionData?.nearlyFocusedData.reduce(
              (acc, cur) => acc + (cur.duration ?? 0),
              0
            ) ?? 0
          }
          numberOfSessions={
            appStats?.sessionData?.nearlyFocusedData?.length ?? 0
          }
        ></AppContributionLine>
        <AppContributionLine
          scoreType={"Fragmented"}
          score={
            appStats?.sessionData?.fragmentedData.reduce(
              (acc, cur) => acc + (cur.duration ?? 0),
              0
            ) ?? 0
          }
          numberOfSessions={appStats?.sessionData?.fragmentedData?.length ?? 0}
        ></AppContributionLine>
        {/* <Box sx={{ paddingLeft: "30px" }}> */}
        <Stack direction={"column"}>
          <AppSubContributionLine
            scoreType={"10m - 15m"}
            score={
              appStats?.sessionData?.fragmentedData
                .filter((nd) => (nd?.duration ?? 0) >= 600)
                .reduce((acc, cur) => acc + (cur.duration ?? 0), 0) ?? 0
            }
            numberOfSessions={
              appStats?.sessionData?.fragmentedData.filter(
                (nd) => (nd?.duration ?? 0) >= 600
              ).length ?? 0
            }
          ></AppSubContributionLine>
          <AppSubContributionLine
            scoreType={"5m - 10m"}
            score={
              appStats?.sessionData?.fragmentedData
                .filter(
                  (nd) =>
                    (nd?.duration ?? 0) >= 300 && (nd?.duration ?? 0) < 600
                )
                .reduce((acc, cur) => acc + (cur.duration ?? 0), 0) ?? 0
            }
            numberOfSessions={
              appStats?.sessionData?.fragmentedData.filter(
                (nd) => (nd?.duration ?? 0) >= 300 && (nd?.duration ?? 0) < 600
              ).length ?? 0
            }
          ></AppSubContributionLine>
          <AppSubContributionLine
            scoreType={"1m - 5m"}
            score={
              appStats?.sessionData?.fragmentedData
                .filter(
                  (nd) => (nd?.duration ?? 0) >= 60 && (nd?.duration ?? 0) < 300
                )
                .reduce((acc, cur) => acc + (cur.duration ?? 0), 0) ?? 0
            }
            numberOfSessions={
              appStats?.sessionData?.fragmentedData.filter(
                (nd) => (nd?.duration ?? 0) >= 60 && (nd?.duration ?? 0) < 300
              ).length ?? 0
            }
          ></AppSubContributionLine>
          <AppSubContributionLine
            scoreType={"<1m"}
            score={
              appStats?.sessionData?.fragmentedData
                .filter((nd) => (nd?.duration ?? 0) < 60)
                .reduce((acc, cur) => acc + (cur.duration ?? 0), 0) ?? 0
            }
            numberOfSessions={
              appStats?.sessionData?.fragmentedData.filter(
                (nd) => (nd?.duration ?? 0) < 60
              ).length ?? 0
            }
          ></AppSubContributionLine>
        </Stack>
      </Stack>
    </Box>
  );
};

interface AppContributionLineProps {
  scoreType: string;
  score: number;
  numberOfSessions: number;
}

const AppContributionLine = (props: AppContributionLineProps) => {
  return (
    <>
      {props.numberOfSessions !== 0 ? (
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ width: "150px" }}>
              <Typography variant="body1Bold">{props.scoreType}</Typography>
            </Box>
            <Box sx={{ width: "100px" }}>
              <Typography variant="body1">
                {props.score > 0
                  ? `${convertSecondsToHHMM(props.score)}`
                  : `No sessions`}
              </Typography>
            </Box>
            <Box sx={{ width: "150px" }}>
              <Typography variant="body1">
                {props.numberOfSessions > 0
                  ? props.numberOfSessions === 1
                    ? `${props.numberOfSessions} session`
                    : `${props.numberOfSessions} sessions`
                  : ``}
              </Typography>
            </Box>
          </Box>
          {/* <Divider></Divider> */}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

const AppSubContributionLine = (props: AppContributionLineProps) => {
  return (
    <>
      {props.numberOfSessions !== 0 ? (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ width: "150px", paddingLeft: "10px" }}>
            <Typography variant="body2Bold">{props.scoreType}</Typography>
          </Box>
          <Box sx={{ width: "100px" }}>
            <Typography variant="body2">
              {props.score > 0
                ? `${convertSecondsToHHMM(props.score)}`
                : `No sessions`}
            </Typography>
          </Box>
          <Box sx={{ width: "150px" }}>
            <Typography variant="body2">
              {props.numberOfSessions > 0
                ? props.numberOfSessions === 1
                  ? `${props.numberOfSessions} session`
                  : `${props.numberOfSessions} sessions`
                : ``}
            </Typography>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};
