import { CognitoUserSession } from "amazon-cognito-identity-js";
import {
  getSession,
  handleSessionNotFound,
} from "../../../accountcontext/account";

const BASE_URL = "/api/usage";

export const getAdminBenefits = async (startTime: number, endTime: number) => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const startTimeStr = startTime.toString();
        const endTimeStr = endTime.toString();
        var params = [
          ["startTime", startTimeStr],
          ["endTime", endTimeStr],
        ];
        const searchParams = new URLSearchParams(params).toString();
        const url = `${BASE_URL}/admin//benefits/all?${searchParams}`;
        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

export const getAdminBenefitsCsv = async (
  startTime: number,
  endTime: number
) => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const startTimeStr = startTime.toString();
        const endTimeStr = endTime.toString();
        var params = [
          ["startTime", startTimeStr],
          ["endTime", endTimeStr],
        ];
        const searchParams = new URLSearchParams(params).toString();
        const url = `${BASE_URL}/admin//benefits/csv?${searchParams}`;
        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};
