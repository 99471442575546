import { CognitoUserSession } from "amazon-cognito-identity-js";
import {
  getSession,
  handleSessionNotFound,
} from "../../../accountcontext/account";
import {
  AgentOverview,
  DeviceOverview,
  UserInfoData,
  UserOverviewData,
} from "./useroverview.model";

const BASE_URL = "/api/usage";
const OFFLINE_METRIC = 1800;

export const getUserData = async (): Promise<{ users: UserInfoData[] }> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        const url = `${BASE_URL}/admin/useroverview`;
        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      })
      .catch(handleSessionNotFound);
  });
};

export const generateUserOverview = (
  user: UserInfoData,
  syncMetric: number,
  taskMetric: number
): UserOverviewData => {
  let syncStatus: boolean = true;
  const devices: DeviceOverview[] = [];
  let runStatus: boolean = true;
  let lastRunTime;

  if (user.lastRunTask) {
    lastRunTime =
      new Date(user.lastRunTask.timestamp * 1000).toLocaleString() +
      " (" +
      convertSecondsToHHMM(
        Math.floor(new Date().getTime() / 1000) - user.lastRunTask.timestamp
      ) +
      " ago)";
    runStatus =
      Math.floor(new Date().getTime() / 1000) - taskMetric >
      user.lastRunTask.timestamp
        ? false
        : true;
  }

  for (const device of user.devices) {
    const agents: AgentOverview[] = [];
    const name = device.name;
    const type = device.type;
    const os = device.os;

    for (const agent of device.agents) {
      const name = agent.name;
      const type = agent.type;
      const lastSyncTime =
        new Date(agent.lastSyncTime * 1000).toLocaleString() +
        " (" +
        convertSecondsToHHMM(
          Math.floor(new Date().getTime() / 1000) - agent.lastSyncTime
        ) +
        " ago)";

      const isActive =
        Math.floor(new Date().getTime() / 1000) - syncMetric >
        agent.lastSyncTime
          ? false
          : true;
      if (!isActive) syncStatus = false;

      const agentOverview: AgentOverview = {
        name: name,
        type: type,
        lastSyncTime: lastSyncTime,
        isActive: isActive,
      };

      agents.push(agentOverview);
    }

    const deviceOverview: DeviceOverview = {
      type: type,
      name: name,
      os: os,
      agents: agents,
    };

    devices.push(deviceOverview);
  }

  const userOverview: UserOverviewData = {
    accountId: user.accountId,
    name: user.name,
    devices: devices,
    syncStatus: syncStatus,
    lastRunTask: lastRunTime ?? "NA",
    runStatus: runStatus,
    email: user.email,
  };
  console.log(userOverview);
  return userOverview;
};

export const convertSecondsToHHMM = (seconds: number | undefined): string => {
  if (seconds === undefined) {
    return "NA";
  }
  let secs = seconds % 60;
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);
  minutes = minutes % 60;
  // days === 0 ?
  return hours === 0 && minutes === 0
    ? `${secs}s`
    : (hours > 0 ? `${hours}h ` : "") + (minutes === 0 ? " " : `${minutes}m`);
  // : `${days}d ${hours}h ${minutes}m`;
};
