import { Box, Stack, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { checkIfAppInstalled, getColor } from "../utils/dashboard.utils";
import Highcharts, {
  Options,
  SeriesBarOptions,
  SeriesOptionsType,
} from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FocusData } from "./focustime.model";
import "../dashboard.styles.css";
import InfoDialog from "../../infodialog/infodialog.component";
import ErrorBoundary from "../../../errorboundary.component";
import { NoDataOverlay } from "../nodataoverlay/nodataoverlay.component";
import { focusOptions } from "./focusDummyOptions";
import { displayOverlay } from "../../../overlay.service";
import InfoIcon from "@mui/icons-material/Info";
const borderRadius = require("highcharts-rounded-corners");
borderRadius(Highcharts);

const tooltipText =
  "Running total of time you spent in focused activities today.";

interface FocusTimeProps {
  focusTime: FocusData;
}

interface FocusTimeState {
  options: Options;
  focusTime: FocusData;
  dialogOpen: boolean;
  showOverlay: string | null;
}

class FocusTime extends React.Component<FocusTimeProps, FocusTimeState> {
  constructor(props: FocusTimeProps) {
    super(props);
    const seriesData = this.generateGraphData(
      this.props.focusTime
    ) as SeriesOptionsType[];
    this.state = {
      dialogOpen: false,
      showOverlay: null,
      options: {
        chart: {
          renderTo: "container",
          type: "bar",
          height: "74px",
          marginTop: 0,
          animation: false,
        },
        credits: {
          enabled: false,
        },

        legend: {
          enabled: false,
        },

        title: {
          text: undefined,
        },
        tooltip: {
          enabled: false,
        },
        xAxis: {
          visible: false,
        },
        yAxis: {
          visible: false,
        },

        plotOptions: {
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
              hover: {
                opacity: 0.9,
              },
            },
            events: {
              click: this.handleClick,
            },
            stacking: "percent",
            // pointWidth: 24,
            // groupPadding: 0,
            dataLabels: {
              enabled: true,
              align: "right",
              verticalAlign: "top",
              y: -2,
              style: {
                color: "#fffff",
                fontFamily: "Roboto, sans-serif",
                fontSize: "max(12px, 0.8vw)",
                fontWeight: "400",
                boxShadow: "none",
                textOutline: "none",
                overflow: "ellipsis",
                position: "absolute",
              },
              formatter: function () {
                const self = this as any;
                const percent = self.percentage as number;
                if (percent < 15) {
                  return "";
                }
                let minutes = this.y as number;
                const hours = Math.floor(minutes / 60);
                minutes = minutes % 60;
                const timeString =
                  (hours != 0 ? hours + "h " : "") +
                  (minutes != 0 ? minutes + "m " : "");
                return timeString;
              },
            },
          },
          bar: {
            grouping: false,
            pointWidth: 24,
            groupPadding: 0,
          },
        },
        series: seriesData,
      },
      focusTime: props.focusTime,
    };
  }

  // static getDerivedStateFromProps(
  //   props: FocusTimeProps,
  //   state: FocusTimeState
  // ) {
  //   const { focusTime } = props;
  //   const seriesData = FocusTime.generateGraphData(focusTime);

  //   let locoptions = state.options;

  //   locoptions.series = seriesData as SeriesOptionsType[];
  //   return {
  //     options: locoptions,
  //     focusTime: props.focusTime,
  //   };
  // }
  componentDidMount() {
    if (this.state.showOverlay === null) {
      displayOverlay("COMPONENT_DASHBOARD_FOCUS_TIME").then(
        (str: string | null) =>
          this.setState({
            showOverlay: str,
          })
      );
    }
  }

  componentDidUpdate(prevProps: FocusTimeProps) {
    if (prevProps.focusTime !== this.props.focusTime) {
      const seriesData = this.generateGraphData(
        this.props.focusTime
      ) as SeriesOptionsType[];

      this.setState({
        focusTime: this.props.focusTime,
        options: {
          ...this.state.options,
          series: seriesData,
        },
      });
    }
  }

  generateGraphData = (focusData: FocusData) => {
    if (focusData) {
      const focusedTime = focusData.value.find((val) => val.name === "Focused");
      const focusedTimeValue = focusedTime
        ? focusedTime?.time?.hours * 60 + focusedTime.time.minutes
        : 0;
      const fragmentedTime = focusData.value.find(
        (val) => val.name === "Fragmented"
      );
      const fragmentedTimeValue = fragmentedTime
        ? fragmentedTime?.time?.hours * 60 + fragmentedTime.time.minutes
        : 0;

      const seriesData = [];
      if (focusedTimeValue > 0 && fragmentedTimeValue > 0) {
        seriesData.push({
          data: [focusedTimeValue],
          type: "bar",
          name: "Focused",
          legendIndex: 0,
          color: getColor("Focused"),
          borderRadiusBottomLeft: "50%",
          borderRadiusBottomRight: "50%",
        });
        seriesData.push({
          data: [fragmentedTimeValue],
          type: "bar",
          name: "Fragmented",
          legendIndex: 1,
          color: getColor("Fragmented"),
          borderRadiusTopLeft: "50%",
          borderRadiusTopRight: "50%",
        });
        seriesData.reverse();
      } else if (focusedTimeValue > 0 && fragmentedTimeValue === 0) {
        seriesData.push({
          data: [focusedTimeValue],
          type: "bar",
          name: "Focused",
          legendIndex: 0,
          color: getColor("Focused"),
          borderRadiusTopLeft: "50%",
          borderRadiusTopRight: "50%",
          borderRadiusBottomLeft: "50%",
          borderRadiusBottomRight: "50%",
        });
      } else if (focusedTimeValue === 0 && fragmentedTimeValue > 0) {
        seriesData.push({
          data: [fragmentedTimeValue],
          type: "bar",
          name: "Fragmented",
          legendIndex: 0,
          color: getColor("Fragmented"),
          borderRadiusTopLeft: "50%",
          borderRadiusTopRight: "50%",
          borderRadiusBottomLeft: "50%",
          borderRadiusBottomRight: "50%",
        });
      }
      return seriesData;
    } else {
      return [] as SeriesOptionsType[];
    }
  };

  handleClick = (event: any) =>
    this.setState({
      dialogOpen: false,
    });

  handleClose = (event: any, reason: string) =>
    this.setState({
      dialogOpen: false,
    });

  IsZero = (
    focus: Array<{
      name: string;
      time: { hours: number; minutes: number; seconds: number };
    }>
  ) => {
    let result = true;
    for (const i in focus) {
      if (!(focus[i].time.hours === 0 && focus[i].time.minutes === 0))
        result = false;
    }
    return result;
  };

  render() {
    console.log("Focus", this.props.focusTime);
    return (
      <Box
        className="masonry-item"
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          height: "11.25vw",
          minHeight: "174px",
          width: "100%",
          alignContent: "center",
          padding: "2% 5%",
          borderRadius: "12px",
        }}
      >
        <ErrorBoundary>
          <InfoDialog
            open={this.state.dialogOpen}
            insights={[]}
            rawdata={[]}
            onClose={this.handleClose}
          />
          <Stack direction={"row"} justifyContent="space-between">
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                width: "100%",
                marginRight: "-24px",
              }}
            >
              <Typography variant="h6">Focus Time</Typography>
            </Box>
            <Box sx={{ marginRight: "-19px" }}>
              <Tooltip title={tooltipText}>
                <InfoIcon
                  sx={{
                    fontSize: "20px",
                    ":hover": { transform: "scale(1.1)" },
                  }}
                />
              </Tooltip>
            </Box>
          </Stack>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent={"space-between"}
            position="relative"
            height="100%"
            overflow="visible"
          >
            <div
              style={{
                width: "100%",
                padding: "0",
                height: "74px",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              {this.props.focusTime && !this.state.showOverlay ? (
                this.props.focusTime.value.length > 0 &&
                !this.IsZero(this.props.focusTime.value) ? (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state.options}
                    allowChartUpdate={true}
                    updateArgs={[true]}
                  />
                ) : (
                  <Box
                    margin="0% auto"
                    borderRadius="8px"
                    height="90%"
                    textAlign="center"
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    border="1px dashed #AAAFBB"
                  >
                    <Typography variant="h6Light" margin="auto">
                      No data available
                    </Typography>
                  </Box>
                )
              ) : (
                <>
                  <NoDataOverlay
                    text={
                      this.state.showOverlay
                        ? this.state.showOverlay
                        : undefined
                    }
                  />
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={focusOptions}
                    allowChartUpdate={true}
                    updateArgs={[true]}
                  />
                </>
              )}
            </div>
            <Box>
              <div
                style={{
                  width: "110%",
                  height: "2px",
                  backgroundColor: "#D6DAE4",
                  marginLeft: "-5%",
                  marginTop: "auto",
                  opacity: "0.5",
                }}
              ></div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: "3%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginRight: "5%",
                  }}
                >
                  <div
                    className="legendBox"
                    style={{ backgroundColor: getColor("Focused") }}
                  ></div>
                  <Typography variant="body1">Focused</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="legendBox"
                    style={{ backgroundColor: getColor("Fragmented") }}
                  ></div>
                  <Typography variant="body1">Fragmented</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </ErrorBoundary>
      </Box>
    );
  }
}

export default FocusTime;
