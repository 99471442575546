import { Box, Grid, Link, Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { GoalSelectorCard } from "./components/goal-selector-card.component";
import { GoalCreatorCard } from "./components/goal-creator-card.component";
import { GoalMonitorCard } from "./components/goal-monitor-card.component";
import {
  CreateGoalDto,
  CustomTemplateOptions,
  ViewGoalDto,
} from "../../../../services/goals/goals.model";
import { DateTime } from "luxon";
import { useState } from "react";
import { useGoalsApi } from "../../../../services/goals/goals.service";

interface GoalsManagerCard {
  goalList: ViewGoalDto[];
  deleteGoal: (goalId: number) => void;
  pinGoal: (goalId: number, pinned: boolean) => void;
  date: DateTime;
  customTemplateOptions: CustomTemplateOptions[];
  updateGoals: () => Promise<void>;
}

export const GoalsManagerCard: React.FC<GoalsManagerCard> = (
  props: GoalsManagerCard
): JSX.Element => {
  const goalsApi = useGoalsApi();
  const [selectedOption, setSelectedOption] = useState<CustomTemplateOptions>();

  const selectGoalOption = (option: CustomTemplateOptions) => {
    setSelectedOption(option);
  };

  const createGoal = (goal: CreateGoalDto) => {
    if (goal) {
      goalsApi.createNewGoal(goal).then(() => {
        props.updateGoals();
      });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "50vh",
        bgcolor: "#FAF2DC",
        borderRadius: "16px",
        padding: "1%",
        paddingTop: "0.3%",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: "1%" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          myTiro Goals
        </Typography>
        <Box>
          <Tooltip title={"How To"}>
            <Link href={"/support?option=how-to&suboption=goals"}>
              <InfoIcon sx={{ color: "#7E4BB7" }} />
            </Link>
          </Tooltip>
        </Box>
      </Box>
      <Grid container columnSpacing={1} rowSpacing={1}>
        <Grid item lg={3} md={6} xs={12} sx={{ height: "40vh" }}>
          <GoalSelectorCard
            customTemplateOptions={props.customTemplateOptions}
            selectGoalOption={selectGoalOption}
            updateGoals={props.updateGoals}
          ></GoalSelectorCard>
        </Grid>
        <Grid item lg={2} md={6} xs={12} sx={{ height: "40vh" }}>
          <GoalCreatorCard
            selectedOption={selectedOption}
            createGoal={createGoal}
          ></GoalCreatorCard>
        </Grid>
        <Grid item lg={7} md={12} xs={12} sx={{ height: "40vh" }}>
          <GoalMonitorCard
            goals={props.goalList}
            deleteGoal={props.deleteGoal}
            pinGoal={props.pinGoal}
            date={props.date}
          ></GoalMonitorCard>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            *Note: Goals will be tracked from when you first start using your
            digital devices
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
