import { Box, Grid } from "@mui/material";

import {
  ScoringValuesDto,
  ScoringType,
} from "../../../../services/scores/scores.model";
import { SubCard } from "../../../scores/score-card.component";

const tooltipText = "myTiro Scores";

export const ScoresBarComponent = (props: {
  scores: ScoringValuesDto[];
  prevScores: ScoringValuesDto[];
}): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "4%",
        backgroundColor: "white",
        minHeight: "50vh",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        {/* <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            marginBottom: "18px",
            marginLeft: "3%",
          }}
        >
          myTiro Scores
        </Typography>
        <Tooltip title={tooltipText}>
          <InfoIcon
            sx={{
              fontSize: "20px",
              ":hover": { transform: "scale(1.1)" },
            }}
          />
        </Tooltip> */}
      </div>
      <ScoreCard scores={props.scores} prevScores={props.prevScores} />
    </Box>
  );
};

interface ScoreCardProps {
  scores: ScoringValuesDto[];
  prevScores: ScoringValuesDto[];
  // title: string;
}
export const ScoreCard = (props: ScoreCardProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      borderRadius={3}
      bgcolor={"#e8f5e9"}
      width="100%"
      height={"100%"}
      sx={{ padding: 1 }}
    >
      <Grid
        spacing={2}
        container
        alignItems={"center"}
        justifyContent={"space-evenly"}
        height={"100%"}
      >
        <Grid item xs={12}>
          <SubCard
            score={
              props.scores.find((s) => s.scoringType === ScoringType.FOCUS_TIME)
                ?.score ?? 0
            }
            // score={220}
            title={"myTiro Focus Score"}
            prevScore={
              props.prevScores.find(
                (s) => s.scoringType === ScoringType.FOCUS_TIME
              )?.score ?? 0
            }
            // prevScore={200}
            min={
              props.scores.find((s) => s.scoringType === ScoringType.FOCUS_TIME)
                ?.minScore ?? 0
            }
            max={
              props.scores.find((s) => s.scoringType === ScoringType.FOCUS_TIME)
                ?.maxScore ?? 500
            }
            tooltipText={
              "Measures how focused you are with your time today. Higher score indicates more focus."
            }
          />
        </Grid>
        <Grid item xs={12}>
          <SubCard
            score={
              props.scores.find((s) => s.scoringType === ScoringType.GOALS)
                ?.score ?? 0
            }
            // score={570}
            title={"myTiro Goals Score"}
            prevScore={
              props.prevScores.find((s) => s.scoringType === ScoringType.GOALS)
                ?.score ?? 0
            }
            // prevScore={585}
            min={
              props.scores.find((s) => s.scoringType === ScoringType.GOALS)
                ?.minScore ?? 0
            }
            max={
              props.scores.find((s) => s.scoringType === ScoringType.GOALS)
                ?.maxScore ?? 500
            }
            tooltipText={
              "Measures how deliberate you are with your time today. Higher score indicates that more of your device time is deliberate."
            }
          />
        </Grid>
        <Grid item xs={12}>
          <SubCard
            score={
              props.scores.find((s) => s.scoringType === ScoringType.HABITS)
                ?.score ?? 0
            }
            title={"myTiro Habit Score"}
            prevScore={
              // props.prevScores.find((s) => s.scoringType === ScoringType.HABITS)
              //   ?.score ?? 0
              -10
            }
            min={
              props.scores.find((s) => s.scoringType === ScoringType.HABITS)
                ?.minScore ?? 0
            }
            max={
              props.scores.find((s) => s.scoringType === ScoringType.HABITS)
                ?.maxScore ?? 1300
            }
            tooltipText={
              "Measures how consistently you have been using your devices for the past 30 days. Higher score indicates higher consistency and more discipline."
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};
