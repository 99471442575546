import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import theme from "../../theme/theme";
import { ScoreChartComponent } from "./components/score-chart.component";
import InfoIcon from "@mui/icons-material/Info";
import {
  ScoringType,
  ScoringValuesDto,
} from "../../services/scores/scores.model";

interface ScoreCardProps {
  scores: ScoringValuesDto[];
  prevScores: ScoringValuesDto[];
  // title: string;
}

interface ScoreSubCardProps {
  score: number;
  prevScore: number | null;
  title: string;
  min: number;
  max: number;
  tooltipText: string;
}

export const SubCard = (props: ScoreSubCardProps) => {
  return (
    <Box
      paddingTop="1%"
      paddingBottom="1%"
      // marginTop="3%"
      textAlign={"center"}
      borderRadius="12px"
      height="110%"
      // maxWidth="200px"
      bgcolor={theme.palette.secondary.main}
      // sx={{ borderRadius: "40px" }}
    >
      <Stack direction={"row"} justifyContent="space-between">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginRight: "-24px",
          }}
        >
          <ScoreChartComponent
            score={props.score}
            title={props.title}
            prevScore={props.prevScore}
            min={props.min}
            max={props.max}
          />
        </Box>
        <Box sx={{ marginRight: "8px" }}>
          <Tooltip title={props.tooltipText}>
            <InfoIcon
              sx={{
                fontSize: "16px",
                ":hover": { transform: "scale(1.1)" },
              }}
            />
          </Tooltip>
        </Box>
      </Stack>
    </Box>
  );
};

export const ScoreCard = (props: ScoreCardProps) => {
  // const { totalTime, switching: switches, focusTime } = props.scores.data;
  // console.log("Prev Score:", props.prevScores);
  // console.log("Score: ", props.scores);
  return (
    <Box
      display="flex"
      flexDirection="column"
      // className="homepage-card"
      borderRadius={3}
      bgcolor={"#e8f5e9"}
      width="100%"
      height={"100%"}
      sx={{ padding: 1 }}
    >
      {/* <Box
        display="flex"
        flexDirection="row"
        alignItems={"center"}
        marginBottom="4%"
      > */}
      {/* <img src="/assets/Tips.png" height="24px" /> */}
      {/* <Typography variant="body1Bold" marginLeft={"2%"}>
          Your scores
        </Typography> */}
      {/* </Box> */}
      <Grid
        spacing={2}
        container
        alignItems={"center"}
        justifyContent={"space-evenly"}
        height={"100%"}
      >
        <Grid item xs={4}>
          <SubCard
            score={
              props.scores.find((s) => s.scoringType === ScoringType.FOCUS_TIME)
                ?.score ?? 0
            }
            // score={220}
            title={"myTiro Focus Score"}
            prevScore={
              props.prevScores.find(
                (s) => s.scoringType === ScoringType.FOCUS_TIME
              )?.score ?? 0
            }
            // prevScore={200}
            min={
              props.scores.find((s) => s.scoringType === ScoringType.FOCUS_TIME)
                ?.minScore ?? 0
            }
            max={
              props.scores.find((s) => s.scoringType === ScoringType.FOCUS_TIME)
                ?.maxScore ?? 500
            }
            tooltipText={
              "Measures how focused you are with your time today. Higher score indicates more focus."
            }
          />
        </Grid>
        <Grid item xs={4}>
          <SubCard
            score={
              props.scores.find((s) => s.scoringType === ScoringType.GOALS)
                ?.score ?? 0
            }
            // score={570}
            title={"myTiro Goals Score"}
            prevScore={
              props.prevScores.find((s) => s.scoringType === ScoringType.GOALS)
                ?.score ?? 0
            }
            // prevScore={585}
            min={
              props.scores.find((s) => s.scoringType === ScoringType.GOALS)
                ?.minScore ?? 0
            }
            max={
              props.scores.find((s) => s.scoringType === ScoringType.GOALS)
                ?.maxScore ?? 500
            }
            tooltipText={
              "Measures how deliberate you are with your time today. Higher score indicates that more of your device time is deliberate."
            }
          />
        </Grid>
        <Grid item xs={4}>
          <SubCard
            score={
              props.scores.find((s) => s.scoringType === ScoringType.HABITS)
                ?.score ?? 0
            }
            title={"myTiro Habit Score"}
            prevScore={
              // props.prevScores.find((s) => s.scoringType === ScoringType.HABITS)
              //   ?.score ?? 0
              -10
            }
            min={
              props.scores.find((s) => s.scoringType === ScoringType.HABITS)
                ?.minScore ?? 0
            }
            max={
              props.scores.find((s) => s.scoringType === ScoringType.HABITS)
                ?.maxScore ?? 1300
            }
            tooltipText={
              "Measures how consistently you have been using your devices for the past 30 days. Higher score indicates higher consistency and more discipline."
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export const ScoreCardComponent = (props: {}): JSX.Element => {
  return (
    <Box
      paddingTop="1%"
      paddingBottom="1%"
      // marginTop="3%"
      textAlign={"center"}
      borderRadius="12px"
      bgcolor={"white"}
    >
      <Box sx={{ width: "500px" }}>{/* <ScoreChartComponent /> */}</Box>
    </Box>
  );
};
