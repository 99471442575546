import { getSidebarData } from "../../data/dummy_data.service";
import {
  getSession,
  handleSessionNotFound,
} from "../../accountcontext/account";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { getScores } from "../../services/scores/scores.service";

const BASE_URL = "/api/user";

const BASE_USAGE_URL = "/api/usage";

const fetchSuggestionsData = (
  startTime: number,
  endTime: number
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const startTimeStr = startTime.toString();
    const endTimeStr = endTime.toString();
    var params = [
      ["startTime", startTimeStr],
      ["endTime", endTimeStr],
    ];
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        const searchParams = new URLSearchParams(params).toString();
        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        const url = `${BASE_URL}/sidebar/suggestions/${username}?${searchParams}`;
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

const fetchInsightsData = (
  startTime: number,
  endTime: number
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const startTimeStr = startTime.toString();
    const endTimeStr = endTime.toString();
    var params = [
      ["startTime", startTimeStr],
      ["endTime", endTimeStr],
    ];
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];

        const searchParams = new URLSearchParams(params).toString();
        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        const url = `${BASE_URL}/sidebar/insights/${username}?${searchParams}`;
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

export const fetchSidebarData = (
  startTime: number,
  endTime: number,
  prevStartTime: number,
  prevEndTime: number
): Promise<any> => {
  const fetchList = [];
  // fetchList.push(fetchSuggestionsData(startTime, endTime));
  // fetchList.push(fetchInsightsData(startTime, endTime));
  // fetchList.push(fetchNewInsightsData());
  fetchList.push(getScores(startTime, endTime));
  fetchList.push(getScores(prevStartTime, prevEndTime));
  return Promise.all(fetchList)
    .then((results) => {
      return {
        scores: results[0],
        prevScores: results[1],
      };
    })
    .catch((error) => {
      return getSidebarData(startTime, endTime);
    });
};

const fetchNewInsightsData = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        const url = `${BASE_USAGE_URL}/insights/list`;
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};
