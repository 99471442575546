import HighchartsReact from "highcharts-react-official";
import HighchartsTreeChart from "highcharts/modules/treemap";
import Highcharts, { SeriesOptionsType } from "highcharts";
import React from "react";
import { TreemapUsagePropsModel } from "./models/treemap_usage_props.model";
import { TreemapUsageStateModel } from "./models/treemap_usage_state.model";
import "./app_usage.style.css";
const borderRadius = require("highcharts-rounded-corners");
borderRadius(Highcharts);
HighchartsTreeChart(Highcharts);

export class TreemapUsage extends React.Component<
  TreemapUsagePropsModel,
  TreemapUsageStateModel
> {
  constructor(props: TreemapUsagePropsModel) {
    super(props);
    this.state = {
      chartOptions: {
        chart: {
          height: "90%",
          borderRadius: 20,
          margin: [0, 5],
        },
        credits: {
          enabled: false,
        },

        tooltip: {
          enabled: true,
          outside: true,
          valueSuffix: " min",
        },

        plotOptions: {
          treemap: {},
        },
        series: [
          {
            type: "treemap",
            layoutAlgorithm: "squarified",
            dataLabels: {
              enabled: false,
            },
            cursor: "pointer",
            borderWidth: 0.4,
            alternateStartingDirection: true,
            levels: [
              {
                level: 1,
                dataLabels: {
                  enabled: true,
                  align: "left",
                  verticalAlign: "bottom",
                  formatter: function () {
                    const point = this.point as any;
                    let minutes = point.value as number;
                    const hours = Math.floor(minutes / 60);
                    minutes = minutes % 60;
                    const timeString =
                      (hours != 0 ? hours + "h " : "") +
                      (minutes != 0 ? minutes + "m " : "");
                    if (
                      point.shapeArgs?.height < 25 ||
                      point.shapeArgs?.width < 25
                    ) {
                      return "";
                    }
                    return this.point.name + "<br/> " + timeString;
                  },
                  style: {
                    color: "#fffff",
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "12px",
                    fontWeight: "light",
                    boxShadow: "none",
                    textOutline: "none",
                    overflow: "ellipsis",
                    padding: "2px",
                    textAlign: "left",
                  },
                  x: 3,
                  y: -5,
                },
                borderWidth: 1,
              },
              {
                level: 2,
                layoutAlgorithm: "stripes",

                dataLabels: {
                  enabled: false,
                },
                borderColor: "#000000",
                borderDashStyle: "DashDot",
              },
            ],
            data: [],
            events: {
              click: this.props.onClick,
            },
          },
        ],
        title: {
          text: "",
        },
      },
    };
  }

  static getDerivedStateFromProps(
    nextProps: TreemapUsagePropsModel,
    prevState: TreemapUsageStateModel
  ) {
    if (nextProps.seriesData !== prevState.chartOptions.series) {
      const chartOptions = prevState.chartOptions as any;
      chartOptions.series[0].data = nextProps.seriesData;
      return {
        chartOptions,
      };
    }
    return null;
  }

  render(): React.ReactNode {
    const { chartOptions } = this.state;
    return (
      <HighchartsReact
        style={{ margin: "-11 auto" }}
        highcharts={Highcharts}
        options={chartOptions}
        allowChartUpdate={true}
        updateArgs={[true]}
      />
    );
  }
}
