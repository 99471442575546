import { Typography } from "@mui/material";

export const PrivacyTldr = (): JSX.Element => {
  return (
    <>
      <Typography variant={"h6"}>TL;DR</Typography>
      <Typography variant={"body1"}>
        <ul>
          <li>
            myTiro wants to help you build strong time habits – which means
            <b> you</b> dictate what you do with your time.
          </li>
          <li>
            We only collect basic information like applications active on your
            computer(s) & mobile(s), website titles, and email headers (if you
            have given myTiro access). So we can tell you where you are spending
            your time and insights derived from our proprietary algorithms.
          </li>
          <li>
            We do not read or capture any content in your browsers, emails,
            files, or screens; we do not track keystrokes, webcams, or mics.
          </li>
          <li>
            We will never sell or share your data without your explicit consent
            with any third party.
          </li>
          <li>All your data is encrypted in transit and at rest.</li>
        </ul>
        <p>
          <Typography variant={"body1"}>And here are the details</Typography>
        </p>
        <p>
          <Typography variant={"body1"}>
            The data policy describes who we are, what we collect, and how we
            collect and use data.{" "}
          </Typography>
        </p>

        <p>
          <Typography variant={"body1"}>
            We at myTiro take data privacy very seriously and ensure that our
            users' data privacy is always maintained. We encourage you to read
            the policy below.
          </Typography>
        </p>
      </Typography>
    </>
  );
};

export const PrivacyIntroduction = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">1. Introduction</Typography>
      <Typography variant="h6">A. About Us</Typography>
      <p>
        <Typography variant="body1">
          myTiro is developed and operated by Yappx Labs Inc, a Delaware
          corporation. "we," "us," "our," and "myTiro" are used interchangeably
          in this document and refer to Yappx Labs Inc.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          By using myTiro.ai, you are agreeing to the collection of your
          personal information to provide you with our services. We reserve the
          right to review and change this policy periodically. We will notify
          users of material changes via email and/or a public announcement.
        </Typography>
      </p>
      <Typography variant="h6">B. Terminology</Typography>
      <p>
        <Typography variant="body1">
          In this privacy policy, these terms have the following meanings:
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          <b>"User"</b> means an individual who has signed up on myTiro to avail
          of the services of the platform
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          <b>"Agent"</b> refers to the myTiro
          software/apps/extensions/plugins/integrations installed by the user on
          devices, within applications, browsers, and any third-party software
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          <b>"Cloud"</b> refers to the infrastructure used to host the services
          provided by myTiro.
        </Typography>
      </p>
    </>
  );
};

export const PrivacyInformation = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">2. Information we collect</Typography>
      <p>
        <Typography variant="body1">
          We collect information in the following ways:
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          <ul>
            <li>
              Email, name, timezone, and other personal information provided by
              you when you sign-up for a myTiro account or request access to
              myTiro Beta.
            </li>
            <li>
              Device data like name, OS, IP, browsers, screen size, time zone,
              applications, active application, active file, notifications,
              shutdown/logoff/suspend/lock events and from computers where
              myTiro agent is installed.
            </li>
            <li>
              Browser information like browsing history (URLs only), tabs,
              bookmarks, web activity and other extensions for browsers where
              the myTiro extension is installed.
            </li>
            <li>
              Plugin information like file name, path, timestamps, and
              attributes (branch names, folders, editor, versions, names,
              languages, line content, user action) from installed myTiro
              plugins.
            </li>
            <li>
              Integration information like names, channels, timestamps,
              activity, titles, and events from third-party software where
              myTiro has been authorized by the user.
            </li>
            <li>
              Mobile information like the installed app, app usage, screen time,
              lock/unlock/usage events, call logs and list of SMSs.
            </li>
            <li>
              Usage data when you access, use or interact with myTiro and any of
              myTiro features through our website, agents, extensions, plugins
              or emails. Third parties, including but not limited to Google
              Analytics, may also use cookies to track your return visits to the
              website.
            </li>
            <li>
              <b>
                We do not read, capture or store OTP, 2FA, passwords or any
                sensitive data. We do not access webcams or mics. We do not
                record screen activity or keystrokes. We do not capture or store
                any content (within files, URLs, and any other sources).
              </b>
            </li>
          </ul>
        </Typography>
      </p>
    </>
  );
};
export const PrivacyInfoShare = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">3. How the information is used</Typography>
      <p>
        <Typography variant="body1">
          We will never sell, rent or share your personal information. No other
          user can see any of your personal information. The information is used
          to provide, maintain, improve, and protect services to our users,
          improve user experience, create/offer new services, and generate
          aggregate statistics/benchmarks & reports with anonymized data.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          The information is used to fulfil payments owed to us, meet legal
          requirements, and communicate with you.
        </Typography>
      </p>
      <p>
        <Typography variant="body1">
          We will ask for your consent before using any of your information for
          a purpose other than set out in this Privacy Policy.
        </Typography>
      </p>
    </>
  );
};
export const PrivacyProtection = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5">4. How is the information protected</Typography>
      <p>
        <Typography variant="body1">
          All data captured and processed by us is done on the cloud while
          ensuring maximum security using administrative, technical and physical
          safeguards to protect the information. The following principles are
          adhered to at all times:
        </Typography>
      </p>
      <Typography variant="h6">Principle of least privilege</Typography>
      <p>
        <Typography variant="body1">
          All services and users are granted minimal permissions that are
          required to perform their required tasks. Ensuring no entity has
          permission to access any data outside their scope. Employees have
          access to only necessary parts of the infrastructure to perform their
          job.
        </Typography>
      </p>
      <Typography variant="h6">Encryption at rest and in transit </Typography>
      <p>
        <Typography variant="body1">
          All data on the network is encrypted using TLS. All our services are
          hosted on Amazon Web Services, using their existing infrastructure
          security to encrypt data at rest. Confidential and/or sensitive data
          may be encrypted at the application level using enterprise-grade
          encryption provided by AWS KMS as an added layer of security.
        </Typography>
      </p>
      <Typography variant="h6">Minimize Attack Surface</Typography>
      <p>
        <Typography variant="body1">
          No internal server is exposed to the internet. All information is
          maintained in private networks. Services exposed to the public
          internet do not contain any personal information.
        </Typography>
      </p>
      <Typography variant="h6">Automatic Updates</Typography>
      <p>
        <Typography variant="body1">
          All systems, including but not limited to laptops, servers, and
          containers, are set to auto-update where possible or periodically
          updated manually to the latest version when available to incorporate
          the latest security updates and patches
        </Typography>
      </p>
      <Typography variant="h6">Security Boundaries</Typography>
      <p>
        <Typography variant="body1">
          All environments (production, staging, development, etc.) are
          maintained separately. Navigating from one to another requires
          additional authorization controlled by AWS Identity and Access
          Management.
        </Typography>
      </p>
      <Typography variant="h6">Infrastructure Security</Typography>
      <p>
        <Typography variant="body1">
          All our infrastructure is hosted on Amazon Web Services (AWS). It is
          covered by, but not limited to, ISO/IEC 27001:2013 and SOC 3
          certifications. The data centers adhere to world-clas security
          standards as described{" "}
          <a
            href="https://aws.amazon.com/compliance/data-center/"
            target={"_blank"}
          >
            here
          </a>
          .
        </Typography>
      </p>
    </>
  );
};

export const Privacy = (): JSX.Element => {
  return (
    <>
      <Typography variant="h5"></Typography>
      <p>
        <Typography variant="body1"></Typography>
      </p>
      <p>
        <Typography variant="body1"></Typography>
      </p>
      <p>
        <Typography variant="body1"></Typography>
      </p>
      <p>
        <Typography variant="body1"></Typography>
      </p>
      <p>
        <Typography variant="body1"></Typography>
      </p>
      <p>
        <Typography variant="body1"></Typography>
      </p>
    </>
  );
};

// export const PrivacyDataProtectionRights = (): JSX.Element => {
//   return (
//     <>
//       <Typography variant="h5">3. Data Protection Rights</Typography>
//       <p>
//         <Typography variant="body1">
//           As a platform user, you have the following rights concerning
//           information captured, processed, or stored by myTiro.
//         </Typography>
//       </p>
//       <Typography variant="body1">
//         <ul>
//           <li>
//             You have the right to access, correct, update, or delete your
//             personal information.
//           </li>
//           <li>
//             You can manage your basic information from within the dashboard and
//             settings provided on the platform. We take reasonable steps to
//             ensure the data made available to you is reliable for its intended
//             use, accurate, complete and up to date.{" "}
//           </li>
//           <li>
//             You can request a copy of all your data collected by us by writing
//             to <a href="mailto:support@mytiro.ai">support@mytiro.ai</a>. This
//             data includes profile information, raw tracking data, and processed
//             information. It does not include information from service providers.
//             You may use this data for personal use only. Exported data is bound
//             by the service agreement that restricts processed data from being
//             shared with third-party tools/Software/ Companies that are directly
//             or indirectly involved in providing services that are in direct or
//             indirect competition with myTiro, or have a vested interest in such
//             services.
//           </li>
//           <li>
//             You have the right to request the deletion of all your data at any
//             point. You may exercise this right by deleting your account or
//             emailing <a href="mailto:support@mytiro.ai">support@mytiro.ai</a>.
//             Once deleted as per retention policy, your data can not be
//             retrieved.
//           </li>
//         </ul>
//       </Typography>
//     </>
//   );
// };

// export const PrivacyModifications = (): JSX.Element => {
//   return (
//     <>
//       <Typography variant="h5">4. Modifications to Privacy Policy</Typography>
//       <p>
//         <Typography variant="body1">
//           We reserve the right to review and change this policy periodically. We
//           will notify users of material changes via email and/or a public
//           announcement.
//         </Typography>
//       </p>
//       <p>
//         <Typography variant="body1">
//           Continued use of the Services will be deemed as acceptance of such
//           changes.
//         </Typography>
//       </p>
//     </>
//   );
// };

// export const PrivacyForUser = (): JSX.Element => {
//   return (
//     <>
//       <Typography variant="h5">2. Information we collect</Typography>
//       <p>
//         <Typography variant="body1">
//           This section describes the data collected and processed from users
//           while using different services offered by the platform. In this
//           section, <b>"you"</b> and <b>"your"</b> refer to Users
//         </Typography>
//       </p>

//       <Typography variant="h6">A. Data Collection</Typography>
//       <p>
//         <Typography variant="body1">
//           Data collected by myTiro is strictly and solely used to provide better
//           services to the user, directly with insights and automation, by
//           providing aggregate benchmarks or improving the product. Data
//           collected by myTiro is critical for the features provided by the
//           platform for users' benefit. It can be broadly classified into the
//           following categories:
//         </Typography>
//       </p>

//       <Typography variant="body1Bold">Information You Provide To Us</Typography>
//       <p>
//         <Typography variant="body1">
//           Information provided by users when using myTiro includes but is not
//           limited to:
//         </Typography>
//       </p>
//       <Typography variant="body1">
//         <ul>
//           <li>Name, email address, timezone.</li>
//           <li>Email address for communications from myTiro.</li>
//           <li>Login credentials required to use myTiro. </li>
//           <li>
//             Device names associated with each agent as defined by you during
//             installation.
//           </li>
//           <li>
//             User Preferences such as calendar settings, working hours/days,
//             tagging information, and other settings as required from time to
//             time to use myTiro.
//           </li>
//           <li>Goals that you want to track.</li>
//           <li>
//             Information related to accounts and services outside myTiro that you
//             choose to connect to myTiro, such as Gmail or Google Calendar.
//           </li>
//           <li>
//             Troubleshooting information and support data provided or otherwise
//             collected concerning queries sent by you. This information may
//             include name, contact, authentication data, the content of your
//             query and the usage information of our products and services.
//           </li>
//           <li>
//             The payment information provided by you to avail of our services via
//             subscription.
//           </li>
//           <li>
//             Feedback information sent to us regarding your experience with our
//             products and suggestions for improvement.
//           </li>
//         </ul>
//       </Typography>

//       <Typography variant="body1Bold">
//         Information We Collect Automatically to provide services to the users
//       </Typography>
//       <p>
//         <Typography variant="body1">
//           When you use our product, you approve the collection of the following
//           data:
//         </Typography>
//       </p>
//       <Typography variant="body1Bold">Product & Service Data</Typography>
//       <p>
//         <Typography variant="body1">
//           Data collected from agents include:
//         </Typography>
//         <Typography variant="body1">
//           <ul>
//             <li>
//               <b>Device Information:</b> The device data is collected by all
//               agents when installed by you. This data may include information
//               such as operating system, IP address, browser type, browser ID,
//               screen size, network information, device ID, device name, system
//               date and time, and data about features available on the device
//               such as sensors, and other system and connection information.
//             </li>
//             <li>
//               <b>Browser Information:</b> The data collected by a browser agent
//               (extension) after installation includes browsing history, tabs,
//               bookmarks, extensions, and web activity like scrolls and clicks.
//               <b>
//                 We do not read, capture, or store password from web pages. We do
//                 not read, capture or store the content of any webpage. We do not
//                 capture keystrokes.
//               </b>
//             </li>
//             <li>
//               <b>Desktop Information:</b> The data collected by a desktop agent
//               once installed by you may include desktop activity such as open &
//               active applications, running processes, background services,
//               system notifications, myTiro login session information, system
//               events (such as shut down, log off, suspend, etc.), actions to
//               understand what application and device are currently active.
//               <b>
//                 {" "}
//                 We do not read, capture or store passwords. We do not read,
//                 capture or store the content of any file. We do not access
//                 webcams or mics or capture screenshots. We do not capture
//                 keystrokes.
//               </b>
//             </li>
//             <li>
//               <b>Mobile Information:</b> The mobile agent may collect
//               information such as notifications, installed apps, app usage,
//               screen time, system events (like unlock, network change, shutdown,
//               etc.), battery status, SMS, contacts, and call logs.{" "}
//               <b>
//                 We do not read, capture or store passwords. We do not read,
//                 capture or store the content of any file. We do not access
//                 webcams or mics or capture screenshots. We do not capture
//                 keystrokes.
//               </b>
//             </li>
//           </ul>
//         </Typography>
//       </p>

//       <Typography variant="body1Bold">Platform Usage Data</Typography>
//       <p>
//         <Typography variant="body1">
//           The data collected by myTiro regarding the product usage by the user
//           is used to improve the product and record user engagement for
//           organizational stats. This data does not include any personal
//           information of the user. It includes the following:
//         </Typography>
//         <Typography variant="body1">
//           <ul>
//             <li>
//               <b>Usage Data:</b> We collect usage information whenever you
//               interact with our product. This information may include the date
//               and time our products were used, which feature was used, and what
//               activity was performed. We also collect information related to the
//               performance metrics of the feature. Actions performed within the
//               product are recorded along with the context of the application.
//             </li>
//             <li>
//               <b>Log Information:</b> Our web servers capture all incoming and
//               outgoing requests along with their corresponding data points such
//               as nature of requests, originating IP address, date and timestamp,
//               the feature being invoked, and device information (like user
//               agent, os, protocol). Information contained in the log is not
//               considered personal or private information
//             </li>
//           </ul>
//         </Typography>
//       </p>

//       <Typography variant="body1Bold">
//         Information Collected from Service Providers
//       </Typography>
//       <p>
//         <Typography variant="body1">
//           As part of the offering of myTiro, data is retrieved from external
//           services periodically on your behalf with your explicit permission and
//           authorization (required only once). The information provided to myTiro
//           is controlled by the permissions selected and consented to at the time
//           of integration. Apart from this policy, information from service
//           providers is also governed by the data policy of the respective
//           service providers. Information collected from service providers
//           includes but is not limited to:
//         </Typography>
//         <Typography variant="body1">
//           <ul>
//             <li>
//               <b>Profile Information:</b> The user's profile may include the
//               name, username, and email address the service provider shares to
//               identify the user.
//             </li>
//             <li>
//               <b>Mailbox information:</b> Mailbox services connected to myTiro
//               are currently used only to retrieve email headers on behalf of the
//               user. The information includes metadata such as sender, recipient,
//               time, client, subject, etc.{" "}
//               <b>myTiro does not access mail contents and attachments</b>.
//               Information related to the mailbox, such as labels, categories,
//               folders, senders, recipients, size, etc., may also be retrieved.
//               Sensitive emails marked by the user will not be fetched from the
//               service. This information is treated as highly confidential; refer
//               to Handling of Information to learn more about managing
//               confidential data.
//             </li>
//             <li>
//               <b>Calendar Information:</b> Calendar services you connect to may
//               provide information such as events, timezone, reminders, tasks,
//               and availability status. This information extends to all calendars
//               (if present) managed by you within the service. This information
//               is treated as highly confidential; refer to Handling of
//               Information to learn more about managing confidential data.
//             </li>
//             <li>
//               <b>Other information:</b> Any information embedded in
//               services/tools that the user has given access to myTiro.
//             </li>
//           </ul>
//         </Typography>
//       </p>
//       <br></br>
//       <Typography variant="h6">B. Use of Information</Typography>
//       <p>
//         <Typography variant="body1">
//           Information shared with us or collected by us or received from service
//           providers is used in three broad categories:
//         </Typography>
//       </p>

//       <Typography variant="body1Bold">Administration & Operations</Typography>
//       <Typography variant="body1">
//         <ul>
//           <li>
//             Profile and billing information is used to fulfill payments owed to
//             us per our contract (subscription) with you for using our products
//             and services. This includes sending invoices, alerts, and receipts
//             to your email address. Transactions are performed by secure
//             third-party payment gateways that might store transaction
//             information as per their policies.
//           </li>
//           <li>
//             To meet legal requirements, including complying with court orders,
//             valid discovery requests, valid subpoenas, and other appropriate
//             legal mechanisms. To provide information to representatives and
//             advisors, including attorneys and accountants, to help us comply
//             with legal, accounting, or security requirements based on our
//             legitimate interests. To prosecute and defend a court, arbitration,
//             or similar legal proceeding. To respond to lawful requests by public
//             authorities, including to meet national security or law enforcement
//             requirements.
//           </li>
//           <li>
//             To communicate with you for legitimate interests such as information
//             about updated and new features, requests for feedback and
//             suggestions, updates to service agreements and policies, and
//             intimation regarding outages or maintenance.
//           </li>
//           <li>
//             To ensure maximum uptime and provide uninterrupted service. To
//             detect and prevent abuse and fraud.
//           </li>
//           <li>
//             To provide support for queries and follow-up on feature requests and
//             suggestions. Investigate and resolve issues.
//           </li>
//           <li>
//             To anonymize and aggregate non-sensitive data to generate stats and
//             reports for use by the company in its legitimate business.
//           </li>
//         </ul>
//       </Typography>
//       <Typography variant="body1Bold">Product Functionality</Typography>
//       <Typography variant="body1">
//         <ul>
//           <li>
//             To provide current and future features such as automation,
//             notification, intelligent insights and patterns, goals creation and
//             monitoring, benchmarking, usage statistics, building communities,
//             and other features covering your devices, browsers, email, calendar,
//             and other applications integrated from time to time.
//           </li>
//           <li>
//             To train, re-train, update and validate personalized machine
//             learning algorithms that are used to provide you with tailor-made
//             insights, actions, and automation. Your data will be used to provide
//             intelligence to features you use. However, the metrics and meta-data
//             collected from processing and usage may be used for platform
//             improvement and benchmarking across the platform. Information marked
//             by you as sensitive will not be used for this purpose other than to
//             filter out other similarly sensitive information.
//           </li>
//           <li>
//             To combine and de-identify data for training, updating, and
//             validating generalized models to provide intelligence across the
//             platform.
//           </li>
//         </ul>
//       </Typography>
//       <Typography variant="body1Bold">Platform Improvement</Typography>
//       <Typography variant="body1">
//         <ul>
//           <li>
//             To provide support and improve our platform to offer better features
//             and services. For example, this may include improvement in
//             navigation for better user experience, a new feature for better
//             optimization of the calendar.
//           </li>
//           <li>
//             Improve the platform by processing, extending and incorporating
//             suggestions and feature requests. A request or suggestion by you may
//             be addressed at the company's discretion. It may be modified in any
//             manner or discarded by the company.
//           </li>
//           <li>
//             To perform data analytics based on our business interests to improve
//             our products and services.{" "}
//           </li>
//           <li>
//             To design, test, and develop new models and heuristics, improving
//             the platform's intelligence.{" "}
//           </li>
//           <li>
//             To build a community that learns from each other by sharing
//             group-level (non-individual) insights as required.
//           </li>
//         </ul>
//       </Typography>
//       <p>
//         <Typography variant="body1">
//           myTiro does not share or sell data with third parties for advertising
//           or promotions.
//         </Typography>
//       </p>
//       <br></br>
//       <Typography variant="h6">C. Handling of Information</Typography>
//       <p>
//         <Typography variant="body1">
//           All data captured and processed by us is done on the cloud while
//           ensuring maximum security using administrative, technical and physical
//           safeguards to protect the information. The following principles are
//           adhered to at all times:
//         </Typography>
//       </p>

//       <Typography variant="body1Bold">Principle of least privilege</Typography>
//       <Typography variant="body1">
//         All services and users are granted minimal permissions that are required
//         to perform their required tasks. Ensuring no entity has permission to
//         access any data outside their scope. Employees have access to only
//         necessary parts of the infrastructure to perform their job.{" "}
//       </Typography>
//       <Typography variant="body1Bold">
//         Encryption at rest and in transit
//       </Typography>
//       <Typography variant="body1">
//         All data on the network is encrypted using TLS. All our services are
//         hosted on Amazon Web Services, using their existing infrastructure
//         security to encrypt data at rest. Confidential and/or sensitive data may
//         be encrypted at the application level using enterprise-grade encryption
//         provided by AWS KMS as an added layer of security.
//       </Typography>
//       <Typography variant="body1Bold">Minimize Attack Surface</Typography>
//       <Typography variant="body1">
//         No internal server is exposed to the internet. All information is
//         maintained in private networks. Services exposed to the public internet
//         do not contain any personal information.
//       </Typography>
//       <Typography variant="body1Bold">Automatic Updates</Typography>
//       <Typography variant="body1">
//         All systems, including but not limited to laptops, servers, and
//         containers, are set to auto-update where possible or periodically
//         updated manually to the latest version when available to incorporate the
//         latest security updates and patches
//       </Typography>
//       <Typography variant="body1Bold">Security Boundaries</Typography>
//       <Typography variant="body1">
//         All environments (production, staging, development, etc.) are maintained
//         separately. Navigating from one to another requires additional
//         authorization controlled by AWS Identity and Access Management.{" "}
//       </Typography>
//       <Typography variant="body1Bold">Infrastructure Security</Typography>
//       <Typography variant="body1">
//         All our infrastructure is hosted on Amazon Web Services (AWS). It is
//         covered by, but not limited to, ISO/IEC 27001:2013 and SOC 3
//         certifications. The data centers adhere to world-clas security standards
//         as described here https://aws.amazon.com/compliance/data-center/{" "}
//       </Typography>
//       <br></br>
//       <Typography variant="h6">D. Retention of Information</Typography>
//       <p>
//         <Typography variant="body1">
//           We retain information where we have an ongoing legitimate business or
//           legal need. We consider the amount, nature, and sensitivity of the
//           information, the potential risk of unauthorized access, the purpose of
//           capturing the information, and whether we can achieve those purposes
//           through other means. The below policies help decide the retention
//           period
//         </Typography>
//       </p>
//       <Typography variant="body1">
//         <ul>
//           <li>
//             Required to be retained as per laws or contracts (e.g., for tax,
//             legal, accounts or other purposes)
//           </li>
//           <li>Necessary to provide the services offered on our platform. </li>
//           <li>
//             Whether you can access the information from within the platform and
//             delete it at your discretion
//           </li>
//           <li>
//             Whether you would reasonably expect that the platform retains the
//             information until you remove it or until you delete your account
//           </li>
//           <li>
//             Necessary for security and maintenance purposes and to prevent abuse
//             or fraud.
//           </li>
//         </ul>
//       </Typography>
//       <p>
//         <Typography variant="body1">
//           When we have no ongoing legitimate business need to retain or process
//           your information, we will either delete or scrub the information of
//           all personal data. Personal information will be discarded within a
//           reasonable time not exceeding 14 days from the account deletion date.
//           In case such information is included in backups, the same will be
//           discarded in not more than 30 days from deletion. Post deletion, the
//           information will not be retrievable in any form. Stats, heuristics,
//           models, algorithms, and other derived information are the intellectual
//           property of myTiro and will be retained based on our legitimate
//           interests in providing services and features on the platform.
//         </Typography>
//       </p>
//     </>
//   );
// };
