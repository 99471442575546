import { Box, Stack, Typography } from "@mui/material";
import Highcharts from "highcharts";
import { Options, SeriesOptionsType } from "highcharts";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { json } from "stream/consumers";
import ErrorBoundary from "../../../../../errorboundary.component";
import {
  TimelineDataStatus,
  TimelineDto,
} from "../../../../../services/timeline/timeline.model";
import { DateTime } from "luxon";
import { purple, deepPurple, grey } from "@mui/material/colors";

HighchartsHeatmap(Highcharts);

interface AppHeatmapChartProps {
  chartData: TimelineDto[];
  appName: string;
}

export interface ChartDataEntry {
  value: number; //mins
  name: string;
  color: string;
}

export const AppHeatmapChart = (props: AppHeatmapChartProps): JSX.Element => {
  //   const data = getData();
  const [time, setTime] = useState<number>(11);
  const [chartOptions, setChartOptions] = useState<Options>({
    chart: {
      renderTo: "container",
      type: "heatmap",
      height: "70px",
      marginTop: 0,
      animation: false,
    },

    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: undefined,
    },

    boost: {
      useGPUTranslations: true,
    },

    xAxis: {
      title: {
        text: null,
      },
      labels: {
        enabled: true,
        formatter: function () {
          //   console.log(this);
          const val: number = this.pos / 60;
          let time = val > 12 ? val - 12 : val;
          let tod: string = val >= 12 && this.pos !== 24 ? "pm" : "am";
          if (val === 0 || val === 24) {
            time = 12;
            tod = "am";
          }
          return `${time}${tod}`;
        },
      },
      //   type: "datetime",
      min: 0,
      max: 1440,
      tickInterval: 240,
      // labels: {
      //   align: "left",
      //   x: 5,
      //   y: 14,
      //   format: "{value:%B}", // long month
      // },
      // showLastLabel: false,
      // tickLength: 16,
    },

    yAxis: {
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      // minPadding: 0,
      // maxPadding: 0,
      // startOnTick: false,
      // endOnTick: false,
      // tickPositions: [0, 6, 12, 18, 24],
      // tickWidth: 50,
      // tickLength: 80,
      tickInterval: 20,
      // max: 0,
      // reversed: true,
    },

    colorAxis: {
      // stops: [
      //   [0, "#3060cf"],
      //   [0.5, "#fffbbc"],
      //   [0.9, "#c4463a"],
      // ],
      min: 0,
      max: 77,
      stops: [
        // [-1, "#000000"],
        [0, grey[50]],
        [0.01, grey[50]],

        //grey - for online
        [0.015, grey[300]],
        [0.05, grey[400]],
        [0.09, grey[500]],

        //purple - for app
        [0.16, "#A581CD"],
        [0.29, "#9166C2"],
        [0.5, "#7E4BB7"],
        [1, "#583480"],
        // [0.16, deepPurple[100]],
        // [0.29, deepPurple[200]],
        // [0.6, deepPurple[400]],
        // [1, deepPurple[600]],
        // [0.16, "#ffb74d"],
        // [0.29, "#ffa726"],
        // // [0.4, "#ff9800"],
        // [0.6, "#ff7043"],
        // [1, "#d32f2f"],
      ],
      // min: -15,
      // max: 25,
      startOnTick: false,
      endOnTick: false,
      labels: {
        style: {
          fontFamily: "Roboto",
        },
      },
    },
  });

  const [noDataOverlay, setNoDataOverlay] = useState<boolean>(false);

  useEffect(() => {
    if (props.chartData.length > 0) {
      const cdata: TimelineDto[] = JSON.parse(JSON.stringify(props.chartData));
      const data = getData(cdata);
      const series = [
        {
          // colsize: 60,
          // rowsize: 24 * 60,
          type: "heatmap",
          data: data,
          crisp: false,
          nullColor: "#EFEFEF",
          tooltip: {
            pointFormatter: function () {
              const point: any = this;
              if (point.value === 0) {
                return "Offline";
              }
              const val: number = point.x;
              const time = convertMinutesToTimeOfDay(val);
              const onlineValue = point.value % 10;
              const appValue = Math.floor(point.value / 10);
              const appStr =
                appValue > 0
                  ? `</br>On ${props.appName}: ${appValue} ${dayStr(appValue)}`
                  : ``;
              const usageStr = `Online: ${onlineValue} ${dayStr(
                onlineValue
              )} ${appStr}`;
              const hoverString = `${time} </br>${usageStr}`;
              return hoverString;
            },
            headerFormat: "",
            // pointFormat: "{point.x} <b>{point.value} times</b>",
          },
        },
      ];
      chartOptions.series = series as unknown as SeriesOptionsType[];

      setChartOptions({ ...chartOptions });
    }
  }, [props.chartData]);

  useEffect(() => {
    setNoDataOverlay(!props.chartData || props.chartData.length == 0);
  }, [props.chartData]);

  return (
    <>
      {noDataOverlay ? (
        <Box
          margin="2% auto"
          borderRadius="8px"
          height="70px"
          textAlign="center"
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          border="1px dashed #AAAFBB"
        >
          <Typography variant="h6Light" margin="auto" width="100%">
            No data available
          </Typography>
        </Box>
      ) : (
        <ErrorBoundary>
          <Box
            borderRadius="8px"
            justifyContent="center"
            bgcolor="#FFFFFF"
            padding={"5px"}
            // sx={{ marginTop: 2 }}
          >
            {/* <Stack direction={"row"}> */}
            <Box
              sx={{
                display: "flex",
                direction: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">
                <span style={{ fontWeight: 400 }}>
                  When did you use{" "}
                  <span style={{ color: "#7E4BB7", fontWeight: 700 }}>
                    {props.appName}
                  </span>
                  ?
                </span>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  direction: "row",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">Offline</Typography>
                <Box
                  sx={{
                    height: "12px",
                    width: "202px",
                    borderRadius: "2px",
                    backgroundImage:
                      "linear-gradient(to right, #fafafa, #A581CD, #9166C2, #7E4BB7, #583480)",
                    // border: "1px solid",
                    marginX: "5px",
                  }}
                ></Box>
                <Typography variant="body2">Online all days</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: "20px",
              }}
            >
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                allowChartUpdate={true}
                updateArgs={[true, true, true]}
              />
            </Box>
          </Box>
        </ErrorBoundary>
      )}
    </>
  );
};

export interface ApplicationData {
  name: string | null;
  time: {
    start: number;
    end: number;
    duration: number;
  };
  icon: string;
  category: string;
  device: any;
  offline: boolean;
}

const getData = (ddata: TimelineDto[]) => {
  console.log(ddata);
  const arry = [];
  const json: any = {};
  const startOfDay = ddata[0]?.time.startTime;
  const startSecond = 0;
  const endSecond = 86399;
  let currentStartSecond = 0;
  let currentEndSecond = 60;
  const resolution = 60;
  ddata.sort((a, b) => a.time.startTime - b.time.startTime);
  for (const data of ddata) {
    let currentStartSecond = data.time.startTime;
    let currentEndSecond = data.time.endTime;
    currentStartSecond = Math.round(currentStartSecond / 60) * 60;
    while (currentStartSecond < currentEndSecond) {
      const key = Math.floor(
        (currentStartSecond -
          (startOfDay + 86400 * Math.floor((data.hour - 1) / 24))) /
          60
      );
      if (key < 0) {
        currentStartSecond += 60;
        continue;
      }
      json[key] ??= 0;
      if (!data.offline) {
        json[key] += 1;
      }
      if (data.status === TimelineDataStatus.APP) {
        json[key] += 10;
      }
      currentStartSecond += 60;
    }
    // arry.push([data.time.start - 1661833800, 0, 1]);
  }
  for (let i = 0; i <= 1440; i++) {
    json[i] ??= 0;
  }
  for (const key in json) {
    if (parseInt(key) < 0) {
      continue;
    }
    // if (json[key] > 5) {
    //   arry.push([parseInt(key), 1, json[key]]);
    //   // arry.push([parseInt(key), 0, json[key] - 6]);
    // } else {
    // }
    arry.push([parseInt(key), 0, json[key]]);
  }
  // console.log(json);
  // console.log(arry);
  return arry;
};

const dayStr = (value: number) => {
  if (value === 1) {
    return "day";
  } else {
    return "days";
  }
};

// const dummyData: ApplicationData[] = appDummyData;
const convertMinutesToTimeOfDay = (value: number | undefined) => {
  if (value === undefined) {
    return "NA";
  }

  let hours = Math.floor(value / 60);
  let hourOfDay = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;
  let ampm = hours < 11 ? "am" : hours === 24 ? "am" : "pm";
  let mins = value % 60;
  let minsOfDay = mins < 10 ? `0${mins}` : `${mins}`;

  return `${hourOfDay}:${minsOfDay}${ampm}`;
};
