import { MutableRefObject, useRef } from "react";

export interface OnboardingContent {
  section: string;
  id: string;
  subsections?: OnboardingContent[];
}

export const onboardingContent: OnboardingContent[] = [
  {
    section: "First Login",
    id: "1",
  },
  {
    section: "Installing myTiro native apps",
    id: "2",
    subsections: [
      {
        section: "For macOS",
        id: "3",
        subsections: [
          {
            section: "Login Flow",
            id: "4",
          },
        ],
      },
      {
        section: "For Windows",
        id: "5",
        subsections: [
          {
            section: "Smart Screen Warning",
            id: "6",
          },
          {
            section: "Login Flow",
            id: "7",
          },
          {
            section: "Antivirus Warnings",
            id: "8",
          },
        ],
      },
    ],
  },
  {
    section: "Installing myTiro browser extensions",
    id: "9",
    subsections: [
      {
        section: "Chrome & Edge",
        id: "10",
        subsections: [
          {
            section: "URL not found",
            id: "11",
          },
          {
            section: "Installation",
            id: "12",
          },
          {
            section: "Login",
            id: "13",
          },
        ],
      },
      {
        section: "Firefox",
        id: "14",
        subsections: [
          {
            section: "Installation",
            id: "15",
          },
          {
            section: "Login",
            id: "16",
          },
        ],
      },
    ],
  },
  {
    section: "Installing myTiro mobile apps",
    id: "17",
    subsections: [
      {
        section: "For Android",
        id: "18",
        subsections: [
          {
            section: "Login",
            id: "19",
          },
          {
            section: "Set Permissions",
            id: "20",
          },
        ],
      },
    ],
  },
  {
    section: "Installing myTiro on new device",
    id: "21",
  },
];
