import * as React from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import {
  MoreHorizOutlined,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";

interface InfoCardState {
  anchorEl: null | HTMLElement;
  isExpandable: Boolean;
  isOpen: boolean;
  title: string;
  description: string;
  actions: Array<{
    id: number;
    title: string;
    description: string;
    type: string;
  }>;
  icon: any;
  color: string;
  progress?: string;
  descriptionStrings?: string[];
}

class InfoCard extends React.Component<any, InfoCardState> {
  constructor(props: any) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.generateActionButtons = this.generateActionButtons.bind(this);
    this.handleExpand = this.handleExpand.bind(this);
  }

  componentDidMount() {
    this.setState({
      isOpen: this.props.isOpen,
    });
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  generateActionButtons() {
    let result = [];
    let actions = this.state.actions || [];
    // Not synchronous
    for (let i = 0; i < 2 && i < actions.length; i++) {
      result.push(
        <Button
          key={i}
          variant={
            actions[i].title === "Continue" ||
            actions[i].title === "No, thanks" ||
            actions[i].title === "Remove Goal"
              ? "outlined"
              : "whiteButton"
          }
          sx={{ marginLeft: "2%", marginTop: "5%" }}
        >
          {actions[i].title}
        </Button>
      );
    }
    if (actions.length <= 2) {
      return result;
    } else {
      let menuprops = [];
      for (let i = 2; i < actions.length; i++) {
        menuprops.push(
          <MenuItem key={i} onClick={this.handleClose}>
            {actions[i].title}
          </MenuItem>
        );
      }
      result.push(
        <div key={actions.length} style={{ marginTop: "5%" }}>
          <IconButton
            onClick={this.handleClick}
            sx={{
              borderRadius: "8px",
              padding: "6px 6px",
              backgroundColor: "transparent",
              color: "#000",
              fontWeight: "bold",
              textTransform: "none",
              marginLeft: "10px",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
          >
            <MoreHorizOutlined />
          </IconButton>
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
          >
            {menuprops}
          </Menu>
        </div>
      );
      return result;
    }
  }

  handleExpand() {
    let isOpen = !this.state.isOpen;
    this.setState({
      isOpen: isOpen,
    });
  }

  getAccordionIcon() {
    if (this.state.isExpandable) {
      return (
        <IconButton
          onClick={this.handleExpand}
          sx={{ color: "#5D626F", padding: 0, marginTop: "4%" }}
        >
          {this.state.isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </IconButton>
      );
    } else {
      return null;
    }
  }

  getTitle() {
    const { title, description, isOpen } = this.state;
    let shorterDescription = description.split(". ", 1)[0] + "...";
    return title !== undefined ? (
      <Typography
        variant="body1Bold"
        sx={{ textAlign: "left", fontWeight: "bold" }}
      >
        {title}
      </Typography>
    ) : (
      <Typography
        variant="body1"
        sx={{
          textAlign: "left",
          color: isOpen ? "#050505" : "#5D626F",
        }}
      >
        {isOpen ? description : shorterDescription}
      </Typography>
    );
  }

  getDescription() {
    const { title, description, progress, descriptionStrings } = this.state;
    return (
      <>
        <Typography variant="body1" sx={{ marginTop: "3%" }}>
          {title !== undefined ? description : ""}
        </Typography>
        {descriptionStrings ? (
          <>
            <Stack direction={"column"}>
              {descriptionStrings.map((desc, index) => {
                return (
                  <Typography
                    variant="body1"
                    sx={{ marginTop: "3%" }}
                    key={index}
                  >
                    {desc}
                  </Typography>
                );
              })}
            </Stack>
          </>
        ) : (
          ""
        )}

        {progress !== undefined ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "4% 0",
              marginBottom: 0,
            }}
          >
            <CircularProgress
              value={90}
              variant="determinate"
              size={25}
              thickness={5}
              sx={{ marginRight: "4%" }}
            />
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", marginTop: "2px" }}
            >
              {progress}
            </Typography>
          </Box>
        ) : (
          ""
        )}
      </>
    );
  }

  render() {
    const { icon, isExpandable, isOpen, color } = this.state;
    return (
      <Card
        sx={{
          display: "block",
          borderRadius: "8px",
          backgroundColor: color,
          padding: "5%",
          marginBottom: isExpandable ? "0" : "10px",
          marginTop: isExpandable ? "2%" : 0,
          minHeight: 0,
        }}
      >
        <Grid container sx={{ alignItems: "self-start" }}>
          <Grid item xs={2} lg={1.5}>
            <img
              src={icon}
              style={{ width: "max(18px, 1.66vw)", marginTop: "1px" }}
            />
          </Grid>
          <Grid item xs={9} lg={9.5}>
            {this.getTitle()}
          </Grid>
          <Grid item xs={1}>
            {this.getAccordionIcon()}
          </Grid>
        </Grid>
        <Collapse in={isExpandable ? isOpen : true}>
          <Grid container>
            <Grid item xs={2} lg={1.5}>
              <Box width="1px" height="1px" sx={{ backgroundColor: color }} />
            </Grid>
            <Grid item xs={9} lg={9.5}>
              {this.getDescription()}
            </Grid>
            {/* <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "right",
                flexWrap: "wrap",
              }}
            >
              {this.generateActionButtons()}
            </Grid> */}
          </Grid>
        </Collapse>
      </Card>
    );
  }
}

export default InfoCard;
