import { environment } from "../../../environment";
import { getProfile } from "../../profile.service";
import {
  getCurrentBrowser,
  getCurrentOs,
} from "../../settings/devicemanagement/device.service";

export const downloadNativeApp = () => {
  const OS = getCurrentOs().toLowerCase();
  if (environment.nativeAppLinks[OS]) {
    window.open(environment.nativeAppLinks[OS], "_blank");
  } else {
    window.alert("Current OS is not supported, Please use Windows or macOS");
  }
  // if (OS === "Windows") {
  //   window.open(
  //     "https://mytiro-dev.s3.amazonaws.com/draw.io-13.9.9-windows-installer.exe",
  //     "_blank"
  //   );
  // } else if (OS === "macOS") {
  //   window.open(
  //     "https://mytiro-dev.s3.amazonaws.com/dev/mytiro_dev_v0.0.3.pkg",
  //     "_blank"
  //   );
  // } else {
  //   window.alert("Current OS is not supported, Please use Windows or macOS");
  // }
};

export const downloadExtension = () => {
  let browser = getCurrentBrowser().toLowerCase();

  // direct all chromium browsers to chrome store
  if (browser === "microsoft edge") {
    browser = "chrome";
  }
  if (environment.browserExtLinks[browser]) {
    window.open(environment.browserExtLinks[browser], "_blank");
  } else {
    window.alert(
      "Current Brower is not supported, Please use Chrome or Firefox"
    );
  }
  // if (browser === "Chrome") {
  //   window.open(
  //     "https://chrome.google.com/webstore/detail/mytiro-dev/lfagbjkmiiiabajkhbgfeaagodapmjim?hl=en-GB",
  //     "_blank"
  //   );
  // } else if (browser === "Firefox") {
  //   window.open(
  //     "https://mytiro-dev.s3.amazonaws.com/dev/myTiro_firefox_dev-0.0.6.xpi",
  //     "_blank"
  //   );
  // } else {
  //   window.alert(
  //     "Current Brower is not supported, Please use Chrome or Firefox"
  //   );
  // }
};

export const checkAppLogin = async () => {
  try {
    const profileInfo = await getProfile();
    return profileInfo.nativeAgentConnected;
  } catch {
    console.log("Could not fetch profile info");
    return false;
  }
};

export const checkBrowserLogin = async () => {
  try {
    const profileInfo = await getProfile();
    return profileInfo.browserAgentConnected;
  } catch {
    console.log("Could not fetch profile info");
    return false;
  }
};
