import { Box, Grid, ThemeProvider, useMediaQuery } from "@mui/material";
import { DateTime } from "luxon";
import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { displayOverlay } from "../../overlay.service";
import { CalendarMobileHeader } from "../../pages/calendar/containercalendar.component";
import { OnboardingComputationInProgress } from "../../services/app.service";
import {
  CreateDimensionDto,
  CreateGoalDto,
  CreateTargetDto,
  CustomTemplateOptions,
  DimensionType,
  GoalTemplate,
  QuantumType,
  TargetFunction,
  TargetType,
  ViewGoalDto,
  ViewTargetDto,
} from "../../services/goals/goals.model";
import { useGoalsApi } from "../../services/goals/goals.service";
import {
  InsightDto,
  InsightType,
} from "../../services/insight-v2/insights-v2.model";
import {
  createNewGoal,
  fetchInsight,
} from "../../services/insight-v2/insights-v2.service";
import { ScoringValuesDto } from "../../services/scores/scores.model";
import { getScores } from "../../services/scores/scores.service";
import { useTimeService } from "../../services/time.service";
import {
  DaySummaryDto,
  DetailedTimelineDto,
  TimelineDto,
} from "../../services/timeline/timeline.model";
import {
  getDaySummary,
  getDetailedTimelineData,
  getTimelineData,
} from "../../services/timeline/timeline.service";
import theme from "../../theme/theme";
import { NoDataOverlay } from "../dashboard/nodataoverlay/nodataoverlay.component";
import { LogoLoader } from "../loader/loader.component";
import { ScoreCard } from "../scores/score-card.component";
import { daySummaryDummy } from "./dummy-data/day-summary.dummy";
import { ScoresDummyData } from "./dummy-data/scores.dummy";
import { timeLineDummyData } from "./dummy-data/timeline.dummy";
import { GoalsManagerCard } from "./landingcards/goals-manager/goals-manager-card.component";
import { GoalSummary } from "./landingcards/goals-summary/goalsummary.component";
import { TimelineCard } from "./landingcards/timeline/timeline.component";
import { InsightsV2Card } from "./insight_v2/insight_v2.component";

export const LandingPage = () => {
  const timeService = useTimeService();
  const queryParams = useLocation().search;
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const dayTs = parseInt(new URLSearchParams(queryParams).get("dayTs") ?? "");
  const [landingPageDate, setlandingPageDate] = useState<DateTime>(
    dayTs && !isNaN(dayTs) ? DateTime.fromSeconds(dayTs) : DateTime.now()
  );

  const [showOverlay, setShowOverlay] = useState<string | null>(null);
  const [showLoader, setShowLoader] = useState(false);

  const [showWaitForDataMessage, setShowWaitForDataMessage] = useState(false);
  const [dateForDataToShow, setDateForDataToShow] = useState<
    string | undefined
  >();
  // const [promptString, setPromptString] = useState<string>("");
  const [timeSavingInsightData, setTimeSavingInsightData] =
    useState<InsightDto>();
  const [focusIncreaseInsightData, setFocusIncreaseInsightData] =
    useState<InsightDto>();

  // Data states
  const [goalData, setGoalData] = useState<ViewGoalDto[]>([]);
  const [scoreData, setScoreData] = useState<ScoringValuesDto[]>([]);
  const [timelineData, setTimelineData] = useState<TimelineDto[]>([]);
  const [daySummary, setDaySummary] = useState<DaySummaryDto>({
    time: undefined,
    focus: undefined,
    activities: undefined,
  });
  // Prev Day data states
  const [prevScoreData, setPrevScoreData] = useState<ScoringValuesDto[]>([]);
  const [prevDayGoalData, setPrevDayGoalData] = useState<ViewGoalDto[]>([]);
  const [prevTimelineData, setPrevTimelineData] = useState<TimelineDto[]>([]);
  const [prevDaySummary, setPrevDaySummary] = useState<DaySummaryDto>({
    time: undefined,
    focus: undefined,
    activities: undefined,
  });

  const [detailedTimelineData, setDetailedTimelineData] = useState<
    DetailedTimelineDto[]
  >([]);

  const [templateData, setTemplateData] = useState<GoalTemplate[]>([]);
  const [goalTemplateOptions, setGoalTemplateOptions] = useState<
    CustomTemplateOptions[]
  >([]);

  const goalsApi = useGoalsApi();

  const loadDetailedTimelineData = async (hour: number, today: boolean) => {
    let startTime: number;
    let endTime: number;
    if (today) {
      startTime =
        landingPageDate.startOf("day").toUnixInteger() + 3600 * (hour - 1);
    } else {
      startTime =
        timeService
          .getPreviousEnabledDay(landingPageDate)
          .startOf("day")
          .toUnixInteger() +
        3600 * (hour - 1);
    }
    endTime = startTime + 3600;
    const data = await getDetailedTimelineData(startTime, endTime);
    setDetailedTimelineData(data);
  };

  const loadTimelineData = async () => {
    const startTime = landingPageDate.startOf("day").toUnixInteger();
    const endTime = landingPageDate.endOf("day").toUnixInteger();
    const prevDay = timeService.getPreviousEnabledDay(landingPageDate);
    const prevStartTime = prevDay.startOf("day").toUnixInteger();
    const prevEndTime = prevDay.endOf("day").toUnixInteger();
    const dataPromise = getTimelineData(startTime, endTime);
    const prevDataPromise = getTimelineData(prevStartTime, prevEndTime);
    const summaryPromise = getDaySummary(startTime, endTime);
    const prevSummaryPromise = getDaySummary(prevStartTime, prevEndTime);
    return Promise.all([
      dataPromise,
      prevDataPromise,
      summaryPromise,
      prevSummaryPromise,
    ]).then((results) => {
      setTimelineData(results[0]);
      setPrevTimelineData(results[1]);
      setDaySummary(results[2]);
      setPrevDaySummary(results[3]);
    });
  };

  const loadInsight = async () => {
    const insightType = InsightType.TIME_SAVING;
    const startTime = landingPageDate.startOf("day").toUnixInteger();
    const endTime = landingPageDate.endOf("day").toUnixInteger();
    const insight = await fetchInsight(insightType, startTime, endTime);
    if (insight) {
      // setPromptString(insight.insight);
      setTimeSavingInsightData(insight.timeSavingInsight);
      setFocusIncreaseInsightData(insight.focusIncreaseInsight);
      // console.log("insight: ", insight.insight);
    }
  };

  const loadScoreData = async () => {
    const startTime = landingPageDate.startOf("day").toUnixInteger();
    const endTime = landingPageDate.endOf("day").toUnixInteger();
    const prevDay = timeService.getPreviousEnabledDay(landingPageDate);
    const prevStartTime = prevDay.startOf("day").toUnixInteger();
    const prevEndTime = prevDay.endOf("day").toUnixInteger();
    const scoresPromise = getScores(prevStartTime, prevEndTime);
    const prevScoresPromise = getScores(startTime, endTime);
    return Promise.all([scoresPromise, prevScoresPromise]).then((results) => {
      setPrevScoreData(results[0]);
      setScoreData(results[1]);
    });
  };

  const onSetGoalInsightClick = async (insight: InsightDto | undefined) => {
    if (insight) {
      const insightType = insight.insightType;
      const startTime = landingPageDate.startOf("day").toUnixInteger();
      const endTime = landingPageDate.endOf("day").toUnixInteger();
      if (insight.goalDtos) {
        console.log(insight.goalDtos);
        insight.goalDtos.startTime = startTime;
        insight.goalDtos.endTime = endTime;
        createNewGoal(insight.goalDtos, insightType, startTime, endTime)
          .then(async () => {
            await updateGoals();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const loadAllGoalData = async () => {
    const startTime = landingPageDate.startOf("day").toUnixInteger();
    const endTime = landingPageDate.endOf("day").toUnixInteger();
    const { goals, templates, options } =
      await goalsApi.loadGoalsDataAndTemplates(startTime, endTime);
    setGoalData(goals);
    setTemplateData(templates);
    setGoalTemplateOptions(options);
  };

  const getPrevEnabledDayGoalData = async () => {
    const prevDay = timeService.getPreviousEnabledDay(landingPageDate);
    const prevStartTime = prevDay.startOf("day").toUnixInteger();
    const prevEndTime = prevDay.endOf("day").toUnixInteger();
    const goals: ViewGoalDto[] = await goalsApi.getGoals(
      prevStartTime,
      prevEndTime
    );
    setPrevDayGoalData(goals);
  };

  const deleteGoal = async (goalId: number) => {
    setShowLoader(true);
    await goalsApi.deleteGoal(goalId);
    await loadAllGoalData();
    await loadInsight();
    setShowLoader(false);
  };

  const pinGoal = async (goalId: number, pinned: boolean) => {
    setShowLoader(true);
    await goalsApi.pinGoal(goalId, pinned);
    await loadAllGoalData();
    setShowLoader(false);
  };

  const initPage = async () => {
    setShowLoader(true);

    displayOverlay("COMPONENT_DASHBOARD_USAGE_BY_APPLICATION").then(
      (str: string | null) => setShowOverlay(str)
    );

    // 1 days from now
    const onboardingInProgress = OnboardingComputationInProgress.get();
    if (onboardingInProgress.status) {
      const dateToShowData =
        onboardingInProgress.completedDateTime?.toFormat("LLL dd, yyyy");
      setDateForDataToShow(dateToShowData);
      setShowWaitForDataMessage(true);
    } else {
      setDateForDataToShow(undefined);
      setShowWaitForDataMessage(false);
    }
    await Promise.allSettled([
      loadAllGoalData(),
      loadScoreData(),
      loadTimelineData(),
      getPrevEnabledDayGoalData(),
      loadInsight(),
    ]);
    // await loadAllGoalData();
    // await loadScoreData();
    // await loadTimelineData();
    // await getPrevEnabledDayGoalData();

    setShowLoader(false);
  };

  useEffect(() => {
    initPage();
  }, []);

  const updateGoals = async () => {
    setShowLoader(true);
    await loadAllGoalData();
    await loadInsight();
    setShowLoader(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <LogoLoader loading={showLoader}></LogoLoader>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={"space-evenly"}
        width={matches ? "100%" : "calc(100% - 110px)"}
        bgcolor={theme.palette.secondary.main}
        padding="1%"
      >
        {matches ? <CalendarMobileHeader /> : ""}
        <Box sx={{ overflow: "auto" }}>
          {showOverlay ? (
            <Grid container justifyContent="center" width="100%" rowGap={1}>
              <Grid item xs={12} sm={12} md={12} padding="0% 1% 0% 1%">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    justifyContent: "center",
                    borderRadius: "12px",
                    margin: "auto",
                    position: "relative",
                  }}
                >
                  <NoDataOverlay text={showOverlay} />
                  <ScoreCard
                    scores={ScoresDummyData}
                    prevScores={ScoresDummyData}
                  ></ScoreCard>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} padding="0% 1% 0% 1%">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    justifyContent: "center",
                    borderRadius: "12px",
                    margin: "auto",
                    position: "relative",
                  }}
                >
                  <NoDataOverlay text={showOverlay} />
                  <TimelineCard
                    timelineData={timeLineDummyData}
                    prevTimelineData={timeLineDummyData}
                    detailedTimelineData={[]}
                    fetchDetailedTimelineData={loadDetailedTimelineData}
                    summary={daySummaryDummy}
                    prevSummary={daySummaryDummy}
                    currentDateTime={landingPageDate}
                    prevDateTime={timeService.getPreviousEnabledDay(
                      landingPageDate
                    )}
                  ></TimelineCard>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container rowGap={1} justifyContent="center" width="100%">
              {timeSavingInsightData?.insightValid === true &&
              timeSavingInsightData?.insightIncomplete === false ? (
                <Grid item xs={12} sm={12} md={12} padding="0% 1% 0% 1%">
                  <InsightsV2Card
                    // text={promptString}
                    timeSavingInsight={timeSavingInsightData}
                    handleClick={onSetGoalInsightClick}
                    focusIncreaseInsight={focusIncreaseInsightData}
                  ></InsightsV2Card>
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12} sm={12} md={12} padding="0% 1% 0% 1%">
                <ScoreCard
                  scores={scoreData}
                  prevScores={prevScoreData}
                ></ScoreCard>
              </Grid>
              <Grid item xs={12} sm={12} md={12} padding="0% 1% 0% 1%">
                <TimelineCard
                  timelineData={timelineData}
                  prevTimelineData={prevTimelineData}
                  detailedTimelineData={detailedTimelineData}
                  fetchDetailedTimelineData={loadDetailedTimelineData}
                  summary={daySummary}
                  prevSummary={prevDaySummary}
                  currentDateTime={landingPageDate}
                  prevDateTime={timeService.getPreviousEnabledDay(
                    landingPageDate
                  )}
                ></TimelineCard>
              </Grid>
              <Grid item xs={12} sm={12} md={12} padding="0% 1% 0% 1%">
                <Box
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {showWaitForDataMessage ? (
                    `myTiro needs one day of active usage to populate goal options.  Expect them from ${dateForDataToShow}`
                  ) : (
                    <GoalsManagerCard
                      goalList={goalData}
                      deleteGoal={deleteGoal}
                      pinGoal={pinGoal}
                      date={landingPageDate}
                      customTemplateOptions={goalTemplateOptions}
                      updateGoals={updateGoals}
                    ></GoalsManagerCard>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} padding="0% 1% 0% 1%">
                {showWaitForDataMessage ? (
                  ""
                ) : (
                  <GoalSummary
                    goalData={prevDayGoalData}
                    date={timeService.getPreviousEnabledDay(landingPageDate)}
                    expanded={false}
                    currentDay={false}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
