import * as React from "react";
import { useEffect, useState } from "react";
import { Notifications } from "@mui/icons-material";
import {
  Menu,
  Badge,
  Box,
  IconButton,
  MenuItem,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import NotificationComponent from "./notification.component";
import AllNotifications from "./allnotifications.component";
import {
  NotificationStatus,
  NotificationType,
} from "./model/notifications.model";
import {
  dismissNotification,
  getAllNotifications,
  getUnreadNotifications,
  markNotificationRead,
} from "../../notification.service";

interface NotificationsMenuProps {}

const NotificationsMenu: React.FunctionComponent<
  NotificationsMenuProps
> = () => {
  const [count, setCount] = useState(0);
  const [notificationAnchor, setnotificationAnchor] = useState(
    null as (EventTarget & HTMLElement) | null
  );
  const showNotifications = Boolean(notificationAnchor);
  const [loading, setloading] = useState(true);
  const [hasRead, sethasRead] = useState(true);
  const [allNotifications, setallNotifications] = useState(
    [] as NotificationType[]
  );
  const [showDialog, setshowDialog] = useState(false);
  const [unreadNotifications, setunreadNotifications] = useState(
    [] as NotificationType[]
  );

  const isNotificationRead = (notification: NotificationType) => {
    return notification.status !== "UNREAD";
  };

  const initNotifications = (options: "panel" | "all"): void => {
    let ret = [] as NotificationType[];
    setloading(true);
    getAllNotifications()
      .then((notiList: NotificationType[]) => {
        setCount(countUnread(notiList));
        notiList.sort((a, b) => {
          if (isNotificationRead(a) && !isNotificationRead(b)) return 1;
          else if (!isNotificationRead(a) && isNotificationRead(b)) return -1;
          else return 0;
        });
        if (options === "panel") {
          for (const n of notiList.slice(0, 5)) {
            ret.push(n);
          }
          setallNotifications(ret);
          setloading(false);
        } else {
          for (const n of notiList) {
            ret.push(n);
          }
          setallNotifications(ret);
          setloading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setloading(false);
      });
  };

  const countUnread = (notifs: NotificationType[]) => {
    let count = 0;
    for (const i in notifs) {
      if (!isNotificationRead(notifs[i])) count += 1;
    }
    return count;
  };

  const markAsRead = async () => {
    let notifs = [...unreadNotifications];
    await Promise.all(
      notifs.map((notif) => {
        if(notif.action === null)
        markNotificationRead(notif.id).then(
          () => (notif.status = NotificationStatus.READ)
        );
      })
    );
    getNewNotifications();
  };

  const handleNotifications = (event: React.MouseEvent<HTMLElement>) => {
    if (notificationAnchor === null) {
      setnotificationAnchor(event.currentTarget);
    } else {
      setnotificationAnchor(null);
      markAsRead();
    }
  };

  const markNotiAsRead = (id: number) => {
    markNotificationRead(id).then();
  };

  const handleClose = () => {
    setnotificationAnchor(null);
    setCount(countUnread(unreadNotifications));
    markAsRead();
  };

  const closeDialog = (event: any, reason: string) => {
    setshowDialog(false);
  };

  const openDialog = () => {
    setshowDialog(true);
  };

  const handleActionClick = (link: string, id: number) => {
    window.location.href = link;
    markNotiAsRead(id);
  };

  const onDismiss = (id: number) => {
    dismissNotification(id)
      .then(getNewNotifications)
  }

  const getNewNotifications = async () => {
      await getUnreadNotifications()
        .then((notifs: NotificationType[]) => {
          let unreadNotifs = [];
          let c = 0;
          for (const notif of notifs) {
            unreadNotifs.unshift(notif);
            if (!isNotificationRead(notif)) c += 1;
          }
          setunreadNotifications(unreadNotifs);
          setCount(c);
          setloading(false);
          sethasRead(false);
        })
        .catch((err) => console.error(err));
  };

  const loadAllNotifications = async () => {
    initNotifications("all");
  };

  useEffect(() => {
    if (notificationAnchor !== null) {
      loadAllNotifications();
    }
  }, [notificationAnchor]);
  
  useEffect(() => {
    setCount(countUnread(unreadNotifications));
  }, [unreadNotifications]);

  useEffect(() => {
    getNewNotifications();
    const interval = setInterval(getNewNotifications, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box position="relative" margin="0 6%">
      <IconButton onClick={handleNotifications}>
        <Badge badgeContent={count} color="primary">
          <Notifications />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={notificationAnchor}
        open={showNotifications}
        onClose={handleClose}
        PaperProps={{ sx: { padding: "0!important", width: "420px", boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' } }}
        sx={{ transform: "translate3d(-140px, 0px, 0px)" }}
      >
        {loading ? (
          <Box
            width="100%"
            minHeight="75px"
            display="flex"
            alignContent="center"
            justifyContent={"center"}
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : unreadNotifications.length === 0 ? (
          <Box
            width="100%"
            minHeight="120px"
            display="flex"
            alignContent="center"
            justifyContent={"center"}
            alignItems="center"
          >
            <Typography variant="body1Light" textAlign={"center"}>
              You are up to date
            </Typography>
          </Box>
        ) : (
          <Box
          display={"block"}
            width="102%"
            margin="0 auto"
            maxHeight="360px"
            height="fit-content"
            overflow="scroll"
            padding={0}
            sx={{
              "&::-webkit-scrollbar": {
                display: "block",
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#E0E4EF",
                outline: 0,
                borderRadius: "40px",
                height: "40%",
              },
            }}
          >
            {unreadNotifications.map((notification, index) => (
                <NotificationComponent
                onDismiss={onDismiss}
                  key={index}
                  notification={notification}
                  onActionClick={handleActionClick}
                />
            ))}
          </Box>
        )}
        <MenuItem
          key={-1}
          disableRipple
          sx={{ borderTop: "1px lightgray solid" }}
        >
          <Button
            variant="onlytextpurple"
            onClick={openDialog}
            sx={{ margin: "auto" }}
          >
            See all
          </Button>
        </MenuItem>
      </Menu>
      <AllNotifications
        onActionClick={handleActionClick}
        notifications={allNotifications}
        onClose={closeDialog}
        open={showDialog}
      />
    </Box>
  );
};

export default NotificationsMenu;
