import { PropaneSharp } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { InsightDto } from "../../../services/insight-v2/insights-v2.model";
import { convertSecondsToHHMM } from "../../admin/dailyemail/dailyemail.service";
import { Insight } from "../../dashboard/insights/insights.model";
import { InsightBarChart } from "./charts/insight_v2-barchart.component";
import { InsightTimelineChart } from "./charts/insight_v2-chart.component";
import { InsightGoal } from "./insight-v2-goal.component";

export {};

interface InsightProgressCardProps {
  focusIncreaseInsight: InsightDto | undefined;
  timeSavingInsight: InsightDto | undefined;
  handleClick: (insight: InsightDto | undefined) => void;
}

export const InsightProgressCard = (props: InsightProgressCardProps) => {
  const [enableButton, setEnabledButton] = useState<boolean>(false);

  useEffect(() => {
    if (
      props.timeSavingInsight?.goalCreated === true ||
      props.timeSavingInsight?.goalDtos === undefined
    ) {
      setEnabledButton(false);
    } else {
      setEnabledButton(true);
    }
  }, [props.timeSavingInsight]);

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        height: "100%",
        borderRadius: "8px",
        // paddingY: "5px",
        // position: "relative",
      }}
    >
      <Box
        display={"flex"}
        alignSelf={"center"}
        sx={{
          //   width: "100vh",
          width: "100%",
          minHeight: 230,
          //   padding: "10px",
          textAlign: "center",
          //   height: "30vh",
        }}
      >
        <Grid
          container
          rowSpacing={1}
          alignItems={"center"}
          sx={{ paddingTop: "0px" }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={3}
            // justifyContent={"center"}
            // alignItems={"center"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              height={"100%"}
              sx={{
                minHeight: 180,
              }}
              // margin={"20px"}
              // justifyContent={"center"}
              //   width={"75%"}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItem: "center",
                  justifyContent: "flex-start",
                  alignSelf: "center",
                }}
              >
                <Typography variant="body1Bold">{`Last week, you could have`}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  paddingTop: "30px",
                }}
                //   width={"75%"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  margin={"10px"}
                  //   width={"75%"}
                >
                  <Typography variant="body1">Saved</Typography>
                  <Typography variant="h5">
                    {`${convertSecondsToHHMM(
                      props.timeSavingInsight?.prevWeekTarget?.fragDuration ?? 0
                    )}`}
                  </Typography>
                  {/* <Typography variant="body2">
                    {"spent in <1m sessions"}
                  </Typography> */}
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  margin={"10px"}
                  //   width={"75%"}
                >
                  <Typography variant="body1">Focused</Typography>
                  <Typography variant="h5">
                    {`${convertSecondsToHHMM(
                      props.focusIncreaseInsight?.prevWeekTarget
                        ?.fragDuration ?? 0
                    )}`}
                  </Typography>
                  <Typography variant="body2">{"more"}</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={12} lg={7} borderLeft={1} borderRight={1}>
            {/* <InsightTimelineChart
              prevWeekSummary={props.insight?.prevWeekTarget}
              curWeekSummary={props.insight?.curWeekTarget}
            ></InsightTimelineChart> */}
            <Box
              display={"flex"}
              flexDirection={"column"}
              // alignItems={"center"}
              justifyContent={"center"}
              // padding={"20px"}
            >
              {/* <Typography variant="body1Bold">{"Time spent in"}</Typography> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                // paddingX={"10px"}
              >
                <InsightBarChart
                  prevWeekSummary={props.timeSavingInsight?.prevWeekTarget}
                  curWeekSummary={props.timeSavingInsight?.curWeekTarget}
                  timeSave={true}
                  title={"Limit time wastage upto"}
                  topActivites={props.timeSavingInsight?.topActivities ?? []}
                ></InsightBarChart>
                <InsightBarChart
                  prevWeekSummary={props.focusIncreaseInsight?.prevWeekTarget}
                  curWeekSummary={props.focusIncreaseInsight?.curWeekTarget}
                  timeSave={false}
                  title={"Ensure minimum focus of"}
                  topActivites={props.focusIncreaseInsight?.topActivities ?? []}
                ></InsightBarChart>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "20px",
              }}
            >
              <InsightGoal
                focusIncreaseInsight={props.focusIncreaseInsight}
                timeSavingInsight={props.timeSavingInsight}
                handleClick={props.handleClick}
              ></InsightGoal>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
