// import { useApi } from "../api.service";
import {
  CreateGoalDto,
  CustomTemplateOptions,
  DimensionType,
  GoalTemplate,
  TargetType,
  ViewCustomTemplateOptionDto,
  ViewDimensionDto,
  ViewGoalDto,
} from "./goals.model";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { useEffect, useState } from "react";
import {
  getSession,
  handleSessionNotFound,
} from "../../accountcontext/account";

export const useGoalsApi = () => {
  // const api = useApi();

  const loadGoalsDataAndTemplates = async (
    startTime: number,
    endTime: number
  ) => {
    const promList = [
      getGoals(startTime, endTime),
      // getTempalates(startTime),
      getCustomTemplateOptions(startTime),
    ];
    return Promise.all(promList).then((values) => {
      const goals: ViewGoalDto[] = values[0] as ViewGoalDto[];
      // const templates: GoalTemplate[] = values[1] as GoalTemplate[];
      const options: CustomTemplateOptions[] =
        values[1] as CustomTemplateOptions[];

      // templates.forEach((td) => {
      //   const goal = goals.find(
      //     (g: ViewGoalDto) => g.templateInstanceId === td.id
      //   );
      //   td.goalCreated = goal === undefined ? false : true;
      // });

      const dimensionsOfGoals = goals.reduce(
        (acc: ViewDimensionDto[], cur: ViewGoalDto) => {
          return [...acc, ...cur.dimensions];
        },
        []
      );

      // const filteredTemplates = templates.filter((t) => {
      //   let status = t.dimensions.some((d) => {
      //     return dimensionsOfGoals.some((gd) => {
      //       return gd.dimension === d.dimension && gd.value === d.value;
      //     });
      //   });
      //   return !(status && !t.goalCreated);
      // });

      // console.log(filteredTemplates);

      const filteredOptions = options.filter((o) => {
        return !dimensionsOfGoals.some(
          (gd) =>
            gd.dimension === o.dimensionType && gd.value === o.dimensionValue
        );
      });
      // .sort((a, b) => {
      //   if (a.dimensionType === DimensionType.USER_CATEGORY) {
      //     return -1;
      //   }
      //   return 0;
      // });

      return {
        goals: goals,
        options: filteredOptions,
        templates: [],
      };
    });
  };

  const getSpecificGoal = async (
    dimensionType: DimensionType,
    value: string,
    startTime: number,
    endTime: number
  ) => {
    var params = [
      ["dimensionType", dimensionType],
      ["value", value],
      ["startTime", startTime.toString()],
      ["endTime", endTime.toString()],
    ];
    const searchParams = new URLSearchParams(params).toString();
    const url = `/goals/specific-multigoal?${searchParams}`;
    try {
      const ret = (await callApi(url, {
        method: "GET",
      })) as ViewGoalDto[];
      return ret;
    } catch {
      return [];
    }
  };

  const createNewGoal = async (createGoalDto: CreateGoalDto) => {
    const url = `/goals/multigoal`;
    console.log("GOAL: ", createGoalDto);
    await callApi(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(createGoalDto),
    });
  };

  const getGoals = async (
    startTime: number,
    endTime: number
  ): Promise<ViewGoalDto[]> => {
    var params = [
      ["startTime", startTime.toString()],
      ["endTime", endTime.toString()],
    ];
    const searchParams = new URLSearchParams(params).toString();
    const url = `/goals/multigoal?${searchParams}`;
    try {
      const ret = (await callApi(url, {
        method: "GET",
      })) as ViewGoalDto[];
      return ret;
    } catch {
      return [];
    }
  };

  const getTempalates = async (today: number): Promise<GoalTemplate[]> => {
    var params = [["day", today.toString()]];
    const searchParams = new URLSearchParams(params).toString();
    const url = `/goals/template?${searchParams}`;
    try {
      const ret = (await callApi(url, {
        method: "GET",
      })) as GoalTemplate[];
      return ret;
    } catch {
      return [];
    }
  };

  const getCustomTemplateOptions = async (
    today: number
  ): Promise<CustomTemplateOptions[]> => {
    var params = [["day", today.toString()]];
    const searchParams = new URLSearchParams(params).toString();
    const url = `/goals/custom-template-options?${searchParams}`;
    try {
      const ret = (await callApi(url, {
        method: "GET",
      })) as ViewCustomTemplateOptionDto;
      return pareseCustomTemplateOptions(ret);
    } catch {
      return [];
    }
  };

  const pareseCustomTemplateOptions = (
    data: ViewCustomTemplateOptionDto
  ): CustomTemplateOptions[] => {
    // remove devices and all
    if (data[DimensionType.DEVICE]) {
      delete data[DimensionType.DEVICE];
    }
    if (data[DimensionType.ALL]) {
      delete data[DimensionType.ALL];
    }

    const dimensionSortOrder: { [dimension: string]: number } = {
      [DimensionType.USER_CATEGORY]: 0,
      [DimensionType.CATEGORY]: 1,
      [DimensionType.APPLICATION]: 2,
    };

    const customOptions = Object.keys(data).reduce(
      (acc: CustomTemplateOptions[], dimension: string) => {
        const customTemplates: CustomTemplateOptions[] = Object.keys(
          data[dimension]
        )
          .filter((dimVal) => dimVal !== "null")
          .map((dimVal) => {
            return {
              dimensionType: dimension as DimensionType,
              dimensionValue: dimVal,
              targets: data[dimension][dimVal],
            };
          });
        customTemplates.sort(
          (a, b) =>
            b.targets[TargetType.TIME]?.value -
            a.targets[TargetType.TIME]?.value
        );
        return [...acc, ...customTemplates];
      },
      []
    );

    customOptions.sort((a, b) => {
      const aIndex = dimensionSortOrder[a.dimensionType];
      const bIndex = dimensionSortOrder[b.dimensionType];
      return aIndex - bIndex;
    });

    // customOptions.sort(
    //   (a, b) => {
    //     if (
    //       a.dimensionType === DimensionType.CATEGORY &&
    //       b.dimensionType === DimensionType.APPLICATION
    //     ) {
    //       return -1;
    //     } else if (
    //       a.dimensionType === DimensionType.APPLICATION &&
    //       b.dimensionType === DimensionType.CATEGORY
    //     ) {
    //       return +1;
    //     } else if (a.dimensionType === b.dimensionType) {
    //       return 0;
    //     } else {
    //       //should never come to this casse
    //       return 0;
    //     }
    //   }
    //   // -dimensionSortOrder[a.dimensionType] -
    //   // dimensionSortOrder[b.dimensionType]
    // );

    // console.log(customOptions);

    return customOptions;
  };

  const deleteGoal = async (goalId: number): Promise<void> => {
    const url = `/goals/multigoal/${goalId}`;
    try {
      await callApi(url, {
        method: "DELETE",
      });
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const pinGoal = async (goalId: number, pinned: boolean): Promise<void> => {
    var params = [["pinned", `${pinned}`]];
    const searchParams = new URLSearchParams(params).toString();
    const url = `/goals/multigoal/${goalId}/pin?${searchParams}`;
    try {
      await callApi(url, {
        method: "PATCH",
      });
    } catch (err) {
      console.log(err);
      return;
    }
  };

  return {
    createNewGoal,
    getSpecificGoal,
    getGoals,
    getTempalates,
    getCustomTemplateOptions,
    loadGoalsDataAndTemplates,
    deleteGoal,
    pinGoal,
  };
};

const BASE_USAGE_URL = `/api/usage`;
const session = async (): Promise<{ token: string; username: string }> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        resolve({ token: idToken, username: username });
      })
      .catch((err) => {
        console.log(err);
        handleSessionNotFound(err);
      });
  });
};

const callApi = async (api: string, reqOpts: RequestInit) => {
  const { token, username } = await session();
  reqOpts.headers = { ...reqOpts.headers, Authorization: `Bearer ${token}` };
  return new Promise((resolve, reject) => {
    const url = `${BASE_USAGE_URL}${api}`;
    fetch(url, reqOpts)
      .then((response) => response.text())
      .then((result) => JSON.parse(result))
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};
