import { CognitoUserSession } from "amazon-cognito-identity-js";
import {
  getSession,
  handleSessionNotFound,
} from "../../../accountcontext/account";

const BASE_URL = "/api/user/support";

export const raiseTicket = async (description: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getAccessToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        const email = session.getIdToken().payload["email"];
        const requestOptions: RequestInit = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            description,
            username,
            email,
          }),
        };
        fetch(`${BASE_URL}/ticket`, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      })
      .catch(handleSessionNotFound);
  });
};
