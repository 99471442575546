import React from "react";
import { Navigate, useLocation } from "react-router";
import {
  isAppInitialized,
  previousAppUrl,
  isUserAdmin,
} from "./services/app.service";

//https://www.robinwieruch.de/react-router-private-routes/

export const ProtectedRoute = (props: {
  redirectPath: string;
  children: JSX.Element;
}): any => {
  const [isInitialized, setIsInitialized] = React.useState(isAppInitialized());
  const location = useLocation();
  if (!isInitialized) {
    previousAppUrl.set(location.pathname + location.search);
    return <Navigate to={props.redirectPath} replace />;
  } else {
    return props.children;
  }
};

export const AdminProtectedRoute = (props: {
  redirectPath: string;
  children: JSX.Element;
}): any => {
  const [isInitialized, setIsInitialized] = React.useState(isAppInitialized());
  const [isAdmin, setIsAdmin] = React.useState<boolean>(isUserAdmin());
  const location = useLocation();

  if (isAdmin) {
    if (!isInitialized) {
      previousAppUrl.set(location.pathname + location.search);
      return <Navigate to={props.redirectPath} replace />;
    } else {
      return props.children;
    }
  } else {
    return <Navigate to={props.redirectPath} replace />;
  }
};
