import React from "react";
import { CssBaseline } from "@mui/material";
import "./index.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import { Dashboard } from "./components/dashboard/dashboard.component";
import CalendarContainer from "./pages/calendar/containercalendar.component";
import FormContainer from "./pages/Login/formcontainer/formcontainer.component";
import { PanelLayout } from "./Layouts/panellayout.component";
import { FormLayout } from "./Layouts/formlayout.component";
import { Account } from "./accountcontext/account";

import Settings from "./components/settings/settings.component";
import SupportTab from "./pages/support-tab/support-tab.component";

import { GoogleCallbackPage } from "./pages/integrationCallbacks/google_callback";
import UserInfo from "./components/admin/userinfo/userinfo.component";
import { LandingPage } from "./components/landingpage/landingpage.components";

import { ErrorLog } from "./components/admin/errorlog/errorlog.component";
import { ErrorPage } from "./pages/errorpage/errorpage.component";
import { UnderMaintenance } from "./pages/undermaintenance/undermaintenance.component";

import { AppLoading } from "./pages/app-loading/appLoading.component";
import { WelcomeOnboard } from "./pages/welcome-onboard/welcome-onboard.component";
import {
  AdminProtectedRoute,
  ProtectedRoute,
} from "./protectedroute.component";
import { Support } from "./pages/support/support.component";
import { AdminPage } from "./pages/admin/admin.page";
import { OverAllBenefits } from "./pages/admin/tabs/benefits.component";
import { UserOverview } from "./components/admin/useroverview/useroverview.component";
import { DailyEmail } from "./components/admin/dailyemail/dailyemail.component";
import { SupportTickets } from "./components/admin/supporttickets/supporttickets.component";
import { PrivacyPage } from "./pages/privacy/privacy.component";
import { TestComponent } from "./test.component";

import { ClearGoals } from "./clear-goals.component";
import { TOSPage } from "./pages/terms-of-service/tos.component";
export class App extends React.Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: "#f3f4f6",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          height: "100vh",
        }}
      >
        <CssBaseline />

        <Account>
          <Router>
            <Routes>
              <Route path="/">
                <Route path="cleargoals" element={<ClearGoals />} />
                <Route
                  path="integration/google/callback"
                  element={<GoogleCallbackPage />}
                />
                <Route path="privacy" element={<PrivacyPage />} />
                <Route path="terms-of-service" element={<TOSPage />} />
                <Route path="app-loading" element={<AppLoading />} />
                <Route path="somethingwrong" element={<ErrorPage />}></Route>
                <Route
                  path="maintenance"
                  element={<UnderMaintenance />}
                ></Route>
                <Route
                  path="administration"
                  element={
                    <AdminProtectedRoute redirectPath={"/app-loading"}>
                      <AdminPage></AdminPage>
                    </AdminProtectedRoute>
                  }
                >
                  <Route path="useroverview" element={<UserOverview />}></Route>
                  <Route path="benefits" element={<OverAllBenefits />}></Route>
                  <Route path="userinfo" element={<UserInfo />}></Route>
                  <Route path="dailyemail" element={<DailyEmail />}></Route>
                  <Route path="errorlogs" element={<ErrorLog />}></Route>
                  <Route
                    path="support-tickets"
                    element={<SupportTickets />}
                  ></Route>
                  <Route path="userinfo" element={<UserInfo />}></Route>
                  <Route path="error" element={<ErrorLog />}></Route>
                  <Route path="support" element={<SupportTickets />}></Route>
                </Route>
                <Route
                  path="createprofile"
                  element={
                    <ProtectedRoute redirectPath="/app-loading">
                      <WelcomeOnboard />
                    </ProtectedRoute>
                  }
                ></Route>
                <Route element={<PanelLayout showInsights></PanelLayout>}>
                  <Route
                    path="dashboard"
                    element={
                      <ProtectedRoute redirectPath="/app-loading">
                        <Dashboard></Dashboard>
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="calendar/*"
                    element={
                      <ProtectedRoute redirectPath="/app-loading">
                        <CalendarContainer />
                      </ProtectedRoute>
                    }
                  ></Route>
                </Route>
                <Route element={<PanelLayout></PanelLayout>}>
                  <Route
                    path="settings/*"
                    element={
                      <ProtectedRoute redirectPath="/app-loading">
                        <Settings></Settings>
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="home/*"
                    element={
                      <ProtectedRoute redirectPath="/app-loading">
                        <LandingPage />
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path="support/*"
                    element={
                      <ProtectedRoute redirectPath="/app-loading">
                        <SupportTab />
                      </ProtectedRoute>
                    }
                  ></Route>
                </Route>
                <Route element={<FormLayout></FormLayout>}>
                  <Route path="login/*" element={<FormContainer />}></Route>
                </Route>
              </Route>
              <Route index element={<Navigate to="app-loading" />} />
            </Routes>
          </Router>
        </Account>
      </div>
    );
  }
}
