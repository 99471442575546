import { Theme } from "@mui/material";
import { createStyles } from "@mui/styles";

export const styles = (theme: Theme) =>
  createStyles({
    buttonText: {
      color: "#7F8493",
      marginTop: "7px",
    },
    buttonTextSelected: {
      fontWeight: "bold",
      color: "#050505",
      marginTop: "7px",
    },
    buttonBackground: {
      width: "48px",
      height: "48px",
      aspectRatio: "1/1",
      borderRadius: "12px",
      margin: "0 auto",
      paddingTop: "10%",
    },
    unselectedButtonBackground: {
      backgroundColor: "transparent",
    },
    selectedButtonBackground: {
      backgroundColor: "#643992",
    },
    imageIcon: {
      height: "100%",
    },
    iconRoot: {
      textAlign: "center",
      display: "block",
    },
  });
