import { CognitoUserSession } from "amazon-cognito-identity-js";
import { getSession } from "../../../accountcontext/account";
import { SupportTicket } from "./supportticket.model";

const BASE_URL = "/api/user";


export const getSupportTickets = async (): Promise<SupportTicket[]> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const url = `${BASE_URL}/admin/support-tickets`;
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      })
      .catch((err) => reject(err));
  });
};