import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { LogoLoader } from "../../../loader/loader.component";
import { AppTimelineChart } from "./charts/app-timeline-chart.component";
import { DateTime } from "luxon";
import { appusagedialogdummyData } from "./dummydata";
import { AppFragmentation } from "./app-fragementation.component";
import { AppTimeline } from "./app-timeline.component";
import { AppSessionDistribution } from "./app-session-distribution.component";
import { AppDialogGoal } from "./unused/app-goal-component";
import {
  DaySummaryDto,
  TimelineDto,
} from "../../../../services/timeline/timeline.model";
import { GoalCreatorCard } from "../../../landingpage/landingcards/goals-manager/components/goal-creator-card.component";
import {
  CreateGoalDto,
  CustomTemplateOptions,
} from "../../../../services/goals/goals.model";
import { useGoalsApi } from "../../../../services/goals/goals.service";
import { AppSetTargetsCard } from "./unused/app-set-targets.component";
import { AppSetGoalCard } from "./unused/app-set-goal.component";
import {
  AppContributionScore,
  AppStats,
  FragmentationData,
  SessionMetrics,
} from "../../dashboard.models";
import { AppUsageChart } from "./charts/app-usage-graphic.component";
import { AppConsistency } from "./unused/app-consistency.component";
import { AppHeatmapChart } from "./charts/app-heatmap.component";
// import { DetailedTimelineDto } from "../../../../services/timeline/timeline.model";

interface AppUsageDialogProps {
  timelineData: TimelineDto[];
  prevTimelineData: TimelineDto[];
  summary: DaySummaryDto;
  prevSummary: DaySummaryDto;
  appStats: AppStats;
  fragmentationData: FragmentationData;
  appContribution: AppContributionScore;
  maxSes: SessionMetrics;
  minSes: SessionMetrics;
  appWeeklyData: DaySummaryDto[];
  isWeek: boolean;
  // timelineHour: number;
  appName: string;
  dateEpoch: number;
  open: boolean;
  onClose: (event: any, reason: string) => void;
}

export const AppUsageDialog = (props: AppUsageDialogProps) => {
  const { onClose, open } = props;
  const [loading, setLoading] = useState(false);
  const goalsApi = useGoalsApi();
  const [appName, setAppName] = useState<string>("App");
  const [selectedOption, setSelectedOption] = useState<CustomTemplateOptions>();
  const [appTimelineData, setAppTimelineData] = useState<TimelineDto[]>([]);
  const [prevAppTimelineData, setPrevAppTimelineData] = useState<TimelineDto[]>(
    []
  );
  const [appWeeklySummaryData, setAppWeeklySummaryData] =
    useState<DaySummaryDto[]>();
  const [appSummaryData, setAppSummaryData] = useState<DaySummaryDto>({
    time: 0,
    focus: 0,
    activities: 0,
  });
  const [appStats, setAppStats] = useState<AppStats>();
  const [maxSes, setMaxSes] = useState<SessionMetrics>({
    duration: 0,
    startTime: 0,
    endTime: 0,
  });
  const [minSes, setMinSes] = useState<SessionMetrics>({
    duration: 0,
    startTime: 0,
    endTime: 0,
  });
  const [prevAppSummaryData, setPrevAppSummaryData] = useState<DaySummaryDto>({
    time: 0,
    focus: 0,
    activities: 0,
  });
  const [dateEpoch, setDateEpoch] = useState<number>(0);

  const createGoal = (goal: CreateGoalDto) => {
    if (goal) {
      goalsApi.createNewGoal(goal).then(() => {
        // props.updateGoals();
      });
    }
  };
  const [fragData, setFragData] = useState<FragmentationData>({});
  const [appContribution, setAppContribution] = useState<AppContributionScore>(
    {}
  );

  useEffect(() => {
    setAppContribution(props.appContribution);
  }, [props.appContribution]);

  useEffect(() => {
    setAppWeeklySummaryData(props.appWeeklyData);
  }, [props.appWeeklyData]);

  useEffect(() => {
    setAppStats(props.appStats);
  }, [props.appStats]);

  useEffect(() => {
    if (props.fragmentationData) {
      setFragData(props.fragmentationData);
    }
  }, [props.fragmentationData]);

  useEffect(() => {
    //TODO remove log
    // console.log("date epoch at comop: ", props.dateEpoch);
    setDateEpoch(props.dateEpoch);
  }, [props.dateEpoch]);

  useEffect(() => {
    setMinSes(props.minSes);
  }, [props.minSes]);

  useEffect(() => {
    setMaxSes(props.maxSes);
  }, [props.maxSes]);

  useEffect(() => {
    setAppName(props.appName);
  }, [props.appName]);

  useEffect(() => {
    setAppTimelineData(props.timelineData);
    setPrevAppTimelineData(props.prevTimelineData);
  }, [props.timelineData, props.prevTimelineData]);

  useEffect(() => {
    setAppSummaryData(props.summary);
    setPrevAppSummaryData(props.prevSummary);
  }, [props.summary, props.prevSummary]);

  const handleClose = () => {
    onClose(undefined, "");
  };

  return (
    <Dialog onClose={() => handleClose()} open={open} fullWidth maxWidth="lg">
      <LogoLoader loading={loading} />
      <DialogTitle sx={{ bgcolor: "#F3F5F6" }}>
        <Box sx={{ position: "relative", height: "20px" }}>
          <CloseIcon
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              ":hover": { cursor: "pointer" },
            }}
          ></CloseIcon>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ bgcolor: "#F3F5F6" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {props.isWeek ? (
              <AppHeatmapChart
                chartData={appTimelineData}
                appName={props.appName}
              ></AppHeatmapChart>
            ) : (
              <AppTimeline
                timelineData={appTimelineData}
                prevTimelineData={prevAppTimelineData}
                dateEpoch={dateEpoch}
                summary={appSummaryData}
                prevSummary={prevAppSummaryData}
                appName={props.appName}
              ></AppTimeline>
            )}
          </Grid>
          <Grid item xs={12} md={5}>
            <AppSessionDistribution
              appContribution={appContribution}
              maxSes={maxSes}
              minSes={minSes}
              summaryData={appSummaryData}
              appStats={appStats ?? {}}
              appName={appName}
            ></AppSessionDistribution>
          </Grid>
          <Grid item xs={12} md={7}>
            <AppFragmentation
              fragmentationData={fragData}
              appName={appName}
            ></AppFragmentation>
          </Grid>
          <Grid item xs={12} md={12}>
            <AppUsageChart
              appWeeklyData={appWeeklySummaryData ?? []}
              appName={appName}
              isWeek={props.isWeek}
            ></AppUsageChart>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
