import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteInputChangeReason, Box, Stack, TextField, Typography } from "@mui/material"
import { ReactChild, ReactFragment, ReactPortal, useState } from "react"
import { SupportTabContent } from "../support-tab.component"
import { SupportsPage } from "../supportpage.component"
import { GlossaryContent, glossaryContent } from "./glossary-content"

interface GlossaryProps { }

export const Glossary = (props: GlossaryProps) => {

    const [inputText, setInputText] = useState("");

    const inputHandler = (input: { target: { value: string } }) => {
        setInputText(input.target.value.toLowerCase())
    }

    const onComplete = (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason, details?: AutocompleteChangeDetails<string> | undefined)=>{
        if(reason === 'clear') {
            setInputText('')
        } else {
            setInputText(value.toLowerCase());
        }
    }

    return (
        <SupportsPage
            title={SupportTabContent.glossary.title}
            subtitle={SupportTabContent.glossary.description}
            loading={false}
        >
            <Stack spacing={2} sx={{}}>
                <Autocomplete
                    freeSolo
                    id="glossary-terms"
                    disableClearable
                    options={glossaryContent.map((option) => option.term)}
                    onInputChange={onComplete}
                    renderInput={(params) => (
                        <Stack>
                            <TextField
                                {...params}
                                label="Search glossary"
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                            <GlossaryList glossaryContent={glossaryContent} input={inputText}></GlossaryList>
                        </Stack>
                    )}
                />
            </Stack>
        </SupportsPage>

    )
}


interface GlossaryListProps {
    glossaryContent: GlossaryContent[],
    input: string,
}


const GlossaryList = (props: GlossaryListProps) => {
    const filteredData = props.glossaryContent.filter((content) => {
        if (props.input === '') {
            return content;
        }
        return content.term.toLowerCase().includes(props.input)
    })
    return (
        <>
            {filteredData.map((content: GlossaryContent) => {
                return (<Content content={content}></Content>)
            })}
        </>
    )
}

interface ContentProps {
    content: GlossaryContent,
}

const Content = (props: ContentProps) => {
    return (
        <Box display="block" sx={{ p: 2 }}>
            <Typography variant="h6">
                {props.content.term}
            </Typography>
            <Typography variant="subtitle1">
                {`${props.content.definition}`}
            </Typography>
            {props.content.examples.map((example: string) => {
                return (
                    <Typography variant="subtitle2">
                        {`"${example}"`}
                    </Typography>
                )
            })}
        </Box>
    )
}
