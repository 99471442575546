import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import ErrorBoundary from "../../../errorboundary.component";
import GoalCard from "./goalcard/goalcard.component";
import { GoalData, RawData } from "./model/goals.model";
import { ErrorMessage } from "../dashboard.models";
import "./styles/goals.styles.css";
import { NoDataOverlay } from "../nodataoverlay/nodataoverlay.component";
import { checkIfAppInstalled } from "../utils/dashboard.utils";
import { displayOverlay } from "../../../overlay.service";
import { ViewGoalDto } from "../../../services/goals/goals.model";
import { MiniGoalCard } from "../../landingpage/landingcards/goals-summary/mini-goalcard.component";
import { DateTime } from "luxon";
import { appState } from "../../../services/app.service";
import { deleteGoal, fetchMultiGoalsData } from "../dashboard.service";
let dummyGoalJson = require("./dummyGoal.json");

const dummyGoal = dummyGoalJson as ViewGoalDto[];

interface GoalsProps {
  goals: Array<GoalData> & ErrorMessage;
  multiGoals: ViewGoalDto[];
}

interface GoalsState {
  goals: Array<GoalData> & ErrorMessage;
  showOverlay: string | null;
  multiGoals: ViewGoalDto[];
}

class Goals extends React.Component<GoalsProps, GoalsState> {
  scrollDiv: any;
  scrollOffset: number;
  constructor(props: GoalsProps) {
    super(props);
    this.state = {
      goals: props.goals,
      showOverlay: null,
      multiGoals: props.multiGoals,
    };
    this.scrollDiv = React.createRef();
    this.scrollOffset = 100;
    this.horizontalScroll = this.horizontalScroll.bind(this);
  }

  componentDidMount() {
    if (this.state.showOverlay === null) {
      displayOverlay("COMPONENT_DASHBOARD_GOALS").then((str: string | null) =>
        this.setState({
          showOverlay: str,
        })
      );
    }
  }

  horizontalScroll(offset: number) {
    this.scrollDiv.current.scrollLeft += offset;
  }

  displayGoalCards() {
    let result = [];
    const { goals, showOverlay, multiGoals } = this.state;
    if (showOverlay) {
      return (
        <div
          className="card-container"
          ref={this.scrollDiv}
          style={{ position: "relative" }}
        >
          {dummyGoal.map((g) => (
            <MiniGoalCard goal={g} currentDay={true} />
          ))}
          <NoDataOverlay text={showOverlay} state="benefits" />
        </div>
      );
    }
    if (!multiGoals || multiGoals.length === 0 || goals.message !== undefined) {
      return (
        <Box
          sx={{
            margin: "auto",
            height: "200px",
            borderRadius: "8px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            border: "1px dashed #AAAFBB",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "light", color: "#AAAFBB", margin: "auto" }}
          >
            {goals.message !== undefined
              ? "Goals are currently unavailable"
              : "There are currently no goals"}
          </Typography>
        </Box>
      );
    }
    // for (const [index, goal] of goals
    //   .sort((a, b) => {
    //     if (a.autoDetected && b.autoDetected) {
    //       if (a.dailyProductivityGoal && !b.dailyProductivityGoal) {
    //         return -1;
    //       } else if (!a.dailyProductivityGoal && b.dailyProductivityGoal) {
    //         return 1;
    //       } else {
    //         let da = new Date(a.createdAt);
    //         let db = new Date(b.createdAt);
    //         return db.getTime() - da.getTime();
    //       }
    //     } else {
    //       return 0;
    //     }
    //   })
    //   .entries()) {
    // result.push(<GoalCard key={index} goal={goal} />);
    // if (count === 3) break;
    // }
    for (const mgoal of multiGoals) {
      result.push(
        <Box sx={{ marginRight: "10px" }} key={mgoal.id}>
          <MiniGoalCard goal={mgoal} currentDay={true} />
        </Box>
      );
    }
    return (
      <div className="card-container" ref={this.scrollDiv}>
        {result}
      </div>
    );
  }

  async reloadGoalData() {
    const goals = await fetchMultiGoalsData();
    this.setState({ multiGoals: goals });
  }

  async deleteGoal(goalId: number) {
    await deleteGoal(goalId);
    await this.reloadGoalData();
  }

  render() {
    const today = DateTime.now();
    const firstName = `${appState.get()?.account.profile?.firstName}'s`;
    return (
      <Box
        sx={{ display: "block", marginTop: "2%", padding: "0%", width: "100%" }}
      >
        <ErrorBoundary>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {firstName} myTiro Goals for{" "}
              {`${today.monthLong} ${today.day}, ${today.year}`}
            </Typography>
            <div
              style={{
                width: "20%",
                minWidth: "max-content",
                textAlign: "right",
              }}
            >
              {/* <Button
                variant="rounded_purple"
                sx={{
                  color: "white",
                  backgroundColor: "#7B46B5",
                  textTransform: "none",
                  borderRadius: "10px",
                  padding: "8px 12px",
                }}
                startIcon={
                  <img src="/assets/dashboardcards/Add.svg" height={"16px"} />
                }
              >
                Add
              </Button> */}
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              height: "auto",
              margin: "2% 0",
            }}
          >
            <IconButton
              key={-1}
              onClick={() => {
                this.horizontalScroll(-1 * this.scrollOffset);
              }}
              size="small"
              disabled={this.state.showOverlay !== null}
              //   sx={{height: '32px', width: '32px', marginRight: '2%'}}
            >
              <img src="/assets/dashboardcards/Previous.svg" height={"32px"} />
            </IconButton>
            {this.displayGoalCards()}
            <IconButton
              key={-2}
              onClick={() => {
                this.horizontalScroll(this.scrollOffset);
              }}
              disabled={this.state.showOverlay !== null}
              size="small"
              //   sx={{height: '32px', width: '32px', marginRight: '2%'}}
            >
              <img src="/assets/dashboardcards/Next.svg" height={"32px"} />
            </IconButton>
          </Box>
        </ErrorBoundary>
      </Box>
    );
  }
}

export default Goals;
