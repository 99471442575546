import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Account, getAccount } from '../components/profile.service';

export const useName = () => {
    const [displayName, setdisplayName] = useState("");
    const [firstName, setfirstName] = useState("");
    let navigate = useNavigate();
    const getNameFromAccount = (account: Account) => {
        if(account.profile === undefined || account.profile === null){
          navigate("/login");
        } else {
          let firstName = account.profile.firstName;
          let lastName = account.profile.lastName;
          if(lastName.length === 0){
            setdisplayName(firstName);
            setfirstName(firstName);
          } else {
            setdisplayName(firstName + " " + lastName);
            setfirstName(firstName);
          }
        }
      }
    
      useEffect(() => {
        const id = localStorage.getItem("id");
        if(id !== null){
          getAccount(id)
            .then(getNameFromAccount)
            .catch((err) => navigate("/login"))
        }
      }, []);

      return {displayName, setdisplayName, firstName};
}