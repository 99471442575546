import { Link, List, ListItemText, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { SupportTabContent } from "../../support-tab.component";
import { SupportsPage } from "../../supportpage.component";
import { goalsRings, GoalsRings } from "./goals-content";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const tabSpace = <Fragment>&nbsp;&nbsp;&nbsp;&nbsp;</Fragment>;

interface GoalsProps {}

export const GoalsBlog = (props: GoalsProps) => {
  return (
    <>
      <Typography
        variant="h6"
        display="block"
        id={SupportTabContent.goals.id}
      ></Typography>
      <SupportsPage
        title={SupportTabContent.goals.title}
        subtitle={SupportTabContent.goals.description}
        loading={false}
      >
        <GoalsHowTo goalsRings={goalsRings}></GoalsHowTo>
      </SupportsPage>
    </>
  );
};

interface GoalsHowToProps {
  goalsRings: GoalsRings[];
}

const GoalsHowTo = (props: GoalsHowToProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h6" display="block">
        Build healthy digital time habits using myTiro Goals
      </Typography>
      <Typography variant="body1" display="block">
        Healthy time habits lead to higher productivity, increased efficiency,
        more deep work, and improved mental well-being.
      </Typography>
      <Typography variant="body1" display="block">
        Now you can be deliberate with your time with myTiro Goals.
      </Typography>
      <Typography variant="body1" display="block">
        myTiro Goals will help you set targets on:
      </Typography>
      <Typography variant="body1" display="block">
        {props.goalsRings.map((icon) => {
          return (
            <ul>
              <li>
                <p style={{ color: "black" }}>{icon.text}</p>
                <img src={icon.imageUrl} alt=""></img>
              </li>
            </ul>
          );
        })}
      </Typography>
      <Typography variant="body1" display="block">
        You can set targets for one or all three of the above.
      </Typography>
      <Typography variant="body1" display="block">
        To use Goals to their full potential, we recommend spending ~1 min each
        at the start of your day to set your goals, check your progress
        throughout the day, and review how you did at the end of the day.
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/goals-new/goals-ui.png" width={"700"}></img>
      </Typography>
      <Typography variant="body1" display="block">
        The above screenshot shows how you can create and monitor goals.
      </Typography>
      <Typography variant="body1" display="block">
        <b>Step 1:</b> Select a goal from the left most panel. You can select a
        goal from your custom categories, from myTiro categories, or from
        specific applications or websites.
      </Typography>
      <Typography variant="body1" display="block">
        <b>Step 2:</b> Set Time, Focus, Switch targets for the selected goals.
        Putting 0 value will exclude it from being a part of that goal. You can
        set a min or a max for any value. Click on Set Goal after you have
        entered your choices to create the goal.
      </Typography>
      <Typography variant="body1" display="block">
        <b>Step 3:</b> You can monitor all your goals in the third panel. The
        progress bar will tell you how well you are doing against each goal. You
        can choose to pin your goals if you want to monitor them regularly. You
        can also choose to delete your goals.
      </Typography>
      <Typography variant="body1" display="block" fontWeight={"bold"}>
        Set Custom Goal
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/goals-new/set-goal.png" width={"200"}></img>
      </Typography>
      <Typography variant="body1" display="block">
        When setting a custom goal, you can choose an application or category
        from what you have recently used and set how much minimum/maximum Time
        you want to spend on it, how much Focus time, and limit Switches
        (open/access) for that Application or category.
      </Typography>
      <Typography variant="body1" display="block">
        The above goal is to spend at least 326 minutes, at least 108 focused
        minutes, and limit open/access to 159 only in Programming (custom
        category as defined by the user).
      </Typography>
      <Typography variant="body1" display="block">
        myTiro will pre-fill the latest values for each Application or Category
        making it easier for you to make changes.
      </Typography>
      <Typography variant="body1" display="block" fontWeight={"bold"}>
        How to monitor your goals
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/goals-new/monitor-goal.png" width={"700"}></img>
      </Typography>
      <Typography variant="body1" display="block">
        Two goals, Email and Office Apps, are being monitored. You can check
        goals on the <Link href="home">Landing Page</Link> and the{" "}
        <Link href="dashboard">Dashboard Page</Link>.
      </Typography>

      <Typography variant="body1" display="block" fontWeight="bold">
        Final Thoughts
      </Typography>
      <Typography variant="body1" display="block">
        {
          <ol>
            <li>
              There are no right or wrong goals. And goals will change daily
              depending on what we want to accomplish and how we feel. Here are
              some examples of how varied we can be in our goals:
              <ol type="a">
                <li>
                  If you are targeting deep work, set higher Focus and lower
                  Switch targets to work on one or two activities.
                </li>
                <li>
                  If you want to clear your inbox, set higher times for Email.
                </li>
                <li>
                  If you want to research a topic online, set higher times for
                  browsing, higher focus time, and a higher switching target.
                </li>
              </ol>
            </li>
            <li>
              We recommend you start by setting 3-4 goals and then add more
              goals slowly as you get comfortable with them. We suggest starting
              by setting the following goals:
              <ol type="a">
                <li>
                  Improve by 1% - will let you increase your Focus and reduce
                  the switching by 1% daily. Meeting this goal will lead to
                  significant compound benefits.
                </li>
                <li>
                  Optimize Email – will let you access Email for the same time
                  as the last working day but with a 1% increase in Focus and 1%
                  less Switching. Helps process Email with awareness.
                </li>
                <li>
                  Set Custom Goal – set one or two custom goals by setting the
                  Time value for your most used categories (listed first in the
                  drop-down). Doing so will help you be deliberate about where
                  you want to spend your time. Subsequently, you can add Focus
                  and Switch metrics.
                </li>
              </ol>
            </li>
            <li>You can set as many Custom Goals as you like.</li>
            <li>
              Whenever you add a Custom Goal, myTiro creates a blank template
              for another Custom Goal.
            </li>
            <li>
              Custom Goals in the drop-down menu are ordered in descending order
              of the time you spent on them on the last working day. For
              example, if you spent the most time on Desktop Apps, that will be
              the first choice in the drop-down list.
            </li>
            <li>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                You can delete any goal on the&nbsp;
                <Link href={"home"}> Landing Page </Link>&nbsp;by clicking on
                the <DeleteOutlineOutlinedIcon />.
              </div>
            </li>
            <li>Focus can only be greater than 20 minutes.</li>
            <li>Time must be greater than or equal to Focus.</li>
            <li>
              Custom goals can be set only on applications or categories you
              have used in the past and the last values will be pre-filled in
              Time, Focus and Switch boxes.
            </li>
            <li>
              If a 0 value is applied to Time, Focus, or Switch metrics, then
              myTiro will not track that metric for that goal.
            </li>
            <li>
              While setting your goals, you can specify whether Time, Focus, or
              Switch are minimum or maximum values. We suggest:
              <ol type="a">
                <li>
                  If you want to limit your Twitter time, set a custom goal for
                  www.twitter.com with a Time Value of 30 at a maximum with 0
                  for focus and Switch.
                </li>
                <li>
                  If you want to clear your inbox with focus and with limited
                  sessions, then you can set a custom goal for Email with a Time
                  Value of 120 at a minimum, Focus of 60 at a minimum, and
                  Switch at 10 at a maximum.
                </li>
                <li>
                  Suppose you are feeling ambitious, and want to finish your ppt
                  draft in one sitting, and you know it’s going to take an hour
                  to do it. Then set a goal for Office Apps with a Time Value of
                  60 at a minimum, a Focus Value of 60 at a minimum, and a
                  Switch setting of 1 at maximum.
                </li>
              </ol>
            </li>
          </ol>
        }
      </Typography>
      <Typography variant="body1" display="block"></Typography>
    </Stack>
  );
};

// <Typography variant="body1" display="block">
//         You can use the following three pre-built templates to set your goals or
//         create your own.
//       </Typography>
//       <Typography variant="body1" display="block" fontWeight="bold">
//         Limit Device Time
//       </Typography>
//       <Typography variant="body1" display="block">
//         <img
//           src="assets/how-to/goals-howto/limitdevicetime.png"
//           width="600"
//         ></img>
//       </Typography>
//       <Typography variant="body1" display="block">
//         Set a minimum or a maximum time you plan to be active on your devices.
//         This goal is the simplest way to start being deliberate about your
//         digital habits. For example, on days when you have lots of meetings or
//         if you want to think, set a maximum device time of 2-3 hours.
//       </Typography>
//       <Typography variant="body1" display="block"></Typography>
//       <Typography variant="body1" display="block" fontWeight="bold">
//         Improve by 1%
//       </Typography>
//       <Typography variant="body1" display="block">
//         <img
//           src="assets/how-to/goals-howto/improveby1perc.png"
//           width="400"
//         ></img>
//       </Typography>
//       <Typography variant="body1" display="block">
//         Focus time is set to 1% more than the last working day. For example, if
//         you previously focused for 156 minutes, then today’s goal is to focus
//         for at least 158 minutes.
//       </Typography>
//       <Typography variant="body1" display="block">
//         AND
//       </Typography>
//       <Typography variant="body1" display="block">
//         Switching is set to 1% less than the last working day. For example, if
//         you previously switched 445 times, today’s goal is to limit switching to
//         450 or less.
//       </Typography>
//       <Typography variant="body1" display="block" fontWeight="bold">
//         Optimize Email
//       </Typography>
//       <Typography variant="body1" display="block">
//         <img
//           src="assets/how-to/goals-howto/optimizeemail.png"
//           width="400"
//         ></img>
//       </Typography>
//       <Typography variant="body1" display="block">
//         Total Email time is set to the same as last working day. For example, if
//         you previously spent 12 minutes on Email, today’s goal is to spend no
//         more than 12 minutes on Email.
//       </Typography>
//       <Typography variant="body1" display="block">
//         AND
//       </Typography>
//       <Typography variant="body1" display="block">
//         Email Focus time is 1% more than the last working day. For example, if
//         you previously focused for 20 minutes on Email, today’s goal is to focus
//         for at least 21 minutes on Email.
//       </Typography>
//       <Typography variant="body1" display="block">
//         AND
//       </Typography>
//       <Typography variant="body1" display="block">
//         Accessing (Switching) Email is set to 1% less than the last working day.
//         For example, if you previously accessed Email 35 times, today’s goal is
//         to limit Email access to 34 or less.
//       </Typography>
//       {/* <Typography variant="body1" display="block">
//                 Be Deliberate in {'<Application>'}
//             </Typography>
//             <Typography variant="body1" display="block">
//                 {'<screen shot>'}
//             </Typography>
//             <Typography variant="body1" display="block">
//                 myTiro will automatically pick up to three goals based on repeated activities.  For example, suppose you are regularly programming.  In that case, myTiro will propose a goal to Be Deliberate about how much time you want to spend programming because choosing is better than being ad-hoc.  You can choose to add focus or limit switching targets.
//             </Typography> */}
//       <Typography variant="body1" display="block" fontWeight="bold">
//         Set Custom Goal
//       </Typography>
//       <Typography variant="body1" display="block">
//         <Stack>
//           <img
//             src="assets/how-to/goals-howto/customgoal1.png"
//             width="400"
//           ></img>
//           &nbsp;&nbsp;
//           <img
//             src="assets/how-to/goals-howto/customgoal2.png"
//             width="400"
//           ></img>
//         </Stack>
//       </Typography>
//       <Typography variant="body1" display="block">
//         When setting a custom goal, you can choose an application or category
//         from what you have recently used and set how much Time you want to spend
//         on it, how much Focus time, and limit Switches (open/access) for that
//         Application or category.
//       </Typography>
//       <Typography variant="body1" display="block">
//         myTiro will pre-fill the latest values for each Application or Category
//         making it easier for you to make changes.
//       </Typography>
//       <Typography variant="body1" display="block" fontWeight="bold">
//         Setting a goal
//       </Typography>
//       <Typography variant="body1" display="block">
//         <img src="assets/how-to/goals-howto/settinggoal.png" width="400"></img>
//       </Typography>
//       <Typography variant="body1" display="block">
//         Once you have created a goal, the associated template will be greyed
//         out.
//       </Typography>
//       <Typography variant="body1" display="block" fontWeight="bold">
//         How to check your goals
//       </Typography>
//       <Typography variant="body1" display="block">
//         <img
//           src="assets/how-to/goals-howto/limitdevicetimegoal.png"
//           width="800"
//         ></img>
//       </Typography>
//       {/* <Typography variant="body1" display="block">
//         <img
//           src="assets/how-to/goals-howto/limitdevicetimegoal.png"
//           width="700"
//         ></img>
//       </Typography> */}
//       <Typography variant="body1" display="block">
//         <img src="assets/how-to/goals-howto/ticklegend.png" width="700"></img>
//       </Typography>
//       <Typography variant="body1" display="block">
//         The above goal is to Limit Device Time. Once a goal is set, it will
//         appear as the image above. You can check goals on the{" "}
//         <Link href={"home"}>Landing Page</Link> and the{" "}
//         <Link href={"dashboard"}>Dashboard Page</Link>.
//       </Typography>
//       <Typography variant="body1" display="block">
//         Here are some examples of custom goals:
//       </Typography>
//       <Typography variant="body1" display="block">
//         <img
//           src="assets/how-to/goals-howto/customgoalex1.png"
//           width="700"
//         ></img>
//       </Typography>
//       {/* <Typography variant="body1" display="block">
//         <img
//           src="assets/how-to/goals-howto/customgoalex2.png"
//           width="700"
//         ></img>
//       </Typography>
//       <Typography variant="body1" display="block">
//         <img
//           src="assets/how-to/goals-howto/customgoalex3.png"
//           width="700"
//         ></img>
//       </Typography> */}
