import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { border } from "@mui/system";
import * as React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { previousAppUrl } from "../../../services/app.service";
import theme from "../../../theme/theme";
import { BoxLoader } from "../../loader/boxloader.component";
import { LogoLoader } from "../../loader/loader.component";
import {
  getCurrentBrowser,
  getCurrentOs,
} from "../../settings/devicemanagement/device.service";
import {
  checkAppLogin,
  checkBrowserLogin,
  downloadExtension,
  downloadNativeApp,
} from "./installation.service";

const doneIcon = "/assets/onboarding/done_filled.svg";
const pendingIcon = "/assets/onboarding/not_done.svg";

const getAppSize = () => {
  const os = getCurrentOs().toLowerCase();
  if (os === "macos") {
    return "~2.5MB";
  } else if (os === "windows") {
    return "~15MB";
  }
};

const isBrowserCompatible = () => {
  const browser = getCurrentBrowser();
  if (browser === "Chrome") {
    return true;
  } else if (browser === "Firefox") {
    return true;
  } else {
    return false;
  }
};

const appInstallSteps = [
  {
    step: 1,
    text: `Download & install the myTiro ${getCurrentOs()} app (${getAppSize()})`,
    buttonText: `Download ${getCurrentOs()} App`,
    back: null,
    skip: "Already Downloaded",
    id: "downloadApp",
  },
  {
    step: 2,
    text: `Log in to the installed myTiro ${getCurrentOs()} app`,
    buttonText: "I have logged in",
    back: "Previous step",
    skip: undefined,
    id: "loginApp",
  },
  {
    step: 3,
    text: `Download the ${getCurrentBrowser()} browser extension`,
    buttonText: `Download ${getCurrentBrowser()} Extension`,
    back: undefined,
    skip: "Already Downloaded",
    id: "downloadExtension",
  },
  {
    step: 4,
    text: "Log in to the extension to track your browser data",
    buttonText: "I have logged in",
    back: "Previous step",
    skip: undefined,
    id: "loginExtension",
  },
];

const extensionInstallSteps = [
  {
    step: 1,
    text: `Download the browser extension for your ${getCurrentBrowser()} browser `,
    buttonText: `Download ${getCurrentBrowser()} Extension`,
    back: undefined,
    skip: "Already Downloaded",
    id: "downloadExtension",
  },
  {
    step: 2,
    text: "Log in to the extension to track your browser data",
    buttonText: "I have logged in",
    back: "Previous step",
    skip: undefined,
    id: "loginExtension",
  },
];

interface InstallationModalProps {
  open: boolean;
  handleClose: () => void;
  extensionOnly?: boolean;
}

interface InstallationStepProps {
  content: string;
  isComplete: boolean;
}

const InstallationStep = (props: InstallationStepProps) => {
  return (
    <Box
      bgcolor={"#F2F3F4"}
      borderRadius="12px"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent={"space-between"}
      padding="3%"
      marginBottom="2%"
      textAlign="left"
    >
      <Typography variant="body1Bold" fontWeight="500">
        {props.content}
      </Typography>
      {props.isComplete ? (
        <img
          src={props.isComplete ? doneIcon : pendingIcon}
          height={"24px"}
          style={{ marginLeft: "2%" }}
        />
      ) : (
        ""
      )}
    </Box>
  );
};

export const InstallationModal = (props: InstallationModalProps) => {
  const installationSteps = props.extensionOnly
    ? extensionInstallSteps
    : appInstallSteps;

  const finishedStep = {
    step: props.extensionOnly
      ? extensionInstallSteps.length + 1
      : appInstallSteps.length + 1,
    text: "Completed",
    buttonText: "Done",
    back: "Previous step",
    skip: undefined,
    id: "finish",
  };

  const [open, setopen] = useState(props.open);
  const [currentStep, setcurrentStep] = useState(
    props.extensionOnly ? extensionInstallSteps[0] : appInstallSteps[0]
  );
  const [errorMsg, seterrorMsg] = useState({ msg: "", display: false });
  const [loading, setloading] = useState(false);
  const totalSteps = props.extensionOnly
    ? extensionInstallSteps.length
    : appInstallSteps.length;

  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    setopen(props.open);
  }, [props.open]);

  useEffect(() => {
    seterrorMsg({ msg: "", display: false });
  }, []);

  const progressStep = () => {
    if (currentStep.step !== totalSteps) {
      setcurrentStep(installationSteps[currentStep.step]);
    } else {
      setcurrentStep(finishedStep);
      previousAppUrl.set(location.pathname + location.search);
      navigate("/app-loading");
      props.handleClose();
    }
    seterrorMsg({
      msg: "",
      display: false,
    });
  };

  const checkNativeAppLogin = () => {
    checkAppLogin()
      .then((res) => {
        setloading(false);
        if (res) {
          progressStep();
        } else {
          seterrorMsg({
            msg: "Please log in to the computer app to continue",
            display: true,
          });
        }
      })
      .catch((err) => {
        setloading(false);
        seterrorMsg({
          msg: err.message || JSON.stringify(err),
          display: true,
        });
      });
  };

  const checkBrowserExtLogin = () => {
    checkBrowserLogin()
      .then((res) => {
        setloading(false);
        if (res) {
          progressStep();
        } else {
          seterrorMsg({
            msg: "Please log in to the browser extension to continue",
            display: true,
          });
        }
      })
      .catch((err) => {
        setloading(false);
        seterrorMsg({
          msg: err.message || JSON.stringify(err),
          display: true,
        });
      });
  };

  const skipStep = () => {
    if (currentStep.id === "downloadApp") {
      progressStep();
    } else if (currentStep.id === "downloadExtension") {
      progressStep();
    }
  };

  const nextStep = () => {
    if (currentStep.id === "downloadApp") {
      downloadNativeApp();
      progressStep();
    } else if (currentStep.id === "downloadExtension") {
      downloadExtension();
      progressStep();
    } else if (currentStep.id === "loginExtension") {
      setloading(true);
      checkBrowserExtLogin();
    } else if (currentStep.id === "loginApp") {
      setloading(true);
      checkNativeAppLogin();
    } else if (currentStep.id === "finish") {
      console.log("F");
      previousAppUrl.set(location.pathname + location.search);
      navigate("/app-loading");
      props.handleClose();
    } else {
      setcurrentStep(installationSteps[currentStep.step]);
      seterrorMsg({
        msg: "",
        display: false,
      });
    }
  };

  const previousStep = () => {
    seterrorMsg({ msg: "", display: false });
    const index = currentStep.step;
    if (index === 2 || index === 3) {
      setcurrentStep(installationSteps[0]);
    } else {
      setcurrentStep(installationSteps[2]);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={props.handleClose}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          bgcolor={theme.palette.background.paper}
          display="block"
          padding="3% 2%"
          paddingBottom={currentStep.step === 1 ? "3%" : "2%"}
          borderRadius="12px"
          width={"30%"}
          minWidth="480px"
          textAlign="center"
          position="relative"
        >
          <Typography
            variant="h6"
            fontWeight="450"
            sx={{ textAlign: "left", margin: "3px" }}
          >
            Steps
          </Typography>
          <BoxLoader loading={loading} />
          <IconButton
            onClick={props.handleClose}
            sx={{ position: "absolute", top: 2, right: 4 }}
          >
            <img src="/assets/dashboardcards/Close.svg" height="24px" />
          </IconButton>
          {installationSteps.map((step, index) => (
            <InstallationStep
              key={index}
              content={step.text}
              isComplete={step.step < currentStep.step}
            />
          ))}
          <Button
            variant="submit_form"
            onClick={nextStep}
            sx={{ marginTop: "4%" }}
          >
            {currentStep.buttonText}
          </Button>
          {currentStep.back ? (
            <Button
              onClick={previousStep}
              variant="onlytext"
              sx={{ color: "#7F8493", margin: "0 auto" }}
            >
              {currentStep.back}
            </Button>
          ) : (
            ""
          )}
          {currentStep.skip ? (
            <Button
              onClick={skipStep}
              variant="onlytext"
              sx={{ color: "#7F8493", margin: "0 auto" }}
            >
              {currentStep.skip}
            </Button>
          ) : (
            ""
          )}
          {errorMsg.display ? (
            <Typography variant="body2" color="red">
              {errorMsg.msg}
            </Typography>
          ) : (
            ""
          )}
        </Box>
      </Modal>
    </>
  );
};

export default InstallationModal;
