import * as React from "react";
import { Box, Button, FormControl, Typography } from "@mui/material";
import "../welcome-onboard.styles.css";

interface UserDetailsFormProps {
  onSubmit: () => void;
  errorMsg: { message: string; showError: boolean };
  onFullnameChange: (fullname: string) => void;
  fullName: string;
}

export const UserDetailsForm: React.FunctionComponent<UserDetailsFormProps> = (
  props: UserDetailsFormProps
) => {
  const handleFullName = (event: React.FormEvent<HTMLInputElement>) =>
    props.onFullnameChange(event.currentTarget.value);

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    props.onSubmit();
  };

  return (
    <Box
      sx={{
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        backgroundColor: "white",
        padding: "8% 8%",
        borderRadius: "20px",
        width: "100%",
      }}
    >
      <Typography variant="h4" textAlign={"center"}>
        Welcome
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: "#7F8493", textAlign: "center" }}
      ></Typography>

      <FormControl component={"form"} variant="standard" onSubmit={onSubmit}>
        <Box sx={{ display: "block", marginTop: "8%" }}>
          <label>
            <span style={{ fontWeight: "500", fontSize: "max(12px,0.83vw)" }}>
              What shall myTiro call you?
            </span>
            <br />
            <input
              type="text"
              placeholder="Enter Full Name"
              value={props.fullName}
              onChange={handleFullName}
              style={{
                background: "transparent",
                border: "1px solid #D6DAE480",
                fontSize: "max(14px, 0.972vw)",
              }}
            />
          </label>
        </Box>
        <Box
          sx={{
            display: "block",
            height: "1.2rem",
            width: "100%",
            lineHeight: "1.2rem",
            backgroundColor: "transparent",
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: "red", textAlign: "center", marginTop: "6%" }}
          >
            {props.errorMsg.showError ? props.errorMsg.message : ""}
          </Typography>
        </Box>
        <Button
          variant="contained"
          className="myButton"
          type="submit"
          sx={{
            margin: "15px 0",
            marginTop: "45px",
            backgroundColor: "#643992",
          }}
        >
          Let's get started
        </Button>
      </FormControl>
    </Box>
  );
};
