import { Box, Typography } from "@mui/material";
import Highcharts, { SeriesOptions, SeriesOptionsType } from "highcharts";
import { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { DaySummaryDto } from "../../../../../services/timeline/timeline.model";
import { convertSecondsToHHMM } from "../../../../admin/useroverview/useroverview.service";
import { AppConsistency } from "../unused/app-consistency.component";

export {};

interface AppUsageChartProps {
  appWeeklyData: DaySummaryDto[];
  appName: string;
  isWeek?: boolean;
}

export const APP_DUMMY_COLOR = "#7E4BB7";

export const AppUsageChart = (props: AppUsageChartProps) => {
  const [noDataOverlay, setNoDataOverlay] = useState<boolean>(false);
  const [chartOptions, setChartOptions] = useState<Options>({
    chart: {
      type: "column",
      height: 200,
      // width: 400,
    },
    title: {
      text: undefined,
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      min: 0,
      tickInterval: 3600,
      title: {
        text: undefined,
      },
      labels: {
        enabled: true,
        formatter: function () {
          if (this.value === 0) return "";
          return `${convertSecondsToHHMM(this.value as number)}`;
        },
      },
      stackLabels: {
        enabled: true,
        formatter: function () {
          if (this.total === 0) return "";
          return `${convertSecondsToHHMM(this.total)}`;
        },
        style: {
          fontWeight: "bold",
          textOutline: "none",
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return [
          "<b>" + this.key + "</b><br/>",
          `${this.series.name}: ${convertSecondsToHHMM(
            this.point.y
          )}<br/>Total: ${convertSecondsToHHMM(this.point.total)}`,
        ];
      },
      //   headerFormat: "<b>{point.x}</b><br/>",
      //   pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
          formatter: function () {
            return `${convertSecondsToHHMM(this.point.y)}`;
          },
        },
      },
    },
    series: [],
  });

  useEffect(() => {
    if (props.appWeeklyData.length > 0) {
      const data = props.appWeeklyData;
      const series: SeriesOptionsType[] = [
        {
          name: "Online Time",
          type: "column",
          color: APP_DUMMY_COLOR + "99",
          data: data.map((d) => {
            return (d?.time ?? 0) - (d?.focus ?? 0);
          }),
        },
        {
          name: "Focus Time",
          color: APP_DUMMY_COLOR,
          type: "column",
          data: data.map((d) => {
            return d?.focus ?? 0;
          }),
        },
      ];
      let xAxis;
      if (props.isWeek) {
        xAxis = {
          categories: props.appWeeklyData.map(
            (d) =>
              `${DateTime.fromSeconds(d?.dayTs ?? 0).toFormat(
                "LLL dd"
              )} - ${DateTime.fromSeconds(d?.endWeekTs ?? 0).toFormat(
                "LLL dd"
              )}`
          ),
        };
      } else {
        xAxis = {
          categories: props.appWeeklyData.map(
            (d) => `${DateTime.fromSeconds(d?.dayTs ?? 0).toFormat("LLL dd")}`
          ),
        };
      }

      setChartOptions({ series, xAxis });
    }
  }, [props.appWeeklyData]);

  useEffect(() => {
    setNoDataOverlay(!props.appWeeklyData || props.appWeeklyData.length == 0);
  }, [props.appWeeklyData]);

  return (
    <Box
      sx={{
        bgcolor: "white",
        width: "100%",
        padding: "1.5%",
        borderRadius: "12px",
        height: "100%",
      }}
    >
      <Typography variant="h6">
        <span style={{ fontWeight: 400 }}>
          How regularly do you use{" "}
          <span style={{ color: "#7E4BB7", fontWeight: 700 }}>
            {props.appName}
          </span>
          ?
        </span>
      </Typography>
      {/* <AppConsistency
        appName={props.appName}
        appWeeklyData={props.appWeeklyData ?? []}
      ></AppConsistency> */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box sx={{ width: "90%", mt: 2 }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            allowChartUpdate={true}
            updateArgs={[true, true, true]}
          />
        </Box>
        <Box></Box>
      </Box>
    </Box>
  );
};
