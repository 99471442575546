import {
  Box,
  Button,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Key,
  ReactChild,
  ReactFragment,
  ReactPortal,
  useEffect,
  useState,
} from "react";
import { render } from "react-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  CustomCategoryDialogTemplateTypes,
  CustomCategoryDialog,
  CustomCategoryDialogTemplate,
} from "./custom-category-dialog.component";
import {
  CategoryType,
  ItemType,
  SaveUpdateCategoryDto,
  UserItemDto,
} from "../models/custom-category.model";
import {
  deleteCustomCategory,
  getCustomCategories,
} from "../util/custom-category.service";
import { LogoLoader } from "../../../loader/loader.component";

interface ManageCustomCategoryProps {
  customCategories: SaveUpdateCategoryDto[];
}

export const ManageCustomCategory = (props: ManageCustomCategoryProps) => {
  const [customCategories, setCustomCategories] = useState<
    SaveUpdateCategoryDto[]
  >([]);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] =
    useState<SaveUpdateCategoryDto | null>(null);

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   fetchCustomCategories();
  // }, []);

  useEffect(() => {
    setCustomCategories(props.customCategories);
  }, [props.customCategories]);

  // useEffect(() => {
  //   console.log("custom cat:", customCategories);
  // }, [customCategories]);

  const fetchCustomCategories = async () => {
    const value = await getCustomCategories();
    setCustomCategories(value);
  };

  const handleDeleteCategory = async (
    customCategory: SaveUpdateCategoryDto
  ) => {
    setLoading(true);
    console.log("Custom Category: ", customCategory);
    if (customCategory.id) {
      await deleteCustomCategory(customCategory.id);
    }
    await fetchCustomCategories();
    console.log("At Delete");
    setLoading(false);
  };

  const handleOpenEditCategory = (customCategory: SaveUpdateCategoryDto) => {
    setSelectedValue(customCategory);
    setOpenEditDialog(true);
    console.log("At Edit");
  };

  const handleEditClose = async () => {
    setOpenEditDialog(false);
    setLoading(true);
    console.log("at close dialog");
    await fetchCustomCategories();
    setLoading(false);
  };

  return (
    <>
      {customCategories.length === 0 ? (
        <Typography variant="body1">No custom categories created</Typography>
      ) : (
        <>
          <LogoLoader loading={loading} />
          <Stack spacing={3}>
            <TableBody>
              {customCategories.map((customCategory: SaveUpdateCategoryDto) => {
                return (
                  <TableRow key={customCategory.id}>
                    <TableCell>{customCategory.categoryName}</TableCell>
                    <TableCell>
                      {customCategory.items
                        .map((app) => app.itemIdentifier)
                        .join(", ")}
                    </TableCell>
                    <TableCell component="th" scope="row" width={"20%"}>
                      <IconButton
                        onClick={() => {
                          handleOpenEditCategory(customCategory);
                        }}
                      >
                        <EditIcon color="primary" />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          handleDeleteCategory(customCategory);
                        }}
                      >
                        <DeleteIcon color="primary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Stack>
          <CustomCategoryDialog
            selectedValue={selectedValue}
            open={openEditDialog}
            onClose={handleEditClose}
            template={CustomCategoryDialogTemplateTypes.edit}
          />
        </>
      )}
    </>
  );
};

const dummyData: SaveUpdateCategoryDto[] = [
  {
    categoryName: "Custom Category 1",
    accountId: "",
    categoryType: CategoryType.USER_CATEGORY,
    items: [
      {
        id: 1,
        itemIdentifier: "Visual Studio Code",
        itemType: ItemType.DESKTOP_APP,
        categories: [],
      },
      {
        id: 1,
        itemIdentifier: "Google Chrome",
        itemType: ItemType.DESKTOP_APP,
        categories: [],
      },
      {
        id: 1,
        itemIdentifier: "Zoom",
        itemType: ItemType.DESKTOP_APP,
        categories: [],
      },
    ],
    id: 1,
  },
  {
    categoryName: "Custom Category 2",
    accountId: "",
    categoryType: CategoryType.USER_CATEGORY,
    items: [
      {
        id: 1,
        itemIdentifier: "Visual Studio Code",
        itemType: ItemType.DESKTOP_APP,
        categories: [],
      },
      {
        id: 1,
        itemIdentifier: "Google Chrome",
        itemType: ItemType.DESKTOP_APP,
        categories: [],
      },
      {
        id: 1,
        itemIdentifier: "Zoom",
        itemType: ItemType.DESKTOP_APP,
        categories: [],
      },
    ],
    id: 2,
  },
];
