import { Link, Stack, Typography } from "@mui/material";
import { SupportsPage } from "../supportpage.component";

interface SupportLandingPageBlogProps {}

export const SupportLandingPageBlog = (props: SupportLandingPageBlogProps) => {
  return (
    <>
      <Typography variant="h6" display="block" id={""}></Typography>
      <SupportsPage title={""} subtitle={""} loading={false}>
        <SupportLandingPagePage></SupportLandingPagePage>
      </SupportsPage>
    </>
  );
};

interface SupportLandingPageProps {}

const SupportLandingPagePage = (props: SupportLandingPageProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="body1" display="block">
        To get the most out of your myTiro subscription, we have categorized
        information in three easy-to-use sections
      </Typography>
      <Typography variant="body1" display="block" fontWeight={"bold"}>
        Getting Started
      </Typography>
      <Typography variant="body1" display="block">
        If you are new to myTiro and want to get to building healthy time
        habits, refer to:
      </Typography>
      <Typography variant="body1" display="block">
        <ul>
          <li>
            <Link href="support?option=understanding&suboption=steps">
              Setting up myTiro in 5 easy steps
            </Link>{" "}
            – Understand what you need to set up myTiro
          </li>
          <li>
            <Link href="support?option=understanding&suboption=onboarding">
              Onboarding
            </Link>{" "}
            – Detailed guide to installing and setting up myTiro on all your
            devices
          </li>
          <li>
            <Link href="support?option=understanding&suboption=results">
              Understand your myTiro output
            </Link>{" "}
            – Start understanding how to get to your information and how to
            interpret it
          </li>
          <li>
            <Link href="support?option=understanding&suboption=howTomyTiro">
              How to for myTiro
            </Link>{" "}
            – An overview of how to setup myTiro to get the best out of your
            subscription
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" display="block" fontWeight={"bold"}>
        Understanding myTiro
      </Typography>
      <Typography variant="body1" display="block">
        If you have started using myTiro and have questions or want to dig
        deeper on various myTiro features, then dig in to:
      </Typography>
      <Typography variant="body1" display="block">
        <ul>
          <li>
            <Link href="support?option=how-to&suboption=goals">
              myTiro Goals
            </Link>{" "}
            – Understand how to set up your goals to be deliberate about your
            time, increase your focus and build healthy time habits.
            <ul>
              <li>
                <Link href="support?option=how-to&suboption=customCategory">
                  myTiro Custom Category
                </Link>{" "}
                – Create custom categories within your goals to track what you
                want and how you want.
              </li>
              <li>
                <Link href="support?option=how-to&suboption=pinGoals">
                  Pin your myTiro Goals
                </Link>{" "}
                – Understand how you can pin your myTiro goals for longer term
                tracking.
              </li>
            </ul>
          </li>
          <li>
            <Link href="support?option=how-to&suboption=insights">
              myTiro Insights
            </Link>{" "}
            – Increase the awareness of your habits..and know how you compare to
            other myTiro users
          </li>
          <li>
            <Link href="support?option=how-to&suboption=scores">
              myTiro Scores
            </Link>{" "}
            – Understand how myTiro Focus, Goals, and Habit scores are
            calculated and how you can interpret them.
          </li>
          <li>
            <Link href="support?option=how-to&suboption=register">
              How does myTiro register activities
            </Link>{" "}
            – Understand what activities myTiro registers and how that
            information is processed.
          </li>
          <li>
            <Link href="support?option=how-to&suboption=interpretTimeFocusSwitches">
              Interpret Time, Focus, and Switch
            </Link>{" "}
            – Understand the three most important levers in your control.
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" display="block" fontWeight={"bold"}>
        Glossary
      </Typography>
      <Typography variant="body1" display="block">
        Definitions for terms used across myTiro. Do let us know if you find a
        term missing or want more details.
      </Typography>
      <Typography variant="body1" display="block" fontWeight={"bold"}>
        Contact Support
      </Typography>
      <Typography variant="body1" display="block">
        Feel free to write to us with any questions or suggestions
      </Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
    </Stack>
  );
};
