import { Box, Grid, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import {
  DaySummaryDto,
  DetailedTimelineDto,
  TimelineDto,
} from "../../../../services/timeline/timeline.model";
import { LogoLoader } from "../../../loader/loader.component";
import { TimelineChart } from "./charts/timeline-chart.component";
import { TimelineDialog } from "./timeline-dialog/timeline-dialog.component";

interface TimelineCardProps {
  timelineData: TimelineDto[];
  prevTimelineData: TimelineDto[];
  detailedTimelineData: DetailedTimelineDto[];
  fetchDetailedTimelineData: (hour: number, today: boolean) => void;
  summary: DaySummaryDto;
  prevSummary: DaySummaryDto;
  currentDateTime: DateTime;
  prevDateTime: DateTime;
}

type LegendItem = {
  name: string;
  color: string;
  index: number;
  pattern: boolean;
};

export const LEGEND_ITEMS: LegendItem[] = [
  {
    name: "Offline",
    color:
      "repeating-linear-gradient(45deg,#AAAFBB,#AAAFBB 10px,#fff 10px,#fff 20px)",
    index: 0,
    pattern: true,
  },
  { name: "Fragmented", color: "#AAAFBBBB", index: 1, pattern: false },
  { name: "Focused", color: "#5D626FBB", index: 2, pattern: false },
  { name: "Future", color: "#FFFFFFFF", index: 3, pattern: false },
];

export const TimelineCard = (props: TimelineCardProps) => {
  const [legendItems, setLegendItems] = useState<LegendItem[]>(LEGEND_ITEMS);
  const [openTimelineDialog, setOpenTimelineDialog] = useState<boolean>(false);
  const [timelineHour, setTimelineHour] = useState<number>(0);
  const [minHour, setMinHour] = useState<number>(0);
  const [maxHour, setMaxHour] = useState<number>(24);
  const [isToday, setIsToday] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);

  const closeDialog = () => {
    setOpenTimelineDialog(false);
  };

  const updateHour = () => {
    if (!props.timelineData || !props.prevTimelineData) {
      // console.log(props.timelineData);
      return;
    }
    let todaymin = 0;
    let todaymax = 24;
    let prevmin = 0;
    let prevmax = 24;

    todaymin = props.timelineData.find((td) => !td.offline)?.hour ?? 24;
    todaymax =
      props.timelineData
        .slice()
        .reverse()
        .find((td) => !td.offline)?.hour ?? 0;

    prevmin = props.prevTimelineData.find((td) => !td.offline)?.hour ?? 0;
    prevmax =
      props.prevTimelineData
        .slice()
        .reverse()
        .find((td) => !td.offline)?.hour ?? 24;

    let min = Math.min(todaymin, prevmin);
    let max = Math.max(todaymax, prevmax);

    setMinHour(min === 0 ? min : min - 1);
    setMaxHour(max === 24 ? max : max + 1);
    // console.log("min at component: ", min);
    // console.log("max at component: ", max);
    // setMinHour(min);
    // setMaxHour(max);
  };

  useEffect(() => {
    updateHour();
  }, [props.timelineData, props.prevTimelineData]);

  const handleOpenDialog = async (hour: number, today: boolean) => {
    setLoading(true);
    setIsToday(today);
    setTimelineHour(hour);
    await props.fetchDetailedTimelineData(hour, today);
    setOpenTimelineDialog(true);
    setLoading(false);
  };

  return (
    <Box
      width="100%"
      height={"100%"}
      display="block"
      bgcolor="#E2E5FA"
      padding={"1%"}
      paddingTop={"0.3%"}
      borderRadius={"10px"}
    >
      <LogoLoader loading={loading} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Timeline
        </Typography>
        {/* <Box sx={{ width: "40%", minWidth: "400px" }}>
          <TimelineCardLegend legendItems={legendItems} />
        </Box> */}
      </Box>
      <TimelineChart
        chartData={props.timelineData}
        title={"Today"}
        summary={props.summary}
        openDialog={handleOpenDialog}
        today={true}
        minHour={minHour}
        maxHour={maxHour}
        date={props.currentDateTime}
        showLegend={true}
      />
      <TimelineChart
        chartData={props.prevTimelineData}
        title={"Previous Day"}
        summary={props.prevSummary}
        openDialog={handleOpenDialog}
        today={false}
        minHour={minHour}
        maxHour={maxHour}
        date={props.prevDateTime}
        showLegend={false}
      />

      <TimelineDialog
        open={openTimelineDialog}
        onClose={closeDialog}
        date={isToday ? props.currentDateTime : props.prevDateTime}
        timelineData={props.detailedTimelineData}
        timelineHour={timelineHour}
      ></TimelineDialog>
      {/* <SwitchHeatmapChart
        chartData={[]}
        title={"Switch Heatmap"}
      ></SwitchHeatmapChart>
      <GoalProgressChart></GoalProgressChart>
      <ApplicationViewChart></ApplicationViewChart> */}
    </Box>
  );
};

export const TimelineCardLegend = (props: {
  legendItems: LegendItem[];
}): JSX.Element => {
  return (
    <Box>
      <Grid container rowSpacing={1}>
        {props.legendItems.map((item) => (
          <Grid item sm={3}>
            <LegendItem {...item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const LegendItem = (props: LegendItem): JSX.Element => {
  return (
    <Stack direction={"row"} spacing={1}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Box
          sx={{
            height: "12px",
            width: "12px",
            borderRadius: "2px",
            backgroundColor: props.pattern ? "" : props.color,
            background: props.pattern ? props.color : "",
            border: "1px solid",
          }}
        ></Box>
      </Box>
      <Typography variant="body2" sx={{ textOverflow: "ellipsis" }}>
        {props.name}
      </Typography>
    </Stack>
  );
};
