import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { PointOptionsObject } from "highcharts";
import React from "react";
import { AppModel } from "./models/app_data.model";
import { AppUsagePropsModel } from "./models/app_usage_props.model";
import { AppUsageStateModel } from "./models/app_usage_state.model";
import { TreemapUsage } from "./treemap_usage.component";
import InfoIcon from "@mui/icons-material/Info";
import {
  checkIfAppInstalled,
  getColor,
  getNewCategoryColor,
} from "../../utils/dashboard.utils";
import InfoDialog from "../../../infodialog/infodialog.component";
import ErrorBoundary from "../../../../errorboundary.component";
import { NoDataOverlay } from "../../nodataoverlay/nodataoverlay.component";
import { displayOverlay } from "../../../../overlay.service";
import { AlignHorizontalRight } from "@mui/icons-material";
import { AppUsageDialog } from "../usage-dialog/app-usage-dialog.component";
import {
  getAppSummary,
  getAppTimelineData,
  getMonthlyAppSummary,
  getWeeklyAppSummary,
} from "../../../../services/timeline/timeline.service";
import { LogoLoader } from "../../../loader/loader.component";
import {
  fetchAppContribution,
  fetchAppFragmentation,
} from "../../dashboard.service";

const tooltipText =
  "Running total of time you spent across applications across devices. It shows which applications you were focused on and for how long.";

const SECONDS_OF_DAY = 86400;
const NO_OF_DAYS = 5;
export class AppUsageComponent extends React.Component<
  AppUsagePropsModel,
  AppUsageStateModel
> {
  constructor(props: AppUsagePropsModel) {
    super(props);
    this.state = {
      seriesData: [],
      duration: {
        start: 0,
        end: 0,
      },
      dateEpoch: 0,
      dialogOpen: false,
      loading: false,
      showOverlay: null,
      timelineData: {
        appTimelineData: [],
        prevAppTimelineData: [],
      },
      selectedApp: "",
      summaryData: {
        appSummaryData: {
          time: 0,
          focus: 0,
          activities: 0,
        },
        prevAppSummaryData: {
          time: 0,
          focus: 0,
          activities: 0,
        },
      },
      appStats: {},
      appWeeklySummary: [],
      appContribution: {},
    };
  }

  static parseDataForTreemap = (
    entry: AppModel,
    id: number
  ): PointOptionsObject => {
    const { name, time, category, focusSplit } = entry;
    const { hours, minutes } = time;
    const value = hours * 60 + minutes;
    if (value < 1) {
      return [];
    }
    const focusedValue =
      focusSplit.focused.hours * 60 + focusSplit.focused.minutes;
    const fraggedValue =
      focusSplit.fragmented.hours * 60 + focusSplit.fragmented.minutes;
    const color = getNewCategoryColor(category) + "A6";
    const fragColor = getNewCategoryColor(category) + "A6";
    const focusColor = getNewCategoryColor(category) + "C6";
    let f = {
      name: name + " (focus)",
      id: id + name + "focus",
      value: focusedValue,
      parent: id + name,
      color: focusColor,
      sortIndex: 0,
      text: name + "||" + category,
    };
    let nf = {
      name: name + " (fragmented)",
      id: id + name + "fragged",
      value: fraggedValue,
      //remove id in parent for using the name
      parent: id + name,
      color: color,
      sortIndex: 1,
      //added category in text
      text: name + "||" + category,
    };
    return [
      { name: name, id: id + name, color, text: name + "||" + category },
      f,
      nf,
    ];
  };

  static getDerivedStateFromProps(
    nextProps: AppUsagePropsModel,
    prevState: AppUsageStateModel
  ) {
    const { duration } = nextProps;
    if (duration) {
      // no change in timelines, then do not update state
      if (
        duration.start === prevState.duration.start &&
        duration.end === prevState.duration.end
      ) {
        return null;
      }
      const { data } = nextProps;
      let index = 0;
      if (data && !prevState.showOverlay) {
        let seriesData: any = [];
        for (let entry of data) {
          const seriesEntries = AppUsageComponent.parseDataForTreemap(
            entry,
            index
          );
          seriesData = seriesData.concat(seriesEntries);
          index += 1;
        }
        return {
          seriesData,
        };
      } else {
        return null;
      }
    }
    return null;
  }

  componentDidMount() {
    if (this.state.showOverlay === null) {
      displayOverlay("COMPONENT_DASHBOARD_USAGE_BY_APPLICATION").then(
        (str: string | null) =>
          this.setState({
            showOverlay: str,
          })
      );
    }
  }

  componentDidUpdate(prevProps: AppUsagePropsModel) {
    //TODO figure out the right way
    if (this.props.date.start != prevProps.date.start) {
      this.setState({
        dateEpoch: this.props.date.start,
      });
    }
  }

  fetchAppPopupData = async (appName: string, cat: string) => {
    //TODO remove hardcoded numbers
    // const dataArray = text.split("||", 2);
    // const appName = dataArray[0];
    // const cat = dataArray[1];
    // console.log(appName, cat);

    const dataPromise = getAppTimelineData(
      appName,
      this.props.date.start,
      this.props.date.end
    );
    const summaryPromise = getAppSummary(
      appName,
      cat,
      this.props.date.start,
      this.props.date.end
    );
    const prevSummaryPromise = getAppSummary(
      appName,
      cat,
      this.props.date.start - SECONDS_OF_DAY,
      this.props.date.end - SECONDS_OF_DAY
    );
    // console.log(summary, prevSummary);
    // const prevDataPromise = getAppTimelineData(
    //   appName,
    //   this.props.date.start - SECONDS_OF_DAY,
    //   this.props.date.end - SECONDS_OF_DAY
    // );
    const appStatsPromise = fetchAppFragmentation(
      appName,
      this.props.date.start,
      this.props.date.end
    );
    const appContributionPromise = fetchAppContribution(
      appName,
      this.props.date.start,
      this.props.date.end
    );
    const appWeeklySummaryPromise = this.props.isWeek
      ? getMonthlyAppSummary(appName, cat, this.props.date.end)
      : getWeeklyAppSummary(appName, cat, this.props.date.start, NO_OF_DAYS);

    return Promise.all([
      dataPromise,
      summaryPromise,
      prevSummaryPromise,
      appStatsPromise,
      appContributionPromise,
      appWeeklySummaryPromise,
    ]).then((results) => {
      const data = results[0];
      const summary = results[1];
      const prevSummary = results[2];
      const appStats = results[3];
      const appContribution = results[4];
      const appWeeklySummary = results[5];
      // console.log(data);
      this.setState({
        dateEpoch: this.props.date.start,
      });
      this.setState({
        timelineData: {
          appTimelineData: data,
          prevAppTimelineData: [],
        },
        summaryData: {
          appSummaryData: summary,
          prevAppSummaryData: prevSummary,
        },
        appStats: appStats,
        appContribution: appContribution,
        appWeeklySummary: appWeeklySummary,
      });
    });
  };

  handleClick = async (event: any) => {
    // console.log(event);
    if (
      this.props.date.end - this.props.date.start > 86400 &&
      !this.props.isWeek
    ) {
      return;
    }
    this.setState({
      loading: true,
    });
    const dataArray = event.point.text.split("||", 2);
    const appName = dataArray[0];
    const cat = dataArray[1];
    this.setState({
      selectedApp: appName,
    });
    await this.fetchAppPopupData(appName, cat);
    this.setState({
      dialogOpen: true,
    });
    this.setState({
      loading: false,
    });
  };

  handleClose = (event: any, reason: string) =>
    this.setState({
      dialogOpen: false,
    });

  render() {
    const { isTablet } = this.props;
    return (
      <Box
        className="masonry-item treemap-container"
        sx={{
          backgroundColor: "#fff", //
          display: "flex",
          flexDirection: "column",
          width: "100%", //
          justifyContent: "left",
          alignContent: "center",
          padding: "5% 2%", //
          borderRadius: "12px", //
          height: isTablet ? "auto" : "80%", //
        }}
      >
        {/* <Box
          sx={{
            position: "relative",
            top:"10px",
            right:"10px",
          }}
        >
          <Tooltip title={""}>
              <InfoIcon
                sx={{
                  fontSize: "20px",
                  ":hover": { transform: "scale(1.1)", cursor: "pointer" },
                }}
              />
            </Tooltip>
        </Box> */}
        <ErrorBoundary>
          <Stack direction={"row"} justifyContent="space-between">
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                width: "100%",
                marginRight: "-24px",
              }}
            >
              <Typography
                variant="h6"
                sx={{ marginBottom: "5%", marginLeft: "10px" }}
              >
                Usage By Application
              </Typography>
            </Box>
            <Box sx={{ marginTop: "-20px" }}>
              <Tooltip title={tooltipText}>
                <InfoIcon
                  sx={{
                    fontSize: "20px",
                    ":hover": { transform: "scale(1.1)" },
                  }}
                />
              </Tooltip>
            </Box>
          </Stack>
          {this.state.showOverlay ? (
            <Box
              sx={{
                width: "95%",
                height: "95%",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "center",
                borderRadius: "12px",
                margin: "auto",
                position: "relative",
              }}
            >
              <NoDataOverlay
                image="/assets/dashboardcards/dummy/treemap.svg"
                text={this.state.showOverlay}
              />
            </Box>
          ) : this.state.seriesData.length !== 0 ? (
            <Box>
              <LogoLoader loading={this.state.loading} />
              <TreemapUsage
                seriesData={this.state.seriesData}
                onClick={this.handleClick}
              />
              {/* <InfoDialog
                open={this.state.dialogOpen}
                insights={[]}
                rawdata={[]}
                onClose={this.handleClose}
              /> */}
              <AppUsageDialog
                // timelineHour={0}
                open={this.state.dialogOpen}
                onClose={this.handleClose}
                timelineData={this.state.timelineData.appTimelineData}
                prevTimelineData={this.state.timelineData.prevAppTimelineData}
                dateEpoch={this.state.dateEpoch}
                summary={this.state.summaryData.appSummaryData}
                prevSummary={this.state.summaryData.prevAppSummaryData}
                appName={this.state.selectedApp}
                appStats={this.state.appStats}
                fragmentationData={this.state.appStats.fragData ?? {}}
                appContribution={this.state.appContribution}
                maxSes={this.state.appStats.maxSes ?? {}}
                minSes={this.state.appStats.minSes ?? {}}
                appWeeklyData={this.state.appWeeklySummary ?? []}
                isWeek={this.props.isWeek}
              ></AppUsageDialog>
            </Box>
          ) : (
            <Box
              sx={{
                width: "95%",
                height: "95%",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "center",
                borderRadius: "12px",
                margin: "auto",
                border: "1px dashed #AAAFBB",
              }}
            >
              <Typography variant="h6Light" margin="auto">
                No data available
              </Typography>
            </Box>
          )}
        </ErrorBoundary>
      </Box>
    );
  }
}
