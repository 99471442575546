import LeftNavigationBar from "../components/leftnavigation/leftnavigation.component";
import { SidePanel } from "../components/sidepanel/sidepanel.component";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import { AccountContext, getSession } from "../accountcontext/account";
import { useMediaQuery, useTheme } from "@mui/material";
import theme from "../theme/theme";
import { getAccount } from "../components/profile.service";
import { useModal } from "../core/modal.hook";
import { UserDetailsModal } from "../pages/onboarding/userdetailsmodal/userdetailsmodal.component";

interface PanelProps {
  showInsights?: Boolean;
}

export const PanelLayout = (props: PanelProps) => {
  const [loginComplete, setloginComplete] = useState(true);
  const {open, handleOpen, handleClose} = useModal();
  let navigate = useNavigate();
  let matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { showInsights } = props;
  useEffect(() => {
    getSession()
      .then((session) => {
        console.log("Logged in");
        // const username = session.getIdToken().payload["cognito:username"];;
        // getAccount(username)
        //  .then((account) => {
        //    if(account.profile?.onboardingComplete){
        //      console.log("Successfully fetched account");
        //    } else {
        //      console.log("Onboarding required");
        //      setloginComplete(false);
        //    }
        //  })

      })
      .catch((err) => navigate("/login"));
  }, []);

  useEffect(() => {
    if(!loginComplete){
      handleOpen();
    }
  }, [loginComplete]);

  const completeLogin = () => {

  }

  if (matches) {
    return (
      <div
        style={{
          backgroundColor: "#f3f4f6",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Outlet />
      </div>
    );
  } else {
    return (
      <div
        style={{
          backgroundColor: "#f3f4f6",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          height: "100vh",
          width: "100vw",
        }}
      >
        {/* <UserDetailsModal open={open} handleClose={handleClose} /> */}
        <LeftNavigationBar />
        <Outlet />
        {showInsights ? (
          <div
            style={{
              width: "21%",
              minWidth: "min-content",
              marginLeft: "auto",
            }}
          >
            <SidePanel isMobile={matches} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
};
