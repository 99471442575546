import {
  Box,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { withStyles, createStyles, WithStyles } from "@mui/styles";
import * as React from "react";
import { displayOverlay } from "../../overlay.service";

const styles = (theme: Theme) =>
  createStyles({
    box: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "left",
      width: "100%",
      backgroundColor: "#f3f4f6",
      margin: "auto 0",
      padding: "1.8%",
    },
    toggle: {
      backgroundColor: "white",
      border: 0,
      marginRight: "15px !important",
      borderRadius: "8px !important",
      color: "black",
      fontWeight: "14px !important",
      "&:disabled": {
        backgroundColor: theme.palette.primary,
      },
    },
  });

interface DataSelectorProps extends WithStyles<typeof styles> {
  onChange: (dateValue: "today" | "yesterday" | "week" | "month") => void;
  toggleValue: string;
}

interface DataSelectorState {
  color: string;
  toggleValue: string;
  dateRange: { start: number; end: number }[];
  showOverlay: string | null;
}

class DataSelector extends React.Component<
  DataSelectorProps,
  DataSelectorState
> {
  constructor(props: DataSelectorProps) {
    super(props);
    const offset =
      props.toggleValue === "today"
        ? 0
        : props.toggleValue === "yesterday"
        ? 1
        : props.toggleValue === "week"
        ? 7
        : props.toggleValue === "month"
        ? 30
        : 0;
    const now = new Date();
    const startDate = new Date(now.setDate(now.getDate() - offset));
    startDate.setHours(0, 0, 0, 0);
    const today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1));
    let dateRange: { start: number; end: number }[] = [];
    dateRange.push({
      start: startDate.getTime(),
      end: yesterday.getTime(),
    });
    this.state = {
      color: "#fafbfd",
      toggleValue: props.toggleValue,
      dateRange: dateRange,
      showOverlay: null,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    if (this.state.showOverlay === null) {
      displayOverlay("COMPONENT_DASHBOARD_TOTAL_TIME").then((res) =>
        this.setState({
          showOverlay: res,
        })
      );
    }
  }

  getDates() {
    const { toggleValue, dateRange } = this.state;
    const week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let datewindow = dateRange[dateRange.length - 1];
    let startdate = new Date(datewindow.start);
    let enddate = new Date(datewindow.end);
    if (toggleValue === "yesterday" || toggleValue === "today") {
      return startdate.toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } else {
      return (
        startdate.toLocaleString("en-US", {
          weekday: "long",
          month: "long",
          day: "numeric",
        }) +
        "  -  " +
        enddate.toLocaleString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      );
    }
  }

  handleDateChange(
    event: any,
    value: "today" | "yesterday" | "week" | "month"
  ) {
    // console.log("dateChange", event.target.value, value);
    this.setState({ toggleValue: event.target.value });
    const dateRange: { start: number; end: number }[] = [];

    const now = new Date();

    if (value == "today") {
      const today = new Date();
      today.setDate(now.getDate());
      today.setHours(0, 0, 0, 0);
      const endOfToday = new Date(today);
      endOfToday.setHours(23, 59, 59, 999);
      dateRange.push({
        start: today.getTime(),
        end: endOfToday.getTime(),
      });
    } else if (value == "yesterday") {
      const yesterday = new Date();
      yesterday.setDate(now.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);
      const endOfYesterday = new Date(yesterday);
      endOfYesterday.setHours(23, 59, 59, 999);
      dateRange.push({
        start: yesterday.getTime(),
        end: endOfYesterday.getTime(),
      });
    } else if (value == "week") {
      const week = new Date();
      week.setDate(now.getDate() - 7);
      week.setHours(0, 0, 0, 0);
      const endOfWeek = new Date();
      endOfWeek.setDate(endOfWeek.getDate() - 1);
      endOfWeek.setHours(23, 59, 59, 999);
      dateRange.push({ start: week.getTime(), end: endOfWeek.getTime() });
    } else if (value == "month") {
      const month = new Date();
      // TODO: how many days does a month have? need to check current month and calculate accordingly
      month.setDate(now.getDate() - 30);
      month.setHours(0, 0, 0, 0);
      const endOfMonth = new Date();
      endOfMonth.setDate(endOfMonth.getDate() - 1);
      endOfMonth.setHours(23, 59, 59, 999);
      dateRange.push({ start: month.getTime(), end: endOfMonth.getTime() });
    }
    this.props.onChange(value);
    this.setState({
      dateRange: dateRange,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Box className={classes.box}>
        <div style={{ marginRight: "8%" }}>
          <ToggleButtonGroup
            value={this.state.toggleValue}
            exclusive
            onChange={this.handleDateChange}
          >
            <ToggleButton
              value="today"
              disabled={
                this.state.showOverlay
                  ? true
                  : this.state.toggleValue === "today"
                  ? true
                  : false
              }
              color="secondary"
              className={classes.toggle}
              sx={{
                backgroundColor: this.state.showOverlay
                  ? "lightgray !important"
                  : "white",
                color: this.state.showOverlay ? "darkgray !important" : "",
              }}
            >
              Today
            </ToggleButton>
            <ToggleButton
              value="yesterday"
              disabled={
                this.state.showOverlay
                  ? true
                  : this.state.toggleValue === "yesterday"
                  ? true
                  : false
              }
              color="secondary"
              className={classes.toggle}
              sx={{
                backgroundColor: this.state.showOverlay
                  ? "lightgray !important"
                  : "white",
                color: this.state.showOverlay
                  ? "darkgray !important"
                  : "inherit",
              }}
            >
              Yesterday
            </ToggleButton>
            <ToggleButton
              id="week"
              value="week"
              disabled={
                this.state.showOverlay
                  ? true
                  : this.state.toggleValue === "week"
                  ? true
                  : false
              }
              className={classes.toggle}
              sx={{
                backgroundColor: this.state.showOverlay
                  ? "lightgray !important"
                  : "white",
                color: this.state.showOverlay
                  ? "darkgray !important"
                  : "inherit",
              }}
            >
              Last 7 days
            </ToggleButton>
            <ToggleButton
              value="month"
              disabled={
                this.state.showOverlay
                  ? true
                  : this.state.toggleValue === "month"
                  ? true
                  : false
              }
              className={classes.toggle}
              sx={{
                backgroundColor: this.state.showOverlay
                  ? "lightgray !important"
                  : "white",
                color: this.state.showOverlay
                  ? "darkgray !important"
                  : "inherit",
              }}
            >
              Monthly
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div
          style={{
            alignItems: "center",
            height: "1.4vw",
            maxHeight: "32px",
            margin: "0 2%",
            marginLeft: "5%",
          }}
        >
          <img src="/assets/Calendar_date_rang.svg" height={"100%"} />
        </div>
        <Typography
          variant="body1"
          sx={{ fontSize: "max(1.11vw, 16px)", fotWeight: "bold" }}
        >
          {this.getDates()}
        </Typography>
      </Box>
    );
  }
}

export default withStyles(styles)(DataSelector);
