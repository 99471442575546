import { AccessTime, AlignVerticalTop } from "@mui/icons-material";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { Component } from "react";
import ErrorBoundary from "../../../errorboundary.component";
import { displayOverlay } from "../../../overlay.service";
import { NoDataOverlay } from "../nodataoverlay/nodataoverlay.component";
import { checkIfAppInstalled } from "../utils/dashboard.utils";
import InfoIcon from "@mui/icons-material/Info";

const tooltipText =
  "Running total of time you are online across all devices. And the time you were active the previous day.";
interface TotalTimeProps {
  totaltime:
    | {
        value: { hours: number; minutes: number; seconds: number };
        previousValue: { hours: number; minutes: number; seconds: number };
      }
    | undefined;
  dateValue: "today" | "yesterday" | "week" | "month" | undefined;
}

interface TotalTimeState {
  value: any;
  previousValue: any;
  dataLoaded: Boolean;
  showOverlay: string | null;
}

class TotalTime extends React.Component<TotalTimeProps, TotalTimeState> {
  constructor(props: TotalTimeProps) {
    super(props);
    this.state = {
      value: props.totaltime?.value || undefined,
      previousValue: props.totaltime?.previousValue || undefined,
      dataLoaded: false,
      showOverlay: null,
    };
  }

  componentDidMount() {
    if (this.state.showOverlay === null) {
      displayOverlay("COMPONENT_DASHBOARD_TOTAL_TIME").then(
        (str: string | null) =>
          this.setState({
            showOverlay: str,
          })
      );
    }
    this.setState({
      dataLoaded: this.props.totaltime ? true : false,
    });
  }

  render() {
    const { totaltime, dateValue } = this.props;
    const pastString =
      dateValue === "today"
        ? "Yesterday"
        : dateValue === "yesterday"
        ? "Day before"
        : dateValue === "week"
        ? "Last week"
        : "Last month";
    return (
      <Box
        className="masonry-item"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          height: "6.66vw",
          minHeight: "120px",
          width: "100%",
          alignItems: "center",
          padding: "5% 2% 5% 5%",
          borderRadius: "12px",
          position: "relative",
        }}
      >
        {this.state.showOverlay ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "3.33vw",
              width: "3.33vw",
              minHeight: "54px",
              minWidth: "54px",
              padding: 0,
              alignItems: "center",
              justifyContent: "left",
            }}
          >
            <NoDataOverlay text={this.state.showOverlay} />
            <img
              src="/assets/dashboardcards/totaltime_clock.svg"
              height="100%"
              style={{ maxHeight: "54px" }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "90%",
                justifyContent: "space-around",
                whiteSpace: "nowrap",
                marginLeft: "20px",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {0}h {0}m
              </Typography>
              <Typography variant="body1">Total online time</Typography>
            </Box>
            {/* <Stack> */}
            <Box sx={{ marginRight: "-15px" }}>
              <Tooltip title={tooltipText}>
                <InfoIcon
                  sx={{
                    fontSize: "20px",
                    ":hover": { transform: "scale(1.1)" },
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
        ) : totaltime && this.state.dataLoaded ? (
          <ErrorBoundary>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "3.33vw",
                width: "3.33vw",
                minHeight: "54px",
                minWidth: "54px",
                padding: 0,
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <img
                src="/assets/dashboardcards/totaltime_clock.svg"
                height="100%"
                style={{ maxHeight: "54px" }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "90%",
                  justifyContent: "space-around",
                  whiteSpace: "nowrap",
                  marginLeft: "20px",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {totaltime.value.hours}h {totaltime.value.minutes}m
                </Typography>
                <Typography variant="body1">Total online time</Typography>
              </Box>
            </Box>
            {
              //if dateValue is not undefined show
              this.props.dateValue ? (
                <Stack justifyContent={"space-between"}>
                  <Box
                    sx={{
                      alignSelf: "flex-end",
                      marginRight: "0px",
                      marginTop: "-35px",
                      justifyContent: "top",
                    }}
                  >
                    <Tooltip title={tooltipText}>
                      <InfoIcon
                        sx={{
                          fontSize: "20px",
                          ":hover": {
                            transform: "scale(1.1)",
                          },
                        }}
                      />
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "#eceff4",
                      alignSelf: "flex-end",
                      borderRadius: "5px",
                      padding: "7px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {/* <Stack direction={"row"} justifyContent="space-between"> */}
                    {/* </Stack> */}
                    <Typography variant="body1" sx={{ color: "#696971" }}>
                      {pastString} it was{" "}
                      <b>
                        {totaltime.previousValue.hours}h{" "}
                        {totaltime.previousValue.minutes}m
                      </b>
                    </Typography>
                  </Box>
                </Stack>
              ) : null
            }
          </ErrorBoundary>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "3.33vw",
              width: "3.33vw",
              padding: 0,
              alignItems: "center",
              justifyContent: "left",
              borderRadius: "12px",
            }}
          >
            {/* <NoDataOverlay image="/assets/dashboardcards/dummy/totaltime.svg"/> */}
            <img
              src="/assets/dashboardcards/totaltime_clock.svg"
              height="100%"
              style={{ maxHeight: "54px" }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "90%",
                justifyContent: "space-around",
                whiteSpace: "nowrap",
                marginLeft: "20px",
              }}
            >
              <Typography variant="h6Light" width="100%" margin="0 2%">
                No data available
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
}

export default TotalTime;
