import HighchartsReact from "highcharts-react-official";
import Highcharts, { Options } from "highcharts";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge";

import { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";

highchartsMore(Highcharts);
solidGauge(Highcharts);

export const getArrowImgUrl = (curScore: number, prevScore: number) => {
  if (curScore < prevScore) {
    return "https://mytiro-dev.s3.amazonaws.com/assets/email/mytiro_down_arrow_red_full.png";
  } else if (curScore > prevScore) {
    return "https://mytiro-dev.s3.amazonaws.com/assets/email/mytiro_up_arrow_green_full.png";
  } else {
    return "";
  }
};

interface ScoreChartProps {
  score: number;
  prevScore: number | null;
  title: string;
  min: number;
  max: number;
}

const min = 0;
const max = 200;

export const ScoreChartComponent = (props: ScoreChartProps): JSX.Element => {
  const [chartOptions, setChartOptions] = useState<any>({
    chart: {
      type: "solidgauge",
      height: "150px",
      renderTo: "container",
      // events: {
      //   load: function () {
      //     this.yAxis[0].ticks[100].label.translate(0, -30);
      //   },
      // },
    },
    credits: {
      enabled: false,
    },
    title: undefined,

    pane: {
      center: ["50%", "90%"],
      size: "140%",
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor:
          Highcharts.defaultOptions.legend?.backgroundColor || "#EEE",
        innerRadius: "60%",
        outerRadius: "100%",
        shape: "arc",
      },
    },
    yAxis: {
      min: 0,
      max: 800,
      stops: [
        [0.1, "#643992"], // green
        [0.5, "#643992"], // yellow
        [0.9, "#643992"], // red
      ],
      // gridLineColor: "transparent",
      // lineColor: "transparent",
      minorTickLength: 0,
      tickPositions: [props.prevScore],
      tickColor: "#000000",
      // tickPosition: "inside",
      tickLength: 35,
      tickWidth: 4,
      zIndex: 4,
      // lineWidth: 0,
      // tickWidth: 0,
      minorTickInterval: null,
      // tickAmount: 2,
      // tickPositions: [50],
      // tickColor: "#000000",
      // tickPosition: "inside",
      title: {
        y: 0,
      },
      labels: {
        // display: false,
        // enabled: false,
        // y: -30,
        // x: -20,
        style: {
          color: "black",
        },
        distance: 15,
        // formatter: function () {
        //   const temp = this as any;
        //   switch (temp.value) {
        //     case props.prevScore:
        //       return "";
        //       break;
        //     default:
        //       return temp.value;
        //       break;
        //   }
        // },
      },
    },

    // lineWidth: 0,
    //   tickWidth: 0,
    //   minorTickInterval: null,
    //   tickAmount: 2,
    //   title: {
    //     y: 0,
    //   },
    //   labels: {
    //     y: 16,
    //   },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false,
          y: 0,
          borderWidth: 0,
          // useHTML: true,
          style: {
            fontSize: "10px",
          },
        },
      },
    },

    tooltip: {
      enabled: false,
    },

    series: [
      {
        // name: "Speed",
        data: [props.score],
        dataLabels: {
          // display: false,
          // format:
          //   '<div style="text-align:center; align-items: center;">' +
          //   '<span style="font-size:25px;">{y}</span><br/>' +
          //   "</div>",
        },
      },
    ],
  });

  useEffect(() => {
    const newOption = { ...chartOptions };
    newOption.yAxis.min = props.min;
    newOption.yAxis.max = Math.max(
      props.max,
      (props.score ?? 0) + 20,
      (props.prevScore ?? 0) + 20
    );
    newOption.yAxis.tickPositions = [props.prevScore ?? -10];
    newOption.series[0].data = [props.score];
    setChartOptions(newOption);
  }, [props]);

  return (
    <>
      <Stack width={"100%"}>
        <Typography
          variant={"body1Bold"}
          // sx={{ marginBottom: "-20px", zIndex: "10" }}
        >
          {props.title}
        </Typography>
        <HighchartsReact
          highcharts={Highcharts}
          constrctor={"solidGauge"}
          options={chartOptions}
          allowChartUpdate={true}
          updateArgs={[true]}
        />
        {/* <Stack
          direction={"row"}
          justifyContent="center"
          // alignSelf={"flex-start"}
        > */}
        <Box sx={{ marginTop: "-60px", zIndex: "4" }}>
          <Typography variant={"h5"} sx={{}}>
            {props.score}
          </Typography>
          <Stack
            direction={"row"}
            justifyContent="center"
            sx={{ marginTop: "-10px" }}
            // alignSelf={"flex-start"}
          >
            {/* <Typography variant={"caption"} sx={{}}>
              <img
                src={`${getArrowImgUrl(props.score, props.prevScore)}`}
                width={"10px"}
              ></img>{" "}
            </Typography> */}
            {/* <Typography
              variant="caption"
              alignItems={"baseline"}
              // sx={{ marginTop: "-10px" }}
            >
              {Math.floor(
                ((props.score - props.prevScore) / props.prevScore) * 100
              ) + "%"}
            </Typography> */}
          </Stack>
          <Stack
            direction={"row"}
            justifyContent="center"
            sx={{}}
            // alignSelf={"flex-start"}
          >
            <Box>
              <Typography variant="caption">{props.min}</Typography>
            </Box>
            <Box width="130px"></Box>
            <Box>
              <Typography variant="caption">
                {chartOptions.yAxis.max}
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box height={"10px"}></Box>
      </Stack>
    </>
  );
};
