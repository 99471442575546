import { Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";

interface CurrentTimeIndicatorProps {
  heights: {
    cell: number;
    accuracyBox: number;
  };
}

interface CurrentTimeIndicatorState {
  time: Date;
  heights: {
    cell: number;
    accuracyBox: number;
  };
}

const CurrentTimeIndicator = (props: CurrentTimeIndicatorProps) => {
  const [time, settime] = useState(new Date());
  const [heights, setheights] = useState(props.heights);

  useEffect(() => {
    setheights(props.heights);
  }, [props.heights]);

  useEffect(() => {
    const interval = setInterval(() => settime(new Date()), 1000);
    return () => clearInterval(interval);
  }, [time]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        position: "absolute",
        top:
          time.getHours() * heights.cell +
          time.getMinutes() * (heights.cell / 60) +
          heights.accuracyBox -
          10 -
          5,
        zIndex: 6,
        alignItems: "center",
        justifyContent: "right",
      }}
    >
      <div
        style={{
          height: "28px",
          backgroundColor: "black",
          color: "white",
          width: "auto",
          textAlign: "center",
          padding: "5px 8px",
          borderRadius: "5px",
        }}
      >
        <Typography
          variant="body2"
          sx={{ fontSize: "12px !important", color: "white", margin: "auto" }}
        >
          {time.toLocaleString("en-US", {
            hour: "numeric",
            hour12: true,
            minute: "numeric",
          })}
        </Typography>
      </div>
      <div
        style={{
          height: 0,
          width: 0,
          borderLeft: "15px solid black",
          borderTop: "15px solid transparent",
          borderBottom: "15px solid transparent",
          position: "relative",
          left: "-3px",
        }}
      ></div>
      <div
        style={{
          height: "1px",
          borderBottom: "1px solid black",
          width: "89%",
        }}
      ></div>
      <div
        style={{
          height: "15px",
          width: "15px",
          borderRadius: "50%",
          backgroundColor: "black",
        }}
      ></div>
    </div>
  );
};

export default CurrentTimeIndicator;
