import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from "@mui/material/Grid/Grid";
import { Container, Stack, TextField } from "@mui/material";
import { generateUserOverview, getUserData } from "./useroverview.service";
import { DeviceOverview, UserInfoData, UserOverviewData } from "./useroverview.model";

export const UserOverview = () => {
    const [users, setUsers] = useState<UserInfoData[]>([]);

    const [overviewCardData, setOverviewCardData] = useState<UserOverviewData[]>([])

    const [deviceSyncThreshold, setDeviceSyncThreshold] = useState<number>(1800)
    const [scheduleTaskThreshold, setScheduleTaskThreshold] = useState<number>(4800)

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if (users) {
            const overviewData = users.map(user => generateUserOverview(user, deviceSyncThreshold, scheduleTaskThreshold))
            setOverviewCardData(overviewData);
        }
    }, [users, deviceSyncThreshold, scheduleTaskThreshold])

    const getUsers = async () => {
        try {
            let response = await getUserData();
            setUsers(response.users);
        } catch (err) {
            console.log(err)
        }
    }

    const handleDeviceSyncThresholdChange = (event: any) => {
        if (event.target.value != deviceSyncThreshold) {
            setDeviceSyncThreshold(event.target.value)
        }
    }
    const handleScheduleTaskThresholdChange = (event: any) => {
        if (event.target.value != scheduleTaskThreshold) {
            setScheduleTaskThreshold(event.target.value)
        }
    }

    return (
        <Container sx={{ marginTop: '2%', overflow: 'scroll' }}>
            <Stack spacing={3}>
                <Stack direction={'row'} sx={{ margin: '10px' }} spacing={4}>
                    <TextField id="outlined-basic" label="Device Sync Threshold (sec)" variant="outlined" value={deviceSyncThreshold} onChange={handleDeviceSyncThresholdChange} />
                    <TextField id="outlined-basic" label="Scheduled Task Threshold (sec)" variant="outlined" value={scheduleTaskThreshold} onChange={handleScheduleTaskThresholdChange} />
                </Stack>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    {overviewCardData.map((card: any) => (
                        <Grid item xs={2} sm={4} md={4}>
                            <UserOverviewCard user={card} />
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        </Container>
    )
}


interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
interface UserOverviewCardProps {
    user: UserOverviewData;
}

const UserOverviewCard = (props: UserOverviewCardProps) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                title={props.user.email}
                subheader={props.user.accountId}
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {"Last Login Time: "} {"NA"}
                </Typography>
                {/* {(props.user.syncStatus) ?
                    (<Typography variant="body2" color="green">
                        {"Sync Status: "} {"Active"}
                    </Typography>) :
                    (<Typography variant="body2" color="red">
                        {"Sync Status: "} {"Inactive"}
                    </Typography>)} */}
                {(props.user.runStatus) ?
                    (<Typography variant="body2" color="green">
                        {"Run Status: "} {props.user.lastRunTask}
                    </Typography>) :
                    (<Typography variant="body2" color="red">
                        {"Run Status: "} {props.user.lastRunTask}
                    </Typography>)}
                {props.user.devices.map((device: DeviceOverview) => {
                    const agentList = device.agents.map(agent => {
                        const color = agent.isActive ? 'green' : 'red';
                        return (
                            <Typography variant="body2" color={color}>
                                {agent.type} {agent.lastSyncTime}
                            </Typography>
                        )
                    });
                    return <Typography paragraph color="text.secondary">
                        {device.type} {device.os} {agentList}
                    </Typography>

                })}
            </CardContent>
        </Card>
    );
}




