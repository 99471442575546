import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { getSession, handleSessionNotFound } from "../accountcontext/account";

export const BASE_URL = "/api/user/notification";

export enum NotificationMethod {
  DASHBOARD = "DASHBOARD",
}

export interface NotificationAction {
  name: string;
  url: string;
}

export enum NotificationStatus {
  READ = "READ",
  UNREAD = "UNREAD",
  DISMISSED = "DISMISSED",
}

interface Notification {
  id: number;
  accountId: string;
  source: string;
  notificationMethod: NotificationMethod;
  title: string;
  message: string;
  action: NotificationAction;
  status: NotificationStatus;
  timeToShow: number;
  readTimestamp: number;
  dismissedTimestamp: number;
  createdAt: Date;
  updatedAt: Date;
}

export const getUnreadNotifications = async (): Promise<Notification[]> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        const url = `${BASE_URL}/unread`;
        fetch(url, requestOptions)
          .then((response) => {
            if (response.ok) return response;
            else {
              throw new Error("Something went wrong");
            }
          })
          .then((response) => response.text())
          .then((response) => JSON.parse(response))
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

export const getAllNotifications = async (): Promise<Notification[]> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        const url = `${BASE_URL}`;
        fetch(url, requestOptions)
          .then((response) => {
            if (response.ok) return response;
            else {
              throw new Error("Something went wrong");
            }
          })
          .then((response) => response.text())
          .then((response) => JSON.parse(response))
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

export const markNotificationRead = async (notificationId: number): Promise<void> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const requestOptions: RequestInit = {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        const url = `${BASE_URL}/${notificationId}/read`;
        fetch(url, requestOptions)
          .then((response) => {
            if (response.ok) return response;
            else {
              throw new Error("Something went wrong");
            }
          })
          .then((response) => response.text())
          .then((response) => JSON.parse(response))
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

export const dismissNotification = async (
  notificationId: number
): Promise<void> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const requestOptions: RequestInit = {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        const url = `${BASE_URL}/${notificationId}/dismiss`;
        fetch(url, requestOptions)
          .then((response) => {
            if (response.ok) return response;
            else {
              throw new Error("Something went wrong");
            }
          })
          .then((response) => response.text())
          .then((response) => JSON.parse(response))
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};
