import { Box, Button } from "@mui/material";
import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { AccountContext } from "../../../accountcontext/account";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";

import { getAccount, Account } from "../../profile.service";
import { SettingsPage } from "../settingspage.component";

interface ProfileSettingsProps {}

const ProfileSettings: React.FunctionComponent<ProfileSettingsProps> = () => {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [profileId, setProfileId] = useState<string | undefined>("");
  const { getSession } = useContext(AccountContext);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    loadAccount();
  }, []);

  const loadAccount = async () => {
    setLoading(true);
    getSession().then((cognitoUser: CognitoUser) => {
      getAccount(cognitoUser.getUsername()).then((account: Account) => {
        setName(account?.profile?.firstName + " " + account?.profile?.lastName);
        setEmail(account.email);
        setProfileId(account?.profile?.id);
        setLoading(false);
      });
    });
  };

  // const handleEmail = (event: React.FormEvent<HTMLInputElement>) =>
  //   setEmail(event.currentTarget.value);

  const handleName = (event: React.FormEvent<HTMLInputElement>) =>
    setName(event.currentTarget.value);

  const saveChanges = () => {
    setLoading(true);
    const newProfile = {
      firstName: Name.split(" ")[0],
      lastName: Name.split(" ")[1],
    };

    getSession().then((cognitoUser: CognitoUser) => {
      cognitoUser.getSession((err: any, session: CognitoUserSession) => {
        if (err) {
          console.log(err);
        } else {
          const idToken = session.getIdToken().getJwtToken();
          const accountId = session.getIdToken().payload["cognito:username"];
          const url = `/api/user/account/${accountId}/profile`;
          const requestOptions: RequestInit = {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newProfile),
          };

          fetch(url, requestOptions)
            .then((response) => response.text())
            .then((result) => {
              setLoading(false);
              loadAccount();
            })
            .catch((error) => console.log("error", error));
        }
      });
    });
  };

  return (
    <SettingsPage
      title="Profile Settings"
      subtitle="Change your profile details"
      loading={loading}
    >
      {/* <Box
        sx={{
          borderRadius: "12px",
          border: "1px solid #D6DAE480",
          display: "flex",
          flexDirection: "row",
          justifyContent: "row",
          alignContent: "center",
          padding: "4%",
          height: "30%",
          margin: "6% 0",
        }}
      >
        <Avatar
          alt="Toni Kroos"
          src="/assets/dummy_user.png"
          sx={{ marginRight: "4%", height: "60px", width: "60px" }}
        >
          <ImageIcon />
        </Avatar>
        <Box sx={{ display: "block", width: "100%", margin: "auto 0" }}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Toni Kroos
          </Typography>
          <a>
            <Typography variant="body2" color="primary" fontWeight={"bold"}>
              Change profile photo
            </Typography>
          </a>
        </Box>
      </Box> */}

      <Box sx={{ display: "block" }}>
        <label>
          <span style={{ fontWeight: "500", fontSize: "max(12px,0.83vw)" }}>
            Name
          </span>
          <br />
          <input
            type="text"
            placeholder="Enter Full Name"
            value={Name}
            onChange={handleName}
            style={{
              background: "transparent",
              border: "1px solid #D6DAE480",
              fontWeight: "bold",
              fontSize: "max(14px, 0.972vw)",
            }}
          />
        </label>
        <label>
          <span style={{ fontWeight: "500", fontSize: "max(12px,0.83vw)" }}>
            Email
          </span>
          <br />
          <input
            type="text"
            placeholder="Enter email"
            value={Email}
            readOnly
            disabled
            style={{
              background: "transparent",
              border: "1px solid #D6DAE480",
              fontWeight: "bold",
              fontSize: "max(14px, 0.972vw)",
            }}
          />
        </label>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "flex-start",
        }}
      >
        <Button variant="rounded_purple" color="primary" onClick={saveChanges}>
          Save changes
        </Button>
        {/* <Button variant="rounded_white" sx={{ marginRight: "3%" }}>
          Cancel
        </Button> */}
      </Box>
    </SettingsPage>
  );
};

export default ProfileSettings;
