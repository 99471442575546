import { Box, Tooltip, Typography } from "@mui/material";
import Highcharts, {
  AlignValue,
  DashStyleValue,
  SeriesOptions,
  SeriesOptionsType,
  VerticalAlignValue,
} from "highcharts";
import { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import {
  TopActivitiesDto,
  WeekTargetTimeSummary,
} from "../../../../services/insight-v2/insights-v2.model";
import { convertSecondsToHHMM } from "../../../admin/dailyemail/dailyemail.service";
import InfoIcon from "@mui/icons-material/Info";

export {};

const TIME_SAVE_PERC = 0.25;
const FOCUS_INC_PERC = 0.1;
interface InsightBarChartProps {
  prevWeekSummary: WeekTargetTimeSummary | undefined;
  curWeekSummary: WeekTargetTimeSummary | undefined;
  topActivites: TopActivitiesDto[];
  timeSave: boolean;
  title: string;
}

export const APP_DUMMY_COLOR = "#7E4BB7";
const GREEN = "#66bb6aBB";
const RED = "#ef5350BB";

export const InsightBarChart = (props: InsightBarChartProps) => {
  const [noDataOverlay, setNoDataOverlay] = useState<boolean>(false);
  const [challengeVal, setChallengeVal] = useState<number>(0);
  const [title, setTitle] = useState<string>("");
  const [tooltipText, setTooltipText] = useState<string>("");
  const [chartOptions, setChartOptions] = useState<Options>({
    chart: {
      type: "column",
      height: 150,
      width: 350,
    },
    title: {
      text: undefined,
    },
    xAxis: {
      categories: [],
      labels: {
        style: {
          fontFamily: "Roboto",
        },
      },
    },
    yAxis: {
      min: 0,
      tickInterval: 1500,
      title: {
        text: undefined,
      },
      labels: {
        style: {
          fontFamily: "Roboto",
        },
        enabled: true,
        formatter: function () {
          if (this.value === 0) return "";
          return `${convertSecondsToHHMM(this.value as number)}`;
        },
      },
      stackLabels: {
        enabled: false,
        formatter: function () {
          if (this.total === 0) return "";
          return `${convertSecondsToHHMM(this.total)}`;
        },
        style: {
          fontWeight: "bold",
          textOutline: "none",
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return [
          "<b>" + this.key + "</b><br/>",
          `${this.series.name}: ${convertSecondsToHHMM(this.point.y)}`,
        ];
      },
      //   headerFormat: "<b>{point.x}</b><br/>",
      //   pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    },
    plotOptions: {
      column: {
        colorByPoint: true,
        stacking: "normal",
        dataLabels: {
          enabled: false,
          formatter: function () {
            return `${convertSecondsToHHMM(this.point.y)}`;
          },
        },
      },
    },
    series: [],
  });

  useEffect(() => {
    if (props.curWeekSummary && props.curWeekSummary?.reqValues?.length > 0) {
      // console.log(props.curWeekSummary.values);
      const data = props.curWeekSummary.reqValues;
      let challengeVal: number;
      let colors: string[];
      if (props.timeSave) {
        challengeVal = (props.prevWeekSummary?.reqAvg ?? 0) * TIME_SAVE_PERC;
      } else {
        challengeVal =
          (props.prevWeekSummary?.reqAvg ?? 0) +
          ((props.prevWeekSummary?.fragDuration ?? 0) * FOCUS_INC_PERC) /
            (props.prevWeekSummary?.reqValues?.length ?? 1);
        challengeVal = challengeVal < 1200 ? 1200 : challengeVal;
      }
      colors = data.map((d) => {
        return d > challengeVal ? GREEN : RED;
      });

      if (props.timeSave) {
        setTitle(`Save ${convertSecondsToHHMM(challengeVal)} every day`);
      } else {
        setTitle(
          `Achieve ${convertSecondsToHHMM(challengeVal)} of focus per day`
        );
      }

      setChallengeVal(challengeVal);
      const len = 5 - props.curWeekSummary.reqValues.length;
      const arr = Array(len).fill(0);
      data.push(...arr);
      const series: SeriesOptionsType[] = [
        {
          name: "Time",
          type: "column",
          data: data,
        },
      ];
      const xAxis = {
        categories: props.curWeekSummary.reqValues.map(
          (d, i) => `${dayOfWeek[i]}`
        ),
      };
      const align = "right" as AlignValue;
      const dashstyle = "Dash" as DashStyleValue;
      const max = Math.max(
        ...props.curWeekSummary.reqValues,
        // (props.prevWeekSummary?.avg ?? 0) * 1.1,
        challengeVal * 1.1
      );
      // const min = Math.min(...props.curWeekSummary.reqValues, 0);
      const maxInterval = Math.ceil(max / 300) * 300;
      // const minInterval = Math.floor(min / 300) * 300;
      const tickInterval = Math.ceil(max / 3 / 300) * 300;
      const yAxis = {
        max: maxInterval,
        // min: minInterval,
        tickInterval: tickInterval,
        plotLines: [
          {
            color: "#00000055",
            width: 1,
            value: challengeVal,
            zIndex: 5,
            dashStyle: dashstyle,
            label: {
              text: `Challenge (${convertSecondsToHHMM(challengeVal)})`,
              align: align,
              y: -4,
              style: {
                fontFamily: "Roboto",
                color: "#00000099",
              },
            },
          },
          // {
          //   color: "#00000055",
          //   width: 1,
          //   value: props.prevWeekSummary?.avg ?? 0,
          //   zIndex: 5,
          //   dashStyle: dashstyle,
          //   label: {
          //     text: `Last Week's Avg (${convertSecondsToHHMM(
          //       props.prevWeekSummary?.avg ?? 0
          //     )})`,
          //     align: props.timeSave ? undefined : align,
          //     y: props.timeSave ? -4 : 12,
          //     style: {
          //       fontFamily: "Roboto",
          //       color: "#00000099",
          //     },
          //   },
          // },
        ],
      };

      setChartOptions({ series, xAxis, yAxis, colors });
    }
  }, [props.curWeekSummary]);

  useEffect(() => {
    let text = ``;
    if (props.timeSave) {
      text += `Applications with potential time savings\n`;
    } else {
      text += `Applications with potential focus increase\n`;
    }
    for (const act of props.topActivites) {
      if (Math.round(act.totalContribution / 5) === 0) {
        continue;
      }
      text += `- ${act.appName} (${convertSecondsToHHMM(
        act.totalContribution / 5
      )})\n`;
    }
    setTooltipText(text);
  }, [props.topActivites]);

  useEffect(() => {
    setNoDataOverlay(
      !props.curWeekSummary || props.curWeekSummary?.reqValues?.length === 0
    );
  }, [props.curWeekSummary]);

  return (
    <Box
      sx={{
        bgcolor: "white",
        width: "100%",
        // padding: "1.5%",
        borderRadius: "12px",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          // marginBottom: "-20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body1Bold">{title}&nbsp;</Typography>
          <Tooltip
            title={<div style={{ whiteSpace: "pre-line" }}>{tooltipText}</div>}
          >
            <InfoIcon
              sx={{
                fontSize: "15px",
                ":hover": { transform: "scale(1.1)" },
              }}
            />
          </Tooltip>
        </Box>
        {/* <Box sx={{ width: "90%", mt: 2 }}> */}
        <Box
          sx={{
            // width: "100%",
            // maxWidth: 400,
            // maxHeight: 200,
            display: "flex",
            paddingY: "10px",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: "-15px",
          }}
        >
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            allowChartUpdate={true}
            updateArgs={[true, true, true]}
          />
        </Box>
        {/* </Box> */}
      </Box>
    </Box>
  );
};

const dayOfWeek: { [index: number]: string } = {
  0: "Mon",
  1: "Tue",
  2: "Wed",
  3: "Thu",
  4: "Fri",
};
