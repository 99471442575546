import { Link, Stack, Typography } from "@mui/material";
import { SupportsPage } from "../../supportpage.component";
import { SupportTabContent } from "../how-to-new.component";

interface ScoresBlogProps {}

export const ScoresBlog = (props: ScoresBlogProps) => {
  return (
    <>
      <Typography
        variant="h6"
        display="block"
        id={SupportTabContent.scores.id}
      ></Typography>
      <SupportsPage
        title={SupportTabContent.scores.title}
        subtitle={SupportTabContent.scores.description}
        loading={false}
      >
        <ScoresPage></ScoresPage>
      </SupportsPage>
    </>
  );
};

interface ScoresProps {}

const ScoresPage = (props: ScoresProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h6" display="block">
        Introducing myTiro Scores ©
      </Typography>
      <Typography variant="body1" display="block">
        Knowing our data help us understand our digital behavior. myTiro shows
        us detailed data about when, where, and how we spent our time. Sometimes
        we would rather get a simple score to benchmark our performance and
        decide if and when to process the detailed data. myTiro Scores © address
        the need to have a single score, well, three in reality.
      </Typography>
      <Typography variant="body1" display="block">
        myTiro Focus Score, myTiro Goals Score, and myTiro Habit Score will
        compute your score on three dimensions – time usage, intentions, and
        consistency.
      </Typography>
      <Typography variant="body1" display="block" fontWeight={"bold"}>
        myTiro Focus Score – How focused am I daily?
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/scores/focus-score.png" width="600"></img>
      </Typography>
      <Typography variant="body1" display="block">
        Our proprietary algorithm considers total time spent on devices, how
        much of it was focused, and how frequently you keep switching between
        applications or websites.
      </Typography>
      <Typography variant="body1" display="block">
        A higher score means you were more focused. If you do deep work or want
        to complete one task with focus and reduce distractions, then strive to
        increase your myTiro Focus Score. The modeled max score of 500 is
        computed based on someone working 9 hours, 6 of which are focused, and
        switching between applications every five minutes. You can get higher
        score if you are more focused than modeled scenario.{" "}
      </Typography>
      <Typography variant="body1" display="block">
        Your real-time Focus score is tentative and the final score will be
        computed after you have finished working. Your final score will always
        be higher than your real-time score. You can see your final Focus Score
        in your daily email and home page.
      </Typography>
      <Typography variant="body1" display="block">
        To increase your myTiro Focus Score, you can increase focus hours,
        increase % of hours that are focused, or reduce the number of times you
        switch between applications. This score is calculated every hour.
      </Typography>
      <Typography variant="body1" display="block">
        As long as your myTiro Focus Score increases day over day, you are on
        your way to being more focused. You can compare your myTiro Focus Score
        with your past rating or others using the platform.
      </Typography>
      <Typography variant="body1" display="block" fontWeight={"bold"}>
        myTiro Goals Score – How deliberate am I with my daily time?
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/scores/goals-score.png" width="600"></img>
      </Typography>
      <Typography variant="body1" display="block">
        Some of us derive joy from setting and checking off completed goals.
        myTiro Goals Score gives you a single number to track your progress on
        being more goal-oriented. Our proprietary algorithm considers
        goals/subgoals created, partially completed, and fully completed to
        calculate your myTiro Goals Score. Goals Score measures primarily how
        deliberately we choose to spend our time on our digital devices.
      </Typography>
      <Typography variant="body1" display="block">
        A higher score means you are much better at being deliberate and
        goal-oriented. If you want to check off goals, plan your time with
        specific goals, or are motivated by finishing goals, then myTiro Goals
        score is for you. The modeled max score of 500 is computed based on
        9-hour working day and setting 5-6 goals, 10-12 sub-goals, covering 67%
        of a 9-hour working day, meeting 4-5 goals and falling short of 1-2
        goals. You can get higher score if you completed more goals covering
        more hours than modeled scenario.
      </Typography>
      <Typography variant="body1" display="block">
        Your real-time Goals score is tentative and the final score will be
        computed after you have finished working. Your final score will always
        be higher than your real-time score. You can see your final Goals Score
        in your daily email and home page.
      </Typography>
      <Typography variant="body1" display="block">
        To increase your myTiro Goals Score, you can set more goals, expand your
        goals (e.g., set larger time or focus values), or complete more
        goals/subgoals. This score is calculated hourly and at the end of every
        day based on the day’s activities.
      </Typography>
      <Typography variant="body1" display="block">
        As long as your myTiro Goals Score increases, you are on your way to
        being more goal-oriented. You can compare your myTiro Goals Score with
        your past or with others.
      </Typography>
      <Typography variant="body1" display="block" fontWeight={"bold"}>
        myTiro Habit Score – How consistent am I in my digital usage?
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/scores/habit-score.png" width="600"></img>
      </Typography>
      <Typography variant="body1" display="block">
        Many of us want to build healthy habits that can continue paying
        dividends. myTiro Habits Score tells you how good your habits are and
        the progress you are making. Our proprietary algorithm considers your
        consistency in your total device time, focus and goals over a rolling
        30-day period to calculate your myTiro Habits Score.
      </Typography>
      <Typography variant="body1" display="block">
        A higher score means you are building strong and healthy time habits. If
        you are the kind of person that wants to invest today for a better
        tomorrow, then you know the power of habits. myTiro Habits Score enables
        you to build your habits over time and continue to better yourself. The
        max score of 1300 assumes 100% consistent digital device usage over the
        past 30 days.
      </Typography>
      <Typography variant="body1" display="block">
        You can increase your myTiro Habit score by continuing to be consistent.
        The score is calculated on a rolling 30-day basis and to improve the
        score, continue to improve a little every day.
      </Typography>
      <Typography variant="body1" display="block">
        As long as your myTiro Habit Score increases, you are on your way to
        building strong and healthy time habits. You can compare your myTiro
        Goals Score with your past or with others.
      </Typography>
      <Typography variant="body1" display="block" fontWeight={"bold"}>
        Conclusion
      </Typography>
      <Typography variant="body1" display="block">
        myTiro has three scores that can help you get a quick and accurate read
        on your past workday performance. myTiro Focus Score tells you how
        focused you were for the time you spent on your devices. myTiro Goals
        Score tells you how well you plan your day and how much of your plan you
        were able to execute. myTiro Habit Score tells you how consistent you
        are in your habits.{" "}
      </Typography>
      <Typography variant="body1" display="block">
        Daily, work on improving all three of your myTiro scores and you will
        have developed strong and healthy time habits!
      </Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
    </Stack>
  );
};
