import {
  KeyboardArrowLeft,
  SettingsAccessibilityOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import { useState, useEffect } from "react";
import { CalendarMobileHeader } from "../../pages/calendar/containercalendar.component";
import theme from "../../theme/theme";
import DeviceManagement from "./devicemanagement/devicemanagement.component";
import IntegrationSettings from "./integrationsettings/integrationsettings.component";
import ProfileSettings from "./profilesettings/profilesettings.component";
import { useSearchParams } from "react-router-dom";
import AccountSettings from "./accountsettings/accountsettings.component";
import { CustomCategory } from "./custom-category/custom-category.component";

export const SettingsContent = {
  profileSettings: {
    title: "Profile Settings",
    description: "Change your profile details",
    id: "profile",
  },
  accountSettings: {
    title: "Account Settings",
    description: "Manage your account",
    id: "account",
  },
  deviceSettings: {
    title: "Devices & Browsers",
    description: "Modify your devices & browsers",
    id: "device",
  },
  integrationSettings: {
    title: "Integration Management",
    description: "Manage your integrations",
    id: "integration",
  },
  customCategory: {
    title: "Custom Category",
    description: "Manage your custom categories",
    id: "custom-category",
  },
};

const settingsButtons = [
  {
    icon: "/assets/profilesettings.svg",
    icon_selected: "/assets/profilesettings_selected.svg",
    title: "Profile Settings",
    description: "Change your profile details",
    display: <ProfileSettings />,
    id: "profile",
  },
  {
    icon: "/assets/accountsettings.svg",
    icon_selected: "/assets/accountsettings_selected.svg",
    title: "Account Settings",
    description: "Manage your account",
    display: <AccountSettings />,
    id: "account",
  },
  {
    icon: "/assets/devicesettings.svg",
    icon_selected: "/assets/devicesettings_selected.svg",
    title: "Devices & Browsers",
    description: "Modify your devices & browsers",
    display: <DeviceManagement />,
    id: "device",
  },
  {
    icon: "/assets/integrationsettings.svg",
    icon_selected: "/assets/integrationsettings_selected.svg",
    title: "Integration Management",
    description: "Manage your integrations",
    display: <IntegrationSettings />,
    id: "integration",
  },
  {
    icon: "/assets/support-tab/contact_support_unselected.svg",
    icon_selected: "/assets/support-tab/contact_support_selected.svg",
    title: SettingsContent.customCategory.title,
    description: SettingsContent.customCategory.description,
    display: <CustomCategory />,
    id: SettingsContent.customCategory.id,
  },
];

interface SettingsProps {}

const SettingsNavigation = (props: {
  icon: string;
  icon_selected: string;
  title: string;
  description: string;
  isSelected: boolean;
  handleClick: (title: string) => void;
}) => {
  const { icon, icon_selected, title, description, isSelected } = props;
  const handleClick = () => {
    props.handleClick(title);
  };

  return (
    <Button
      onClick={handleClick}
      variant="settings"
      disabled={isSelected}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
        marginBottom: "2%",
      }}
    >
      <Grid container>
        <Grid item xs={2} sx={{ paddingTop: "5px", textAlign: "center" }}>
          <img
            src={isSelected ? icon_selected : icon}
            height="70%"
            style={{ maxHeight: "36px" }}
          />
        </Grid>
        <Grid item xs={10} sx={{ textAlign: "left", paddingLeft: "4%" }}>
          <Box sx={{ margin: "auto" }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "black" }}
            >
              {title}
            </Typography>
            <Typography variant="body2">{description}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Button>
  );
};

const SettingsPanel: React.FunctionComponent<SettingsProps> = (props: any) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "4%",
        height: "85vh",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          display: "block",
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#E0E4EF",
          outline: 0,
          borderRadius: "40px",
          height: "40%",
        },
      }}
    >
      <Box sx={{ width: "100%", maxWidth: "1024px", margin: "auto" }}>
        {props.children}
      </Box>
    </Box>
  );
};

const SettingsMobilePanel = (props: any) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "4%",
        height: "85vh",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          display: "block",
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#E0E4EF",
          outline: 0,
          borderRadius: "40px",
          height: "40%",
        },
      }}
    >
      <Button
        variant="onlytext"
        onClick={props.handleClick}
        startIcon={<KeyboardArrowLeft />}
      >
        Back
      </Button>
      <Box sx={{ width: "100%", margin: "auto" }}>{props.children}</Box>
    </Box>
  );
};

const SettingsOptions = (props: {
  handleSelect: (id: string) => void;
  currentSetting: string;
}) => {
  const { handleSelect, currentSetting } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "4%",
        height: "85vh",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          display: "block",
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#E0E4EF",
          outline: 0,
          borderRadius: "40px",
          height: "40%",
        },
      }}
    >
      {settingsButtons.map((setting, index) => (
        <SettingsNavigation
          key={index}
          isSelected={setting.id === currentSetting}
          handleClick={() => handleSelect(setting.id)}
          {...setting}
        />
      ))}
    </Box>
  );
};

const Settings: React.FunctionComponent<SettingsProps> = () => {
  const findSettingFromID = (id: string) => {
    const setting = settingsButtons.find((setting) => setting.id === id);
    if (setting) {
      return setting.display;
    } else {
      return settingsButtons[0].display;
    }
  };

  const matchesMedium = useMediaQuery(theme.breakpoints.down("md"));
  const [searchParams, setsearchParams] = useSearchParams();
  const [currentPanel, setCurrentPanel] = useState(settingsButtons[0].display);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [showMenu, setshowMenu] = useState(true);
  const [currentSetting, setcurrentSetting] = useState("none");

  const handleSelect = (id: string) => {
    const setting = settingsButtons.find((setting) => setting.id === id);
    if (setting !== undefined) {
      setcurrentSetting(setting?.id);
      setCurrentPanel(setting.display);
      if (matchesMedium) {
        setshowMenu(false);
      }
      const option = setting.id;
      setsearchParams({ option });
    }
    return;
  };

  const showOptions = () => {
    setcurrentSetting("none");
    setshowMenu(true);
  };

  useEffect(() => {
    if (showMenu) {
      setcurrentSetting("none");
    }
  }, [showMenu]);

  useEffect(() => {
    setCurrentPanel(findSettingFromID(currentSetting));
  }, [currentSetting]);

  useEffect(() => {
    const option = searchParams.get("option");
    if (option === null) {
      setcurrentSetting(settingsButtons[0].id);
    } else {
      setcurrentSetting(option);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box display="block" width="100%">
        {matches ? <CalendarMobileHeader /> : ""}
        <Box
          sx={{
            display: "block",
            width: "100%",
            padding: "2%",
            height: "100vh",
          }}
        >
          <Typography variant="h5" sx={{ marginBottom: "1.5%" }}>
            Settings
          </Typography>
          {matchesMedium ? (
            <Box
              display="block"
              width="100%"
              sx={{ backgroundColor: "white", borderRadius: "8px" }}
            >
              {showMenu ? (
                <SettingsOptions
                  currentSetting={currentSetting}
                  handleSelect={handleSelect}
                />
              ) : (
                <SettingsMobilePanel handleClick={showOptions}>
                  {currentPanel}
                </SettingsMobilePanel>
              )}
            </Box>
          ) : (
            <Grid
              container
              sx={{ background: "white", height: "85vh", borderRadius: "8px" }}
            >
              <Grid
                item
                lg={3}
                md={5}
                xs={12}
                sx={{ borderRight: "1px solid #D6DAE480" }}
              >
                <SettingsOptions
                  currentSetting={currentSetting}
                  handleSelect={handleSelect}
                />
              </Grid>
              <Grid item lg={9} md={7} xs={12}>
                <SettingsPanel>{currentPanel}</SettingsPanel>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Settings;
