import { Box, Typography } from "@mui/material";
import { withStyles, WithStyles } from "@mui/styles";
import React from "react";
import { Link } from "react-router-dom";
import { styles } from "./styles/leftnavigation.styles";

interface NavButtonProps extends WithStyles<typeof styles> {
  icon: string;
  icon_selected: string;
  text: string;
  path: string;
  selected: boolean;
  index: number;
  onSelect: (id: number) => void;
}

interface NavButtonState {
  icon: string;
  icon_selected: string;
  selected: boolean;
  displayIcon: string;
  text: string;
  index: number;
}

class NavButton extends React.Component<NavButtonProps, NavButtonState> {
  constructor(props: NavButtonProps) {
    super(props);
    this.state = {
      icon: props.icon,
      icon_selected: props.icon_selected,
      displayIcon: props.icon,
      text: props.text,
      index: props.index,
      selected: props.selected,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidUpdate(prevProps: NavButtonProps) {
    if (prevProps.selected !== this.props.selected) {
      if (this.props.selected) {
        this.setState({
          displayIcon: this.state.icon_selected,
          selected: true,
        });
      } else {
        this.setState({ displayIcon: this.state.icon, selected: false });
      }
    }
  }

  handleClick = () => {
    if (this.props.text === "Goals") return;
    // if (!this.props.selected) {
    this.props.onSelect(this.state.index);
    // }
  };

  render() {
    const { classes, path } = this.props;
    const { text } = this.state;
    return (
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          marginTop: "16px",
          marginBottom: "24px",
          padding: "0 5%",
          transition: "all .2s ease-in-out",
          ":hover": {
            cursor: "pointer",
            transform: "scale(1.05)",
          },
        }}
        onClick={this.handleClick}
      >
        <Box
          className={
            classes.buttonBackground +
            ` ${
              this.state.selected
                ? classes.selectedButtonBackground
                : classes.unselectedButtonBackground
            }`
          }
        >
          <img
            src={
              this.state.selected ? this.state.icon_selected : this.state.icon
            }
            alt={text}
          />
        </Box>
        <div
          // variant="body1"
          className={
            this.state.selected
              ? classes.buttonTextSelected
              : classes.buttonText
          }
        >
          {text}
        </div>
      </Box>
    );
  }
}

export default withStyles(styles)(NavButton);
