import { Box, Button, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { validatePassword } from "../../../pages/onboarding/Authentication/Regex";
import { AccountContext } from "../../../accountcontext/account";
import { CognitoUser } from "amazon-cognito-identity-js";
import { SettingsPage } from "../settingspage.component";

interface AccountSettingsProps {}

const AccountSettings = (props: AccountSettingsProps) => {
  const [loading, setloading] = useState(false);
  const [fields, setfields] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errorMsg, seterrorMsg] = useState({
    message: "",
    showError: false,
  });

  const { getSession } = React.useContext(AccountContext);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    let fld = {
      ...fields,
      [event.currentTarget.name]: event.currentTarget.value,
    };
    setfields(fld);
  };

  const checkPasswordValidity = (pass: string) => {
    let isValid = validatePassword(pass);
    if (isValid !== null) {
      seterrorMsg(isValid);
      return false;
    } else {
      seterrorMsg({
        message: "",
        showError: false,
      });
      return true;
    }
  };

  const changePass = () => {
    const { oldPassword, newPassword } = fields;
    setloading(true);
    getSession()
      .then((user: CognitoUser) => {
        user.changePassword(
          oldPassword,
          newPassword,
          (err: any, result: any) => {
            if (err) {
              seterrorMsg({
                message:
                  err.message === "Incorrect username or password."
                    ? "Incorrect password"
                    : err.message || JSON.stringify(err),
                showError: true,
              });
            } else {
              seterrorMsg({
                message: "Password changed successfully",
                showError: true,
              });
            }
            setloading(false);
            console.log(result);
          }
        );
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const handleSubmit = (e: any) => {
    const { oldPassword, newPassword, confirmPassword } = fields;
    if (newPassword !== confirmPassword) {
      seterrorMsg({ message: "The passwords do not match", showError: true });
    } else if (newPassword.length === 0) {
      seterrorMsg({
        message: "Please enter your password",
        showError: true,
      });
    } else if (confirmPassword.length === 0) {
      seterrorMsg({
        message: "Please confirm your password",
        showError: true,
      });
    } else {
      if (checkPasswordValidity(newPassword)) {
        changePass();
      }
    }
  };

  return (
    <SettingsPage
      title="Account Settings"
      subtitle="Manage your account"
      loading={loading}
    >
      <Box display="block" width="100%">
        <Typography variant="h6" sx={{ margin: "auto 0" }}>
          Change Password
        </Typography>
      </Box>
      <Box sx={{ display: "block", marginTop: "4%" }}>
        <label>
          <span style={{ fontWeight: "500", fontSize: "max(12px,0.83vw)" }}>
            Verify old password
          </span>
          <br />
          <input
            type="password"
            placeholder="Verify old password"
            name="oldPassword"
            value={fields.oldPassword}
            onChange={handleChange}
            style={{
              background: "transparent",
              border: "1px solid #D6DAE480",
              fontWeight: "bold",
              fontSize: "max(14px, 0.972vw)",
            }}
          />
        </label>
        <label>
          <span style={{ fontWeight: "500", fontSize: "max(12px,0.83vw)" }}>
            New password
          </span>
          <br />
          <input
            type="password"
            placeholder="Enter new password"
            name="newPassword"
            value={fields.newPassword}
            onChange={handleChange}
            style={{
              background: "transparent",
              border: "1px solid #D6DAE480",
              fontWeight: "bold",
              fontSize: "max(14px, 0.972vw)",
            }}
          />
        </label>
        <label>
          <span style={{ fontWeight: "500", fontSize: "max(12px,0.83vw)" }}>
            Confirm password
          </span>
          <br />
          <input
            type="password"
            placeholder="Confirm password"
            name="confirmPassword"
            value={fields.confirmPassword}
            onChange={handleChange}
            style={{
              background: "transparent",
              border: "1px solid #D6DAE480",
              fontWeight: "bold",
              fontSize: "max(14px, 0.972vw)",
            }}
          />
        </label>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "flex-start",
          marginTop: "4%",
        }}
      >
        <Button variant="rounded_purple" color="primary" onClick={handleSubmit}>
          Change Password
        </Button>
      </Box>
      {errorMsg.showError ? (
        <Typography color="red">{errorMsg.message}</Typography>
      ) : (
        <></>
      )}
    </SettingsPage>
  );
};

export default AccountSettings;
