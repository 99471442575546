import { darkScrollbar } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import React from "react";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    outlined: true;
    whiteButton: true;
    settings: true;
    settings_selected: true;
    rounded_purple: true;
    rounded_white: true;
    submit_form: true;
    onlytext: true;
    onlytextpurple: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body1Bold: React.CSSProperties;
    body2Bold: React.CSSProperties;
    body2Black: React.CSSProperties;
    body1Light: React.CSSProperties;
    h6Light: React.CSSProperties;
    calendarHeading: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body1Bold?: React.CSSProperties;
    body2Bold?: React.CSSProperties;
    body2Black?: React.CSSProperties;
    body1Light?: React.CSSProperties;
    h6Light?: React.CSSProperties;
    calendarHeading?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1Bold: true;
    body2Bold: true;
    body2Black: true;
    body1Light: true;
    h6Light: true;
    calendarHeading: true;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    event?: {main: string},
  }
  interface Palette {
    event: {main: string}
  }
}

declare module "@mui/material/Snackbar" {
  interface SnackBarPropsVariantOverrides {
    success: true;
    error: true;
  }
}

declare module "@mui/material/Switch" {
  interface SwitchPropsColorOverrides {
    event: true;
  }
}

const primary = "#7E4BB7";
const event = "#598DF1";

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      contrastText: "#FFF",
    },
    secondary: {
      main: "#fff",
    },
    event: {
      main: event,
    },
    background: {
      paper: '#fff',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            backgroundColor: "transparent",
            border: "1px solid #CACEDC",
            color: "#050505",
            fontWeight: "600",
            whiteSpace: 'nowrap',
            flexBasis: 'max-content',
            flexShrink: 0,
            flexGrow: 0,
          },
        },
        {
          props: { variant: "whiteButton" },
          style: {
            backgroundColor: "#fff",
            border: "1px transparent #CACEDC",
            color: "#050505",
            fontWeight: "600",
            boxShadow: "0px 4px 14px 0px #E5E9EB",
            whiteSpace: 'nowrap',
            flexBasis: 'auto',
          },
        },
        {
          props: { variant: "settings" },
          style: {
            backgroundColor: "transparent",
            color: "#050505",
            flexBasis: 'max-content',
            flexShrink: 0,
            flexGrow: 0,
            padding: '4% 6%!important',
            "&.Mui-disabled": {
              backgroundColor: '#F2ECF8',
              color: "#000",
              fontWeight: "bold",
            },
            "&:hover": {
              backgroundColor: '#F2ECF8',
              color: "#000",
            },
          },
        },
        {
          props: { variant: "onlytext" },
          style: {
            backgroundColor: "transparent",
            color: "#050505",
            fontWeight: "600",
            flexBasis: 'max-content',
            flexShrink: 0,
            flexGrow: 0,
            padding: '2% !important',
            "&.Mui-disabled": {
              backgroundColor: '#F2ECF8',
              color: "#000",
              fontWeight: "bold",
            },
            "&:hover": {
              backgroundColor: 'transparent',
              color: "#000",
              fontWeight: "bold",
            },
          },
        },
        {
          props: { variant: "rounded_purple" },
          style: {
            fontWeight: '700',
            borderRadius: '72px',
            backgroundColor: primary,
            color: 'white',
            padding: '2% 4%',
            fontSize: "max(12px,0.83vw)",
            "&:hover": {
              backgroundColor: primary+'E6',
              color: "#FFF",
            },
          },
        },
        {
          props: { variant: "rounded_white" },
          style: {
            fontWeight: 'bold',
            borderRadius: '72px',
            backgroundColor: 'transparent',
            border: '1px solid #D6DAE4',
            color: 'black',
            padding: '2% 4%',
            fontSize: "max(12px,0.83vw)"
          },
        },
        {
          props: { variant: "submit_form" },
          style: {
            fontWeight: '700',
            borderRadius: '72px',
            backgroundColor: primary,
            color: 'white',
            width: '100%',
            padding: '2% 4%',
            fontSize: "max(12px,0.83vw)",
            "&:hover": {
              backgroundColor: primary+'E6',
              color: "#FFF",
            },
          },
        },
        {
          props: { variant: "onlytextpurple" },
          style: {
            backgroundColor: "transparent",
            color: primary,
            fontWeight: "600",
            flexBasis: 'max-content',
            flexShrink: 0,
            flexGrow: 0,
            padding: '0 !important',
            "&.Mui-disabled": {
              backgroundColor: '#F2ECF8',
              color: "#000",
              fontWeight: "bold",
            },
            "&:hover": {
              backgroundColor: 'transparent',
              color: "#000",
              fontWeight: "bold",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: "max(14px, 0.972vw)",
          textTransform: "none",
          borderRadius: "8px",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "&::-webkit-scrollbar": {
            display: "block",
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#E0E4EF",
            outline: 0,
            borderRadius: "40px",
            height: "40%",
          },
        }
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          border: 0,
          whiteSpace: "nowrap",
          color: "black",
          fontSize: "max(12px,0.83vw)",
          padding: "2% 4%",
          "&.Mui-selected": {
            backgroundColor: primary,
            color: "#FFF",
            fontWeight: "bold",
          },
          "&.Mui-disabled": {
            backgroundColor: primary,
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#D0C5DB",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "max(12px, 0.9vw)",
          fontWeight: "bold",
          padding: 0,
          minHeight: "0 !important",
          whiteSpace: "nowrap",
          "&.Mui-selected": {
            color: "black",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          margin: '1% 0',
          backgroundColor: '#F3F5F6 !important',
          boxShadow: 'none',
          border: 0,
          borderRadius: "12px",
          padding: '1%',
          '&:before': {
            display: 'none',
          }
        }
      }
    },
    MuiAccordionSummary:{
      styleOverrides: {
        root: {
          backgroundColor: '#F3F5F6!important',
          borderRadius: '12px',
        }
      }
    },

    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: '#F3F5F6!important',
        }
      }
    },
  },
  typography: {
    fontFamily: `"Roboto", sans-serif`,
    h6: {
      fontSize: "max(18px, 1.25vw)",
      fontWeight: "bold",
    },
    h6Light: {
      fontSize: "max(18px, 1.25vw)",
      fontWeight: "light",
      color: "#AAAFBB",
    },
    h5: {
      fontSize: "max(28px,1.95vw)",
      fontWeight: "bold",
    },
    h4: {
      fontSize: "max(34px, 2.36vw)",
      fontWeight: "bold",
    },
    body2: {
      fontSize: "max(12px,0.83vw)",
      fontWeight: "regular",
      color: "#7F8493",
    },
    body2Black: {
      fontSize: "max(12px,0.83vw)",
      fontWeight: "regular",
      color: "#050505",
    },
    body2Bold: {
      fontSize: "max(12px,0.83vw)",
      fontWeight: "bold",
      color: "#050505",
    },
    body1: {
      fontSize: "max(14px, 0.972vw)",
      color: "#050505",
    },
    body1Bold: {
      fontSize: "max(14px, 0.972vw)",
      color: "#050505",
      fontWeight: 'bold',
    },
    body1Light: {
      fontSize: "max(14px, 0.972vw)",
      color: "#AAAFBB",
      fontWeight: 'light',
    },
    calendarHeading: {
      fontSize: "max(14px, 0.972vw)",
      color: "#787483",
      fontWeight: "500",
      textTransform: "uppercase",
    }
  },
});

export default theme;
