import { Link, Stack, Typography } from "@mui/material";
import { SupportTabContent } from "../../support-tab.component";
import { SupportsPage } from "../../supportpage.component";
// import { SupportTabContent } from "../understanding-tab.component";

interface DetailedHowToBlogProps {}

export const DetailedHowToBlog = (props: DetailedHowToBlogProps) => {
  return (
    <>
      <Typography
        variant="h6"
        display="block"
        id={SupportTabContent.howTomyTiro.id}
      ></Typography>
      <SupportsPage
        title={SupportTabContent.howTomyTiro.title}
        subtitle={SupportTabContent.howTomyTiro.description}
        loading={false}
      >
        <DetailedHowToPage></DetailedHowToPage>
      </SupportsPage>
    </>
  );
};

interface DetailedHowToProps {}

const DetailedHowToPage = (props: DetailedHowToProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="body1" display="block">
        myTiro wants you to define your own success and to build healthy time
        habits that develop and change each day. Having healthy time habits
        helps with increased productivity, higher ROI on time, and improved
        mental wellbeing.
      </Typography>
      <Typography variant="body1" display="block">
        At myTiro, we define a healthy time habit as:
      </Typography>
      <Typography variant="body1" display="block">
        <ul>
          <li>
            What % of our digital time is deliberate and set with intent. For
            example, setting two hours to check emails.
          </li>
          <li>
            What % of our digital time is deliberate and focused. For example,
            processing emails with focus and without distractions.
          </li>
          <li>
            What % of our digital time is deliberate, focused, and targeted. For
            example, processing emails with focus and only twice daily.
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" display="block">
        myTiro collects and aggregates data from all of your working devices and
        gives users a single source of truth on where, when, and how they are
        using your time. myTiro does not define what is and is not productive,
        that is entirely up to you. We believe that only you, the user, has the
        full context on whether the time spent was useful to you or not.
      </Typography>
      <Typography variant="body1" display="block">
        For you to decide if your time is being spent efficiently and in your
        best interest, the first step is to understand how you truly spent the
        time. The second step is to know your habits/patterns, and the third
        step is to identify what you need to change to build healthier time
        habits and reach all of your goals. Only then can you begin to implement
        actionable steps to change your approach.
      </Typography>
      <Typography variant="body1" display="block">
        <ul>
          <li>
            myTiro’s personalized dashboard and calendar pages tell you where,
            when, and how you spent your time.
          </li>
          <li>myTiro Insights tell you what your habits are.</li>
          <li>myTiro Goals can help you implement the changes you want to</li>
        </ul>
      </Typography>
      <Typography variant="body1" display="block">
        To build healthier time habits, we suggest:
      </Typography>
      <Typography variant="body1" display="block">
        <ol>
          <li>
            Spending 5-10 days understanding where you are spending your time,
            how much you are focusing, and how much you are switching using
            Dashboard, Calendar, and daily recap myTiro emails.
          </li>
          <li>
            Observing your trending data in the weekly emails to see what is
            improving and what needs improvement each day. Spending more/less
            time in an application, being more focused in general or in specific
            applications, and reducing switching are some examples of potential
            improvements you can make
          </li>
          <li>
            Choosing one or two improvement areas to focus on in the beginning.
            Setting specific time – generally or within a specific application –
            is a good start and can help you gain an understanding of the
            capabilities of myTiro.
          </li>
          <li>
            Every morning as you start working on your device, use myTiro goals
            to set a minimum/maximum time for the day or for a specific
            application.
          </li>
          <li>
            Every afternoon around lunchtime, check your progress on the goal
            you set.
          </li>
          <li>
            During the day, be aware of how you feel about the goal you have
            created and any changes in your behavior because of the goal. We bet
            there will be changes.
          </li>
          <li>
            The next morning, check your daily recap email on your previous
            day's performance against your goal. If you exceeded your goal, set
            a higher target for today. If you missed your goal, set a lower
            target. Continue experimenting until you are regularly able to meet
            your goal.
          </li>
          <li>
            Over time, you will find that you will be able to set time goals for
            most of your device time, be able to achieve your focus goals for
            the day, and you will be able to do so with minimal switching.
          </li>
        </ol>
      </Typography>
      <Typography variant="body1" display="block"></Typography>
    </Stack>
  );
};
