import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { DetailedTimelineDto } from "../../../../../services/timeline/timeline.model";
import { LogoLoader } from "../../../../loader/loader.component";
import { ApplicationViewChart } from "../charts/application-chart.component";
import { GoalProgressChart } from "../charts/goal-progress-chart.component";

import { SwitchHeatmapChart } from "../charts/switch-heatmap.component";
import CloseIcon from "@mui/icons-material/Close";
import { DateTime } from "luxon";

interface TimelineDialogProps {
  timelineData: DetailedTimelineDto[];
  timelineHour: number;
  open: boolean;
  date: DateTime;
  onClose: () => void;
}

export const TimelineDialog = (props: TimelineDialogProps) => {
  const { onClose, open } = props;
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={() => handleClose()}
      open={open}
      fullWidth
      maxWidth="lg"
      sx={
        {
          // width: "75%",
          // paddingBottom: "200",
        }
      }
    >
      <LogoLoader loading={loading} />
      <DialogTitle>
        <Box sx={{ position: "relative" }}>
          <CloseIcon
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              ":hover": { cursor: "pointer" },
            }}
          ></CloseIcon>
        </Box>
      </DialogTitle>

      {/* <SwitchHeatmapChart
        chartData={[]}
        title={"Switch Heatmap"}
      ></SwitchHeatmapChart> */}
      <DialogContent>
        <Typography variant="body1Bold">
          {props.date.toFormat("LLL dd, yyyy")}
        </Typography>
        <GoalProgressChart
          timelineData={props.timelineData}
          timelineHour={props.timelineHour}
        ></GoalProgressChart>
        <ApplicationViewChart
          timelineData={props.timelineData}
          timelineHour={props.timelineHour}
        ></ApplicationViewChart>
      </DialogContent>
    </Dialog>
  );
};
