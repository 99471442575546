import Masonry from "react-masonry-css";
import "./dashboard.styles.css";
import * as React from "react";

import DeviceUsage from "./deviceusage/deviceusage.component";
import FocusTime from "./focustime/focustime.component";
import TotalTime from "./totaltime/totaltime.component";
// import dataObject from "./dashboard_usage_api.json";
import { Box } from "@mui/material";
import { fetchDashboardData } from "./dashboard.service";
import DataSelector from "../dataselector/dataselector.component";
import InformationChannels from "./informationchannels/informationchannels.component";
import { AppUsageComponent } from "./components/app_usage/app_usage.component";
import Goals from "./goals/goals.component";
//import { GoalData } from "./goals/model/goals.model";
import { EmailInfoChannel } from "./informationchannels/emailtable/email_table.model";
import WeeklyReport from "./weeklyreport/weeklyreport.component";
import Benefits from "./benefits/benefits.component";
import { LogoLoader } from "../loader/loader.component";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme/theme";
import { UsageByCategory } from "./components/usage_category/usage_category.component";
import { TabsInfoChannel } from "./informationchannels/tabstable/tabstable.model";
import {
  URLSearchParamsInit,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { Grid, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { onboardingRequired } from "../../services/app.service";
import { MobileHeader } from "./mobileheader.component";
import { UsageByCategoryCard } from "./components/usage_category_new/usage_category.component";
import { ViewGoalDto } from "../../services/goals/goals.model";

const gtag = require("ga-gtag");

interface DashboardState {
  usageData: any;
  informationData: any;
  emailsData: EmailInfoChannel[];
  tabsData: TabsInfoChannel[];
  benefitsData: any;
  goalsData: any;
  multiGoalData: ViewGoalDto[];
  dataLoaded: boolean;
  dateRange: { start: number; end: number }[];
  dateValue: "today" | "yesterday" | "week" | "month" | undefined;
  isMobile: boolean;
  isTablet: boolean;
  isWeek: boolean;
}

interface DashboardClassProps {
  closeLoader: () => void;
  onboardingRequired: boolean;
  searchParams: URLSearchParams;
  isMobile: boolean;
  isTablet: boolean;
  setsearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          state?: any;
        }
      | undefined
  ) => void;
}

interface InformationApiData {
  duration: {
    start: string;
    end: string;
  };
  data: {
    informationChannel: {
      email: Array<
        | {
            name: string;
            value: {
              received: number;
              opened: number;
              sent: number;
            };
          }
        | unknown
      >;
      tabs: Array<{
        name: string;
        value:
          | {
              opened: number;
              closed: number;
              total: number;
            }
          | unknown;
      }>;
    };
  };
}

export class DashboardClass extends React.Component<
  DashboardClassProps,
  DashboardState
> {
  constructor(props: any) {
    super(props);
    let dateVal = props.searchParams.get("dates");
    this.state = {
      isWeek: false,
      usageData: undefined,
      informationData: undefined,
      emailsData: [],
      tabsData: [],
      benefitsData: undefined,
      goalsData: undefined,
      multiGoalData: [],
      dataLoaded: false,
      dateRange: [{ start: 0, end: 0 }],
      dateValue: dateVal ? dateVal : "today",
      isMobile: props.isMobile,
      isTablet: props.isTablet,
    };
    //this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.loadDashboardData = this.loadDashboardData.bind(this);
    // this.checkIfLoaded = this.checkIfLoaded.bind(this);
  }

  componentDidMount() {
    const { searchParams, setsearchParams } = this.props;
    const now = new Date();
    const start = new Date(now.getTime());
    start.setDate(start.getDate());
    start.setHours(0, 0, 0, 0);
    const end = new Date(start.getTime());
    end.setHours(23, 59, 59, 999);
    let datesParam: "today" | "yesterday" | "week" | "month" | undefined =
      undefined;
    if (searchParams) {
      let dateString = searchParams.get("dates");
      if (
        dateString === "today" ||
        dateString === "yesterday" ||
        dateString === "week" ||
        dateString === "month"
      )
        datesParam = dateString;
    }
    if (datesParam === "yesterday") {
      start.setDate(start.getDate() - 1);
      end.setDate(end.getDate() - 1);
    } else if (datesParam === "week") {
      this.setState({ isWeek: true });
      start.setDate(start.getDate() - 7);
      end.setDate(end.getDate() - 1);
    } else if (datesParam === "month") {
      start.setDate(start.getDate() - 30);
      end.setDate(end.getDate() - 1);
    }
    const startTime = Math.floor(start.getTime() / 1000);
    const endTime = Math.floor(end.getTime() / 1000);
    this.setState({
      dateRange: [{ start: startTime, end: endTime }],
      dateValue: datesParam ? datesParam : "today",
    });

    console.log("DO we onboard?? ", this.props.onboardingRequired);
    // if (!this.props.onboardingRequired) {
    this.loadDashboardData(startTime, endTime);
    // } else {
    //   this.setState({ dataLoaded: true });
    //   this.props.closeLoader();
    // }
  }

  loadDashboardData = (startTime: number, endTime: number) => {
    console.log(
      "Fetching",
      new Date(startTime * 1000),
      new Date(endTime * 1000)
    );
    this.setState({ dataLoaded: false }, () => {
      try {
        fetchDashboardData(startTime, endTime).then((data: any) => {
          this.setState(
            {
              usageData: data.usage,
              informationData: data.information,
              benefitsData: data.benefits,
              goalsData: data.goals,
              multiGoalData: data.multiGoals,
            },
            () => {
              this.setState({ dataLoaded: true });
              this.props.closeLoader();
              this.getInformation();
            }
          );
        });
      } catch {}
    });
  };
  getInformation() {
    const infoData: InformationApiData = this.state.informationData;
    const emailsData: EmailInfoChannel[] = infoData.data.informationChannel
      .email as EmailInfoChannel[];
    // infoData.data.informationChannel.email.forEach((item: any) => {
    //   emailsData.push({
    //     name: item.name,
    //     received: item.value?.received,
    //     opened: item.value?.opened,
    //     sent: item.value?.sent,
    //   });
    // });
    const tabs: TabsInfoChannel[] = [];
    infoData.data.informationChannel.tabs.forEach((item: any) => {
      tabs.push({
        name: item.name,
        opened: item.value.opened,
        closed: item.value.closed,
        total: item.value.total,
        sites: item.value.sites,
      });
    });
    this.setState({ emailsData: emailsData, tabsData: tabs });
  }

  render() {
    const { isMobile, dateValue, isTablet } = this.state;
    return (
      <>
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: isMobile ? "0" : "0 1.8%",
              paddingBottom: "2%",
              height: isMobile ? "90%" : "100%",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                display: "block",
                width: "0.6em",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#E0E4EF",
                outline: 0,
                borderRadius: "40px",
                height: "20%",
              },
            }}
          >
            {this.state.dataLoaded && (
              <Masonry
                breakpointCols={{ default: 2, 1204: 1 }}
                className="masonry-dashboard"
                columnClassName="masonry-columns"
              >
                <Box
                  className="masonry-flexbox"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <TotalTime
                    totaltime={
                      this.state.usageData?.data
                        ? this.state.usageData.data.totalTime
                        : undefined
                    }
                    dateValue={this.state.dateValue}
                  />
                  <AppUsageComponent
                    data={
                      this.state.usageData?.data
                        ? this.state.usageData.data?.usageByApplication.value
                        : undefined
                    }
                    date={{
                      start: this.state.dateRange[0]?.start ?? 0,
                      end: this.state.dateRange[0]?.end ?? 0,
                    }}
                    duration={this.state.usageData?.duration}
                    isTablet={isTablet}
                    isWeek={this.state.isWeek}
                  />
                </Box>
                <Box
                  className="masonry-flexbox"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <FocusTime
                    focusTime={this.state.usageData?.data?.focusTime}
                  />
                  <DeviceUsage
                    usageItems={this.state.usageData?.data?.usageByDevice}
                  />
                  {/* <UsageByCategory
                    categoryData={this.state.usageData?.data?.usageByCategory}
                  /> */}
                  <UsageByCategoryCard
                    categoryData={this.state.usageData?.data?.usageByCategory}
                  />
                </Box>
              </Masonry>
            )}
            {/* {this.state.dataLoaded && (
              <InformationChannels
                emailChannel={this.state.emailsData}
                timeData={this.state.informationData?.data.summary}
                tabData={this.state.tabsData}
              />
            )} */}
            {this.state.dataLoaded && (
              <Goals
                goals={this.state.goalsData}
                multiGoals={this.state.multiGoalData}
              />
            )}
            {/* {this.state.dataLoaded && (
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item md={4} xs={12} height="33vh" minHeight={"300px"}>
                  <WeeklyReport
                    data={
                      this.state.benefitsData?.data !== undefined
                        ? this.state.benefitsData?.data.benefits
                        : undefined
                    }
                    dateValue={this.state.dateValue}
                  />
                </Grid>
                <Grid item md={8} xs={12} height="33vh" minHeight={"300px"}>
                  <Benefits
                    benefits={
                      this.state.benefitsData?.data !== undefined
                        ? this.state.benefitsData?.data.cumulative
                        : undefined
                    }
                  />
                </Grid>
              </Grid>
            )} */}
          </Box>
        </ThemeProvider>
      </>
    );
  }
}

export const DashboardBody = (props: { closeLoader: () => void }) => {
  const [searchParams, setsearchParams] = useSearchParams();
  const location = useLocation();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const onboardingRequiredAll = onboardingRequired();

  return (
    <Box sx={{ height: "90%", width: "100%" }}>
      <DashboardClass
        closeLoader={props.closeLoader}
        key={location.key}
        searchParams={searchParams}
        setsearchParams={setsearchParams}
        isMobile={matches}
        isTablet={isTablet}
        onboardingRequired={onboardingRequiredAll}
      />
    </Box>
  );
};

export const Dashboard = () => {
  const [searchParams, setsearchParams] = useSearchParams();
  const [dates, setdateValue] = useState(searchParams.get("dates"));
  const [toggleValue, settoggleValue] = useState(
    searchParams.get("dates") === null ? "today" : searchParams.get("dates")
  );
  const [dataLoaded, setdataLoaded] = useState(false);
  let matches = useMediaQuery(theme.breakpoints.down("sm"));

  const closeLoader = () => {
    setdataLoaded(true);
  };

  const checkParamValidity = (dateVal: string) => {
    return (
      dateVal === "today" ||
      dateVal === "month" ||
      dateVal === "yesterday" ||
      dateVal === "week"
    );
  };

  useEffect(() => {
    if (dates === null || !checkParamValidity(dates)) {
      setdateValue("today");
      settoggleValue("today");
      gtag.gtag("event", "view_dashboard", {
        dashboard_type: "today",
      });
    } else {
      setsearchParams({ dates });
      settoggleValue(dates);
      gtag.gtag("event", "view_dashboard", {
        dashboard_type: dates,
      });
    }
  }, [dates]);

  const handleDateRangeChange = (
    dateValue: "today" | "yesterday" | "week" | "month" | undefined
  ) => {
    if (dateValue) {
      setdateValue(dateValue);
      settoggleValue(dateValue);
      gtag.gtag("event", "view_dashboard", {
        dashboard_type: dateValue,
      });
      setdataLoaded(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <LogoLoader loading={!dataLoaded}></LogoLoader>
      <Box
        display="block"
        width={matches ? "100%" : "72%"}
        height="100%"
        overflow={"hidden"}
      >
        {matches ? (
          <MobileHeader
            onChange={handleDateRangeChange}
            value={
              toggleValue === null || !checkParamValidity(toggleValue)
                ? "today"
                : toggleValue
            }
          />
        ) : (
          <DataSelector
            toggleValue={
              toggleValue === null || !checkParamValidity(toggleValue)
                ? "today"
                : toggleValue
            }
            onChange={handleDateRangeChange}
          ></DataSelector>
        )}
        <DashboardBody closeLoader={closeLoader} />
      </Box>
    </ThemeProvider>
  );
};

// export default Dashboard;
