import { Typography, Box } from "@mui/material";
import {
  getCurrentBrowser,
  getCurrentOs,
} from "../../../../../components/settings/devicemanagement/device.service";
import { environment } from "../../../../../environment";

const browserName = getCurrentBrowser();
const OSName = getCurrentOs();

export const urls = {
  Windows: environment.nativeAppLinks["windows"],
  macOS: environment.nativeAppLinks["macos"],
  Chrome: environment.browserExtLinks["chrome"],
  Firefox: environment.browserExtLinks["firefox"],
  Andorid: environment.mobileAppLinks["android"],
};

export const agentItems = [
  {
    title: "Computer app",
    icon: "/assets/onboarding/computer_app.svg",
    details: (
      <Typography variant="body1" color="#5D626F">
        Download the app for{" "}
        <a
          href={
            OSName === "Windows"
              ? urls.Windows
              : OSName === "macOS"
              ? urls.macOS
              : ""
          }
          target="_blank"
        >
          {OSName}
        </a>
      </Typography>
    ),
  },
  {
    title: "Browser Extension",
    icon: "/assets/onboarding/extension_icon.svg",
    details: (
      <Typography variant="body1" color="#5D626F">
        Download the extension for{" "}
        <a
          href={
            browserName === "Chrome"
              ? urls.Chrome
              : browserName === "Firefox"
              ? urls.Firefox
              : ""
          }
          target="_blank"
        >
          {browserName}
        </a>
      </Typography>
    ),
  },
  {
    title: "Mobile app",
    icon: "/assets/onboarding/mobile_app.svg",
    details: (
      <Typography variant="body1" color="#5D626F">
        Download the mobile app from your app store
        <br />
        iOS: myTiro
        <br />
        Android: myTiro
        <br />
      </Typography>
    ),
  },
];

export const allAgentItems = [
  {
    title: "Computer app",
    icon: "/assets/onboarding/computer_app.svg",
    details: (
      <Box display="block" width="100%">
        <Typography variant="body1" color="#5D626F">
          Download the app for{" "}
          <a href={urls.Windows} target="_blank">
            Windows
          </a>
        </Typography>
        <Typography variant="body1" color="#5D626F">
          Download the app for{" "}
          <a href={urls.macOS} target="_blank">
            macOS
          </a>
        </Typography>
      </Box>
    ),
  },
  {
    title: "Browser Extension",
    icon: "/assets/onboarding/extension_icon.svg",
    details: (
      <Box display="block" width="100%">
        <Typography variant="body1" color="#5D626F">
          Download the extension for{" "}
          <a href={urls.Chrome} target="_blank">
            Google Chrome
          </a>
        </Typography>
        <Typography variant="body1" color="#5D626F">
          Download the extension for{" "}
          <a href={urls.Firefox} target="_blank">
            Mozilla Firefox
          </a>
        </Typography>
        <Typography variant="body1" color="#5D626F">
          Download the extension for{" "}
          <a href={urls.Chrome} target="_blank">
            Microsoft Edge
          </a>
        </Typography>
      </Box>
    ),
  },
  {
    title: "Mobile app",
    icon: "/assets/onboarding/mobile_app.svg",
    details: (
      <Typography variant="body1" color="#5D626F">
        Download the mobile app for
        <br />
        Android:{" "}
        <a href={urls.Andorid} target="_blank">
          Play Store
        </a>
        <br />
      </Typography>
    ),
  },
];
