import { CognitoUserSession } from "amazon-cognito-identity-js";
import {
  getSession,
  handleSessionNotFound,
} from "../../../accountcontext/account";

export interface Integration {
  id: number;
  accountId: string;
  integrationMethod: string;
  integrationType: "calendar" | "mail";
  integrationProvider: "google_calendar" | "google_gmail";
  providerAccountId: string;
  metadata: {
    emailAddress: string;
  };
  scopes: string[];
  expired: boolean;
}

export interface Mailbox {
  account_id: string;
  created_at: string;
  email_address: string;
  id: number;
  last_updated: number;
  provider: number;
  provider_account_id: string;
  updated_at: string;
}

export interface Calendar {
  account_id: string;
  created_at: string;
  email_address: string;
  id: number;
  last_updated: number;
  provider: number;
  provider_account_id: string;
  provider_specific_id: string;
  updated_at: string;
}

const BASE_URL = "/api/integration";

export const getAllIntegrations = (): Promise<Integration[]> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };

        const url = `${BASE_URL}/account/${username}/integration`;
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

const EMAIL_BASE_URL = "/api/email";

const CALENDAR_BASE_URL = "/api/calendar";

export const getAllMailboxes = (): Promise<Mailbox[]> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };

        const url = `${EMAIL_BASE_URL}/account/${username}/mailbox/`;
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result.mailboxes))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

export const getAllCalendars = (): Promise<Calendar[]> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };

        const url = `${CALENDAR_BASE_URL}/account/${username}/calendar/`;
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result.calendars))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

export const timeFromNow = (d: number): string => {
  if (d) {
    const now = Math.floor(Date.now() / 1000);
    const diff = now - d;
    const years = Math.floor(diff / 31556926);
    const months = Math.floor(diff/3629743);
    const weeks = Math.floor(diff / 604800);
    const days = Math.floor(diff / 86400);
    const h = Math.floor(diff / 3600);
    const m = Math.floor((diff % 3600) / 60);
    const s = Math.floor((diff % 3600) % 60);

    const yearDisplay = years > 0 ? years + (years == 1 ? " year" : ' years') : undefined;
    const monthDisplay = months > 0 ? months + (months == 1 ? " month" : " months") : undefined; 
    const weekDisplay = weeks > 0 ? weeks + (weeks == 1 ? " week" : " weeks") : undefined;
    const dayDisplay = days > 0 ? days + (days == 1 ? " day" : " days" ) : undefined;
    const hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : undefined;
    const mDisplay =
      m > 0 ? m + (m == 1 ? " minute " : " minutes ") : undefined;
    const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : undefined;
    return yearDisplay ?? monthDisplay?? weekDisplay?? dayDisplay?? hDisplay ?? mDisplay ?? sDisplay ?? "0 seconds";
  } else {
    return " - ";
  }
};
