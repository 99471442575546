import { CognitoUserSession } from "amazon-cognito-identity-js";
import { DateTime, FixedOffsetZone } from "luxon";
import {
  getSession,
  handleSessionNotFound,
} from "../../../accountcontext/account";
import {
  AccountData,
  DeviceEmailData,
  EmailData,
  ExtendedAccountData,
  FocusEmailData,
  Users,
} from "./dailyemail.model";

const BASE_URL_USAGE = "/api/usage";

export const generateEmailData = (
  accountData: ExtendedAccountData
): EmailData => {
  let mobileAppInstalled: boolean = false;
  const devices: DeviceEmailData[] = [];
  accountData.devices?.forEach((device) => {
    if (device.type === "Mobile") mobileAppInstalled = true;
    device.agents.forEach((agent) => {
      let lastSyncTime: string = "";
      if (agent.lastSyncTime > accountData.focus.startOfDay) {
        lastSyncTime =
          "syncing normally (last synced on " +
            (DateTime.fromMillis(agent.lastSyncTime * 1000)
              .setZone(
                FixedOffsetZone.instance(
                  parseInt(accountData.profile?.timezone ?? "0")
                )
              )
              .toLocaleString({ dateStyle: "long", timeStyle: "short" }) +
              ")") ?? "NA";
      } else {
        lastSyncTime =
          "not synced since " +
            convertSecondsToHHMM(
              accountData.focus.endOfDay - agent.lastSyncTime
            ) +
            " (last synced on " +
            (DateTime.fromMillis(agent.lastSyncTime * 1000)
              .setZone(
                FixedOffsetZone.instance(
                  parseInt(accountData.profile?.timezone ?? "0")
                )
              )
              .toLocaleString({ dateStyle: "long", timeStyle: "short" }) +
              ")") ?? "NA";
      }

      const deviceData = {
        name:
          agent.type === "mobile" || agent.type === "native"
            ? device.name
            : agent.browser
            ? agent.browser
            : agent.name,
        type: device.type ?? "NA",
        agentType: agent.type,
        lastSyncTime: lastSyncTime,
        deviceId: device.deviceId,
      };
      devices.push(deviceData);
    });
  });

  let totalMobileTime = ".";

  if (mobileAppInstalled) {
    totalMobileTime =
      ", and " +
      convertSecondsToHHMM(accountData.focus?.totalMobileTime) +
      " on your mobile.";
  }

  const focusData: FocusEmailData = {
    timeSpentInFocus: convertSecondsToHHMM(accountData.focus?.timeSpentInFocus),
    changeInFocusTime:
      (accountData.focus?.changeInFocusTime > 0 ? "+" : "") +
        Math.floor(accountData.focus?.changeInFocusTime * 100)?.toString() ??
      "NA",
    numFocusSessions: accountData.focus?.numFocusSessions?.toString() ?? "NA",
    changeInFocusSessions:
      (accountData.focus?.changeInFocusSessions > 0 ? "+" : "") +
        Math.floor(
          accountData.focus?.changeInFocusSessions * 100
        )?.toString() ?? "NA",
    numFocusBreaks: accountData.focus?.numFocusBreaks?.toString() ?? "NA",
    changeInNumOfBreaks:
      (accountData.focus?.changeInNumOfBreaks > 0 ? "+" : "") +
        Math.floor(accountData.focus?.changeInNumOfBreaks * 100)?.toString() ??
      "NA",
    timeSpentInBreaks: convertSecondsToHHMM(
      accountData.focus?.timeSpentInBreaks
    ),
    changeInBreakTime:
      (accountData.focus?.changeInBreakTime > 0 ? "+" : "") +
        Math.floor(accountData.focus?.changeInBreakTime * 100)?.toString() ??
      "NA",
    totalBrowserTime: convertSecondsToHHMM(accountData.focus?.totalBrowserTime),
    changeInBrowserTime:
      (accountData.focus?.changeInBrowserTime > 0 ? "+" : "") +
        Math.floor(accountData.focus?.changeInBrowserTime * 100)?.toString() ??
      "NA",
    totalEmailTime: convertSecondsToHHMM(accountData.focus?.totalEmailTime),
    changeInEmailTime:
      (accountData.focus?.changeInEmailTime > 0 ? "+" : "") +
        Math.floor(accountData.focus?.changeInEmailTime * 100)?.toString() ??
      "NA",
    totalAppTime: convertSecondsToHHMM(accountData.focus?.totalAppTime),
    changeInAppTime:
      (accountData.focus?.changeInAppTime > 0 ? "+" : "") +
        Math.floor(accountData.focus?.changeInAppTime * 100)?.toString() ??
      "NA",
    totalMobileTime: totalMobileTime,
    totalOfficeAppsTime: convertSecondsToHHMM(
      accountData.focus?.totalOfficeAppsTime
    ),
    totalMeetingTime: convertSecondsToHHMM(accountData.focus?.totalMeetingTime),
    numOfSwitches: accountData.focus?.numOfSwitches?.toString() ?? "NA",
    changeInNumOfSwitches:
      (accountData.focus?.changeInNumOfSwitches > 0 ? "+" : "") +
        Math.floor(
          accountData.focus?.changeInNumOfSwitches * 100
        )?.toString() ?? "NA",
    avgTimePerApp: convertSecondsToHHMM(accountData.focus?.avgTimePerApp),
    changeInAvgTimePerApp:
      (accountData.focus?.changeInAvgTimePerApp > 0 ? "+" : "") +
        Math.floor(
          accountData.focus?.changeInAvgTimePerApp * 100
        )?.toString() ?? "NA",
    startOfDay: accountData.focus?.startOfDay?.toString() ?? "NA",
    endOfDay: accountData.focus?.endOfDay?.toString() ?? "NA",
    startOfPrevDay: accountData.focus?.startOfPrevDay?.toString() ?? "NA",
    endOfPrevDay: accountData.focus?.endOfPrevDay?.toString() ?? "NA",
    mobileAppInstalled: mobileAppInstalled,
    onlineTime: {
      totalOnlineTime: convertSecondsToHHMM(
        accountData.focus?.onlineTime.totalOnlineTime
      ),
      changeInOnlineTime:
        (accountData.focus?.onlineTime.changeInOnlineTime > 0 ? "+" : "") +
          Math.floor(
            accountData.focus?.onlineTime.changeInOnlineTime * 100
          )?.toString() ?? "NA",
      firstOnlineTimestamp: DateTime.fromMillis(
        accountData.focus?.onlineTime.firstOnlineTimestamp * 1000
      )
        .setZone(
          FixedOffsetZone.instance(
            parseInt(accountData.profile?.timezone ?? "0")
          )
        )
        .toLocaleString({
          hour: "numeric",
          minute: "2-digit",
          hourCycle: "h12",
        }),
      lastOnlineTimestamp: DateTime.fromMillis(
        accountData.focus?.onlineTime.lastOnlineTimestamp * 1000
      )
        .setZone(
          FixedOffsetZone.instance(
            parseInt(accountData.profile?.timezone ?? "0")
          )
        )
        .toLocaleString({
          hour: "numeric",
          minute: "2-digit",
          hourCycle: "h12",
        }),
    },
    totalOfflineTime: convertSecondsToHHMM(accountData.focus.totalOfflineTime),
    numOffline: accountData.focus.numOffline,
    maxSwitchedApp: accountData.focus.maxSwitchedApp,
    maxSwitchedAppCount: accountData.focus.maxSwitchedAppCount,
    maxSwitchedAppDuration: convertSecondsToHHMM(
      accountData.focus.maxSwitchedAppDuration
    ),
  };

  const lastRunSyncTask = getDateStringFromTimestamp(
    DateTime.fromMillis(accountData.focus.startOfDay * 1000).setZone(
      FixedOffsetZone.instance(parseInt(accountData.profile?.timezone ?? "0"))
    )
  );
  // .toLocaleString({ dateStyle: "long" });
  // + " (% change vs. " + DateTime.fromMillis(accountData.focus.endOfPrevDay * 1000).setZone(FixedOffsetZone.instance(parseInt(accountData.profile?.timezone ?? "0"))).toLocaleString({ month: 'long', day: '2-digit' }) + ")";

  return {
    firstName: accountData.profile?.firstName ?? "NA",
    deviceEmailData: devices,
    focusEmailData: focusData,
    lastRunSyncTask: lastRunSyncTask,
  };
};

export const getUserData = (
  id: string,
  noCache = false
): Promise<AccountData> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        const headers: any = {
          Authorization: `Bearer ${idToken}`,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        };
        if (noCache) {
          headers["Cache-Control"] = "no-cache";
          headers["Pragma"] = "no-cache";
        }
        const requestOptions: RequestInit = {
          method: "GET",
          headers: headers,
        };
        const timestamp = Math.round(new Date().getTime() / 1000);
        const url = `${BASE_URL_USAGE}/admin/userdata?accountId=${id}&timestamp=${timestamp}`;
        fetch(url, requestOptions)
          .then((response) => {
            console.log(response);
            if (response.ok) return response;
            else if (response.status === 404) {
              throw new Error("NOT_FOUND");
            } else {
              throw new Error("Something went wrong");
            }
          })
          .then((response) => response.text())
          .then((response) => JSON.parse(response))
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      })
      .catch((err) => {
        handleSessionNotFound(err);
        reject(err);
      });
  });
};

export const getAccountList = (): Promise<Users> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const headers: any = {
          Authorization: `Bearer ${idToken}`,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        };
        const requestOptions: RequestInit = {
          method: "GET",
          headers: headers,
        };
        const url = `${BASE_URL_USAGE}/admin/allaccounts`;
        fetch(url, requestOptions)
          .then((response) => {
            console.log(response);
            if (response.ok) return response;
            else if (response.status === 404) {
              throw new Error("NOT_FOUND");
            } else {
              throw new Error("Something went wrong");
            }
          })
          .then((response) => response.text())
          .then((response) => JSON.parse(response))
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      })
      .catch((err) => {
        handleSessionNotFound(err);
        reject(err);
      });
  });
};

export const generateEmail = (emailData: EmailData): string => {
  let emailString = `Dear ${emailData.firstName},\n
Thank you for being a part of our beta program.\n`;
  // myTiro is installed on:
  //     Computers:`

  // let deviceId: string = ""
  // if (emailData.deviceEmailData) {
  // 	emailData.deviceEmailData.filter((device) => {
  // 		return (device.type.toLowerCase() == "computer" && (device.agentType === "native"))
  // 	}).forEach(device => {
  // 		deviceId = device.deviceId;
  // 		const deviceStr = `
  //       - ${device.name}, ${device.lastSyncTime}:`
  // 		emailString += deviceStr
  // 		emailData.deviceEmailData.filter((device) => {
  // 			return (device.type.toLowerCase() == "computer" && (device.agentType === "browser") && (device.deviceId === deviceId))
  // 		}).forEach(device => {
  // 			const deviceStr = `
  //           - ${device.name}, ${device.lastSyncTime}`
  // 			emailString += deviceStr
  // 		});
  // 	});

  // 	emailString += `
  //   Mobiles:`

  // 	emailData.deviceEmailData.filter((device) => {
  // 		return device.type.toLowerCase() == "mobile"
  // 	}).forEach(device => {
  // 		const deviceStr = `
  //       - ${device.name}, ${device.lastSyncTime}`
  // 		emailString += deviceStr
  // 	});
  // }
  //- You took ${emailData.focusEmailData.numFocusBreaks} break(s) during these sessions, for a total of ${emailData.focusEmailData.timeSpentInBreaks}.

  const breakString =
    emailData.focusEmailData.numOffline > 0
      ? `You took ${emailData.focusEmailData.numOffline} breaks of at least 10 min for a total of ${emailData.focusEmailData.totalOfflineTime} from ${emailData.focusEmailData.onlineTime.firstOnlineTimestamp} to ${emailData.focusEmailData.onlineTime.lastOnlineTimestamp}.`
      : `You did not take any break of at least 10min from ${emailData.focusEmailData.onlineTime.firstOnlineTimestamp} to ${emailData.focusEmailData.onlineTime.lastOnlineTimestamp}.`;

  const focusStr = `
On ${emailData.lastRunSyncTask}:
    - You used your digital devices for ${emailData.focusEmailData.onlineTime.totalOnlineTime} from ${emailData.focusEmailData.onlineTime.firstOnlineTimestamp} to ${emailData.focusEmailData.onlineTime.lastOnlineTimestamp}.
    - You were focused for ${emailData.focusEmailData.timeSpentInFocus} across ${emailData.focusEmailData.numFocusSessions} sessions.
    - ${breakString}
    - You spent ${emailData.focusEmailData.totalEmailTime} in Email, ${emailData.focusEmailData.totalBrowserTime} in Browsing, ${emailData.focusEmailData.totalAppTime} in Desktop Apps, ${emailData.focusEmailData.totalMeetingTime} in Meetings, ${emailData.focusEmailData.totalOfficeAppsTime} in Office Apps${emailData.focusEmailData.totalMobileTime} 
    - On average, you switch every ${emailData.focusEmailData.avgTimePerApp} for a total of ${emailData.focusEmailData.numOfSwitches} times across applications, devices, or webpages. You switched the most in ${emailData.focusEmailData.maxSwitchedApp} every ${emailData.focusEmailData.maxSwitchedAppDuration} for ${emailData.focusEmailData.maxSwitchedAppCount} times.

Want to know more about your digital behavior(https://beta.mytiro.ai/dashboard?dates=yesterday) yesterday?`;

  emailString += focusStr;

  const deviceStr = `\n \nCheck if all of your devices are syncing(beta.mytiro.ai/settings?option=device)`;

  const endStr = `\n
Please email us at support@mytiro.ai if you do not wish to receive daily emails.

Thank you for continuing to participate in myTiro's early beta!

myTiro Team
`;
  emailString += deviceStr;
  emailString += endStr;
  return emailString;
};

export const convertSecondsToHHMM = (seconds: number | undefined): string => {
  if (seconds === undefined) {
    return "NA";
  }
  let negVal = false;
  if (seconds < 0) {
    seconds = -seconds;
    negVal = true;
  }
  seconds = Math.round(seconds);
  let secs = seconds % 60;
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);
  minutes = minutes % 60;
  let str =
    days === 0
      ? hours === 0 && minutes === 0
        ? `${secs}s`
        : (hours > 0 ? `${hours}h ` : "") + `${minutes}m`
      : `${days} days`;
  return negVal ? `-${str}` : str;
};

export const getEmailSubject = () => {
  const today = DateTime.now();
  const weekDaySubjects = [
    `Your myTiro stats for the day`,
    `myTiro Stats - When did you start using your digital devices?`,
    `myTiro Stats - Which application did you open the most?`,
    `myTiro Stats - When did you stop using your digital device?`,
    `myTiro Stats - How much time did you spend in email?`,
    `myTiro Stats - On average, how often do you switch between applications?`,
    `Your myTiro stats for the day`,
    `Your myTiro stats for the day`,
    `myTiro Stats - How much time did you spend on your devices yesterday?`,
    `myTiro Stats - How much did you focus while on your digital devices?`,
    `myTiro Stats - How many breaks did you take?`,
    `myTiro Stats - Where did you spend your time?`,
    `myTiro Stats - How frequently do you switch between activities?`,
    `Your myTiro stats for the day`,
  ];
  const index = (today.weekNumber % 2) * 7 + today.weekday - 1;
  return weekDaySubjects[index];
};

export const getDateStringFromTimestamp = (dateObj: DateTime): string => {
  const str = `${dateObj.monthLong} ${dateObj.day}, ${dateObj.year}`;
  return str;
};
