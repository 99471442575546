import { createContext, FunctionComponent } from "react";
import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { environment } from "../environment";
import { CognitoUser } from "amazon-cognito-identity-js";
import { appState } from "../services/app.service";

const ADMIN_GROUP = "ADMIN";
interface AccountCtxt {
  authenticate: (Username: string, Password: string) => Promise<any>;
  changePassword: (
    Username: string,
    Password: string,
    newPassword: string
  ) => Promise<any>;
  getSession: () => Promise<any>;
  logout: () => void;
  forgotPassword: (Username: string) => Promise<any>;
  confirmPassword: (
    Username: string,
    verificationCode: string,
    newPassword: string
  ) => Promise<any>;
}

const AccountContext = createContext<AccountCtxt>({} as AccountCtxt);

const handleSessionNotFound = (err: any) => {
  console.error(err.message || JSON.stringify(err));
};

const getSession = async (): Promise<CognitoUserSession> => {
  return await new Promise((resolve, reject) => {
    const Pool = new CognitoUserPool(environment.cognitoUserPoolId);
    const cognitoUser: CognitoUser | null = Pool.getCurrentUser();
    if (cognitoUser === null) {
      reject("User not logged in");
    } else {
      cognitoUser.getSession((err: any, sess: CognitoUserSession | null) => {
        if (sess) {
          if (sess.isValid()) {
            resolve(sess);
          } else {
            reject("Session not valid");
          }
        } else {
          reject(err);
        }
      });
    }
  });
};

const getUserEmail = async (): Promise<string> => {
  const session = await getSession();
  return session.getIdToken().payload.email;
};

const getUsername = async (): Promise<string> => {
  const session = await getSession();
  return session.getIdToken().payload["cognito:username"];
};

const getGroups = async (): Promise<string[]> => {
  const session = await getSession();
  return session.getIdToken().payload["cognito:groups"];
};

const isInAdminGroup = async (): Promise<boolean> => {
  const session = await getSession();
  const groups = session.getIdToken().payload["cognito:groups"];
  return ADMIN_GROUP in groups;
};

const Account: FunctionComponent<any> = (props) => {
  const Pool = new CognitoUserPool(environment.cognitoUserPoolId);

  const authenticate = async (Username: string, Password: string) => {
    return await new Promise((resolve, reject) => {
      let authenticationData = {
        Username,
        Password,
      };
      var authenticationDetails = new AuthenticationDetails(authenticationData);
      const cognitoUser = new CognitoUser({ Username, Pool });
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          console.log("Login successful");
          resolve(result);
        },
        onFailure: function (err) {
          if (err.code !== "PasswordResetRequiredException") {
            console.log(err.message || JSON.stringify(err));
            reject(err);
          } else {
            // TODO: handle password reset
            // cognitoUser.confirmPassword(
            //   "616859", // Put your verification code here
            //   "Pranav1234", // Here is your new password
            //   {
            //     onSuccess: result => {
            //       console.log("Password changed successfully COMMENT THIS CODE OUT")
            //     },
            //     onFailure: err => {
            //       console.log("LMAO")
            //       console.log(err.message);
            //     }
            //   }
            // );
          }
        },
        newPasswordRequired: function (userAttributes, requiredAttributes) {
          console.log("Password change required");
          reject("changePassword");
        },
      });
    });
  };

  const changePassword = async (
    Username: string,
    Password: string,
    newPassword: string
  ) => {
    return await new Promise((resolve, reject) => {
      let authenticationData = {
        Username,
        Password,
      };
      var authenticationDetails = new AuthenticationDetails(authenticationData);
      const cognitoUser = new CognitoUser({ Username, Pool });
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          console.log("Login successful");
          resolve(result);
        },
        onFailure: function (err) {
          console.log(err.message || JSON.stringify(err));
          reject(err);
        },
        newPasswordRequired: function (userAttributes, requiredAttributes) {
          cognitoUser.completeNewPasswordChallenge(
            newPassword,
            {},
            {
              onSuccess: function (result) {
                console.log("Password changed successfully");
                resolve(result);
              },
              onFailure: function (err) {
                reject(err);
              },
            }
          );
        },
      });
    });
  };

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const cognitoUser = Pool.getCurrentUser();
      if (cognitoUser === null) {
        reject("User not logged in");
      } else {
        cognitoUser.getSession((err: any, sess: CognitoUserSession | null) => {
          if (sess) {
            if (sess.isValid()) {
              resolve(cognitoUser);
            } else {
              reject("Session not valid");
            }
          } else {
            reject(err);
          }
        });
      }
    });
  };

  const forgotPassword = async (Username: string) => {
    return await new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({ Username, Pool });
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          console.log("Verification code sent", result);
          resolve(result);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  };

  const confirmPassword = async (
    Username: string,
    verificationCode: string,
    newPassword: string
  ) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({ Username, Pool });
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: function (result) {
          resolve(result);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  };

  const logout = () => {
    const cognitoUser = Pool.getCurrentUser();
    if (cognitoUser) {
      console.log("Log out success");
      cognitoUser.signOut();
    } else {
      console.log("No user to log out");
    }
    localStorage.removeItem("id");
    localStorage.removeItem("email");
    appState.clear();
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        changePassword,
        getSession,
        logout,
        forgotPassword,
        confirmPassword,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export {
  Account,
  AccountContext,
  getSession,
  handleSessionNotFound,
  getUserEmail,
  getUsername,
  isInAdminGroup,
  getGroups,
};
