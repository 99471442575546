import { Interface } from "readline";
import { CreateGoalDto, DimensionType } from "../goals/goals.model";

export interface UsageInsightDto {
  timeSavingInsight: InsightDto;
  focusIncreaseInsight: InsightDto;
}

export interface InsightDto {
  accountId: string;
  weekTimestamp: number;
  startTime: number;
  endTime: number;
  prevWeekTarget: WeekTargetTimeSummary;
  curWeekTarget: WeekTargetTimeSummary;
  goalDtos: CreateGoalDto | undefined;
  insightType: InsightType;
  insight: string;
  referenceData: LongestFragmentedActivityRef;
  goalCreated: boolean;
  insightValid: boolean;
  insightIncomplete: boolean;
  topActivities: TopActivitiesDto[];
}

export interface TopActivitiesDto {
  appName: string;
  totalContribution: number;
}
export interface WeekTargetTimeSummary {
  totalCount: number;
  totalDuration: number;
  challengeVal: number;
  reqAvg: number;
  reqMedian: number;
  reqValues: number[];
  focusDuration: number;
  fragDuration: number;
}

export enum InsightType {
  TIME_SAVING = "timeSaving",
  FOCUS_INC = "focusIncrease",
}

export interface LongestFragmentedActivityRef {
  dimension: DimensionType;
  dimensionValue: string;
  totalSwitches: number;
  fragmentedSwitches: number;
  fragDuration: number;
  totalDuration?: number;
  focusDuration?: number;
}

export interface LongestNearlyFocusedActivityRef {
  dimension: DimensionType;
  dimensionValue: string;
  totalSwitches: number;
  fragmentedSwitches: number;
  fragDuration: number;
  totalDuration: number;
  focusDuration: number;
}
