import {
  getGoalsData,
  getInformationData,
  getUsageData,
} from "../../data/dummy_data.service";
import {
  getSession,
  handleSessionNotFound,
} from "../../accountcontext/account";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { ViewGoalDto } from "../../services/goals/goals.model";
import { DateTime } from "luxon";
import {
  AppContributionScore,
  AppImpactScore,
  AppStats,
  FragmentationData,
} from "./dashboard.models";

const BASE_URL = "/api/user";

const BASE_USAGE_URL = "/api/usage";

const fetchUsageData = (startTime: number, endTime: number): Promise<any> => {
  return new Promise((resolve, reject) => {
    const startTimeStr = startTime.toString();
    const endTimeStr = endTime.toString();
    var params = [
      ["startTime", startTimeStr],
      ["endTime", endTimeStr],
    ];
    getSession()
      .then((session: CognitoUserSession) => {
        const searchParams = new URLSearchParams(params).toString();
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };

        const url = `${BASE_URL}/dashboard/usage/${username}?${searchParams}`;
        console.log("url", url);
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result))
          // .catch((error) => reject(error));
          .catch((error) => {
            reject(error);
          });
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

export const fetchAppFragmentation = async (
  appName: string,
  startTime: number,
  endTime: number
): Promise<AppStats> => {
  const startTimeStr = startTime.toString();
  const endTimeStr = endTime.toString();
  // console.log(startTimeStr);
  var params = [
    ["appName", appName],
    ["startTime", startTimeStr],
    ["endTime", endTimeStr],
  ];
  const searchParams = new URLSearchParams(params).toString();
  const url = `/api/dashboard/fragmentation-data?${searchParams}`;
  try {
    const ret = await callApi(url, {
      method: "GET",
    });
    // console.log(JSON.stringify(ret));
    return ret as AppStats;
  } catch {
    return {};
  }
};

export const fetchGoalImpact = async (
  time: number,
  focus: number,
  activities: number
) => {
  const timeStr = time.toString();
  const focusStr = focus.toString();
  const activitiesStr = activities.toString();

  let params = [
    ["time", timeStr],
    ["focus", focusStr],
    ["activities", activitiesStr],
  ];
  // console.log("params: ", params);
  const searchParams = new URLSearchParams(params).toString();
  const url = `/scoring/impact-score?${searchParams}`;
  try {
    const ret = await callApi(url, {
      method: "GET",
    });
    // console.log(JSON.stringify(ret));
    return ret as AppImpactScore;
  } catch {
    return {};
  }
};

export const fetchAppContribution = async (
  appName: string,
  startTime: number,
  endTime: number
) => {
  const startTimeStr = startTime.toString();
  const endTimeStr = endTime.toString();
  // console.log(startTimeStr);
  var params = [
    ["appName", appName],
    ["startTime", startTimeStr],
    ["endTime", endTimeStr],
  ];
  const searchParams = new URLSearchParams(params).toString();
  const url = `/scoring/app-score?${searchParams}`;
  try {
    const ret = await callApi(url, {
      method: "GET",
    });
    // console.log(JSON.stringify(ret));
    return ret as AppContributionScore;
  } catch {
    return {};
  }
};

const fetchInformationData = (
  startTime: number,
  endTime: number
): Promise<any> => {
  return new Promise((resolve, reject) => {
    resolve({
      duration: {
        start: "2022-09-12T18:30:00.000Z",
        end: "2022-09-13T18:29:59.000Z",
      },
      data: {
        informationChannel: {
          email: [],
          tabs: [],
        },
        summary: {
          received: {
            least: { from: "14:00", to: "15:00" },
            most: { from: "10:00", to: "11:00" },
          },
          responded: {
            least: { from: "11:00", to: "12:00" },
            most: { from: "17:00", to: "18:00" },
          },
        },
      },
    });
    // const startTimeStr = startTime.toString();
    // const endTimeStr = endTime.toString();
    // var params = [
    //   ["startTime", startTimeStr],
    //   ["endTime", endTimeStr],
    // ];
    // getSession()
    //   .then((session: CognitoUserSession) => {
    //     const searchParams = new URLSearchParams(params).toString();
    //     const idToken = session.getIdToken().getJwtToken();
    //     const username = session.getIdToken().payload["cognito:username"];
    //     const requestOptions: RequestInit = {
    //       method: "GET",
    //       headers: {
    //         Authorization: `Bearer ${idToken}`,
    //       },
    //     };

    //     const url = `${BASE_URL}/dashboard/information/${username}?${searchParams}`;

    //     fetch(url, requestOptions)
    //       .then((response) => response.text())
    //       .then((result) => JSON.parse(result))
    //       .then((result) => resolve(result))
    //       // .catch((error) => reject(error));
    //       .catch((error) => {
    //         resolve(getInformationData(startTime, endTime));
    //       });
    //   })
    //   .catch((err) => handleSessionNotFound(err));
  });
};

const fetchGoalsData = (startTime: number, endTime: number): Promise<any> => {
  return new Promise((resolve, reject) => {
    resolve([]);
    // const startTimeStr = startTime.toString();
    // const endTimeStr = endTime.toString();
    // var params = [
    //   ["startTime", startTimeStr],
    //   ["endTime", endTimeStr],
    // ];
    // getSession()
    //   .then((session: CognitoUserSession) => {
    //     const idToken = session.getIdToken().getJwtToken();
    //     const username = session.getIdToken().payload["cognito:username"];
    //     const searchParams = new URLSearchParams(params).toString();

    //     const requestOptions: RequestInit = {
    //       method: "GET",
    //       headers: {
    //         Authorization: `Bearer ${idToken}`,
    //       },
    //     };

    //     const url = `${BASE_URL}/dashboard/goals/${username}?${searchParams}`;

    //     fetch(url, requestOptions)
    //       .then((response) => response.text())
    //       .then((result) => JSON.parse(result))
    //       .then((result) => resolve(result))
    //       // .catch((error) => reject(error));
    //       .catch((error) => {
    //         resolve(getGoalsData(startTime, endTime));
    //       });
    //   })
    //   .catch((err) => handleSessionNotFound(err));
  });
};
const fetchBenifitsData = (
  startTime: number,
  endTime: number
): Promise<any> => {
  return new Promise((resolve, reject) => {
    resolve({
      duration: {
        start: "2022-08-18T18:30:00.000Z",
        end: "2022-08-19T18:29:59.000Z",
      },
      data: {
        benefits: [],
        cumulative: {
          total: 0,
          benefits: [],
          since: 0,
        },
      },
    });
    // const startTimeStr = startTime.toString();
    // const endTimeStr = endTime.toString();
    // var params = [
    //   ["startTime", startTimeStr],
    //   ["endTime", endTimeStr],
    // ];
    // getSession()
    //   .then((session: CognitoUserSession) => {
    //     const idToken = session.getIdToken().getJwtToken();
    //     const username = session.getIdToken().payload["cognito:username"];
    //     const searchParams = new URLSearchParams(params).toString();

    //     const requestOptions: RequestInit = {
    //       method: "GET",
    //       headers: {
    //         Authorization: `Bearer ${idToken}`,
    //       },
    //     };

    //     const url = `${BASE_URL}/dashboard/benefits/${username}?${searchParams}`;

    //     fetch(url, requestOptions)
    //       .then((response) => response.text())
    //       .then((result) => JSON.parse(result))
    //       .then((result) => resolve(result))
    //       // .catch((error) => reject(error));
    //       .catch((error) => {
    //         //resolve(getBenefitsData(startTime, endTime));
    //         reject(error);
    //       });
    //   })
    //   .catch((err) => handleSessionNotFound(err));
  });
};

export const fetchDashboardData = (
  startTime: number,
  endTime: number
): Promise<any> => {
  const fetchList = [];
  fetchList.push(fetchUsageData(startTime, endTime));
  fetchList.push(fetchInformationData(startTime, endTime));
  fetchList.push(fetchGoalsData(startTime, endTime));
  fetchList.push(fetchBenifitsData(startTime, endTime));
  fetchList.push(fetchMultiGoalsData());
  return Promise.all(fetchList)
    .then((values) => {
      return {
        usage: values[0],
        information: values[1],
        goals: values[2],
        benefits: values[3],
        multiGoals: values[4],
      };
    })
    .catch((error) => {
      console.log(error);
      //return getDashboardData(startTime, endTime);
      throw error;
    });
};

export const fetchMultiGoalsData = (): Promise<ViewGoalDto[]> => {
  return new Promise((resolve, reject) => {
    //always fetch today's goals
    const startTime = Math.floor(DateTime.now().startOf("day").toSeconds());
    const endTime = Math.floor(DateTime.now().endOf("day").toSeconds());
    var params = [
      ["startTime", startTime.toString()],
      ["endTime", endTime.toString()],
    ];

    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const searchParams = new URLSearchParams(params).toString();

        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        const url = `${BASE_USAGE_URL}/goals/multigoal?${searchParams}`;

        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result))
          .catch((error) => reject(error));
        // .catch((error) => {
        //   resolve(getGoalsData(startTime, endTime));
        // });
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

export const deleteGoal = (goalId: number): Promise<void> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();

        const requestOptions: RequestInit = {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        const url = `${BASE_USAGE_URL}/goals/multigoal/${goalId}`;

        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => resolve())
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

const session = async (): Promise<{ token: string; username: string }> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        resolve({ token: idToken, username: username });
      })
      .catch((err) => {
        console.log(err);
        handleSessionNotFound(err);
      });
  });
};

const callApi = async (api: string, reqOpts: RequestInit) => {
  const { token, username } = await session();
  reqOpts.headers = { ...reqOpts.headers, Authorization: `Bearer ${token}` };
  return new Promise((resolve, reject) => {
    const url = `${BASE_USAGE_URL}${api}`;
    fetch(url, reqOpts)
      .then((response) => response.text())
      .then((result) => JSON.parse(result))
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};
