import { Box, ThemeProvider, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  getSession,
  getUserEmail,
  getUsername,
} from "../../accountcontext/account";
import theme from "../../theme/theme";

import "./appLoading.styles.css";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Account } from "../../components/profile.service";
import { useNavigate } from "react-router";
import { initializeMyTiro } from "./appLoading.service";
import { appState, previousAppUrl } from "../../services/app.service";

const yellowpattern = "/assets/forms/Sign_in_Sign_up_Pattern_3.svg";
const greenpattern = "/assets/forms/Sign_in_Sign_up_Pattern_2.svg";
const graypattern = "/assets/forms/Sign_in_Sign_up_Pattern_1.svg";

export const AppLoading = () => {
  let navigate = useNavigate();

  useEffect(() => {
    console.log("Initliazing Tiro");
    try {
      getSession()
        .then((session: CognitoUserSession) => {
          try {
            initializeMyTiro(initComplete);
          } catch {
            navigate("/somethingwrong");
          }
        })
        .catch(() => {
          appState.clear();
          console.log("Not Logged in");
          navigate("/login");
        });
    } catch {}
  }, []);

  const initComplete = (
    maintenence: boolean,
    createProfile: boolean,
    account: Account
  ) => {
    if (maintenence) {
      navigate("/maintenance");
    } else if (createProfile) {
      navigate("/createprofile");
    } else {
      const prevUrl = previousAppUrl.get();
      if (prevUrl) {
        previousAppUrl.clear();
        navigate(prevUrl);
      } else {
        navigate("/home");
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100vw"
        height="100vh"
        bgcolor={theme.palette.secondary.main}
        position="relative"
      >
        <Box
          sx={{ position: "absolute", top: "74vh", left: "-200px", zIndex: 0 }}
        >
          <img src={yellowpattern} />
        </Box>
        <Box
          sx={{ position: "absolute", top: "25vh", right: "-200px", zIndex: 0 }}
        >
          <img src={greenpattern} />
        </Box>
        <Box
          sx={{ position: "absolute", top: "20vh", left: "30vw", zIndex: 0 }}
        >
          <img src={graypattern} style={{ zIndex: 0 }} />
        </Box>
        <img
          src="/assets/logo_only.svg"
          className="app-loader-logo"
          height="120px"
        />
        <Typography variant="h4" sx={{ marginTop: "2%", fontWeight: "bold" }}>
          Initializing{" "}
          <span style={{ color: theme.palette.primary.main }}>myTiro</span>
        </Typography>
        {/* <Typography variant="h6" sx={{ marginTop: "2%" }}>
          Crunching Numbers
        </Typography> */}
      </Box>
    </ThemeProvider>
  );
};
