export const validPassword = new RegExp(
  "/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^$*.[]{}()?-\"!@#%&/,><':;|_~`])S{8,99}$/"
);

export const hasLowerCase = (str: string) => {
  return /[a-z]/.test(str);
};

export const hasUpperCase = (str: string) => {
  return /[A-Z]/.test(str);
};

export const hasNumbers = (str: string) => {
  return /[0-9]/.test(str);
};

export const hasSplCharacters = (str: string) => {
  let format =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;
  return format.test(str);
};

export const hasWhiteSpace = (str: string) => {
  return /\s/.test(str);
};

export const meetsCharacterLimit = (str: string) => {
  return /^.{8,99}$/.test(str);
};

export const isValidEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

export const validatePassword = (password: string) => {
  // if (hasWhiteSpace(password)) {
  //   return {
  //     message: "Password cannot contain whitespaces",
  //     showError: true,
  //   };
  // } else
  if (!meetsCharacterLimit(password)) {
    return {
      message: "Password must be atleast 8 characters long",
      showError: true,
    };
  }
  // else if (!hasLowerCase(password)) {
  //   return {
  //     message: "Password must contain at least one lowercase character",
  //     showError: true,
  //   };
  // } else if (!hasUpperCase(password)) {
  //   return {
  //     message: "Password must contain at least one uppercase character",
  //     showError: true,
  //   };
  // } else if (!hasNumbers(password)) {
  //   return {
  //     message: "Password must contain at least one numeric character",
  //     showError: true,
  //   };
  // }
  // else if (!hasSplCharacters(password)) {
  //   return({
  //     message: "Password must contain at least one special character",
  //     showError: true,
  //   });
  // }
  else {
    return null;
  }
};
