import { Box, Link, Stack, Typography } from "@mui/material";
import { map } from "highcharts";
import { MouseEventHandler, MutableRefObject, useRef } from "react";
import { SupportTabContent } from "../../support-tab.component";
import { SupportsPage } from "../../supportpage.component";
import { onboardingContent, OnboardingContent } from "./onboarding-content";

interface OnboardingFAQProps {}

export const OnboardingFAQ = (props: OnboardingFAQProps) => {
  return (
    <>
      <Typography
        variant="h6"
        display="block"
        id={SupportTabContent.onboarding.id}
      ></Typography>
      <SupportsPage
        title={SupportTabContent.onboarding.title}
        subtitle={SupportTabContent.onboarding.description}
        loading={false}
      >
        <Onboarding onboardingList={onboardingContent}></Onboarding>
      </SupportsPage>
    </>
  );
};

interface OnboardingProps {
  onboardingList: OnboardingContent[];
}

const Onboarding = (props: OnboardingProps) => {
  const handleClick = (
    ref: MutableRefObject<any>
  ): MouseEventHandler<HTMLParagraphElement> | undefined => {
    if (ref.current) ref.current.scrollIntoView({ behavior: "smooth" });
    return undefined;
  };
  return (
    <Stack spacing={2}>
      {/* <Typography variant="body1" display="block"></Typography> */}
      <Typography variant="h6" display="block">
        Contents
      </Typography>
      <Typography variant="body1" display="block">
        {props.onboardingList.map((section) => {
          return (
            <ul>
              <li>
                <div>
                  <p style={{ color: "black" }}>
                    <Link href={`#${section.id}`}>{section.section}</Link>
                  </p>
                </div>
                <div>
                  <p style={{ color: "black" }}>
                    {section.subsections?.map((section1) => {
                      return (
                        <ul>
                          <li>
                            <div>
                              <p style={{ color: "black" }}>
                                <Link href={`#${section1.id}`}>
                                  {section1.section}
                                </Link>
                              </p>
                            </div>
                            <div>
                              <p style={{ color: "black" }}>
                                {section1.subsections?.map((section2) => {
                                  return (
                                    <ul>
                                      <li>
                                        <div>
                                          <p style={{ color: "black" }}>
                                            <Link href={`#${section2.id}`}>
                                              {section2.section}
                                            </Link>
                                          </p>
                                        </div>
                                      </li>
                                    </ul>
                                  );
                                })}
                              </p>
                            </div>
                          </li>
                        </ul>
                      );
                    })}
                  </p>
                </div>
              </li>
            </ul>
          );
        })}
      </Typography>
      <Typography variant="h6" display="block" id="1">
        First Login
      </Typography>
      <Typography variant="body1" display="block">
        Use the credentials received in the invite to login to myTiro
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/first-login/first-login-1.png"></img>
      </Typography>
      <Typography variant="body1" display="block">
        <Box sx={{ fontStyle: "italic" }}>
          Note: The credentials expire in 7 days, please contact support in case
          the credentials have expired
        </Box>
      </Typography>
      <Typography variant="body1" display="block">
        You will be asked to set a new password at first login.
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/first-login/new-password.png"></img>
      </Typography>
      <Typography variant="body1" display="block">
        <Box sx={{ fontStyle: "italic" }}>
          Password Requirement: password must be at least 8 characters long, at
          least one uppercase letter and one lowercase letter.
        </Box>
      </Typography>
      <Typography variant="body1" display="block">
        Once logged in you will be asked to enter your name that myTiro will use
        across the platform.
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/first-login/welcome.png"></img>
      </Typography>
      <Typography variant="body1" display="block">
        <Box sx={{ fontStyle: "italic" }}>
          Note: You can change the name from settings at any time
        </Box>
      </Typography>
      <Typography variant="h6" display="block" id="2">
        Installing myTiro native apps
      </Typography>
      <Typography variant="body1" display="block">
        To get started with myTiro, at a minimum you need to install myTiro app
        on your computer. To begin with, you will be presented with download
        options for the computer from which you have signed on. You can add
        multiple computers at a later time from the settings page.
      </Typography>
      <Typography variant="body1" display="block">
        Click on Download App
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/native-app/macos/download-app.png"></img>
      </Typography>
      <Typography variant="body1" display="block">
        In four simple steps you will be up and running with myTiro. You will be
        prompted to download the app for the current operating system.
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/native-app/macos/steps.png"></img>
      </Typography>
      <Typography variant="body1" display="block">
        <Box sx={{ fontStyle: "italic" }}>
          Note: You can change the name from settings at any time.&nbsp;
          <Link href="#21">(Installing myTiro on other devices)</Link>
        </Box>
      </Typography>
      <Typography variant="h6" display="block" id="3">
        <Box sx={{ fontSize: 22 }}>For macOS</Box>
      </Typography>
      <Typography variant="body1" display="block">
        You may be presented with warning messages during the installation
        process, since the app is downloaded outside of the App Store. You can
        ignore the warnings while Apple approves our pending application. If the
        warning does not appear for you, you may skip to the installation steps.
      </Typography>
      <Typography variant="h6" display="block" id="4">
        <Box sx={{ fontSize: 18 }}>Login Flow</Box>
      </Typography>
      <Typography variant="body1" display="block">
        - Once myTiro app opens, click on login
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/native-app/macos/login.png"></img>
      </Typography>
      <Typography variant="body1" display="block">
        - You will be prompted to login in your browser. Use the same
        credentials you used to login to myTiro. (remember to use the new
        password you created when logging in)
      </Typography>
      <Typography variant="body1" display="block">
        - Once logged in you may be presented with a prompt to open myTiro.
        Click on ‘Open myTiro Dev’. You can tick ‘Always Allow’ that will open
        myTiro app automatically after login.
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/native-app/macos/open-app.png"></img>
      </Typography>
      <Typography variant="body1" display="block">
        - If you have selected ‘Always Allow’ in the past, the app will
        automatically open. In case it does not open, you can click on ‘Open
        app’.
      </Typography>
      <Typography variant="body1" display="block">
        - Once logged in, you can close the tab
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/native-app/macos/open-app-cred.png"></img>
      </Typography>
      <Typography variant="body1" display="block">
        - Please be patient, as the first login could take upto 30 seconds to
        complete device setup.
      </Typography>
      <Typography variant="h6" display="block" id="5">
        <Box sx={{ fontSize: 22 }}>For Windows</Box>
      </Typography>
      <Typography variant="body1" display="block">
        You may be presented with Smart Screen warnings from windows during
        installation, while we get our applications certified by Microsoft, you
        can continue using the myTiro in Beta.
      </Typography>
      <Typography variant="h6" display="block" id="6">
        <Box sx={{ fontSize: 18 }}>Smart Screen Warning</Box>
      </Typography>
      <Typography variant="body1" display="block">
        - Open the installer ‘myTiro.msi’, you may be presented with a smart
        screen warning depending on your smart screen protection setup. Click on
        ‘More Info’ to show more options. And then click on ‘Run Anyway’ to
        start the installation
      </Typography>
      <Typography variant="body1" display="block">
        <Stack direction={"row"}>
          <img src="assets/how-to/onboarding/native-app/windows/windows-1.png"></img>
          &nbsp;&nbsp;&nbsp;
          <img src="assets/how-to/onboarding/native-app/windows/windows-2.png"></img>
        </Stack>
      </Typography>
      <Typography variant="body1" display="block">
        - Go through the installation step in the installer. Provide
        administrator access to the installer when prompted
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/native-app/windows/setup.png"></img>
      </Typography>
      <Typography variant="h6" display="block" id="7">
        <Box sx={{ fontSize: 18 }}>Login Flow</Box>
      </Typography>
      <Typography variant="body1" display="block">
        - Once installed open myTiro from the Start Menu
      </Typography>
      <Typography variant="body1" display="block">
        - Use your myTiro credentials to login to the app.
      </Typography>
      <Typography variant="body1" display="block">
        - Please wait as the first time login might take upto 30 seconds to set
        up the device.
      </Typography>
      <Typography variant="body1" display="block">
        myTiro app will auto run on boot.
      </Typography>
      <Typography variant="h6" display="block" id="8">
        <Box sx={{ fontSize: 18 }}>Antivirus Warnings</Box>
      </Typography>
      <Typography variant="body1" display="block">
        Antivirus sometimes flag myTiro as malicious as it is yet to be verified
        by Microsoft. In case your antivirus prompts block messages. Head into
        your antivirus and create an exception for myTiro to run normally.
      </Typography>
      <Typography variant="h6" display="block" id="9">
        Installing myTiro browser extensions
      </Typography>
      <Typography variant="body1" display="block">
        myTiro is currently supported on Chrome, Edge & Firefox. It should work
        on all Chromium based browsers, but it has not been tested on all of
        them.
      </Typography>
      <Typography variant="body1" display="block">
        You will be prompted to download the extension for your current browser.
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/browser-agent/chrome/onboarding.png"></img>
      </Typography>
      <Typography variant="body1" display="block">
        <Box sx={{ fontStyle: "italic" }}>
          Note: You can download extensions for other browsers from
          settings&nbsp;
          <Link href="#21">(Installing myTiro on other devices)</Link>
        </Box>
      </Typography>
      <Typography variant="h6" display="block" id="10">
        <Box sx={{ fontSize: 22 }}>Chrome & Edge</Box>
      </Typography>
      <Typography variant="body1" display="block">
        - You will be directed to the Chrome Web Store to download the chrome
        extension for your browser.
      </Typography>
      <Typography variant="body1" display="block">
        - The chrome extension while in Beta is made available only to users of
        myTiro, hence you will not be able to search for the extension directly
        in the Store or via any search engine.
      </Typography>
      <Typography variant="h6" display="block" id="11">
        <Box sx={{ fontSize: 18 }}>URL not found</Box>
      </Typography>
      <Typography variant="body1" display="block">
        If you have more than one google account logged in the browser, chrome
        store will default to using the first google account to download the
        extension.
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/browser-agent/chrome/url-not-found.png"></img>
      </Typography>
      <Typography variant="body1" display="block">
        If you have a second google account logged into chrome, you can shoot us
        an email at support@mytiro.ai and we will grant access to both accounts
        so that you don’t have any trouble with installation of hte chrome
        extension
      </Typography>
      <Typography variant="body1" display="block">
        Another quick fix for the above error is to append
        ‘&authuser=user@gmail.com’ to the url where user@gmail.com is your
        google account used with myTiro
      </Typography>
      <Typography variant="body1" display="block">
        The URL would look like:
        <Box sx={{ fontStyle: "italic" }}>
          https://chrome.google.com/webstore/detail/mytiro/lhlmgglaaabocaikjlebjdnandcolhde?hl=en-GB&authuser=user@gmail.com
        </Box>
      </Typography>
      <Typography variant="body1" display="block">
        If you would like to install the extension through a different account,
        please contact support@mytiro.ai to get access for other accounts.
      </Typography>
      <Typography variant="h6" display="block" id="12">
        <Box sx={{ fontSize: 18 }}>Installation</Box>
      </Typography>
      <Typography variant="body1" display="block">
        - Click on ‘Add to Chrome’ in the chrome store page to install the
        extension
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/browser-agent/chrome/added-to-chrome.png"></img>
      </Typography>
      <Typography variant="body1" display="block">
        - Once installed you will be presented with a message in the toolbar
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/browser-agent/chrome/pin-extn.png"></img>
      </Typography>
      <Typography variant="body1" display="block">
        <Box sx={{ fontStyle: "italic" }}>
          Note: You can access the extension from the extensions menu, or you
          can pin the extension to the toolbar using the pin icon
        </Box>
      </Typography>
      <Typography variant="h6" display="block" id="13">
        <Box sx={{ fontSize: 18 }}>Login</Box>
      </Typography>
      <Typography variant="body1" display="block">
        - Click on the myTiro icon on the toolbar/ extension menu to open myTiro
        extension
      </Typography>
      <Typography variant="body1" display="block">
        - Login with your myTiro credentials.
      </Typography>
      <Typography variant="body1" display="block">
        - Please be patient as the first login may take upto 30 seconds.
      </Typography>
      <Typography variant="body1" display="block">
        <Stack direction={"row"}>
          <img src="assets/how-to/onboarding/browser-agent/chrome/login.png"></img>
          &nbsp;&nbsp;&nbsp;
          <img src="assets/how-to/onboarding/browser-agent/chrome/after-login.png"></img>
        </Stack>
      </Typography>
      <Typography variant="body1" display="block">
        - Once logged in, myTiro chrome extension will start syncing with the
        server. You can see the last sync time any time by clicking on the
        myTiro icon from the toolbar/ extensions menu.
      </Typography>
      <Typography variant="h6" display="block" id="14">
        <Box sx={{ fontSize: 22 }}>Firefox</Box>
      </Typography>
      <Typography variant="body1" display="block">
        - On click of download, an .xpi file will be downloaded to your computer
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/browser-agent/firefox/firefox.png"></img>
      </Typography>
      <Typography variant="h6" display="block" id="15">
        <Box sx={{ fontSize: 18 }}>Installation</Box>
      </Typography>
      <Typography variant="body1" display="block">
        - Drag the file from the downloads menu or from the folder where you
        downloaded it and drop it in Firefox window to install the extension
      </Typography>
      <Typography variant="body1" display="block">
        - Click on ‘Add’ to add the extension to firefox
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/browser-agent/firefox/add-firefox.png"></img>
      </Typography>
      <Typography variant="body1" display="block">
        - On successful installation, a message will be displayed on the toolbar
      </Typography>
      <Typography variant="body1" display="block">
        - You may tick the checkbox to run myTiro in Private mode (incognito).
        If not ticked, myTiro will not report the websites usage in Incognito
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/browser-agent/firefox/installation.png"></img>
      </Typography>
      <Typography variant="h6" display="block" id="16">
        <Box sx={{ fontSize: 18 }}>Login</Box>
      </Typography>
      <Typography variant="body1" display="block">
        - Click on the myTiro icon on the toolbar to open myTiro extension
      </Typography>
      <Typography variant="body1" display="block">
        - Login with your myTiro credentials.
      </Typography>
      <Typography variant="body1" display="block">
        - Please be patient as the first login may take upto 30 seconds.
      </Typography>
      <Typography variant="body1" display="block">
        <Stack direction={"row"}>
          <img src="assets/how-to/onboarding/browser-agent/firefox/login.png"></img>
          &nbsp;&nbsp;&nbsp;
          <img src="assets/how-to/onboarding/browser-agent/firefox/app.png"></img>
        </Stack>
      </Typography>
      <Typography variant="body1" display="block">
        - Once logged in, myTiro firefox extension will start syncing with the
        server. You can see the last sync time any time by clicking on the
        myTiro icon from the toolbar
      </Typography>
      <Typography variant="h6" display="block" id="17">
        Installing myTiro Mobile App
      </Typography>
      <Typography variant="body1" display="block">
        myTiro currently supports Android phones running Android Q (10+) and
        above.
      </Typography>
      <Typography variant="h6" display="block" id="18">
        <Box sx={{ fontSize: 22 }}>For Android</Box>
      </Typography>
      <Typography variant="body1" display="block">
        Download the android app from Playstore. If you are not able to find the
        app on playstore, contact support@mytiro.ai to get access to the Android
        Application
      </Typography>
      <Typography variant="body1" display="block">
        Once installed open the app
      </Typography>
      <Typography variant="h6" display="block" id="19">
        <Box sx={{ fontSize: 18 }}>Login</Box>
      </Typography>
      <Typography variant="body1" display="block">
        - Click on the myTiro app icon in your app drawer.
      </Typography>
      <Typography variant="body1" display="block">
        - Login with your myTiro credentials.
      </Typography>
      <Typography variant="body1" display="block">
        - Please be patient as the first login may take upto 30 seconds.
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/mobile-app/android/login.png"></img>
      </Typography>
      <Typography variant="h6" display="block" id="20">
        <Box sx={{ fontSize: 18 }}>Set Permissions</Box>
      </Typography>
      <Typography variant="body1" display="block">
        After the first login you will be prompted to set permissions for the
        app
      </Typography>
      <Typography variant="body1" display="block">
        Click on the toggle buttons one by one to grant permissions and follow
        the below steps. Device Usage permission is required for basic
        functioning of myTiro. All other permissions are optional. Permissions
        can be modified later from settings.
      </Typography>
      <Typography variant="body1" display="block">
        1. Click on Track Usage toggle, you will be presented with a screen like
        below. Scroll down till you find myTiro. Click on myTiro and click on
        Permit Usage Access toggle
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/mobile-app/android/permissions.png"></img>
      </Typography>
      <Typography variant="body1" display="block">
        2. Press back twice to go back to myTiro and click on Track
        Notifications toggle. You will be presented with a screen like below.
        Click on myTiro and toggle Allow Notification Access. Click on Allow in
        the dialog box that opens
      </Typography>
      <Typography variant="body1" display="block">
        <Stack direction={"row"}>
          <img src="assets/how-to/onboarding/mobile-app/android/usage-1.png"></img>
          &nbsp;&nbsp;&nbsp;
          <img src="assets/how-to/onboarding/mobile-app/android/usage-1.png"></img>
        </Stack>
      </Typography>
      <Typography variant="body1" display="block">
        3. Press back twice to go back to myTiro and click on the remaining
        three toggles one by one and press allow in the permission dialog box
      </Typography>
      <Typography variant="body1" display="block">
        <Stack direction={"row"}>
          <img src="assets/how-to/onboarding/mobile-app/android/perm-1.png"></img>
          &nbsp;&nbsp;&nbsp;
          <img src="assets/how-to/onboarding/mobile-app/android/perm-2.png"></img>
          &nbsp;&nbsp;&nbsp;
          <img src="assets/how-to/onboarding/mobile-app/android/perm3.png"></img>
        </Stack>
      </Typography>
      <Typography variant="h6" display="block" id="21">
        Installing myTiro on new device
      </Typography>
      <Typography variant="body1" display="block">
        You can download myTiro apps on new devices from the web application.
        Open myTiro and head to Settings, Devices & Browsers. Scroll down to the
        bottom to the list of downloads and download the setup file required on
        the new device.
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/onboarding/new-device/new-device.png"></img>
      </Typography>
    </Stack>
  );
};

//list of contents, currently not in use
{
  /* <Typography variant="body1" display="block">
  {props.onboardingList.map((section) => {
    return (
      <ul>
        <li>
          <div id="1">
            <p style={{ color: "black" }}>{section.section}</p>
          </div>
          <div>
            <p>
              {section.subsections?.map((section1) => {
                return (
                  <ul>
                    <li>
                      <div>
                        <p style={{ color: "black" }}>{section1.section}</p>
                      </div>
                      <div>
                        <p>
                          {section1.subsections?.map((section2) => {
                            return (
                              <ul>
                                <li>
                                  <div>
                                    <p style={{ color: "black" }}>
                                      {section2.section}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            );
                          })}
                        </p>
                      </div>
                    </li>
                  </ul>
                );
              })}
            </p>
          </div>
        </li>
      </ul>
    );
  })}
</Typography>; */
}
