import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { GoalData } from "../../landing.model";
import {
  GoalTargetValue,
  GoalTargetVariant,
  MultiGoalProgress,
} from "../../../dashboard/goals/goalcard/multiprogress/multiprogress.component";
import {
  QuantumType,
  TargetType,
  ViewGoalDto,
} from "../../../../services/goals/goals.model";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import PushPinIcon from "@mui/icons-material/PushPin";

import InfoIcon from "@mui/icons-material/Info";
import { useGoalsApi } from "../../../../services/goals/goals.service";

interface MiniGoalCardProps {
  goal: ViewGoalDto;
  deleteGoal?: (goalId: number) => void;
  pinGoal?: (goalId: number, pinned: boolean) => void;
  currentDay: boolean;
}

export const MiniGoalCard = (props: MiniGoalCardProps) => {
  const [targets, setTargets] = useState<{ [target: string]: GoalTargetValue }>(
    {}
  );

  useEffect(() => {
    const target: { [target: string]: GoalTargetValue } = {};
    props.goal.targets.forEach((tg) => {
      target[tg.target] = {
        total: tg.targetQuantum,
        value: tg.actualQuantum,
        unit: tg.quantumType,
        // variant: GoalTargetVariant.MIN,
        targetFunction: tg.targetFunction,
        targetMet: tg.targetMet,
      };
    });
    setTargets(target);
  }, [props.goal]);

  const parseTimeToDisplay = (
    target: GoalTargetValue,
    targetType: TargetType
  ) => {
    if (!target) {
      return "-/-";
    }
    if (targetType === TargetType.TIME || targetType === TargetType.FOCUS) {
      return secondsToMMHH(target?.value) + "/" + secondsToMMHH(target?.total);
    } else {
      return target.value + "/" + target.total;
    }
  };

  const secondsToMMHH = (value: number | undefined): string => {
    if (!value) return "0s";
    let retStr = "";
    let sec = value % 60;
    let mins = Math.floor(value / 60);
    let hrs = Math.floor(mins / 60);
    // mins = mins % 60;
    retStr = `${mins}m`;
    return retStr;
  };

  return (
    <Box sx={{ justifyContent: "center" }}>
      <Box
        sx={{
          borderRadius: "16px",
          backgroundColor: "white",
          display: "block",
          padding: "2%",
          width: "20vw",
          minWidth: "300px",
          height: "100%",

          //   width: "wrap-content",
        }}
      >
        <Stack direction={"row"} justifyContent="space-between">
          <Typography
            variant="body1Bold"
            className="one-line-only"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              wordWrap: "break-word",
              // cursor: "pointer  ",
              height: "3vh",
            }}
          >
            {props.goal.name}
          </Typography>
          <Stack direction={"row"}>
            <Box>
              {props.pinGoal ? (
                <Tooltip title={"Pin Goal"}>
                  {props.goal.pinned ? (
                    <PushPinIcon
                      sx={{
                        color: "#7E4BB7",
                        fontSize: "20px",
                        ":hover": {
                          transform: "scale(1.1)",
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        if (props.pinGoal) props.pinGoal(props.goal.id, false);
                      }}
                    />
                  ) : (
                    <PushPinOutlinedIcon
                      sx={{
                        color: "#7E4BB7",
                        fontSize: "20px",
                        ":hover": {
                          transform: "scale(1.1)",
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        if (props.pinGoal) props.pinGoal(props.goal.id, true);
                      }}
                    />
                  )}
                </Tooltip>
              ) : (
                ""
              )}
            </Box>
            <Box>
              {props.deleteGoal ? (
                <Tooltip title={"Delete Goal"}>
                  <DeleteOutlineIcon
                    sx={{
                      fontSize: "20px",
                      ":hover": { transform: "scale(1.1)", cursor: "pointer" },
                    }}
                    onClick={() => {
                      if (props.deleteGoal) props.deleteGoal(props.goal.id);
                    }}
                  />
                </Tooltip>
              ) : (
                ""
              )}
            </Box>
          </Stack>
        </Stack>
        {/* <Tooltip title={props.goal.description}> */}

        {/* </Tooltip> */}
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ width: "100%", height: "100%" }}>
              <MultiGoalProgress
                totalTime={targets["time"]}
                focusTime={targets["focus"]}
                activities={targets["activities"]}
                mini={true}
                currentDay={props.currentDay}
              />
            </Box>
          </Grid>
          {/* <Grid item xs={5}>
            <Box sx={{ verticalAlign: "middle", display: "flex" }}>
              <Stack
                direction={"column"}
                sx={{
                  alignContent: "end",
                  marginLeft: "auto",
                }}
              >
                <Typography
                  variant={"h6"}
                  sx={{
                    marginLeft: "auto",
                    alignSelf: "end",
                  }}
                >
                  {parseTimeToDisplay(
                    targets[TargetType.TIME],
                    TargetType.TIME
                  )}
                </Typography>
                <Typography
                  variant={"h6"}
                  sx={{ marginLeft: "auto", alignSelf: "end" }}
                >
                  {parseTimeToDisplay(
                    targets[TargetType.FOCUS],
                    TargetType.FOCUS
                  )}
                </Typography>
                <Typography
                  variant={"h6"}
                  sx={{ marginLeft: "auto", alignSelf: "end" }}
                >
                  {parseTimeToDisplay(
                    targets[TargetType.ACTIVITIES],
                    TargetType.ACTIVITIES
                  )}
                </Typography>
              </Stack>
            </Box>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
};
