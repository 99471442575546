import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import * as React from "react";
import { getColor } from "../../../../utils/dashboard.utils";
import { RawData } from "../../../model/goals.model";
const icon = require("./assets/Raw_data.png");
interface RawDataCardProps {
  rawdata: RawData;
  isExpandable: Boolean;
  isOpen?: boolean;
}

interface RawDataCardState {
  anchorEl: null | HTMLElement;
  rawdata: RawData;
  isExpandable: Boolean;
  isOpen: boolean;
}

class RawDataCard extends React.Component<RawDataCardProps, RawDataCardState> {
  constructor(props: RawDataCardProps) {
    super(props);
    let isOpen: boolean = false;
    if (props.isOpen !== undefined) {
      isOpen = props.isOpen;
    }
    this.state = {
      anchorEl: null,
      rawdata: props.rawdata,
      isExpandable: props.isExpandable,
      isOpen: isOpen,
    };
    this.handleExpand = this.handleExpand.bind(this);
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleExpand() {
    let isOpen = !this.state.isOpen;
    this.setState({
      isOpen: isOpen,
    });
    console.log("open" + this.state.isOpen);
  }

  getAccordionIcon() {
    if (this.state.isExpandable) {
      return (
        <IconButton onClick={this.handleExpand}>
          {this.state.isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      );
    } else {
      return null;
    }
  }

  getTimeString(totalSeconds: number): string {
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    let seconds = totalSeconds - hours * 3600 - minutes * 60;
    let timeString = "";
    if (hours > 0) {
      timeString += hours + "h ";
    }
    if (minutes > 0) {
      timeString += minutes + "m ";
    }
    if (seconds > 0) {
      timeString += seconds + "s";
    }
    return timeString;
  }

  getDescription() {
    const { rawdata } = this.state;
    const startdate = new Date(rawdata.start * 1000);
    const enddate = new Date(rawdata.end * 1000);
    return (
      this.getTimeString(rawdata.duration) +
      " session in " +
      (rawdata.subapplication ? rawdata.subapplication : rawdata.application) +
      " on " +
      startdate.toLocaleString("en-us", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }) +
      " from " +
      startdate.toLocaleString("en-us", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      }) +
      " to " +
      enddate.toLocaleString("en-us", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })
    );
  }

  displayContent() {
    const { rawdata } = this.state;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            marginTop: "4%",
            marginLeft: "4%",
            justifyContent: "flex-start",
          }}
        >
          Category :{"  "}
          <Box
            sx={{
              height: "0.65vw",
              width: "0.65vw",
              minHeight: "10px",
              minWidth: "10px",
              backgroundColor: getColor(rawdata.category),
              marginRight: "10px",
              borderRadius: "2px",
            }}
          />
          <Typography variant="body2">
            {/* {rawdata.subapplication === null */}
            {rawdata.category}
            {/* : rawdata.subapplication} */}
          </Typography>
          <KeyboardArrowDown />
        </Box>
        <div
          style={{
            height: "1px",
            width: "106%",
            marginLeft: "-3%",
            backgroundColor: "#DEE2E4",
            marginTop: "4%",
          }}
        />
        {/* <Button
          variant="outlined"
          sx={{
            width: "100%",
            marginTop: "4%",
          }}
        >
          Change action to participant
        </Button>
        <Button
          variant="whiteButton"
          sx={{
            width: "100%",
            marginTop: "4%",
          }}
        >
          Add to Goal
        </Button> */}
      </Box>
    );
  }

  render() {
    const { rawdata, isExpandable, isOpen } = this.state;
    return (
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          borderRadius: "8px",
          backgroundColor: "#F3F5F6",
          padding: "3% 3%",
          marginTop: "2%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={icon}
            height="1.6vw"
            style={{
              minHeight: "24px",
              alignSelf: "baseline",
              marginTop: "1%",
            }}
          />
          <Box
            sx={{ textAlign: "left", display: "flex", flexDirection: "column" }}
          >
            <Typography
              variant="body1"
              sx={{
                textAlign: "left",
                marginLeft: "10px",
                color: "#5D626F",
              }}
            >
              {this.getDescription()}
            </Typography>
          </Box>
          {this.getAccordionIcon()}
        </Box>
        <Collapse in={isExpandable ? isOpen : true}>
          {this.displayContent()}
        </Collapse>
      </Card>
    );
  }
}

export default RawDataCard;
