import { DesktopWindows } from "@mui/icons-material";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import {
  getColor,
  getNewCategoryColor,
} from "../../../components/dashboard/utils/dashboard.utils";
import { Activity } from "../models/calendar_main.model";
import * as utils from "../utils/calendar.utils";
import { calendarStyles } from "../styles/calendar.styles";
import { withStyles, WithStyles } from "@mui/styles";

interface ActivityItemProps extends WithStyles<typeof calendarStyles> {
  startTime: Date;
  activity: Activity;
  minutes: number;
  isOverlap: Boolean;
  showText: Boolean;
  showIcon: Boolean;
  isToday: Boolean;
  oneMinute: number;
  showDevice: boolean;
}

interface ActivityItemState {
  oneMinute: number;
}

class ActivityItem extends React.Component<
  ActivityItemProps,
  ActivityItemState
> {
  constructor(props: ActivityItemProps) {
    super(props);
    this.state = {
      oneMinute: props.oneMinute,
    };
  }

  static getDerivedStateFromProps(
    nextProps: ActivityItemProps,
    prevState: ActivityItemState
  ) {
    if (nextProps.oneMinute !== prevState.oneMinute) {
      return {
        oneMinute: nextProps.oneMinute,
      };
    }
    return null;
  }

  render() {
    const {
      startTime,
      activity,
      minutes,
      isOverlap,
      showText,
      showIcon,
      classes,
      isToday,
      showDevice,
    } = this.props;
    const { oneMinute } = this.state;
    // if(activity.name === "Terminal")
    //   console.log(this.props);
    return (
      <Tooltip
        key={startTime.getTime()}
        placement="right"
        title={
          <div style={{ whiteSpace: "pre-line" }}>
            {utils.getCellText(activity)}
          </div>
        }
        arrow
        followCursor
      >
        <Box
          className={classes.activityItem}
          sx={{
            backgroundColor:
              getNewCategoryColor(
                showDevice
                  ? activity.device.type
                    ? activity.device.type
                    : JSON.stringify(activity.device)
                  : activity.category
              ) + (isToday ? "" : "33"),
            height: Math.round(minutes) + "px",
            width: isOverlap ? "80%" : "100%",
            position: "absolute",
            top: startTime.getMinutes() * oneMinute + "px",
            textAlign: "left",
            boxSizing: "border-box",
            border: "1px solid white",
            zIndex: 2,
          }}
        >
          <Box
            className={classes.activityDescription}
            sx={{ maxHeight: minutes + "px" }}
          >
            {/* {showIcon ? (
              <DesktopWindows
                sx={{
                  height: "15px",
                  width: "15px",
                  alignSelf: "baseline",
                  marginTop: "2px",
                  marginRight: "4%",
                  color: isToday ? "white" : "darkgray",
                }}
              />
            ) : (
              ""
            )} */}
            {/* <Typography
              variant="body1"
              className={classes.text}
              sx={
                isToday
                  ? {
                      lineHeight: "1.2em",
                      color: "white !important",
                      fontWeight: "600 !important",
                      textShadow: "1px 1px #00000040 !important",
                      wordWrap: "break-word",
                      width: "100%",
                      "&::before": {
                        content:
                          "url('/assets/calendar/Device_toggle_selected.svg')",
                        display: showIcon ? "block" : "none",
                        height: showIcon ? minutes : 0,
                        float: "left",
                        marginRight: "1%",
                      },
                    }
                  : {
                      lineHeight: "1.2em",
                      wordWrap: "break-word",
                      width: "100%",
                      "&::before": {
                        content: "url('/assets/calendar/Device_toggle.svg')",
                        display: showIcon ? "block" : "none",
                        height: showIcon ? minutes : 0,
                        float: "left",
                        marginRight: "1%",
                      },
                    }
              }
            >
              {(minutes/18)>=1 ? activity.name : ""}
            </Typography> */}
            {minutes / 18 >= 1 ? (
              <Grid container rowGap={2}>
                <Grid item xs={3}>
                  <img
                    height="18px"
                    src={
                      isToday
                        ? "/assets/calendar/Device_toggle_selected.svg"
                        : "/assets/calendar/Device_toggle.svg"
                    }
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="body1"
                    className={classes.text}
                    sx={
                      isToday
                        ? {
                            maxWidth: "100%",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: Math.floor(minutes / 18),
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            color: "white !important",
                            fontWeight: "600 !important",
                            textShadow: "1px 1px #00000040 !important",
                            width: "100%",
                          }
                        : {
                            maxWidth: "100%",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: Math.floor(minutes / 18),
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                          }
                    }
                  >
                    {minutes / 18 >= 1 ? activity.name : ""}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Tooltip>
    );
  }
}

export default withStyles(calendarStyles)(ActivityItem);
