import { CognitoUserSession } from "amazon-cognito-identity-js";
import { DateTime } from "luxon";
import {
  getSession,
  handleSessionNotFound,
} from "../../../../accountcontext/account";
import { App } from "../../../../App";
import {
  SaveUpdateCategoryDto,
  UserItemDto,
} from "../models/custom-category.model";

export {};

const BASE_USAGE_URL = "/api/usage";

export const fetchAppDomainList = async (): Promise<UserItemDto> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getAccessToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        const url = `${BASE_USAGE_URL}/categorizer/${username}/user-items`;
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      })
      .catch(handleSessionNotFound);
  });
};

export const getUserItems = async (): Promise<UserItemDto[]> => {
  const url = `/categorizer/user-items`;
  try {
    const res = (await callApi(url, {
      method: "GET",
    })) as UserItemDto[];
    return res;
  } catch {
    return [];
  }
};

export const deleteCustomCategory = async (
  categoryId: number,
  today?: number
): Promise<void> => {
  if (!today) {
    today = Math.floor(DateTime.now().startOf("day").toSeconds());
  }
  var params = [["day", today.toString()]];
  const searchParams = new URLSearchParams(params).toString();
  const url = `/categorizer/custom-category/${categoryId}?${searchParams}`;
  try {
    await callApi(url, {
      method: "DELETE",
    });
  } catch (err) {
    console.log(err);
    return;
  }
};

export const getCustomCategories = async (): Promise<
  SaveUpdateCategoryDto[]
> => {
  const url = `/categorizer/custom-category`;
  try {
    const res = (await callApi(url, {
      method: "GET",
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
    })) as SaveUpdateCategoryDto[];
    return res;
  } catch {
    return [];
  }
};

export const saveUpdateUserCategory = async (
  saveUpdateCategoryDto: SaveUpdateCategoryDto,
  today?: number
) => {
  if (!today) {
    today = Math.floor(DateTime.now().startOf("day").toSeconds());
  }
  var params = [["day", today.toString()]];
  const searchParams = new URLSearchParams(params).toString();
  const url = `/categorizer/custom-category?${searchParams}`;
  console.log("Save update USER CATEGORY: ", saveUpdateCategoryDto);
  return await callApi(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(saveUpdateCategoryDto),
  });
};

const session = async (): Promise<{ token: string; username: string }> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        resolve({ token: idToken, username: username });
      })
      .catch((err) => {
        console.log(err);
        handleSessionNotFound(err);
      });
  });
};

const callApi = async (api: string, reqOpts: RequestInit) => {
  const { token, username } = await session();
  reqOpts.headers = { ...reqOpts.headers, Authorization: `Bearer ${token}` };
  return new Promise((resolve, reject) => {
    const url = `${BASE_USAGE_URL}${api}`;
    fetch(url, reqOpts)
      .then((response) => response.text())
      .then((result) => JSON.parse(result))
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};
