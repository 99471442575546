import { Box, Button, Typography } from "@mui/material";
import { CreateGoalDto } from "../../../services/goals/goals.model";
import { InsightDto } from "../../../services/insight-v2/insights-v2.model";
import { convertSecondsToHHMM } from "../../admin/dailyemail/dailyemail.service";
import { InsightProgressCard } from "./insight-v2-progress-new.component";
import { InsightProgress } from "./insight-v2-progress.component";

interface InsightsV2CardProps {
  // text: string;
  timeSavingInsight: InsightDto | undefined;
  focusIncreaseInsight: InsightDto | undefined;
  //   promptData: UsagePromptsDto | undefined;
  handleClick: (insight: InsightDto | undefined) => void;
}

export const InsightsV2Card = (props: InsightsV2CardProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      // className="homepage-card"
      borderRadius={3}
      bgcolor={"#ffcdd266"}
      width="100%"
      padding={"0.5%"}
      // paddingTop={"0.3%"}
      height={"100%"}
      // sx={{ padding: 1 }}
    >
      {/* <Box
        sx={{
          display: "flex",
          direction: "row",
          alignItems: "center",
          // justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Weekly Challenge:&nbsp;
        </Typography>
        {(props.timeSavingInsight?.prevWeekTarget?.totalDuration ?? 0) > 0 ? (
          <Typography variant="body1Bold" sx={{ fontWeight: "bold" }}>
            Spend less than{" "}
            {`${convertSecondsToHHMM(
              (props.timeSavingInsight?.prevWeekTarget?.avg ?? 0) * 0.9
            )}`}
            {" in <1m sessions and increase focus to "}
            {`${convertSecondsToHHMM(
              (props.focusIncreaseInsight?.prevWeekTarget?.avg ?? 0) * 1.1
            )}`}
          </Typography>
        ) : (
          <Typography variant="body1Bold" sx={{ fontWeight: "bold" }}>
            No challenge this week
          </Typography>
        )}
      </Box> */}
      {(props.timeSavingInsight?.prevWeekTarget?.totalDuration ?? 0) > 0 ? (
        <InsightProgressCard
          timeSavingInsight={props.timeSavingInsight}
          handleClick={props.handleClick}
          focusIncreaseInsight={props.focusIncreaseInsight}
        ></InsightProgressCard>
      ) : (
        ""
      )}
    </Box>
  );
};
