import { useEffect } from "react";
import { useNavigate } from "react-router";

export const ClearGoals = (props: any) => {
  localStorage.removeItem("TEMP_CACHED_GOALS");
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/home");
  });

  return <>"clearing goals"</>;
};
