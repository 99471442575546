import { Box, Divider, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ErrorBoundary from "../../../../errorboundary.component";
import { displayOverlay } from "../../../../overlay.service";
import { NoDataOverlay } from "../../nodataoverlay/nodataoverlay.component";
import { getColor, getNewCategoryColor } from "../../utils/dashboard.utils";
import { UsageByCategoryProps } from "./models/usage_category_props.model";
import { UsageCategoryChart } from "./usage_category_chart.component";
import InfoIcon from "@mui/icons-material/Info";

const tooltipText = "Running total of time you spent across categories";

type LegendItem = { name: string; color: string; index: number };

export const UsageByCategoryCard = (
  props: UsageByCategoryProps
): JSX.Element => {
  const [categoryData, setCategoryData] = useState<any>([]);

  const [showOverlay, setShowOverlay] = useState<string | null>(null);

  const [legendItems, setLegendItems] = useState([
    { name: "Email", color: "#E8BE4F", index: 0 },
    { name: "Browsing", color: "#8C95EB", index: 1 },
    { name: "Desktop Apps", color: "#F39A8C", index: 2 },
    { name: "Meetings", color: "#7ACCAA", index: 3 },
    { name: "Office Apps", color: "#75B5D9", index: 4 },
    { name: "Mobile Apps", color: "#B68CEB", index: 5 },
  ]);

  useEffect(() => {
    if (props.categoryData?.value) {
      const indexMap = legendItems.reduce(
        (acc: { [name: string]: number }, cur) => {
          acc[cur.name] = cur.index;
          return acc;
        },
        {}
      );
      const data = props.categoryData.value.map((entry) => {
        return {
          value: entry.time.hours * 60 + entry.time.minutes,
          name: entry.name,
          color: getNewCategoryColor(entry.name),
        };
      });
      data.sort((a, b) => {
        return indexMap[b.name] - indexMap[a.name];
      });
      setCategoryData(data);
    }
  }, [props.categoryData]);

  useEffect(() => {
    legendItems.forEach((lengend) => {
      lengend.color = getNewCategoryColor(lengend.name);
    });
    setLegendItems([...legendItems]);
    if (showOverlay === undefined) {
      displayOverlay("COMPONENT_DASHBOARD_USAGE_BY_CATEGORY").then(
        (str: string | null) => setShowOverlay(str)
      );
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        width: "100%",
        padding: "2% 5%",
        borderRadius: "12px",
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            width: "100%",
            marginRight: "-24px",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Usage by Category
          </Typography>
        </Box>
        <Box sx={{ marginRight: "-19px" }}>
          <Tooltip title={tooltipText}>
            <InfoIcon
              sx={{
                fontSize: "20px",
                ":hover": { transform: "scale(1.1)" },
              }}
            />
          </Tooltip>
        </Box>
      </Stack>
      <ErrorBoundary>
        <Stack
          direction={"column"}
          divider={<Divider sx={{ width: "100%" }} />}
          spacing={2}
        >
          {showOverlay ? (
            <Box
              sx={{
                position: "relative",
                width: "100%",
                padding: "0px",
                height: "auto",
              }}
            >
              <img
                src="/assets/dashboardcards/dummy/categoryusage.svg"
                width="100%"
                style={{ marginBottom: "10%" }}
              />
              <NoDataOverlay text={showOverlay ? showOverlay : undefined} />
            </Box>
          ) : (
            <UsageCategoryChart chartData={categoryData} />
          )}

          <UsageCategoryCardLegend legendItems={legendItems} />
        </Stack>
      </ErrorBoundary>
    </Box>
  );
};

const UsageCategoryCardLegend = (props: {
  legendItems: LegendItem[];
}): JSX.Element => {
  return (
    <>
      <Box>
        <Grid container rowSpacing={1}>
          {props.legendItems.map((item) => (
            <Grid item sm={6}>
              <LegendItem
                name={item.name}
                color={item.color}
                index={item.index}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

const LegendItem = (props: LegendItem): JSX.Element => {
  return (
    <Stack direction={"row"} spacing={1}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Box
          sx={{
            height: "12px",
            width: "12px",
            borderRadius: "2px",
            backgroundColor: props.color,
          }}
        ></Box>
      </Box>
      <Typography variant="body1" sx={{ textOverflow: "ellipsis" }}>
        {props.name}
      </Typography>
    </Stack>
  );
};
