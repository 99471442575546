import * as React from "react";
import { useState, useEffect } from "react";
import { Box, Theme, Typography } from "@mui/material";
import { createStyles, withStyles, WithStyles } from "@mui/styles";
import ErrorBoundary from "../../../errorboundary.component";
import CurrentTimeIndicator from "../currenttimeindicator.component";
import LegendBox from "../legendbox/legendbox.component";
import { Activity, DayActivities } from "../models/calendar_main.model";
import CalendarDay from "../calendarday.component";
import dummyActivities from './dummyActivities.json';
const styles = {
  container: {
    borderRadius: "12px",
    border: "1px solid white",
    backgroundColor: "white",
    padding: 0,
    height: "auto",
    width: "100%",
  },

  toptext: {
    width: "15%",
    minWidth: "121px",
    textAlign: "center",
  },
  flexbox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "80%",
    position: "relative",
    left: "-4%",
  },
  datebox: {
    height: "7vh",
    margin: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    backgroundColor: "#F7F4FB",
    width: "100%",
    alignItems: "center",
    border: "1px solid white",
    borderLeft: 0,
    paddingRight: "50px",
  },
};

interface DemoCalendarProps {}

export const DemoCalendar = (props: DemoCalendarProps) => {
  let date = new Date();
  let dummyDate: DayActivities = {
    date: date.toDateString(),
    accuracy: "0%",
    activities: [],
  };
  let dummyDate2: DayActivities = {
    date: date.toDateString(),
    accuracy: '0%',
    activities: dummyActivities,
  }
  const heights = { cell: 60, accuracyBox: 45 };
  const timelineDiv = React.createRef();
  const displayDates = () => {
    let week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    
    let result = week.map((day, index) => 
        <Typography
          variant="calendarHeading"
          key={date.getTime()}
          sx={styles.toptext}
        >
          {day}{" "}{index}
        </Typography>)
    return result;
  };

  const getTimeZoneOffset = () => {
    const x = new Date();
    const tzOff = x.getTimezoneOffset();
    let timezoneOffsetString = "UTC";
    if (tzOff <= 0) timezoneOffsetString += "+";
    else timezoneOffsetString += "-";
    const hours = Math.floor(Math.abs(tzOff) / 60)
      .toString()
      .padStart(2, "0");
    const minutes = (Math.abs(tzOff) % 60).toString().padStart(2, "0");
    timezoneOffsetString += hours + ":" + minutes;
    return timezoneOffsetString;
  };

  const displayTimeFrames = () => {
    let result = [];
    let curr: number = 12;
    let ampm: string = "AM";
    let arr = Array(24).keys();
    let initPos =
      heights.accuracyBox - ((8.5 / 12) * (0.83 * window.innerWidth)) / 100; // Subtract to align to grid lines
    for (const i of arr) {
      result.push(
        <Box
          key={i}
          sx={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: initPos + "px",
            right: "10px",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Typography
            variant="body2"
            sx={{ marginRight: "25%", whiteSpace: "nowrap" }}
          >
            {curr} {ampm}
          </Typography>
          {/* <div
                style={{
                  height: "2px",
                  width: "14px",
                  borderTop: "2px solid #E0E4EF",
                  alignSelf: "center",
                }}
              ></div> */}
        </Box>
      );
      initPos += heights.cell;
      if (curr === 12) {
        curr = 1;
      } else {
        if (curr === 11) ampm = ampm === "AM" ? "PM" : "AM";
        curr += 1;
      }
    }
    return result;
  };

  return (
    <Box
      sx={{
        ...styles.container,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        borderRadius: "12px",
        overflow: "hidden",
        margin: "0 auto",
        height: "auto",
        overflowX: "scroll",
      }}
    >
      <ErrorBoundary>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#F7F4FB",
            border: "1px white",
            width: "100%",
            position: "relative",
          }}
        >
          {
            <Box
              sx={{
                height: "7vh",
                width: "10%",
                minWidth: "100px",
                borderTop: "1px solid white",
                borderLeft: "1px solid white",
                boxSizing: "border-box",
                overflow: "visible",
                whiteSpace: "nowrap",
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "#7D708E",
                  width: "100%",
                }}
              >
                {getTimeZoneOffset()}
              </Typography>
            </Box>
          }
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "90%",
              minWidth: "850px",
            }}
          >
            <Box sx={styles.datebox}>{displayDates()}</Box>
            <LegendBox showDevices />
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "white",
            height: "75vh",
            width: "100%",
            minWidth: "951px",
            overflowY: "auto",
            overflowX: "hidden",
            position: "relative",
            paddingRight: "50px",
          }}
          ref={timelineDiv}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <CurrentTimeIndicator heights={heights} />
            <Box
              sx={{
                position: "absolute",
                width: "10%",
                minWidth: "100px",
                backgroundColor: "white",
              }}
            >
              <Box sx={{ position: "relative", width: "100%" }}>
                {displayTimeFrames()}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "90%",
                minWidth: "850px",
                marginLeft: "max(10%, 100px)",
                flex: "0 0 90%",
              }}
            >
              <CalendarDay
                columnId={0}
                date={dummyDate2}
                isFuture={true}
                events={[]}
                heights={heights}
                focusSessions={[]}
                showEvents={false}
                showDevices={true}
              />
              <CalendarDay
                columnId={1}
                date={dummyDate2}
                isFuture={true}
                events={[]}
                heights={heights}
                focusSessions={[]}
                showEvents={false}
                showDevices={true}
              />
              <CalendarDay
                columnId={2}
                date={dummyDate2}
                isFuture={true}
                events={[]}
                heights={heights}
                focusSessions={[]}
                showEvents={false}
                showDevices={true}
              />
              <CalendarDay
                columnId={3}
                date={dummyDate}
                isFuture={true}
                events={[]}
                heights={heights}
                focusSessions={[]}
                showEvents={false}
                showDevices={true}
              />
              <CalendarDay
                columnId={4}
                date={dummyDate}
                isFuture={true}
                events={[]}
                heights={heights}
                focusSessions={[]}
                showEvents={false}
                showDevices={true}
              />
              <CalendarDay
                columnId={5}
                date={dummyDate}
                isFuture={true}
                events={[]}
                heights={heights}
                focusSessions={[]}
                showEvents={false}
                showDevices={true}
              />
              <CalendarDay
                columnId={6}
                date={dummyDate}
                isFuture={true}
                events={[]}
                heights={heights}
                focusSessions={[]}
                showEvents={false}
                showDevices={true}
              />
            </Box>
          </Box>
          <Box
            key={-2}
            sx={{
              height: heights.accuracyBox,
              width: "100%",
              borderTop: "1px solid black",
            }}
          ></Box>
        </Box>
      </ErrorBoundary>
    </Box>
  );
};
