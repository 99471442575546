import {
  Box,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import PushPinIcon from "@mui/icons-material/PushPin";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { TargetColors } from "../../../../dashboard/goals/goalcard/multiprogress/multiprogress.component";
import {
  QuantumType,
  TargetFunction,
  TargetType,
  ViewGoalDto,
  ViewTargetDto,
} from "../../../../../services/goals/goals.model";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";

interface GoalMonitorCardProps {
  goals: ViewGoalDto[];
  deleteGoal: (goalId: number) => void;
  pinGoal: (goalId: number, pinned: boolean) => void;
  date: DateTime;
}

export const GoalMonitorCard: React.FC<GoalMonitorCardProps> = (
  props: GoalMonitorCardProps
): JSX.Element => {
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        height: "100%",
        borderRadius: "8px",
        padding: "5px",
        position: "relative",
      }}
    >
      <Box>
        <Typography variant="body1Bold">Monitor Goals</Typography>
      </Box>
      <TableContainer sx={{ height: "90%", overflowY: "auto" }}>
        <Table sx={{ width: "100%" }} size="small">
          <TableHead>
            <TableRow>
              <TableCell
                size={"small"}
                sx={{ width: "10px", padding: 0 }}
              ></TableCell>
              <TableCell sx={{}}>Goal</TableCell>
              <TableCell align="right">Time</TableCell>
              <TableCell align="right">Focus</TableCell>
              <TableCell align="right">Switch</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.goals.map((goal) => (
              <GoalTableRow
                key={goal.id}
                goalData={goal}
                deleteGoal={props.deleteGoal}
                pinGoal={props.pinGoal}
                date={props.date}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

interface GoalTableRowProps {
  goalData: ViewGoalDto;
  deleteGoal: (goalId: number) => void;
  pinGoal: (goalId: number, pinned: boolean) => void;
  date: DateTime;
}

const GoalTableRow: React.FC<GoalTableRowProps> = (
  props: GoalTableRowProps
): JSX.Element => {
  const [goalMet, setGoalMet] = useState<boolean | undefined>(false);

  useEffect(() => {
    let finalMet: boolean | undefined = true;
    const met = props.goalData.targets.map((tg) => {
      if (
        tg.targetFunction === TargetFunction.MAX &&
        !tg.targetMet &&
        tg.actualQuantum > 0
      ) {
        return false;
      } else if (tg.targetFunction === TargetFunction.MIN && tg.targetMet) {
        return true;
      }
      return undefined;
    });

    met.forEach((m) => {
      if (m === undefined) {
        finalMet = undefined;
      } else if (finalMet != undefined) {
        finalMet = finalMet && m;
      }
    });

    setGoalMet(finalMet);
  }, [props.goalData]);

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell sx={{ padding: 1 }}>
        {goalMet !== undefined ? (
          goalMet ? (
            <img width="12px" src="assets/goals-met-icons/tick.png"></img>
          ) : (
            <img width="12px" src="assets/goals-met-icons/cross.png"></img>
          )
        ) : (
          ""
        )}
      </TableCell>
      <TableCell component="th" scope="row" sx={{}}>
        {props.goalData.name}
      </TableCell>
      {Object.values(TargetType).map((targetType: TargetType) => {
        const target = props.goalData.targets.find(
          (ob) => ob.target === targetType
        );
        return (
          <GoalTargetCell
            key={targetType}
            targetData={target}
            enabled={target ? true : false}
          />
        );
      })}
      <TableCell align="right">
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {props.goalData.pinned ? (
            <PushPinIcon
              sx={{
                color: "#7E4BB7",
                fontSize: "20px",
                ":hover": {
                  transform: "scale(1.1)",
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                if (props.pinGoal) props.pinGoal(props.goalData.id, false);
              }}
            />
          ) : (
            <PushPinOutlinedIcon
              sx={{
                color: "#7E4BB7",
                fontSize: "20px",
                ":hover": {
                  transform: "scale(1.1)",
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                if (props.pinGoal) props.pinGoal(props.goalData.id, true);
              }}
            />
          )}

          <DeleteOutlineIcon
            sx={{
              fontSize: "20px",
              ":hover": {
                transform: "scale(1.1)",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              if (props.deleteGoal) props.deleteGoal(props.goalData.id);
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

interface GoalTargetCellProps {
  targetData?: ViewTargetDto;
  enabled: boolean;
}
const GoalTargetCell: React.FC<GoalTargetCellProps> = (
  props: GoalTargetCellProps
): JSX.Element => {
  const [color, setColor] = useState<any>();

  const [quantum, setQuntum] = useState<{ actual: number; target: number }>({
    actual: 0,
    target: 0,
  });

  useEffect(() => {
    if (props.targetData) {
      let q = {
        actual: props.targetData.actualQuantum,
        target: props.targetData.targetQuantum,
      };
      if (props.targetData.quantumType === QuantumType.TIME) {
        q = {
          actual: Math.floor(props.targetData.actualQuantum / 60),
          target: Math.floor(props.targetData.targetQuantum / 60),
        };
      }
      setQuntum(q);
    }
  }, [props.targetData]);

  useEffect(() => {
    if (props.targetData?.target === TargetType.TIME) {
      setColor("primary");
    } else if (props.targetData?.target === TargetType.FOCUS) {
      setColor("success");
    } else if (props.targetData?.target === TargetType.ACTIVITIES) {
      setColor("warning");
    }
  }, [props.targetData]);
  return (
    <TableCell align="right">
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {props.enabled ? (
          <>
            <LinearProgress
              color={color}
              variant="determinate"
              value={Math.min(
                Math.ceil(
                  ((quantum.actual ?? 0) / (quantum.target ?? 1)) * 100
                ),
                100
              )}
            />
            <Stack
              direction={"row"}
              columnGap={1}
              sx={{ mt: 1, display: "flex", justifyContent: "space-between" }}
            >
              {props.targetData ? (
                props.targetData.actualQuantum === 0 ? (
                  <span></span>
                ) : props.targetData?.targetFunction === TargetFunction.MAX ? (
                  props.targetData?.targetMet === true ? (
                    <img
                      width="14px"
                      height={"14px"}
                      src="assets/goals-met-icons/dotted-tick.png"
                    ></img>
                  ) : (
                    <img
                      width="14px"
                      height={"14px"}
                      src="assets/goals-met-icons/cross.png"
                    ></img>
                  )
                ) : props.targetData.targetMet === false ? (
                  <img
                    width="14px"
                    height={"14px"}
                    src="assets/goals-met-icons/dotted-tick.png"
                  ></img>
                ) : (
                  <img
                    width="14px"
                    height={"14px"}
                    src="assets/goals-met-icons/tick.png"
                  ></img>
                )
              ) : (
                <span></span>
              )}
              {/* {props.targetData?.targetMet ? (
                props.targetData.targetFunction === TargetFunction.MAX ? (
                  <img
                    width="14px"
                    height={"14px"}
                    src="assets/goals-met-icons/dotted-tick.png"
                  ></img>
                ) : (
                  <img
                    width="14px"
                    height={"14px"}
                    src="assets/goals-met-icons/tick.png"
                  ></img>
                )
              ) : props.targetData?.targetFunction === TargetFunction.MAX &&
                props.targetData.actualQuantum > 0 ? (
                <img
                  width="14px"
                  height={"14px"}
                  src="assets/goals-met-icons/cross.png"
                ></img>
              ) : (
                <img
                  width="14px"
                  height={"14px"}
                  src="assets/goals-met-icons/dotted-tick.png"
                ></img>
              )} */}

              <Box
                sx={
                  {
                    // alignItems: "end",
                  }
                }
              >
                <Typography variant="body2">
                  {quantum.actual}/{quantum.target}
                  <span style={{ fontSize: "0.75rem" }}>
                    ({props.targetData?.targetFunction})
                  </span>
                </Typography>
              </Box>
            </Stack>
          </>
        ) : (
          "- / -"
        )}
      </Box>
    </TableCell>
  );
};
