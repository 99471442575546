import { Box, Divider, Typography } from "@mui/material";
import { withStyles, WithStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { styles } from "./styles/leftnavigation.styles";
import NavButton from "./leftnavigationbutton.component";
import { useLocation, useNavigate } from "react-router-dom";
interface LeftNavigationProps extends WithStyles<typeof styles> {}

interface NavItem {
  text: string;
  path: string;
  icon: string;
  selectedIcon: string;
  selected: boolean;
}

interface LeftNavigationState {
  selected: number;
  navItems: NavItem[];
}

export default withStyles(styles)(LeftNavigationBar);

const nvitems = [
  {
    icon: "/assets/homeIcon_unselected.svg",
    selectedIcon: "/assets/homeIcon_selected.svg",
    text: "Home",
    path: "/home",
    selected: false,
  },
  {
    icon: "/assets/Dashboard.svg",
    selectedIcon: "/assets/Dashboard_selected.svg",
    text: "Dashboard",
    path: "/dashboard",
    selected: false,
  },
  {
    icon: "/assets/Calendar.svg",
    selectedIcon: "/assets/Calendar_selected.svg",
    text: "Calendar",
    path: "/calendar",
    selected: false,
  },
  // {
  //   icon: "/assets/Goals_Tasks.svg",
  //   selectedIcon: "/assets/Goals_Tasks_selected.svg",
  //   text: "Goals",
  //   path: "/goals",
  //   selected: false,
  // },
  {
    icon: "/assets/Settings.svg",
    selectedIcon: "/assets/settings_selected.svg",
    text: "Settings",
    path: "/settings",
    selected: false,
  },
  {
    icon: "/assets/create_account.svg",
    selectedIcon: "/assets/create_account_selected.svg",
    text: "Support",
    path: "/support",
    selected: false,
  },
];

function LeftNavigationBar(props: LeftNavigationProps) {
  const [navItems, setNavItems] = useState(nvitems);
  let navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClick = (id: number) => {
    const newNavItems: NavItem[] = navItems.map(
      (item: NavItem, index: number) => {
        if (index === id) {
          item.selected = true;
        } else {
          item.selected = false;
        }
        return item;
      }
    );
    setNavItems(newNavItems);
    navigate(newNavItems[id].path);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  useEffect(() => {
    const newNavItems: NavItem[] = navItems.map(
      (item: NavItem, index: number) => {
        if (item.path === pathname) {
          item.selected = true;
        } else {
          item.selected = false;
        }
        return item;
      }
    );
    setNavItems(newNavItems);
  }, [pathname]);

  return (
    <Box
      sx={{
        width: "110px",
        minWidth: "110px",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
        overflowY: "auto",
        padding: "1% 0%",
        alignItems: "center",
        marginRight: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          ":hover": {
            cursor: "pointer",
          },
        }}
        onClick={handleLogoClick}
      >
        <img src="/assets/logo.svg" alt="logo" />
        <div
          style={{
            backgroundColor: "#D6DAE480",
            height: "2px",
            width: "102%",
            marginLeft: "-2%",
            marginTop: "6%",
          }}
        ></div>
      </Box>
      {navItems.map((item, index) => (
        <NavButton
          key={index}
          index={index}
          icon={item.icon}
          icon_selected={item.selectedIcon}
          text={item.text}
          path={item.path}
          onSelect={handleClick}
          selected={item.selected}
        />
      ))}
      <Box
        sx={{
          position: "absolute",
          bottom: "10px",
          border: "solid",
          padding: "2px",
          borderRadius: "5px",
          backgroundColor: "#CE8080DD",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        <Typography
          variant="body1Bold"
          sx={{
            color: "white",
            fontWeight: "bold",
          }}
        >
          BETA
        </Typography>
      </Box>
      {/* <Box sx={{ position: "relative", zIndex: 0 }}> */}
      {/* <img
        style={{ position: "relative", bottom: "50px" }}
        src="/assets/leftNavDesign.svg"
        alt="logo"
      /> */}
      {/* </Box> */}
    </Box>
  );
}
