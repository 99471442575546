import { Box, Button, Typography } from "@mui/material";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import * as React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { getSession } from "../../../accountcontext/account";

import { useModal } from "../../../core/modal.hook";
import {
  startIntegration,
  processIntegrationResponse,
} from "../../../google_login.service";
import { previousAppUrl } from "../../../services/app.service";
import InstallationModal from "../installationmodal/installationmodal.component";
import errorMsgdb from "./errormessages.json";

interface OverlayProps {
  text?: string;
  image?: string;
  style?: React.CSSProperties;
  state?: "browser" | "email" | "benefits";
}

export const NoDataOverlay = (props: OverlayProps) => {
  const { open, handleOpen, handleClose } = useModal();
  const provider = "google_gmail";
  const location = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    if (props.state === "email") {
      window.addEventListener("message", compeleteIntegration);
      return () => {
        window.removeEventListener("message", compeleteIntegration);
      };
    }
  }, []);

  const linkEmail = () => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        startIntegration(provider, idToken, username, () => {});
      })
      .catch((err) => console.log(err));
    // props.onConnect();
  };

  const compeleteIntegration = (event: MessageEvent) => {
    if (!event || !("code" in event.data)) {
      return;
    }
    getSession().then((session: CognitoUserSession) => {
      const idToken = session.getIdToken().getJwtToken();
      const username = session.getIdToken().payload["cognito:username"];
      processIntegrationResponse(event, username, idToken, provider)
        .then(() => {
          setTimeout(() => {
            previousAppUrl.set(location.pathname + location.search);
            navigate("/");
          }, 1000);
        })
        .catch((err: any) => {
          console.log(err);
          // toast.error("Something went wrong. please try again later", {
          //   position: "bottom-left",
          //   autoClose: 5000,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        });
    });
  };

  return (
    <>
      {props.image ? (
        <img
          src={props.image}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            marginRight: "auto",
            marginLeft: "auto",
            width: "98%",
            height: "98%",
            zIndex: 4,
          }}
        />
      ) : (
        ""
      )}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          marginRight: "auto",
          marginLeft: "auto",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          backgroundColor: "#FFFFFFCC",
          backdropFilter: "blur(1px)",
          textAlign: "center",
          zIndex: 5,
          borderRadius: "12px",
          ...props.style,
        }}
      >
        <Box display="block" textAlign="center" margin="auto">
          <Typography
            variant="h6"
            sx={{
              zIndex: 2,
              margin: "auto",
              width: props.state ? "100%" : "70%",
              fontWeight: "regular",
            }}
          >
            {props.text ? props.text : errorMsgdb.appNotInstalled}
          </Typography>
          {props.state !== "benefits" ? (
            <Button
              variant="outlined"
              onClick={
                props.state
                  ? props.state === "email"
                    ? linkEmail
                    : handleOpen
                  : handleOpen
              }
            >
              {props.state
                ? props.state === "email"
                  ? "Link Gmail"
                  : props.state === "browser"
                  ? "Download extension"
                  : "Download App"
                : "Download App"}
            </Button>
          ) : (
            ""
          )}
          <InstallationModal
            open={open}
            handleClose={handleClose}
            extensionOnly={props.state === "browser"}
          />
        </Box>
      </Box>
    </>
  );
};
