import {
  Box,
  Collapse,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import {
  CustomTemplateOptions,
  DimensionType,
} from "../../../../../services/goals/goals.model";
import { SaveUpdateCategoryDto } from "../../../../settings/custom-category/models/custom-category.model";
import {
  CustomCategoryDialog,
  CustomCategoryDialogTemplateTypes,
} from "../../../../settings/custom-category/components/custom-category-dialog.component";

interface GoalSelectorCardProps {
  customTemplateOptions: CustomTemplateOptions[];
  updateGoals: () => Promise<void>;
  selectGoalOption: (option: CustomTemplateOptions) => void;
}

export const GoalSelectorCard: React.FC<GoalSelectorCardProps> = (
  props: GoalSelectorCardProps
): JSX.Element => {
  const [listItems, setListItems] = useState<{
    [DimensionType.USER_CATEGORY]: CustomTemplateOptions[];
    [DimensionType.CATEGORY]: CustomTemplateOptions[];
    [DimensionType.APPLICATION]: CustomTemplateOptions[];
  }>({
    [DimensionType.USER_CATEGORY]: [],
    [DimensionType.CATEGORY]: [],
    [DimensionType.APPLICATION]: [],
  });

  const [openCustomCategoryPopup, setOpenCustomCategoryPopup] =
    useState<boolean>(false);

  const [tempOptionHolder, setTempOptionHolder] = useState<string | undefined>(
    undefined
  );

  const [searchCriteria, setSearchCriteria] = useState<string>("");

  const [expandOverride, setExpandOverride] = useState<boolean>(false);

  const generateOptions = (
    customOptions: CustomTemplateOptions[],
    addCreate: boolean
  ) => {
    const t = {
      [DimensionType.USER_CATEGORY]: customOptions.filter(
        (o) => o.dimensionType === DimensionType.USER_CATEGORY
      ),
      [DimensionType.CATEGORY]: customOptions.filter(
        (o) => o.dimensionType === DimensionType.CATEGORY
      ),
      [DimensionType.APPLICATION]: customOptions.filter(
        (o) => o.dimensionType === DimensionType.APPLICATION
      ),
    };
    if (addCreate) {
      t[DimensionType.USER_CATEGORY].push({
        dimensionType: DimensionType.USER_CATEGORY,
        dimensionValue: "Create...",
        targets: {},
      });
    }
    setListItems(t);
  };

  useEffect(() => {
    generateOptions(props.customTemplateOptions, true);
  }, [props.customTemplateOptions]);

  useEffect(() => {
    if (tempOptionHolder) {
      const op = props.customTemplateOptions.find(
        (option) =>
          option.dimensionValue === tempOptionHolder &&
          option.dimensionType === DimensionType.USER_CATEGORY
      );
      if (op) {
        setTempOptionHolder(undefined);
        props.selectGoalOption(op);
      }
    }
  }, [tempOptionHolder, props.customTemplateOptions]);

  const onOptionSelect = (option: CustomTemplateOptions) => {
    if (
      option.dimensionType === DimensionType.USER_CATEGORY &&
      option.dimensionValue === "Create..."
    ) {
      setOpenCustomCategoryPopup(true);
    } else {
      props.selectGoalOption(option);
      setExpandOverride(false);
      setSearchCriteria("");
    }
  };

  const handlePopupClose = (value: SaveUpdateCategoryDto | null) => {
    setOpenCustomCategoryPopup(false);
    setTempOptionHolder(value?.categoryName);
    props.updateGoals();
  };

  const handleSearchCriteriaChanged = (event: any) => {
    setSearchCriteria(event.target.value);
  };

  useEffect(() => {
    if (searchCriteria && searchCriteria != null && searchCriteria !== "") {
      const filteredOptions = props.customTemplateOptions.filter((op) =>
        op.dimensionValue.toLowerCase().includes(searchCriteria.toLowerCase())
      );
      generateOptions(filteredOptions, false);
      setExpandOverride(true);
    } else {
      generateOptions(props.customTemplateOptions, true);
      setExpandOverride(false);
    }
  }, [searchCriteria]);

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        height: "100%",
        borderRadius: "8px",
        padding: "5px",
      }}
    >
      <Box>
        <Typography variant="body1Bold">Select Goal</Typography>
      </Box>
      <TextField
        placeholder="Search"
        hiddenLabel
        fullWidth
        variant="standard"
        size="small"
        margin="none"
        value={searchCriteria}
        onChange={handleSearchCriteriaChanged}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Box sx={{ height: "80%", overflow: "hidden", overflowY: "auto" }}>
        <List
          dense={true}
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <SubListComponent
            header="Custom Categories"
            optionsList={listItems[DimensionType.USER_CATEGORY]}
            selectGoalOption={onOptionSelect}
            openDefault={true || expandOverride}
          />
          <SubListComponent
            header="myTiro Categories"
            optionsList={listItems[DimensionType.CATEGORY]}
            selectGoalOption={onOptionSelect}
            openDefault={false || expandOverride}
          />
          <SubListComponent
            header="Applications/Websites"
            optionsList={listItems[DimensionType.APPLICATION]}
            selectGoalOption={onOptionSelect}
            openDefault={false || expandOverride}
          />
        </List>
      </Box>
      <CustomCategoryDialog
        // selectedValue={}
        open={openCustomCategoryPopup}
        onClose={handlePopupClose}
        template={CustomCategoryDialogTemplateTypes.create}
      />
    </Box>
  );
};

interface SubListProps {
  header: string;
  optionsList: CustomTemplateOptions[];
  selectGoalOption: (option: CustomTemplateOptions) => void;
  openDefault?: boolean;
}

const SubListComponent = (props: SubListProps) => {
  const [open, setOpen] = useState(props.openDefault);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(props.openDefault);
  }, [props.openDefault]);
  return (
    <>
      <ListItemButton sx={{ py: 0, minHeight: 32 }} onClick={handleClick}>
        <ListItemText
          primary={props.header}
          primaryTypographyProps={{
            fontSize: 14,
            fontWeight: "medium",
            color: "black",
          }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.optionsList.map((op, index) => (
          <List disablePadding key={index} dense>
            <ListItemButton
              sx={{ pl: 3 }}
              onClick={() => props.selectGoalOption(op)}
            >
              <ListItemText primary={op.dimensionValue} />
            </ListItemButton>
          </List>
        ))}
      </Collapse>
    </>
  );
};
