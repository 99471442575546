import * as React from "react";

import { Box } from "@mui/material";
import { fetchSidebarData } from "./sidebar.service";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme/theme";
import ProfileTopRight from "./profiletopright.component";
import { ScoresBarComponent } from "./items/scores/scores-bar.component";
import { ScoringValuesDto } from "../../services/scores/scores.model";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
interface SidePanelProps {
  isMobile: boolean;
}

interface SidePanelState {
  scores: ScoringValuesDto[];
  prevScores: ScoringValuesDto[];
  dataLoaded: boolean;
}

export const SidePanel: React.FC<SidePanelProps> = (
  props: SidePanelProps
): JSX.Element => {
  const [scores, setScores] = useState<ScoringValuesDto[]>([]);
  const [prevScores, setPrevScores] = useState<ScoringValuesDto[]>([]);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    loadSidePanelData();
  }, []);

  const loadSidePanelData = async () => {
    const today = DateTime.now().startOf("day");
    let prevDay = today.minus({ days: 1 });
    if (prevDay.weekday === 7) {
      prevDay = prevDay.minus({ days: 2 });
    } else if (prevDay.weekday === 6) {
      prevDay = prevDay.minus({ days: 1 });
    }
    const startTime = today.toUnixInteger();
    const endTime = today.endOf("day").toUnixInteger();
    const prevStartTime = prevDay.toUnixInteger();
    const prevEndTime = prevDay.endOf("day").toUnixInteger();

    fetchSidebarData(startTime, endTime, prevStartTime, prevEndTime).then(
      (data: any) => {
        setScores(data.scores);
        setPrevScores(data.prevScores);
        setDataLoaded(true);
      }
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "100%",
        }}
      >
        {dataLoaded && (
          <Box
            sx={{
              height: "100vh",
              backgroundColor: "white",
              overflow: "hidden",
            }}
          >
            <ProfileTopRight />
            <Box
              sx={{
                height: "100vh",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  display: "block",
                  width: "0.6em",
                  marginRight: "0.2em",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "darkgray",
                  outline: 0,
                  borderRadius: "40px",
                  height: "20%",
                },
              }}
            >
              <ScoresBarComponent scores={scores} prevScores={prevScores} />
              <Box
                sx={{
                  height: "10vh",
                  backgroundColor: "white",
                  width: "100%",
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export class SidePanelOld extends React.Component<
  SidePanelProps,
  SidePanelState
> {
  constructor(props: SidePanelProps) {
    super(props);
    this.state = {
      scores: [],
      prevScores: [],
      dataLoaded: false,
    };
  }

  componentDidMount() {
    this.loadSidePanelData = this.loadSidePanelData.bind(this);
    this.loadSidePanelData();
  }

  loadSidePanelData = () => {
    const today = DateTime.now().startOf("day");
    let prevDay = today.minus({ days: 1 });
    if (prevDay.weekday === 7) {
      prevDay = prevDay.minus({ days: 2 });
    } else if (prevDay.weekday === 6) {
      prevDay = prevDay.minus({ days: 1 });
    }
    const startTime = today.toUnixInteger();
    const endTime = today.endOf("day").toUnixInteger();
    const prevStartTime = prevDay.toUnixInteger();
    const prevEndTime = prevDay.endOf("day").toUnixInteger();
    // console.log("Fetching");
    fetchSidebarData(startTime, endTime, prevStartTime, prevEndTime).then(
      (data: any) => {
        this.setState({
          scores: data.scores,
          prevScores: data.prevScores,
          dataLoaded: true,
        });
      }
    );
  };

  render() {
    const { isMobile } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            width: "100%",
          }}
        >
          {this.state.dataLoaded && (
            <Box
              sx={{
                height: "100vh",
                backgroundColor: "white",
                overflow: "hidden",
              }}
            >
              <ProfileTopRight />
              <Box
                sx={{
                  height: "100vh",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "block",
                    width: "0.6em",
                    marginRight: "0.2em",
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "darkgray",
                    outline: 0,
                    borderRadius: "40px",
                    height: "20%",
                  },
                }}
              >
                <ScoresBarComponent
                  scores={this.state.scores}
                  prevScores={this.state.prevScores}
                />
                <Box
                  sx={{
                    height: "10vh",
                    backgroundColor: "white",
                    width: "100%",
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </ThemeProvider>
    );
  }
}
