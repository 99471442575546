import { CognitoUserSession } from "amazon-cognito-identity-js";
import { getSession } from "../../../accountcontext/account";
import colors from "./colors.json";

const categoryColors: { [category: string]: string } = {
  Email: "#E8BE4F",
  Browsing: "#8C95EB",
  "Desktop Apps": "#F39A8C",
  Meetings: "#7ACCAA",
  "Office Apps": "#75B5D9",
  "Mobile Apps": "#B68CEB",
  Computer: "#91CF74",
  Mobile: "#75B5D9",
  Tablet: "#CF9494",
};

const colorMap = colors.colors.reduce(
  (acc: { [color: string]: string }, curr) => {
    acc[curr.category] = curr.color;
    return acc;
  },
  {}
);

export const getColor = (category: string): string => {
  return colorMap[category] ?? "#B68CEB";
};

export const getCategory = (category: string): string => {
  if (category in colorMap) {
    return category;
  }
  return "Unknown";
};

export const getNewCategoryColor = (category: string): string => {
  return categoryColors[category] ?? "#AAAFBBBB";
};

export const checkIfAppInstalled = () => {
  getSession().then((sess: CognitoUserSession) => {});
  return true;
};
