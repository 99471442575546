import HighchartsReact from "highcharts-react-official";
import Highcharts, { Options } from "highcharts";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge";
import * as React from "react";
// import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import {
  QuantumType,
  TargetFunction,
  TargetType,
} from "../../../../../services/goals/goals.model";

highchartsMore(Highcharts);
solidGauge(Highcharts);

export enum GoalTargetVariant {
  MIN = "min",
  MAX = "max",
}

export enum TargetLabels {
  TIME = "Time",
  FOCUS = "Focus",
  ACTIVITIES = "Switch",
}

// export enum QuantumTye {
//   COUNT = "count",
//   TIME = "time",
// }

// cannot change until raduis values are computed dynamically
enum TargetIndex {
  TIME = 0,
  FOCUS = 1,
  ACTIVITIES = 2,
}

export enum TargetColors {
  TIME = "#B68CEA",
  // TIME = "#7ACCAA",
  FOCUS = "#7ACCAA",
  ACTIVITIES = "#F39A8C",
}

export enum TargetTooltipText {
  TIME = "Must be equal to or more than Focus. Enter 0 to not track. Default is minimum.",
  FOCUS = "Must be at least 20 min and less than or equal to Time. Enter 0 to not track. Default is minimum.",
  ACTIVITIES = "Enter 0 to not track. Default is maximum.",
}

// all values in minutes
export interface GoalTargetValue {
  total: number;
  value: number;
  unit: QuantumType;
  targetFunction: TargetFunction;
  targetMet?: boolean;
}

export interface MultiGoalProps {
  totalTime: GoalTargetValue | undefined;
  focusTime: GoalTargetValue | undefined;
  activities: GoalTargetValue | undefined;
  mini?: boolean;
  currentDay: boolean;
}

export const MultiGoalProgress = (props: MultiGoalProps): JSX.Element => {
  const [goalData, setGoalData] = useState<{
    totalTime: GoalTargetValue | undefined;
    focusTime: GoalTargetValue | undefined;
    activities: GoalTargetValue | undefined;
  }>({ totalTime: undefined, focusTime: undefined, activities: undefined });

  const getTargetValueString = (target: TargetType): string => {
    if (target === TargetType.TIME) {
      const val = goalData.totalTime?.value
        ? Math.floor(goalData.totalTime.value / 60)
        : undefined;
      const total = goalData.totalTime?.total
        ? Math.floor(goalData.totalTime.total / 60)
        : undefined;
      // const targetEmoji = goalData.totalTime?.targetMet ? "✔️" : "❌" ?? "";
      return `${val ?? " - "}/${total ?? " - "}`;
    } else if (target === TargetType.FOCUS) {
      const val = goalData.focusTime?.value
        ? Math.floor(goalData.focusTime.value / 60)
        : undefined;
      const total = goalData.focusTime?.total
        ? Math.floor(goalData.focusTime.total / 60)
        : undefined;
      // const targetEmoji = goalData.focusTime?.targetMet ? "✔️" : "❌" ?? "";
      return `${val ?? " - "}/${total ?? " - "}`;
    } else if (target === TargetType.ACTIVITIES) {
      // const targetEmoji = goalData.activities?.targetMet ? "✔️" : "❌" ?? "";
      return `${goalData.activities?.value ?? " - "}/${
        goalData.activities?.total ?? " - "
      }`;
    }
    return " - ";
  };

  const getImageUrl = (target: TargetType): string => {
    if (target === TargetType.TIME) {
      if (goalData.totalTime?.value) {
        if (goalData.totalTime?.targetFunction === TargetFunction.MAX) {
          if (goalData.totalTime?.targetMet === true) {
            if (props.currentDay) {
              return "assets/goals-met-icons/dotted-tick.png";
            } else {
              return "assets/goals-met-icons/tick.png";
            }
          } else {
            return "assets/goals-met-icons/cross.png";
          }
        } else if (goalData.totalTime?.targetFunction === TargetFunction.MIN) {
          if (goalData.totalTime?.targetMet === false) {
            if (props.currentDay) {
              return "assets/goals-met-icons/dotted-tick.png";
            } else {
              return "assets/goals-met-icons/cross.png";
            }
          } else {
            return "assets/goals-met-icons/tick.png";
          }
        }
      } else {
        return "";
      }
    } else if (target === TargetType.FOCUS) {
      if (goalData.focusTime?.value) {
        if (goalData.focusTime?.targetFunction === TargetFunction.MAX) {
          if (goalData.focusTime?.targetMet === true) {
            if (props.currentDay) {
              return "assets/goals-met-icons/dotted-tick.png";
            } else {
              return "assets/goals-met-icons/tick.png";
            }
          } else {
            return "assets/goals-met-icons/cross.png";
          }
        } else if (goalData.focusTime?.targetFunction === TargetFunction.MIN) {
          if (goalData.focusTime?.targetMet === false) {
            if (props.currentDay) {
              return "assets/goals-met-icons/dotted-tick.png";
            } else {
              return "assets/goals-met-icons/cross.png";
            }
          } else {
            return "assets/goals-met-icons/tick.png";
          }
        }
      } else {
        return "";
      }
    } else if (target === TargetType.ACTIVITIES) {
      if (goalData.activities?.value) {
        if (goalData.activities?.targetFunction === TargetFunction.MAX) {
          if (goalData.activities?.targetMet === true) {
            if (props.currentDay) {
              return "assets/goals-met-icons/dotted-tick.png";
            } else {
              return "assets/goals-met-icons/tick.png";
            }
          } else {
            return "assets/goals-met-icons/cross.png";
          }
        } else if (goalData.activities?.targetFunction === TargetFunction.MIN) {
          if (goalData.activities?.targetMet === false) {
            if (props.currentDay) {
              return "assets/goals-met-icons/dotted-tick.png";
            } else {
              return "assets/goals-met-icons/cross.png";
            }
          } else {
            return "assets/goals-met-icons/tick.png";
          }
        }
      } else {
        return "";
      }
    }
    return "";
  };

  const getTargetFunctionString = (target: TargetType): string => {
    if (target === TargetType.TIME) {
      return goalData.totalTime?.targetFunction
        ? `(${goalData.totalTime?.targetFunction})`
        : "";
    } else if (target === TargetType.FOCUS) {
      return goalData.focusTime?.targetFunction
        ? `(${goalData.focusTime?.targetFunction})`
        : "";
    } else if (target === TargetType.ACTIVITIES) {
      return goalData.activities?.targetFunction
        ? `(${goalData.activities?.targetFunction})`
        : "";
    }
    return "";
  };

  const [chartOptions, setChartOptions] = useState<any>({
    chart: {
      type: "solidgauge",
      // height: props.mini ? "90%" : "90%",
      height: "50%",
      spacingRight: 175,
      spacingBottom: 5,
      marginLeft: 0,
      // width: 175,
      backgroundColor: "none",
      events: {
        render() {
          var chart = this as any;
          // console.log("PlotX", chart.plotSizeX);
          // console.log("plotY", chart.plotSizeY);
          // console.log(
          //   "ChartWidth",
          //   // chart.plotWidth + chart.marginRight + chart.plotLeft
          //   chart.chartWidth
          // );
          // console.log("ChartHeight", chart.chartHeight);
          // console.log("PlotLeft", chart.plotRight);
          // console.log("PlotTop", chart.plotTop);
          // console.log(chart);
          if (chart.sideTimeTargetMet) {
            chart.sideTimeTargetMet.destroy();
          }
          if (chart.sideFocusTargetMet) {
            chart.sideFocusTargetMet.destroy();
          }
          if (chart.sideActTargetMet) {
            chart.sideActTargetMet.destroy();
          }
          if (chart.sideTimeLabel) {
            chart.sideTimeText.destroy();
          }
          if (chart.sideFocusLabel) {
            chart.sideFocusLabel.destroy();
          }
          if (chart.sideActLabel) {
            chart.sideActLabel.destroy();
          }
          if (chart.sideTimeText) {
            chart.sideTimeText.destroy();
          }
          if (chart.sideFocusText) {
            chart.sideFocusText.destroy();
          }
          if (chart.sideActText) {
            chart.sideActText.destroy();
          }
          chart.sideTimeLabel = chart.renderer
            .text(
              `${TargetLabels.TIME} ${getTargetFunctionString(
                TargetType.TIME
              )}`,
              chart.chartWidth - 140,
              chart.chartHeight - 130
            )
            .css({
              fontSize: "10px",
              color: TargetColors.TIME,
              fontWeight: 400,
            })
            .add();
          // chart.sideTimeTargetMet = chart.renderer
          //   .text(`${getTargetMetIcon(TargetType.TIME)}`, 175, 37)
          //   .css({
          //     fontSize: "10px",
          //     color: TargetColors.TIME,
          //     fontWeight: 400,
          //   })
          //   .add();
          chart.sideTimeTargetMet = chart.renderer
            .image(
              getImageUrl(TargetType.TIME),
              chart.chartWidth - 165,
              chart.chartHeight - 127,
              20,
              20
            )
            .css({
              // fontSize: "10px",
              color: TargetColors.TIME,
              // fontWeight: 400,
            })
            .add();
          chart.sideTimeText = chart.renderer
            .text(
              getTargetValueString(TargetType.TIME),
              chart.chartWidth - 140,
              chart.chartHeight - 110
            )
            .css({
              fontSize: "20px",
              color: TargetColors.TIME,
              fontWeight: 900,
            })
            .add();
          chart.sideFocusLabel = chart.renderer
            .text(
              `${TargetLabels.FOCUS} ${getTargetFunctionString(
                TargetType.FOCUS
              )}`,
              chart.chartWidth - 140,
              chart.chartHeight - 90
            )
            .css({
              fontSize: "10px",
              color: TargetColors.FOCUS,
              fontWeight: 400,
            })
            .add();
          // chart.sideFocusTargetMet = chart.renderer
          //   .text(`${getTargetMetIcon(TargetType.FOCUS)}`, 175, 77)
          //   .css({
          //     fontSize: "10px",
          //     color: TargetColors.FOCUS,
          //     fontWeight: 400,
          //   })
          //   .add();
          chart.sideFocusTargetMet = chart.renderer
            .image(
              getImageUrl(TargetType.FOCUS),
              chart.chartWidth - 165,
              chart.chartHeight - 87,
              20,
              20
            )
            .css({
              // fontSize: "10px",
              color: TargetColors.TIME,
              // fontWeight: 400,
            })
            .add();
          chart.sideFocusText = chart.renderer
            .text(
              getTargetValueString(TargetType.FOCUS),
              chart.chartWidth - 140,
              chart.chartHeight - 70
            )
            .css({
              fontSize: "20px",
              color: TargetColors.FOCUS,
              fontWeight: 900,
            })
            .add();
          chart.sideActLabel = chart.renderer
            .text(
              `${TargetLabels.ACTIVITIES} ${getTargetFunctionString(
                TargetType.ACTIVITIES
              )}`,
              chart.chartWidth - 140,
              chart.chartHeight - 50
            )
            .css({
              fontSize: "10px",
              color: TargetColors.ACTIVITIES,
              fontWeight: 400,
            })
            .add();
          // chart.sideActTargetMet = chart.renderer
          //   .text(`${getTargetMetIcon(TargetType.ACTIVITIES)}`, 175, 117)
          //   .css({
          //     fontSize: "10px",
          //     color: TargetColors.ACTIVITIES,
          //     fontWeight: 400,
          //   })
          //   .add();
          chart.sideActTargetMet = chart.renderer
            .image(
              getImageUrl(TargetType.ACTIVITIES),
              chart.chartWidth - 165,
              chart.chartHeight - 47,
              20,
              20
            )
            .css({
              // fontSize: "10px",
              color: TargetColors.TIME,
              // fontWeight: 400,
            })
            .add();
          chart.sideActText = chart.renderer
            .text(
              getTargetValueString(TargetType.ACTIVITIES),
              chart.chartWidth - 140,
              chart.chartHeight - 30
            )
            .css({
              fontSize: "20px",
              color: TargetColors.ACTIVITIES,
              fontWeight: 900,
            })
            .add();
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: undefined,
    tooltip: {
      // pointFormat: `{series.name}<br><span style="font-size:1em; color: {point.color}; font-weight: bold">{point.y}</span>`,
      formatter: function () {
        const temp = this as any;
        let value = temp.y;
        if (
          temp.series.name === TargetLabels.TIME ||
          temp.series.name === TargetLabels.FOCUS
        ) {
          value = Math.floor(temp.y / 60);
        }
        return `${temp.series.name}: ${value}`;
      },
    },
    // plot: { width: 175 },
    pane: {
      startAngle: 0,
      endAngle: 360,
      background: [
        {
          // Tack Total TIme
          outerRadius: "112%",
          innerRadius: "88%",
          backgroundColor: Highcharts.color(TargetColors.TIME)
            .setOpacity(0)
            .get(),
          borderWidth: 0,
        },
        {
          // Track Focus Time
          outerRadius: "87%",
          innerRadius: "63%",
          backgroundColor: Highcharts.color(TargetColors.FOCUS)
            .setOpacity(0)
            .get(),
          borderWidth: 0,
        },
        {
          // Track activities
          outerRadius: "62%",
          innerRadius: "38%",
          backgroundColor: Highcharts.color(TargetColors.ACTIVITIES)
            .setOpacity(0)
            .get(),
          borderWidth: 0,
        },
      ],
    },

    yAxis: [
      {
        min: 0,
        max: 0,
        lineWidth: 0,
        tickPositions: [],
      },
      {
        min: 0,
        max: 0,
        lineWidth: 0,
        tickPositions: [],
      },
      {
        min: 0,
        max: 0,
        lineWidth: 0,
        tickPositions: [],
      },
    ],

    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false,
        },
        linecap: "round",
        stickyTracking: false,
        rounded: true,
      },
    },

    series: [
      {
        name: TargetLabels.TIME,
        yAxis: 0,
        data: [
          {
            color: Highcharts.color(TargetColors.TIME).setOpacity(1).get(),
            radius: "112%",
            innerRadius: "88%",
            y: 0,
          },
        ],
      },
      {
        name: TargetLabels.FOCUS,
        yAxis: 1,
        data: [
          {
            color: Highcharts.color(TargetColors.FOCUS).setOpacity(1).get(),
            radius: "87%",
            innerRadius: "63%",
            y: 0,
          },
        ],
      },
      {
        name: TargetLabels.ACTIVITIES,
        yAxis: 2,
        data: [
          {
            color: Highcharts.color(TargetColors.ACTIVITIES)
              .setOpacity(1)
              .get(),
            radius: "62%",
            innerRadius: "38%",
            y: 0,
          },
        ],
      },
    ],
  });

  useEffect(() => {
    if (
      props.activities != goalData.activities ||
      props.focusTime != goalData.focusTime ||
      props.totalTime != goalData.totalTime
    ) {
      setGoalData({
        totalTime: props.totalTime,
        focusTime: props.focusTime,
        activities: props.activities,
      });
    }
    // console.log(props.totalTime, props.focusTime, props.activities);
  }, [props]);

  useEffect(() => {
    const backgrounds = generateBackgroundList(
      goalData.totalTime != undefined,
      goalData.focusTime != undefined,
      goalData.activities != undefined
    );
    const yAxis = generateYAxis(
      goalData.totalTime?.total,
      goalData.focusTime?.total,
      goalData.activities?.total
    );
    const series = generateSeries(
      goalData.totalTime?.value,
      goalData.focusTime?.value,
      goalData.activities?.value
    );
    chartOptions.pane.background = backgrounds;
    chartOptions.yAxis = yAxis;
    chartOptions.series = series;

    chartOptions.chart.events = {
      render() {
        var chart = this as any;
        // console.log("PlotX", chart.plotSizeX);
        // console.log("plotY", chart.plotSizeY);
        // console.log("PlotLeft", chart.plotRight);
        // console.log("PlotTop", chart.plotTop);
        // console.log(chart);
        if (chart.sideTimeTargetMet) {
          chart.sideTimeTargetMet.destroy();
        }
        if (chart.sideFocusTargetMet) {
          chart.sideFocusTargetMet.destroy();
        }
        if (chart.sideActTargetMet) {
          chart.sideActTargetMet.destroy();
        }
        if (chart.sideTimeLabel) {
          chart.sideTimeLabel.destroy();
        }
        if (chart.sideFocusLabel) {
          chart.sideFocusLabel.destroy();
        }
        if (chart.sideActLabel) {
          chart.sideActLabel.destroy();
        }
        if (chart.sideTimeText) {
          chart.sideTimeText.destroy();
        }
        if (chart.sideFocusText) {
          chart.sideFocusText.destroy();
        }
        if (chart.sideActText) {
          chart.sideActText.destroy();
        }
        chart.sideTimeLabel = chart.renderer
          .text(
            `${TargetLabels.TIME} ${getTargetFunctionString(TargetType.TIME)}`,
            chart.chartWidth - 140,
            chart.chartHeight - 130
          )
          .css({ fontSize: "10px", color: TargetColors.TIME, fontWeight: 400 })
          .add();
        // chart.sideTimeTargetMet = chart.renderer
        //   .text(`${getTargetMetIcon(TargetType.TIME)}`, 175, 37)
        //   .css({
        //     fontSize: "10px",
        //     color: TargetColors.TIME,
        //     fontWeight: 400,
        //   })
        //   .add();
        chart.sideTimeTargetMet = chart.renderer
          .image(
            getImageUrl(TargetType.TIME),
            chart.chartWidth - 165,
            chart.chartHeight - 127,
            20,
            20
          )
          .css({
            // fontSize: "10px",
            color: TargetColors.TIME,
            // fontWeight: 400,
          })
          .add();
        chart.sideTimeText = chart.renderer
          .text(
            getTargetValueString(TargetType.TIME),
            chart.chartWidth - 140,
            chart.chartHeight - 110
          )
          .css({
            fontSize: "20px",
            color: TargetColors.TIME,
            fontWeight: 900,
          })
          .add();
        chart.sideFocusLabel = chart.renderer
          .text(
            `${TargetLabels.FOCUS} ${getTargetFunctionString(
              TargetType.FOCUS
            )}`,
            chart.chartWidth - 140,
            chart.chartHeight - 90
          )
          .css({
            fontSize: "10px",
            color: TargetColors.FOCUS,
            fontWeight: 400,
          })
          .add();
        // chart.sideFocusTargetMet = chart.renderer
        //   .text(`${getTargetMetIcon(TargetType.FOCUS)}`, 175, 77)
        //   .css({
        //     fontSize: "10px",
        //     color: TargetColors.FOCUS,
        //     fontWeight: 400,
        //   })
        //   .add();
        chart.sideFocusTargetMet = chart.renderer
          .image(
            getImageUrl(TargetType.FOCUS),
            chart.chartWidth - 165,
            chart.chartHeight - 87,
            20,
            20
          )
          .css({
            // fontSize: "10px",
            color: TargetColors.FOCUS,
            // fontWeight: 400,
          })
          .add();
        chart.sideFocusText = chart.renderer
          .text(
            getTargetValueString(TargetType.FOCUS),
            chart.chartWidth - 140,
            chart.chartHeight - 70
          )
          .css({
            fontSize: "20px",
            color: TargetColors.FOCUS,
            fontWeight: 900,
          })
          .add();
        chart.sideActLabel = chart.renderer
          .text(
            `${TargetLabels.ACTIVITIES} ${getTargetFunctionString(
              TargetType.ACTIVITIES
            )}`,
            chart.chartWidth - 140,
            chart.chartHeight - 50
          )
          .css({
            fontSize: "10px",
            color: TargetColors.ACTIVITIES,
            fontWeight: 400,
          })
          .add();
        // chart.sideActTargetMet = chart.renderer
        //   .text(`${getTargetMetIcon(TargetType.ACTIVITIES)}`, 175, 117)
        //   .css({
        //     fontSize: "10px",
        //     color: TargetColors.ACTIVITIES,
        //     fontWeight: 400,
        //   })
        //   .add();
        chart.sideActTargetMet = chart.renderer
          .image(
            getImageUrl(TargetType.ACTIVITIES),
            chart.chartWidth - 165,
            chart.chartHeight - 47,
            20,
            20
          )
          .css({
            // fontSize: "10px",
            color: TargetColors.ACTIVITIES,
            // fontWeight: 400,
          })
          .add();
        chart.sideActText = chart.renderer
          .text(
            getTargetValueString(TargetType.ACTIVITIES),
            chart.chartWidth - 140,
            chart.chartHeight - 30
          )
          .css({
            fontSize: "20px",
            color: TargetColors.ACTIVITIES,
            fontWeight: 900,
          })
          .add();
      },
    };

    setChartOptions({ ...chartOptions });
  }, [goalData]);

  const generateBackgroundList = (
    time: boolean,
    focus: boolean,
    activities: boolean
  ) => {
    const backgrounds: any[] = [];
    const timeBg = {
      // Tack Total TIme
      outerRadius: "112%",
      innerRadius: "88%",
      backgroundColor: time
        ? Highcharts.color(TargetColors.TIME).setOpacity(0.5).get()
        : Highcharts.color(TargetColors.TIME).setOpacity(0.1).get(),
      borderWidth: 0,
    };
    const focusBg = {
      // Track Focus Time
      outerRadius: "87%",
      innerRadius: "63%",
      backgroundColor: focus
        ? Highcharts.color(TargetColors.FOCUS).setOpacity(0.5).get()
        : Highcharts.color(TargetColors.FOCUS).setOpacity(0.1).get(),
      borderWidth: 0,
    };
    const activitesBg = {
      // Track activities
      outerRadius: "62%",
      innerRadius: "38%",
      backgroundColor: activities
        ? Highcharts.color(TargetColors.ACTIVITIES).setOpacity(0.5).get()
        : Highcharts.color(TargetColors.ACTIVITIES).setOpacity(0.1).get(),
      borderWidth: 0,
    };
    backgrounds.splice(TargetIndex.TIME, 0, timeBg);
    backgrounds.splice(TargetIndex.FOCUS, 0, focusBg);
    backgrounds.splice(TargetIndex.ACTIVITIES, 0, activitesBg);
    return backgrounds;
  };

  const generateYAxis = (
    timeLimit: number | undefined,
    focusLimit: number | undefined,
    activitiesLimit: number | undefined
  ) => {
    const yAxis: any[] = [];
    const timeYAxis = {
      min: 0,
      max: timeLimit ? timeLimit : 0,
      lineWidth: 0,
      tickPositions: [],
    };
    const focusYAxis = {
      min: 0,
      max: focusLimit ? focusLimit : 0,
      lineWidth: 0,
      tickPositions: [],
    };
    const activitiesYAxis = {
      min: 0,
      max: activitiesLimit ? activitiesLimit : 0,
      lineWidth: 0,
      tickPositions: [],
    };
    yAxis.splice(TargetIndex.TIME, 0, timeYAxis);
    yAxis.splice(TargetIndex.FOCUS, 0, focusYAxis);
    yAxis.splice(TargetIndex.ACTIVITIES, 0, activitiesYAxis);
    return yAxis;
  };

  const generateSeries = (
    timeVal: number | undefined,
    focusVal: number | undefined,
    activitiesVal: number | undefined
  ) => {
    const series: any[] = [];

    const timeSeries = {
      name: TargetLabels.TIME,
      yAxis: TargetIndex.TIME,
      data: [
        {
          color: timeVal
            ? Highcharts.color(TargetColors.TIME).setOpacity(1).get()
            : Highcharts.color(TargetColors.TIME).setOpacity(0).get(),
          radius: "112%",
          innerRadius: "88%",
          y: timeVal ? timeVal : 0,
        },
      ],
    };
    const focusSeries = {
      name: TargetLabels.FOCUS,
      yAxis: TargetIndex.FOCUS,
      data: [
        {
          color: focusVal
            ? Highcharts.color(TargetColors.FOCUS).setOpacity(1).get()
            : Highcharts.color(TargetColors.FOCUS).setOpacity(0).get(),
          radius: "87%",
          innerRadius: "63%",
          y: focusVal ? focusVal : 0,
        },
      ],
    };
    const activitiesSeries = {
      name: TargetLabels.ACTIVITIES,
      yAxis: TargetIndex.ACTIVITIES,
      data: [
        {
          color: activitiesVal
            ? Highcharts.color(TargetColors.ACTIVITIES).setOpacity(1).get()
            : Highcharts.color(TargetColors.ACTIVITIES).setOpacity(0).get(),
          radius: "62%",
          innerRadius: "38%",
          y: activitiesVal ? activitiesVal : 0,
        },
      ],
    };
    series.splice(TargetIndex.TIME, 0, timeSeries);
    series.splice(TargetIndex.FOCUS, 0, focusSeries);
    series.splice(TargetIndex.ACTIVITIES, 0, activitiesSeries);
    return series;
  };

  const generateRenderEvent = () => {};

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constrctor={"solidGauge"}
      options={chartOptions}
      allowChartUpdate={true}
      updateArgs={[true]}
    />
  );
};
