import { Box, Grid, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsPatternFill from "highcharts/modules/pattern-fill";
import { Options, SeriesOptionsType } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import ErrorBoundary from "../../../../../errorboundary.component";
import {
  DaySummaryDto,
  TimelineDto,
} from "../../../../../services/timeline/timeline.model";
import { appDummyData24 } from "./dummy-24h";
import { convertSecondsToHHMM } from "../../../../admin/dailyemail/dailyemail.service";
import { ApplicationData } from "./switch-heatmap.component";
import { DateTime } from "luxon";
import { TargetColors } from "../../goals-manager/components/goal-creator-card.component";
import { LEGEND_ITEMS, TimelineCardLegend } from "../timeline.component";

HighchartsPatternFill(Highcharts);

export const timelineChartColors = {
  Offline: {
    color: {
      pattern: {
        path: {
          d: "M0,0 L30,24",
          strokeWidth: 10,
          fill: "#F5F6F9",
        },
        color: "#AAAFBB",
        width: 35,
        height: 30,
        opacity: 0.25,
      },
    },
  },
  Online: {
    color: "#AAAFBBBB",
  },
  Focus: {
    color: "#5D626FBB",
  },
  Goal: {
    color: "#29b6f6",
  },
  NoGoal: {
    color: "#29b6f633",
  },
  App: { color: "" },
};
interface TimelineChartProps {
  chartData: TimelineDto[];
  title: string;
  summary: DaySummaryDto;
  today: boolean;
  minHour: number;
  maxHour: number;
  openDialog: (hour: number, today: boolean) => void;
  date: DateTime;
  showLegend: boolean;
}

export interface ChartDataEntry {
  value: number; //mins
  name: string;
  color: string;
}

export const TimelineChart = (props: TimelineChartProps): JSX.Element => {
  const [maxHour, setMaxHour] = useState<number>(props.maxHour);
  const [minHour, setMinHour] = useState<number>(props.minHour);

  const [chartOptions, setChartOptions] = useState<Options>({
    chart: {
      renderTo: "container",
      type: "bar",
      height: "70px",
      marginTop: 0,
      animation: false,
      // events: {
      //   click: function (e) {
      //     console.log(e);
      //   },
      // },
    },

    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: undefined,
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return `Click for more details`;
      },
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: true,
      tickInterval: 60 * 1,
      // min: minHour * 60,
      // max: maxHour * 60,
      title: {
        text: "",
      },
      labels: {
        style: {
          textOverflow: "hidden",
        },
        enabled: true,
        formatter: function () {
          //   console.log(this);
          const min = props.minHour * 60;
          const tickVal = this.tick.pos - min;
          const hide = tickVal % (60 * 4) != 0;
          // console.log(
          //   "Time:",
          //   props.minHour,
          //   min,
          //   this.tick.pos,
          //   tickVal,
          //   hide
          // );
          if (hide) {
            return "";
          }
          const val = this.pos / 60;
          let time = val > 12 ? val - 12 : val;
          let tod = val >= 12 && this.pos !== 24 ? "pm" : "am";
          if (val === 0 || val === 24) {
            time = 12;
            tod = "am";
          }
          return `${time}${tod}`;
        },
      },
    },

    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            opacity: 0.9,
          },
        },
        // point: {
        //   events: {
        //     click: function (event) {
        //       console.log("point", this);
        //       // props.openDialog(true);
        //     },
        //     //   click: this.handleClick,
        //   },
        // },
        events: {
          click: function (event) {
            // console.log(this.name);
            props.openDialog(parseInt(this.name) ?? 0, props.today);
          },
          //   click: this.handleClick,
        },
        stacking: "normal",
        dataLabels: {
          enabled: false,
          align: "right",
          y: -2,
          style: {
            color: "#050505",
            fontFamily: "Roboto, sans-serif",
            fontSize: "max(0.8vw,12px)",
            fontWeight: "light",
            boxShadow: "none",
            textOutline: "none",
            overflow: "ellipsis",
            position: "relative",
            top: "-6px",
          },
          //   formatter: function () {
          //     const self = this as any;
          //     const percent = self.percentage as number;
          //     if (percent < 15) {
          //       return "";
          //     }
          //     let minutes = this.y as number;
          //     const hours = Math.floor(minutes / 60);
          //     minutes = minutes % 60;
          //     const timeString =
          //       (hours != 0 ? hours + "h " : "") +
          //       (minutes != 0 ? minutes + "m " : "");
          //     return timeString;
          //   },
        },
      },
      bar: {
        grouping: false,
        pointWidth: 24,
        groupPadding: 0,
        // point: {
        //   events: {
        //     click: function (event) {
        //       console.log(this);
        //     },
        //   },
        // },
      },
    },
  });

  const [noDataOverlay, setNoDataOverlay] = useState<boolean>(false);

  useEffect(() => {
    if (props.chartData.length > 0) {
      const cdata: TimelineDto[] = JSON.parse(JSON.stringify(props.chartData));
      const series = cdata.reverse().map((data) => {
        return {
          data: [data.time.duration / 60],
          name: data.hour,
          color: timelineChartColors[data.status].color,
          cursor: `${data.status !== "Offline" ? "pointer" : "normal"}`,
          borderRadiusTopLeft: "",
          borderRadiusTopRight: "",
          borderRadiusBottomLeft: "",
          borderRadiusBottomRight: "",
        };
      });
      // if (series.length > 0) {
      //   series[0].borderRadiusTopLeft = "50%";
      //   series[0].borderRadiusTopRight = "50%";
      //   series[series.length - 1].borderRadiusBottomLeft = "50%";
      //   series[series.length - 1].borderRadiusBottomRight = "50%";
      // }
      chartOptions.series = series as unknown as SeriesOptionsType[];

      setChartOptions({ ...chartOptions });
    }
  }, [props.chartData]);

  useEffect(() => {
    setNoDataOverlay(!props.chartData || props.chartData.length == 0);
  }, [props.chartData]);

  useEffect(() => {
    const tempOptions = { ...chartOptions } as any;
    tempOptions.yAxis.min = props.minHour * 60;
    tempOptions.yAxis.max = props.maxHour * 60;
    tempOptions.yAxis.labels.formatter = function () {
      //   console.log(this);
      const min = props.minHour * 60;
      const tickVal = this.tick.pos - min;
      const hide = tickVal % (60 * 4) != 0;
      // console.log("NewTime:", props.minHour, min, this.tick.pos, tickVal, hide);
      if (hide) {
        return "";
      }
      const val = this.pos / 60;
      let time = val > 12 ? val - 12 : val;
      let tod = val >= 12 && this.pos !== 24 ? "pm" : "am";
      if (val === 0 || val === 24) {
        time = 12;
        tod = "am";
      }
      return `${time}${tod}`;
    };

    setChartOptions(tempOptions);
    // console.log("min hour ", props.minHour);
    // console.log("max hour ", props.maxHour);
  }, [props.minHour, props.maxHour]);

  return (
    <>
      {noDataOverlay ? (
        <>
          <Box
            borderRadius="8px"
            justifyContent="center"
            bgcolor="#FFFFFF"
            padding={"5px"}
            // paddingTop={"2px"}
            sx={{ marginTop: "0.3%" }}
          >
            <Typography variant="body1Bold">
              {props.date.toFormat("LLL dd, yyyy")}
            </Typography>
            <Box
              // margin="2% auto"
              borderRadius="8px"
              height="70px"
              textAlign="center"
              width="100%"
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              border="1px dashed #AAAFBB"
            >
              <Typography variant="h6Light" margin="auto" width="100%">
                No data available
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <ErrorBoundary>
          <Box
            borderRadius="8px"
            justifyContent="center"
            bgcolor="#FFFFFF"
            padding={"5px"}
            // paddingTop={"2px"}
            sx={{ marginTop: "0.3%" }}
          >
            {/* <Stack direction={"row"}> */}

            {/* <Typography variant="body1Bold">{props.title}</Typography> */}
            <Grid container sx={{ mb: 1 }}>
              <Grid item md={7}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1Bold">
                    {props.date.toFormat("LLL dd, yyyy")}
                  </Typography>
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    (
                    <span style={{ color: TargetColors.time }}>
                      Time: {convertSecondsToHHMM(props.summary.time)}
                    </span>
                    {" | "}
                    <span style={{ color: TargetColors.focus }}>
                      Focus: {convertSecondsToHHMM(props.summary.focus)}
                    </span>
                    {" | "}
                    <span style={{ color: TargetColors.activities }}>
                      Switch: {props.summary.activities})
                    </span>
                  </Typography>
                </Box>
              </Grid>
              {props.showLegend ? (
                <Grid md={5}>
                  <TimelineCardLegend legendItems={LEGEND_ITEMS} />
                </Grid>
              ) : (
                ""
              )}
            </Grid>

            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
              allowChartUpdate={true}
              updateArgs={[true, true, true]}
            />
            {/* </Stack> */}
          </Box>
        </ErrorBoundary>
      )}
    </>
  );
};

const getMergedData = () => {
  const SECONDS_OF_HOUR = 3600;
  const data: ApplicationData[] = appDummyData24;
  const startTime = 1661538600;
  const endTime = 1661624999;
  const newData: ChartDataEntry[] = [];
  if (data.length === 0) {
    return newData;
  }
  let startHour = 0;
  let endHour = 1;
  // let temp = data[0]
  // let temp = {
  //   value: 0,
  //   name: "",
  //   color: "",
  // }
  for (const act of data) {
    let temp = {
      value: 0,
      name: "",
      color: "",
    };
    if (act.offline) {
      temp = {
        value: act.time.duration / 60,
        name: "Offline",
        color: "#00000011",
      };
    } else {
      temp = {
        value: act.time.duration / 60,
        name: "Online",
        color: "#29b6f633",
      };
    }
    newData.push(temp);
  }

  return newData;
};
