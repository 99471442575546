import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import {
  CreateGoalDto,
  CustomTemplateOptions,
  QuantumType,
  TargetFunction,
  TargetType,
} from "../../../../../services/goals/goals.model";

import { LimitsErrorDialog } from "../../goal-templates/components/limit-error.modal";

export const TargetColors = {
  [TargetType.TIME]: "#B68CEA",
  [TargetType.FOCUS]: "#7ACCAA",
  [TargetType.ACTIVITIES]: "#F39A8C",
};

export const TargetTooltipText = {
  [TargetType.TIME]:
    "Must be equal to or more than Focus. Enter 0 to not track. Default is minimum.",
  [TargetType.FOCUS]:
    "Must be at least 20 min and less than or equal to Time. Enter 0 to not track. Default is minimum.",
  [TargetType.ACTIVITIES]: "Enter 0 to not track. Default is maximum.",
};

interface GoalCreatorCardProps {
  selectedOption: CustomTemplateOptions | undefined;
  createGoal: (goal: CreateGoalDto) => void;
}

export const GoalCreatorCard: React.FC<GoalCreatorCardProps> = (
  props: GoalCreatorCardProps
): JSX.Element => {
  const [enabled, setEnabled] = useState<boolean>(false);

  const [focusErrorDialog, setFocusErrorDialog] = useState<boolean>(false);
  const [timeErrorDialog, setTimeErrorDialog] = useState<boolean>(false);

  const [targets, setTargets] = useState({
    [TargetType.TIME]: {
      name: "Time",
      unit: QuantumType.TIME,
      value: 0,
      prevValue: 0,
      enabled: true,
      targetFunction: TargetFunction.MIN,
    },
    [TargetType.FOCUS]: {
      name: "Focus",
      unit: QuantumType.TIME,
      value: 0,
      prevValue: 0,
      enabled: false,
      targetFunction: TargetFunction.MIN,
    },
    [TargetType.ACTIVITIES]: {
      name: "Switch",
      unit: QuantumType.COUNT,
      value: 0,
      prevValue: 0,
      enabled: true,
      targetFunction: TargetFunction.MAX,
    },
  });

  useEffect(() => {
    setEnabled(props.selectedOption ? true : false);
    loadTargets();
  }, [props.selectedOption]);

  const loadTargets = () => {
    if (props.selectedOption) {
      Object.keys(props.selectedOption.targets).forEach((tg: string) => {
        const target = props.selectedOption?.targets[tg];
        if (target) {
          targets[target.target] = {
            ...targets[target.target],
            value: target.value,
            prevValue: target.value,
          };
        }
      });
      setTargets({ ...targets });
    }
  };

  const onTargetChange = (
    targetType: TargetType,
    value: number,
    targetFunction: TargetFunction
  ) => {
    targets[targetType].value = value;
    setTargets({ ...targets });
  };

  const onTargetFunctionChange = (
    targetType: TargetType,
    targetFunction: TargetFunction
  ) => {
    targets[targetType].targetFunction = targetFunction;
    setTargets({ ...targets });
  };

  const onSetGoalBtn = () => {
    if (enabled) {
      if (targets[TargetType.FOCUS].value > 0) {
        if (
          targets[TargetType.FOCUS].value > targets[TargetType.TIME].value &&
          targets[TargetType.TIME].enabled &&
          targets[TargetType.TIME].value > 0
        ) {
          setTimeErrorDialog(true);
          return;
        }
        if (targets[TargetType.FOCUS].value < 20) {
          setFocusErrorDialog(true);
          return;
        }
      }

      generateAndCreateGoal();
    }
  };

  const generateAndCreateGoal = () => {
    const selectedGoalName = props.selectedOption?.dimensionValue ?? "";

    const startTime = DateTime.now().startOf("day").toUnixInteger();
    const endTime = DateTime.now().endOf("day").toUnixInteger();

    const tgs = Object.keys(targets).map((tg) => {
      const t = tg as TargetType;
      return {
        type: t,
        quantum: targets[t].value,
        targetFunction: targets[t].targetFunction,
      };
    });
    const dims = [];
    if (props.selectedOption) {
      dims.push({
        type: props.selectedOption.dimensionType,
        value: props.selectedOption.dimensionValue,
      });
    }

    const goalDescription =
      "Custom goal set in " +
      dims.map((d) => `${d.value} ${d.type}`).join(", ") +
      " for " +
      tgs.map((t) => t.type).join(", ");

    const referenceTargets = Object.keys(targets).map((target: any) => {
      return {
        target: target,
        value: targets[target as TargetType].prevValue,
        targetFunction: targets[target as TargetType].targetFunction,
      };
    });
    const goal: CreateGoalDto = {
      startTime: startTime,
      endTime: endTime,
      name: `${selectedGoalName}`,
      description: goalDescription,
      userDefined: false,
      targets: tgs,
      dimensions: dims,
      referenceData: { targets: referenceTargets },
    };

    props.createGoal(goal);
  };

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        height: "100%",
        borderRadius: "8px",
        padding: "5px",
        position: "relative",
      }}
    >
      <LimitsErrorDialog
        open={focusErrorDialog}
        closeDialog={() => {
          setFocusErrorDialog(false);
        }}
        dialogType={"focus"}
      />
      <LimitsErrorDialog
        open={timeErrorDialog}
        closeDialog={() => {
          setTimeErrorDialog(false);
        }}
        dialogType={"time"}
      />
      <Box>
        <Typography variant="body1Bold">
          {enabled
            ? `${props.selectedOption?.dimensionValue} - Set Targets`
            : "Set Targets"}
        </Typography>
      </Box>
      {/* <Box sx={{ ml: 2 }}>
        <Typography variant="body2Bold">Programming</Typography>
      </Box> */}
      <Box sx={{ overflow: "hidden", overflowY: "auto" }}>
        {[TargetType.TIME, TargetType.FOCUS, TargetType.ACTIVITIES].map(
          (targetType: TargetType) => (
            <Box
              key={targetType}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  border: `5px solid ${TargetColors[targetType]}AA`,
                  borderRadius: "100%",
                  height: "20px",
                  width: "25px",
                }}
              ></Box>
              <TargetInputField
                name={targets[targetType].name}
                unit={targets[targetType].unit}
                prevValue={targets[targetType].prevValue}
                value={targets[targetType].value}
                enabled={enabled}
                onValueUpdate={(value, targetFunction) =>
                  onTargetChange(targetType, value, targetFunction)
                }
                onTargetFunctionUpdate={(targetFunction) =>
                  onTargetFunctionChange(targetType, targetFunction)
                }
                onlyDisplay={false}
                outlineColor={TargetColors[targetType]}
                tooltipText={TargetTooltipText[targetType]}
                targetFunction={targets[targetType].targetFunction}
              />
            </Box>
          )
        )}
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 16,
          right: 16,
        }}
      >
        <Button variant="contained" onClick={onSetGoalBtn}>
          Set Goal
        </Button>
      </Box>
    </Box>
  );
};

interface TargetInputFieldProps {
  unit: QuantumType;
  name: string;
  prevValue: number;
  subText?: string;
  value: number;
  enabled: boolean;
  onlyDisplay: boolean;
  onValueUpdate: (value: number, targetFunction: TargetFunction) => void;
  onTargetFunctionUpdate: (targetFunction: TargetFunction) => void;
  outlineColor: string;
  tooltipText: string;
  targetFunction: TargetFunction;
}

const TargetInputField = (props: TargetInputFieldProps): JSX.Element => {
  const [minMaxTarget, setMinMaxTarget] = useState<TargetFunction>(
    props.targetFunction
  );

  const [switchToggle, setSwitchToggle] = useState<boolean>(false);

  const [targetValue, setTargetValue] = useState<number>(0);

  useEffect(() => {
    let tg = props.value;
    if (props.unit === QuantumType.TIME) {
      tg = Math.floor(tg / 60);
    }
    setTargetValue(tg);
  }, [props.value]);

  const onValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let val: string | number = event.target.value;
    if (val === "" || val === null || val === undefined) {
      val = 0;
    } else {
      val = parseInt(val);
    }
    if (!isNaN(val)) {
      if (props.unit === QuantumType.TIME) {
        val = val * 60;
      }
      props.onValueUpdate(val, minMaxTarget);
    }
  };

  const disabledColor = `${props.outlineColor}77`;

  const handleMinMax = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setMinMaxTarget(checked ? TargetFunction.MAX : TargetFunction.MIN);
  };

  useEffect(() => {
    setMinMaxTarget(props.targetFunction);
  }, [props.targetFunction]);

  useEffect(() => {
    if (props.targetFunction !== minMaxTarget) {
      props.onTargetFunctionUpdate(minMaxTarget);
    }
    setSwitchToggle(minMaxTarget === TargetFunction.MAX);
  }, [minMaxTarget]);

  return (
    <Box>
      <FormControl
        sx={{
          m: 1,
          "& .MuiInputLabel-root": {
            color: `${props.outlineColor} !important`,
          },
          "& .MuiOutlinedInput-root": {
            "& > fieldset": { borderColor: `${props.outlineColor} !important` },
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": { borderColor: `${props.outlineColor}` },
          },
          "& .MuiOutlinedInput-root.Mui-disabled": {
            color: `${props.outlineColor}`,
            "& > fieldset": { borderColor: `${disabledColor} !important` },
          },
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: `${disabledColor}`,
          },
          "& .MuiInputLabel-root.Mui-disabled": {
            WebkitTextFillColor: `${disabledColor}`,
          },
        }}
        variant="outlined"
      >
        <Tooltip title={props.tooltipText}>
          <TextField
            type="text"
            disabled={!props.enabled || props.onlyDisplay}
            value={props.enabled ? targetValue : "-"}
            label={props.name}
            size="small"
            variant="outlined"
            onChange={onValueChange}
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FormControlLabel
                    value="top"
                    sx={{
                      width: "2ch",
                      margin: 0,
                    }}
                    // {...props.enabled ? disabled : enabled}
                    disabled={!props.enabled || props.onlyDisplay}
                    control={
                      <Switch
                        color={"default"}
                        onChange={handleMinMax}
                        size="small"
                        checked={switchToggle}
                        sx={
                          {
                            // transform: "rotate(-90deg)",
                          }
                        }
                      />
                    }
                    label={
                      <Typography variant="caption">{minMaxTarget}</Typography>
                    }
                    labelPlacement="top"
                  />
                </InputAdornment>
              ),
            }}
            helperText={props.subText}
            FormHelperTextProps={{
              style: {
                color: `${
                  !props.enabled || props.onlyDisplay
                    ? `${disabledColor}`
                    : props.outlineColor
                }`,
              },
            }}
            sx={{
              input: {
                color: `${props.outlineColor}`,
              },
            }}
          />
        </Tooltip>
      </FormControl>
    </Box>
  );
};
