import { TimelineDto } from "../../../../services/timeline/timeline.model";

export const appusagedialogdummyData: any[] = [
  {
    time: { startTime: 1661970600, endTime: 1661974200, duration: 3600 },
    hour: 1,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1661974200, endTime: 1661977800, duration: 3600 },
    hour: 2,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1661977800, endTime: 1661981400, duration: 3600 },
    hour: 3,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1661981400, endTime: 1661985000, duration: 3600 },
    hour: 4,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1661985000, endTime: 1661987391, duration: 2391 },
    hour: 5,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1661987391, endTime: 1661988187, duration: 796 },
    hour: 5,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1661988187, endTime: 1661988188, duration: 1 },
    hour: 5,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1661988188, endTime: 1661988368, duration: 180 },
    hour: 5,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1661988368, endTime: 1661988600, duration: 232 },
    hour: 5,
    offline: false,
    focus: true,
    status: "Focus",
  },
  {
    time: { startTime: 1661988600, endTime: 1661990040, duration: 1440 },
    hour: 6,
    offline: false,
    focus: true,
    status: "Focus",
  },
  {
    time: { startTime: 1661990040, endTime: 1661990117, duration: 77 },
    hour: 6,
    offline: true,
    focus: true,
    status: "Offline",
  },
  {
    time: { startTime: 1661990117, endTime: 1661990472, duration: 355 },
    hour: 6,
    offline: false,
    focus: true,
    status: "Focus",
  },
  {
    time: { startTime: 1661990472, endTime: 1661990473, duration: 1 },
    hour: 6,
    offline: true,
    focus: true,
    status: "Offline",
  },
  {
    time: { startTime: 1661990473, endTime: 1661992200, duration: 1727 },
    hour: 6,
    offline: false,
    focus: true,
    status: "Focus",
  },
  {
    time: { startTime: 1661992200, endTime: 1661992209, duration: 9 },
    hour: 7,
    offline: false,
    focus: true,
    status: "Focus",
  },
  {
    time: { startTime: 1661992209, endTime: 1661992210, duration: 1 },
    hour: 7,
    offline: true,
    focus: true,
    status: "Offline",
  },
  {
    time: { startTime: 1661992210, endTime: 1661992465, duration: 255 },
    hour: 7,
    offline: false,
    focus: true,
    status: "App",
  },
  {
    time: { startTime: 1661992465, endTime: 1661993461, duration: 996 },
    hour: 7,
    offline: false,
    focus: false,
    status: "App",
  },
  {
    time: { startTime: 1661993461, endTime: 1661993462, duration: 1 },
    hour: 7,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1661993462, endTime: 1661994092, duration: 630 },
    hour: 7,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1661994092, endTime: 1661994093, duration: 1 },
    hour: 7,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1661994093, endTime: 1661994863, duration: 770 },
    hour: 7,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1661994863, endTime: 1661994864, duration: 1 },
    hour: 7,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1661994864, endTime: 1661995054, duration: 190 },
    hour: 7,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1661995054, endTime: 1661995800, duration: 746 },
    hour: 7,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1661995800, endTime: 1661998286, duration: 2486 },
    hour: 8,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1661998286, endTime: 1661998636, duration: 350 },
    hour: 8,
    offline: false,
    focus: false,
    status: "App",
  },
  {
    time: { startTime: 1661998636, endTime: 1661999400, duration: 764 },
    hour: 8,
    offline: true,
    focus: false,
    status: "App",
  },
  {
    time: { startTime: 1661999400, endTime: 1662003000, duration: 3600 },
    hour: 9,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662003000, endTime: 1662006600, duration: 3600 },
    hour: 10,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662006600, endTime: 1662008361, duration: 1761 },
    hour: 11,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662008361, endTime: 1662009609, duration: 1248 },
    hour: 11,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662009609, endTime: 1662009610, duration: 1 },
    hour: 11,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662009610, endTime: 1662010200, duration: 590 },
    hour: 11,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662010200, endTime: 1662013419, duration: 3219 },
    hour: 12,
    offline: false,
    focus: false,
    status: "App",
  },
  {
    time: { startTime: 1662013419, endTime: 1662013420, duration: 1 },
    hour: 12,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662013420, endTime: 1662013800, duration: 380 },
    hour: 12,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662013800, endTime: 1662017400, duration: 3600 },
    hour: 13,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662017400, endTime: 1662020881, duration: 3481 },
    hour: 14,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662020881, endTime: 1662021000, duration: 119 },
    hour: 14,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662021000, endTime: 1662023940, duration: 2940 },
    hour: 15,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662023940, endTime: 1662024600, duration: 660 },
    hour: 15,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662024600, endTime: 1662024946, duration: 346 },
    hour: 16,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662024946, endTime: 1662024947, duration: 1 },
    hour: 16,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662024947, endTime: 1662025747, duration: 800 },
    hour: 16,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662025747, endTime: 1662025748, duration: 1 },
    hour: 16,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662025748, endTime: 1662027955, duration: 2207 },
    hour: 16,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662027955, endTime: 1662027956, duration: 1 },
    hour: 16,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662027956, endTime: 1662028200, duration: 244 },
    hour: 16,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662028200, endTime: 1662029850, duration: 1650 },
    hour: 17,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662029850, endTime: 1662029851, duration: 1 },
    hour: 17,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662029851, endTime: 1662030466, duration: 615 },
    hour: 17,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662030466, endTime: 1662030481, duration: 15 },
    hour: 17,
    offline: false,
    focus: true,
    status: "Focus",
  },
  {
    time: { startTime: 1662030481, endTime: 1662030482, duration: 1 },
    hour: 17,
    offline: true,
    focus: true,
    status: "Offline",
  },
  {
    time: { startTime: 1662030482, endTime: 1662031800, duration: 1318 },
    hour: 17,
    offline: false,
    focus: true,
    status: "Focus",
  },
  {
    time: { startTime: 1662031800, endTime: 1662031808, duration: 8 },
    hour: 18,
    offline: false,
    focus: true,
    status: "Focus",
  },
  {
    time: { startTime: 1662031808, endTime: 1662033495, duration: 1687 },
    hour: 18,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662033495, endTime: 1662033496, duration: 1 },
    hour: 18,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662033496, endTime: 1662033596, duration: 100 },
    hour: 18,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662033596, endTime: 1662035400, duration: 1804 },
    hour: 18,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662035400, endTime: 1662039000, duration: 3600 },
    hour: 19,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662039000, endTime: 1662042600, duration: 3600 },
    hour: 20,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662042600, endTime: 1662046200, duration: 3600 },
    hour: 21,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662046200, endTime: 1662046681, duration: 481 },
    hour: 22,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662046681, endTime: 1662048239, duration: 1558 },
    hour: 22,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662048239, endTime: 1662048240, duration: 1 },
    hour: 22,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662048240, endTime: 1662049025, duration: 785 },
    hour: 22,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662049025, endTime: 1662049026, duration: 1 },
    hour: 22,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662049026, endTime: 1662049736, duration: 710 },
    hour: 22,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662049736, endTime: 1662049737, duration: 1 },
    hour: 22,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662049737, endTime: 1662049800, duration: 63 },
    hour: 22,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662049800, endTime: 1662050422, duration: 622 },
    hour: 23,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662050422, endTime: 1662050423, duration: 1 },
    hour: 23,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662050423, endTime: 1662051113, duration: 690 },
    hour: 23,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662051113, endTime: 1662051114, duration: 1 },
    hour: 23,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662051114, endTime: 1662051819, duration: 705 },
    hour: 23,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662051819, endTime: 1662051820, duration: 1 },
    hour: 23,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662051820, endTime: 1662053371, duration: 1551 },
    hour: 23,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662053371, endTime: 1662053400, duration: 29 },
    hour: 23,
    offline: false,
    focus: true,
    status: "Focus",
  },
  {
    time: { startTime: 1662053400, endTime: 1662053797, duration: 397 },
    hour: 24,
    offline: false,
    focus: true,
    status: "Focus",
  },
  {
    time: { startTime: 1662053797, endTime: 1662054147, duration: 1050 },
    hour: 24,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662054147, endTime: 1662054148, duration: 3 },
    hour: 24,
    offline: true,
    focus: false,
    status: "Offline",
  },
  {
    time: { startTime: 1662054148, endTime: 1662054857, duration: 2127 },
    hour: 24,
    offline: false,
    focus: false,
    status: "Online",
  },
  {
    time: { startTime: 1662054857, endTime: 1662057000, duration: 6429 },
    hour: 24,
    offline: true,
    focus: false,
    status: "Offline",
  },
];
