import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { LogoLoader } from "../../loader/loader.component";
import { SettingsContent } from "../settings.component";
import { SettingsPage } from "../settingspage.component";
import {
  CustomCategoryDialogTemplateTypes,
  CustomCategoryDialog,
} from "./components/custom-category-dialog.component";
import { ManageCustomCategory } from "./components/custom-category-table.component";
import { SaveUpdateCategoryDto } from "./models/custom-category.model";
import { getCustomCategories } from "./util/custom-category.service";

interface CustomCategroyProps {}

export const CustomCategory = (props: CustomCategroyProps) => {
  const [customCategories, setCustomCategories] = useState<
    SaveUpdateCategoryDto[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [openCreateCustomCategoryPopup, setOpenCreateCustomCategoryPopup] =
    useState(false);

  useEffect(() => {
    fetchCustomCategories();
  }, []);

  const handleClickOpen = () => {
    setOpenCreateCustomCategoryPopup(true);
  };

  const handlePopupClose = async () => {
    await fetchCustomCategories();
    setOpenCreateCustomCategoryPopup(false);
  };

  const fetchCustomCategories = async () => {
    setLoading(true);
    const value = await getCustomCategories();
    setCustomCategories(value);
    setLoading(false);
  };

  return (
    <SettingsPage
      title={SettingsContent.customCategory.title}
      subtitle={SettingsContent.customCategory.description}
      loading={loading}
    >
      <Stack spacing={3}>
        <LogoLoader loading={loading} />
        <CustomCategoryDialog
          // selectedValue={""}
          open={openCreateCustomCategoryPopup}
          onClose={handlePopupClose}
          template={CustomCategoryDialogTemplateTypes.create}
        />
        <ManageCustomCategory customCategories={customCategories} />
        <Box
          sx={{
            width: "15%",
          }}
        >
          <Button variant="outlined" onClick={handleClickOpen}>
            Add new custom category
          </Button>
        </Box>
        <div
          style={{
            height: "1px",
            borderTop: "1px dashed #D6DAE480",
            margin: "4% 0",
          }}
        />
      </Stack>
    </SettingsPage>
  );
};
