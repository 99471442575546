import { ScoringType } from "../../../services/scores/scores.model";

export const ScoresDummyData = [
  {
    id: 0,
    accountId: "",
    dayTimestamp: 0,
    startTime: 0,
    endTime: 0,
    scoringType: ScoringType.FOCUS_TIME,
    maxScore: 500,
    minScore: 0,
    score: 250,
    referenceData: {
      data: [],
    },
  },
  {
    id: 0,
    accountId: "",
    dayTimestamp: 0,
    startTime: 0,
    endTime: 0,
    scoringType: ScoringType.GOALS,
    maxScore: 500,
    minScore: 0,
    score: 350,
    referenceData: {
      data: [],
    },
  },
  {
    id: 0,
    accountId: "",
    dayTimestamp: 0,
    startTime: 0,
    endTime: 0,
    scoringType: ScoringType.HABITS,
    maxScore: 1300,
    minScore: 0,
    score: 500,
    referenceData: {
      data: [],
    },
  },
];
