import { CognitoUserSession } from "amazon-cognito-identity-js";
import { getSession } from "../../../accountcontext/account";

const BASE_URL = "/api/usage";

export interface ErrorItem {
  id: number;
  accountId: number;
  error: string;
  timestamp: number;
}

export const fetchErrorLog = async (): Promise<{
  errors: Array<ErrorItem>;
}> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const url = `${BASE_URL}/admin/errors`;
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      })
      .catch((err) => reject(err));
  });
};
