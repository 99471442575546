import { Box, Container, ThemeProvider } from "@mui/material";
import "../../onboarding/components/onboarding.styles.css";
import theme from "../../../theme/theme";
import {
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import { AccountContext } from "../../../accountcontext/account";
import { Login } from "../login/login.component";
// import SignUp from "../../onboarding/components/signup/signup.component";
import { ChangePassword } from "../changepassword/changepassword.component";
import { useNavigate } from "react-router-dom";
import { ForgotPassword } from "../forgotpassword/forgotpassword.component";
import { ConfirmPassword } from "../confirmpassword/confirmpassword.component";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { BoxLoader } from "../../../components/loader/boxloader.component";
import { isValidEmail } from "../../onboarding/Authentication/Regex";

interface FormContainerProps {}

const FormContainer = (props: FormContainerProps) => {
  const {
    authenticate,
    changePassword,
    getSession,
    forgotPassword,
    confirmPassword,
  } = useContext(AccountContext);
  const [currentForm, setCurrentForm] = useState("login");
  const [credentials, setCredentials] = useState<{
    email: string | null;
    password: string | null;
  }>({
    email: "",
    password: "",
  });

  // const [oldCredentials, setoldCredentials] = useState({
  //   email: "",
  //   password: "",
  // });
  const [newPassword, setnewPassword] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [showErrorMessage, setshowErrorMessage] = useState(false);
  const [emailForPassReset, setemailForPassReset] = useState("");
  const [loading, setloading] = useState(false);
  const firstPassword = useRef(true);
  let navigate = useNavigate();

  const handleError = (err: any) => {
    setloading(false);
    if (err === "changePassword") {
      setCurrentForm("changepassword");
    } else {
      seterrorMessage(err.message || JSON.stringify(err));
      setshowErrorMessage(true);
    }
  };

  const loginFlow = (session: CognitoUserSession) => {
    const id = session.getIdToken().decodePayload().sub;
    const email = session.getIdToken().decodePayload().email;
    localStorage.setItem("id", id);
    localStorage.setItem("email", email);
    validateSessionAndRedirect();
  };

  const validateSessionAndRedirect = () => {
    setloading(true);
    getSession()
      .then((session: CognitoUserSession) => {
        setloading(false);
        if (session) {
          navigate("/app-loading");
        }
      })
      .catch((err) => {
        setloading(false);
      });
  };

  useEffect(() => {
    validateSessionAndRedirect();
  }, []);

  useEffect(() => {
    seterrorMessage("");
    setshowErrorMessage(false);
  }, [currentForm]);

  useLayoutEffect(() => {
    if (firstPassword.current) {
      firstPassword.current = false;
    } else {
      changePassword(
        credentials.email ?? "",
        credentials.password ?? "",
        newPassword
      )
        .then(loginFlow)
        .catch(handleError);
    }
  }, [newPassword]);

  const initLogin = () => {
    setloading(true);
    if (credentials.email && credentials.password) {
      authenticate(credentials.email, credentials.password)
        .then((session) => {
          setloading(false);
          return session;
        })
        .then(loginFlow)
        .catch(handleError);
    } else {
      seterrorMessage("Please enter your email and password");
      setshowErrorMessage(true);
      setloading(false);
    }
  };

  const handlePasswordChange = (newPassword: string) => {
    setnewPassword(newPassword);
  };

  const returnToLogin = () => {
    setCurrentForm("login");
  };

  const isCredentialsEmailValid = () => {
    if (
      !credentials.email ||
      credentials.email.length === 0 ||
      !isValidEmail(credentials.email)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const sendVerificationCode = () => {
    setloading(true);
    if (isCredentialsEmailValid()) {
      forgotPassword(credentials.email ?? "")
        .then((result) => {
          setloading(false);
          setCurrentForm("confirmpassword");
          setemailForPassReset(credentials.email ?? "");
        })
        .catch((err) => {
          setloading(false);
          seterrorMessage(err.message || JSON.stringify(err));
          setshowErrorMessage(true);
        });
    } else {
      setloading(false);
      seterrorMessage("Please enter a valid email");
      setshowErrorMessage(true);
    }
  };

  const confirmPasswordChange = (
    verificationCode: string,
    newPassword: string
  ) => {
    setloading(true);
    confirmPassword(emailForPassReset, verificationCode, newPassword)
      .then((result) => {
        setloading(false);
        setCurrentForm("login");
      })
      .catch((err) => {
        setloading(false);
        seterrorMessage(err.message || JSON.stringify(err));
        setshowErrorMessage(true);
      });
  };

  const onForgetPassword = () => {
    localStorage.setItem("email", credentials.email ?? "");
    setCurrentForm("forgotpassword");
  };

  const onEmailChange = (email: string | null) => {
    setCredentials({ ...credentials, email: email });
  };

  const onPasswordChange = (password: string | null) => {
    setCredentials({ ...credentials, password: password });
  };

  const getForm = () => {
    if (currentForm === "login") {
      return (
        <Login
          errorMessage={errorMessage}
          showErrorMessage={showErrorMessage}
          credentials={credentials}
          onEmailChange={onEmailChange}
          onPasswordChange={onPasswordChange}
          initLogin={initLogin}
          onForgetPassword={onForgetPassword}
        />
      );
      // } else if (currentForm === "signup") {
      //   return <SignUp />;
    } else if (currentForm === "changepassword") {
      return (
        <ChangePassword
          errorMessage={errorMessage}
          showErrorMessage={showErrorMessage}
          onSubmit={handlePasswordChange}
        />
      );
      // return <ChangePassword oldPassword={password} onSubmit={handlePasswordChange}  />
    } else if (currentForm === "forgotpassword") {
      return (
        <ForgotPassword
          credentials={credentials}
          onEmailChange={onEmailChange}
          errorMessage={errorMessage}
          showErrorMessage={showErrorMessage}
          onSubmit={sendVerificationCode}
          onClose={returnToLogin}
        />
      );
    } else if (currentForm === "confirmpassword") {
      return (
        <ConfirmPassword
          errorMessage={errorMessage}
          showErrorMessage={showErrorMessage}
          onSubmit={confirmPasswordChange}
          onClose={returnToLogin}
        />
      );
    } else {
      return <Box>An Error occured</Box>;
    }
  };

  return (
    <>
      <BoxLoader loading={loading} />
      <ThemeProvider theme={theme}>
        <Container maxWidth="md" fixed sx={{ padding: 5 }}>
          {getForm()}
        </Container>
      </ThemeProvider>
    </>
  );
};

export default FormContainer;
