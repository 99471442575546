import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { NotificationType } from "./model/notifications.model";

interface NotificationComponentProps {
  notification: NotificationType;
  onActionClick: (link: string, id: number) => void;
  onDismiss: (id: number) => void;
  isAll?: boolean;
}

const NotificationComponent: React.FunctionComponent<
  NotificationComponentProps
> = (props: NotificationComponentProps) => {
  const [notification, setnotification] = useState({ ...props.notification });
  const { id, title, action, status, message } = notification;
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignContent={"center"}
      width="100%"
      sx={{
        backgroundColor: status !== "UNREAD" ? "#F3F5F6" : "white",
        borderRadius: "2px",
        padding: "4%",
        borderBottom: "1px solid lightgray",
      }}
    >
      <Box display="block">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          alignItems="flex-start"
        >
          <Typography
            variant={status !== "UNREAD" ? "body1" : "body1Bold"}
            noWrap={false}
          >
            {title}
          </Typography>
          {props.isAll ? "": (
            <IconButton onClick={() => props.onDismiss(id)} sx={{ padding: 0 }}>
              <Close />
            </IconButton>
          )}
        </Box>
        <Typography
          variant={status !== "UNREAD" ? "body2" : "body2"}
          noWrap={false}
        >
          {message}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" marginTop="2%">
        {/* {actions
          ? actions.map((action, index) => (
              <Button
                key={index}
                variant="whiteButton"
                onClick = {()=> props.onActionClick(action.link, id)}
              >
                {action.title}
              </Button>
            ))
          : ""} */}
        {action ? (
          <Button
            variant="outlined"
            onClick={() => props.onActionClick(action.url, id)}
          >
            {action.name}
          </Button>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default NotificationComponent;
