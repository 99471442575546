import { Box, Stack, Typography } from "@mui/material";
import Highcharts from "highcharts";
import { Options, SeriesOptionsType } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { json } from "stream/consumers";
import ErrorBoundary from "../../../../../errorboundary.component";
import {
  DetailedTimelineDto,
  NormalizedDataSource,
} from "../../../../../services/timeline/timeline.model";
import { convertSecondsToHHMM } from "../../../../admin/dailyemail/dailyemail.service";
import { getNewCategoryColor } from "../../../../dashboard/utils/dashboard.utils";
import { TemplateEntry } from "../../goal-templates/template-entry/mytiro-template.component";
import { appDummyData } from "./dummy-data";
import { ApplicationData } from "./switch-heatmap.component";
import { timelineChartColors } from "./timeline-chart.component";

interface ApplicationViewChartProps {
  //   chartData: ChartDataEntry[];
  //   title: string;
  timelineData: DetailedTimelineDto[];
  timelineHour: number;
}

export interface ChartDataEntry {
  value: number; //mins
  name: string;
  color: string;
}

export const ApplicationViewChart = (
  props: ApplicationViewChartProps
): JSX.Element => {
  const [time, setTime] = useState<number>(props.timelineHour - 1);
  const [chartOptions, setChartOptions] = useState<Options>({
    chart: {
      renderTo: "container",
      type: "bar",
      height: "70px",
      marginTop: 0,
      animation: false,
      events: {
        click: function (e) {
          console.log(e);
        },
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: undefined,
    },
    tooltip: {
      enabled: true,
      // useHTML: true,
      formatter: function () {
        // console.log(this); // just to see , what data you can access
        // <span style="color:${this.color}>\u25CF ${this.color}</span>
        return `${this.series.name}: ${convertSecondsToHHMM(this.y)}`;
      },
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: true,
      // crosshair: false,
      //   endOnTick: false,
      max: 3600,
      tickInterval: 1800,
      title: {
        text: "",
      },
      labels: {
        enabled: true,
        formatter: function () {
          const mins = Math.floor(this.pos / 60);
          let tempTime = this.tick.isLast ? time + 1 : time;
          let t = tempTime > 12 ? tempTime - 12 : tempTime;
          let tod = tempTime >= 12 && this.pos !== 24 ? "pm" : "am";
          if (tempTime === 0 || time === 24) {
            t = 12;
            tod = "am";
          }
          return `${t}:${mins % 60 === 0 ? `00` : mins}${tod}`;
        },
        // formatter: function () {
        //   //   console.log(this);
        //   // let tod = time > 12 ? time - 12 : time;
        //   // let ampm = time >= 12 ? "pm" : "am";
        //   // if (time === 0 || time + 1 === 24) {
        //   //   tod = 12;
        //   //   ampm = "am";
        //   // }
        //   return `${this.tick.isLast ? time + 1 : time}:${
        //     (this.pos / 60) % 60 === 0 ? `00` : this.pos / 60
        //   }`;
        //   // return `${this.tick.isLast ? time + 1 : time}`
        // },
      },
    },

    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            opacity: 0.9,
          },
        },
        events: {
          //   click: this.handleClick,
        },
        stacking: "normal",
        dataLabels: {
          enabled: false,
          align: "right",
          y: -2,
          style: {
            color: "#050505",
            fontFamily: "Roboto, sans-serif",
            fontSize: "max(0.8vw,12px)",
            fontWeight: "light",
            boxShadow: "none",
            textOutline: "none",
            overflow: "ellipsis",
            position: "relative",
            top: "-6px",
          },
          //   formatter: function () {
          //     const self = this as any;
          //     const percent = self.percentage as number;
          //     if (percent < 15) {
          //       return "";
          //     }
          //     let minutes = this.y as number;
          //     const hours = Math.floor(minutes / 60);
          //     minutes = minutes % 60;
          //     const timeString =
          //       (hours != 0 ? hours + "h " : "") +
          //       (minutes != 0 ? minutes + "m " : "");
          //     return timeString;
          //   },
        },
      },
      bar: {
        grouping: false,
        pointWidth: 24,
        groupPadding: 0,
      },
    },
  });

  const [noDataOverlay, setNoDataOverlay] = useState<boolean>(false);

  useEffect(() => {
    if (props.timelineData.length > 0) {
      const cdata = JSON.parse(JSON.stringify(props.timelineData));
      const newData = mergeData(cdata);
      const series = newData.reverse().map((data) => {
        return {
          data: [data.duration],
          name:
            (data.subapplication ?? data.application ?? "Offline") +
            (data.focusSession && !data.focusBreak ? " (Focus)" : ""),
          color: getCategoryColor(data),
          borderRadiusTopLeft: "",
          borderRadiusTopRight: "",
          borderRadiusBottomLeft: "",
          borderRadiusBottomRight: "",
        };
      });
      // if (series.length > 0) {
      //   series[0].borderRadiusTopLeft = "50%";
      //   series[0].borderRadiusTopRight = "50%";
      //   series[series.length - 1].borderRadiusBottomLeft = "50%";
      //   series[series.length - 1].borderRadiusBottomRight = "50%";
      // }
      chartOptions.series = series as unknown as SeriesOptionsType[];

      setChartOptions({ ...chartOptions });
    }
  }, [props.timelineData]);

  useEffect(() => {
    setNoDataOverlay(!props.timelineData || props.timelineData.length == 0);
  }, [props.timelineData]);

  return (
    <>
      {noDataOverlay ? (
        <Box
          margin="2% auto"
          borderRadius="8px"
          height="70px"
          textAlign="center"
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          border="1px dashed #AAAFBB"
        >
          <Typography variant="h6Light" margin="auto" width="100%">
            No data available
          </Typography>
        </Box>
      ) : (
        <ErrorBoundary>
          <Box
            borderRadius="8px"
            justifyContent="center"
            bgcolor="#FFFFFF"
            padding={"5px"}
            sx={{ marginTop: 2 }}
          >
            {/* <Stack direction={"row"}> */}
            <Typography variant="body1Bold">{"Application View"}</Typography>
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
              allowChartUpdate={true}
              updateArgs={[true, true, true]}
            />
            {/* </Stack> */}
          </Box>
        </ErrorBoundary>
      )}
    </>
  );
};

const getCategoryColor = (data: DetailedTimelineDto) => {
  const color = getNewCategoryColor(data.category);
  if (data.offline) {
    return timelineChartColors.Offline.color;
  }
  return data.focusSession && !data.focusBreak ? color : color + "99";
};

const mergeData = (data: DetailedTimelineDto[]) => {
  const newData: DetailedTimelineDto[] = [];
  if (data.length === 0) {
    return newData;
  }
  let temp: DetailedTimelineDto = data[0];
  for (let i = 1; i < data.length; i++) {
    const tempName = temp.subapplication ?? temp.application;
    const name = data[i].subapplication ?? data[i].application;
    const focusSessionMatch =
      temp.focusSession &&
      data[i].focusSession &&
      temp.focusSession?.id === data[i].focusSession?.id;
    const enterTooSmall = data[i].duration < 10;
    if (tempName === name || focusSessionMatch || enterTooSmall) {
      temp.duration += data[i].duration;
    } else {
      newData.push(temp);
      temp = data[i];
    }
  }
  if (temp) {
    newData.push(temp);
  }
  return newData;
};

// const mergeData = (data: ApplicationData[]) => {
//   const newData: ApplicationData[] = [];
//   if (data.length === 0) {
//     return newData;
//   }
//   let temp: ApplicationData = data[0];
//   for (let i = 1; i < data.length; i++) {
//     if (temp.name === data[i].name || data[i].time.duration <= 5) {
//       temp.time.duration += data[i].time.duration;
//     } else {
//       newData.push(temp);
//       temp = data[i];
//     }
//   }
//   newData.push(temp);
//   return newData;
// };

const dummyData: ApplicationData[] = appDummyData;
