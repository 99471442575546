import { Box, Dialog, DialogContent, IconButton, Tab, Tabs } from "@mui/material";
import * as React from "react";
import ErrorBoundary from "../../errorboundary.component";
import RawDataCard from "../dashboard/goals/goalcard/goaltabs/rawdata/rawdatacard.component";
import { RawData } from "../dashboard/goals/model/goals.model";
import InsightCard from "../dashboard/insights/insightcard.component";
import { Insight } from "../dashboard/insights/insights.model";
import dummyData from '../../insights_dummy.json' 

interface InfoDialogProps {
  open: boolean;
  insights: Insight[];
  rawdata: RawData[];
  onClose: (event: any, reason: string) => void;
}

interface InfoDialogState {
  tabState: string;
  insights: Insight[];
  rawdata: RawData[];
}

class InfoDialog extends React.Component<InfoDialogProps, InfoDialogState> {
  constructor(props: InfoDialogProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      tabState: "insights",
      //insights: props.insights,
      insights: dummyData.insights,
      //rawdata: props.rawdata,
      rawdata: dummyData.rawData,
    };
  }

  handleChange = (event: any, value: string) =>
    this.setState({
      tabState: value,
    });

  printInsights() {
    let result = [];
    for (let i = 0; i < this.state.insights?.length; i++) {
      result.push(
        <InsightCard
          key={i}
          insight={this.state.insights[i]}
          isExpandable={true}
          isOpen={i===0}
        />
      );
    }
    return result;
  }

  printRawData() {
    let result: any = [];
    this.state.rawdata
      .sort((a, b) => b.duration - a.duration)
      .slice(0, 10)
      .forEach((rawdata, index) => {
        result.push(
          <RawDataCard
            rawdata={rawdata}
            key={rawdata.start}
            isExpandable={true}
            isOpen={index===0}
          />
        );
      });
    return result;
  }

  render() {
    const { open, onClose } = this.props;
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent sx={{ height: "70vh", width: "100vw", maxWidth: '568px', borderRadius: '12px', padding: '5%'}}>
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Tabs onChange={this.handleChange} value={this.state.tabState} TabIndicatorProps={{color: 'black'}} sx={{padding: 0, height: '1.6vw', minHeight: '24px', width: '80%'}}>
                <Tab value="insights" label="Insights" aria-label="insights tab" sx={{marginRight: '8%'}}/>
                <Tab value="rawdata" label="Raw data" aria-label="raw data tab" />
              </Tabs>
              <IconButton onClick={(event: any)=>onClose(event, "Button clicked")}>
                  <img src="/assets/dashboardcards/Close.svg" height='90%'/>
              </IconButton>
          </Box>
          <ErrorBoundary>
            <Box
              sx={{
                display: this.state.tabState == "insights" ? "block" : "none",
              }}
            >
              {this.printInsights()}
            </Box>
          </ErrorBoundary>
          <ErrorBoundary>
            <Box
              sx={{
                display: this.state.tabState == "rawdata" ? "block" : "none",
              }}
            >
              {this.printRawData()}
            </Box>
          </ErrorBoundary>
        </DialogContent>
      </Dialog>
    );
  }
}

export default InfoDialog;
