import { Link, Stack, Typography } from "@mui/material";
import { SupportsPage } from "../../supportpage.component";
import { SupportTabContent } from "../how-to-new.component";

interface IntreprtBlogProps {}

export const IntreprtBlog = (props: IntreprtBlogProps) => {
  return (
    <>
      <Typography
        variant="h6"
        display="block"
        id={SupportTabContent.interpretTimeFocusSwitches.id}
      ></Typography>
      <SupportsPage
        title={SupportTabContent.interpretTimeFocusSwitches.title}
        subtitle={SupportTabContent.interpretTimeFocusSwitches.description}
        loading={false}
      >
        <IntreprtPage></IntreprtPage>
      </SupportsPage>
    </>
  );
};

interface IntreprtProps {}

const IntreprtPage = (props: IntreprtProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="body1" display="block" fontWeight={"bold"}>
        How to interpret Time, Focus, and Switch data
      </Typography>
      <Typography variant="body1" display="block">
        When working on a digital device, most of us have a distorted perception
        of where we spend our time and focus. We may think we know how much time
        we spend on Email, Social Media, or our favorite app/website, and while
        some of us do, most do not have the data to back their perceptions.
      </Typography>
      <Typography variant="body1" display="block">
        myTiro gives you actual data to accept or refute your perceptions
      </Typography>
      <Typography variant="body1" display="block">
        Our device behavior typically comprises three factors: where, when, and
        how much time we spend on a particular activity, how much focus we are
        giving to a particular activity, and how frequently we are switching our
        attention from one application to another.
      </Typography>
      <Typography variant="body1" display="block">
        The Time value on myTiro tells how much actual time we have spent on a
        device/application/website on a given day and at what point throughout
        the day we have spent that time. We can interpret our Time data to:
      </Typography>
      <Typography variant="body1" display="block">
        <ul>
          <li>
            Build awareness of how much time we spend on our devices
            collectively or within specific applications.
          </li>
          <li>
            Track if we are using the specific applications consistently at the
            same time of the day
          </li>
          <li>
            Create goals to increase or decrease the time we want to spend on a
            particular application or website.
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" display="block">
        The Focus value on myTiro tells how focused we were on a
        device/application/website on a given day and when during the day we
        were focused. We can interpret our Focus data to:
      </Typography>
      <Typography variant="body1" display="block">
        <ul>
          <li>
            Know where and when we are focused across applications and the day
          </li>
          <li>
            Track whether we are focused on the applications that we choose
          </li>
          <li>Create goals to set minimum or maximum focus.</li>
        </ul>
      </Typography>
      <Typography variant="body1" display="block">
        The Switch value on myTiro tells how often we changed our attention from
        one application to another or how many times we opened/accessed an
        application. We can interpret our Switch data to:
      </Typography>
      <Typography variant="body1" display="block">
        <ul>
          <li>
            Know how many times we opened or accessed a specific application,
            website, or custom category. For example, if the Switch value for
            our Email is 55, we opened/accessed our Email 55 times during the
            day.
          </li>
          <li>Know how many times we changed applications during the day</li>
          <li>
            Understand how frequently, on average, we change between
            applications
          </li>
          <li>
            Create goals to limit Switching. For example, we can create an Email
            goal with Switch value 5 if we want to access our Email only 5 times
            during the day
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" display="block">
        Time, Focus, and Switch dimensions give a complete picture of how we
        spend our time across our devices. Once we have this data, we can choose
        to act on it with myTiro Goals to build healthy time habits.
      </Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
    </Stack>
  );
};
