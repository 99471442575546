import { Box, ThemeProvider, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import theme from "../../../theme/theme";
import { SupportTicket } from "./supportticket.model";
import { getSupportTickets } from "./supportticket.service";
import { TicketsTable } from "./supporttickettable.component";

const yellowpattern = "/assets/forms/Sign_in_Sign_up_Pattern_3.svg";
const greenpattern = "/assets/forms/Sign_in_Sign_up_Pattern_2.svg";
const graypattern = "/assets/forms/Sign_in_Sign_up_Pattern_1.svg";

export const SupportTickets = () => {
  const [tickets, setTickets] = useState<SupportTicket[]>([]);
  
  useEffect(() => {
    getTickets();
  }, [])

  const getTickets = async () => {
    let response = await getSupportTickets();
    // let response = dummyData;
    console.log(response)
    setTickets(response);
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        bgcolor={theme.palette.secondary.main}
        display="block"
        height="100vh"
        width="100vw"
        position="relative"
        padding="2%"
        overflow= 'hidden'
      >
        <Box
          sx={{ position: "absolute", top: "74vh", left: "-200px", zIndex: 0 }}
        >
          <img src={yellowpattern} />
        </Box>
        <Box
          sx={{ position: "absolute", top: "25vh", right: "-200px", zIndex: 0 }}
        >
          <img src={greenpattern} />
        </Box>
        <Box
          sx={{ position: "absolute", top: "20vh", left: "30vw", zIndex: 0 }}
        >
          <img src={graypattern} style={{ zIndex: 0 }} />
        </Box>
        <Typography variant="h6">Support Tickets</Typography>
        <Box height="85vh">
          <TicketsTable tickets={tickets} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const dummyData: SupportTicket[] = [
    {
        "id": 1,
        "accountId": "1231232",
        "email": "rithvik@yappxlabs.com",
        "description": "Test",
        "status": "Open",
        "fullName": "Rithvik Konappa",
        "created": "2022-06-29 16:18:40.422",
        "updated": "2022-06-29 16:18:40.422",
    },
    {
        "id": 1,
        "accountId": "1231232",
        "email": "rithvik@yappxlabs.com",
        "description": "Test",
        "status": "Open",
        "fullName": "Rithvik Konappa",
        "created": "2022-06-29 16:18:40.422",
        "updated": "2022-06-29 16:18:40.422",
    },
    {
        "id": 1,
        "accountId": "1231232",
        "email": "rithvik@yappxlabs.com",
        "description": "Test",
        "status": "Open",
        "fullName": "Rithvik Konappa",
        "created": "2022-06-29 16:18:40.422",
        "updated": "2022-06-29 16:18:40.422",
    },
    {
        "id": 1,
        "accountId": "1231232",
        "email": "rithvik@yappxlabs.com",
        "description": "Test",
        "status": "Open",
        "fullName": "Rithvik Konappa",
        "created": "2022-06-29 16:18:40.422",
        "updated": "2022-06-29 16:18:40.422",
    },
    {
        "id": 1,
        "accountId": "1231232",
        "email": "rithvik@yappxlabs.com",
        "description": "Test",
        "status": "Open",
        "fullName": "Rithvik Konappa",
        "created": "2022-06-29 16:18:40.422",
        "updated": "2022-06-29 16:18:40.422",
    },
    {
        "id": 1,
        "accountId": "1231232",
        "email": "rithvik@yappxlabs.com",
        "description": "Test",
        "status": "Open",
        "fullName": "Rithvik Konappa",
        "created": "2022-06-29 16:18:40.422",
        "updated": "2022-06-29 16:18:40.422",
    },{
        "id": 1,
        "accountId": "1231232",
        "email": "rithvik@yappxlabs.com",
        "description": "Test",
        "status": "Open",
        "fullName": "Rithvik Konappa",
        "created": "2022-06-29 16:18:40.422",
        "updated": "2022-06-29 16:18:40.422",
    },
    {
        "id": 1,
        "accountId": "1231232",
        "email": "rithvik@yappxlabs.com",
        "description": "Test",
        "status": "Open",
        "fullName": "Rithvik Konappa",
        "created": "2022-06-29 16:18:40.422",
        "updated": "2022-06-29 16:18:40.422",
    },
    {
        "id": 1,
        "accountId": "1231232",
        "email": "rithvik@yappxlabs.com",
        "description": "Test",
        "status": "Open",
        "fullName": "Rithvik Konappa",
        "created": "2022-06-29 16:18:40.422",
        "updated": "2022-06-29 16:18:40.422",
    },
    {
        "id": 1,
        "accountId": "1231232",
        "email": "rithvik@yappxlabs.com",
        "description": "Test",
        "status": "Open",
        "fullName": "Rithvik Konappa",
        "created": "2022-06-29 16:18:40.422",
        "updated": "2022-06-29 16:18:40.422",
    },
    {
        "id": 1,
        "accountId": "1231232",
        "email": "rithvik@yappxlabs.com",
        "description": "Test",
        "status": "Open",
        "fullName": "Rithvik Konappa",
        "created": "2022-06-29 16:18:40.422",
        "updated": "2022-06-29 16:18:40.422",
    },
    
]