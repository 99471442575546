import { Box, ThemeProvider, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import theme from "../../../theme/theme";
import { ErrorItem, fetchErrorLog } from "./errorlog.service";
import { ErrorLogTable } from "./errorlogtable.component";

const yellowpattern = "/assets/forms/Sign_in_Sign_up_Pattern_3.svg";
const greenpattern = "/assets/forms/Sign_in_Sign_up_Pattern_2.svg";
const graypattern = "/assets/forms/Sign_in_Sign_up_Pattern_1.svg";

export const ErrorLog = () => {
  const [errors, seterrors] = useState([] as ErrorItem[]);

  const getErrorLog = () => {
    fetchErrorLog()
      .then((res) => {
        console.log(res);
        if ("errors" in res) {
          seterrors(res.errors.sort((a, b) => a.timestamp - b.timestamp));
        } else {
          console.log(res);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getErrorLog();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        bgcolor={theme.palette.secondary.main}
        display="block"
        height="100vh"
        width="100vw"
        position="relative"
        padding="2%"
        overflow= 'hidden'
      >
        <Box
          sx={{ position: "absolute", top: "74vh", left: "-200px", zIndex: 0 }}
        >
          <img src={yellowpattern} />
        </Box>
        <Box
          sx={{ position: "absolute", top: "25vh", right: "-200px", zIndex: 0 }}
        >
          <img src={greenpattern} />
        </Box>
        <Box
          sx={{ position: "absolute", top: "20vh", left: "30vw", zIndex: 0 }}
        >
          <img src={graypattern} style={{ zIndex: 0 }} />
        </Box>
        <Typography variant="h6">Error log</Typography>
        <Box height="85vh">
          <ErrorLogTable errors={errors} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};
