import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AdminBenefits } from "../model/admin_benefits.model";
import {
  getAdminBenefits,
  getAdminBenefitsCsv,
} from "../service/admin_benefits.service";
import { DateRangePicker, RangeKeyDict, Range } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { ExportToCsv } from "export-to-csv";

const BenefitCards = (
  props: React.PropsWithChildren<AdminBenefits>
): JSX.Element => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (props.unit === "time") {
      let minutes = Math.floor(props.value / 60);
      let hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      minutes = Math.floor(minutes % 60);
      hours = hours % 24;
      const dayString = days > 0 ? `${days} days` : "";
      const hourString = hours > 0 ? `${hours} hours` : "";
      const minString = minutes > 0 ? `${minutes} mins` : "";
      setValue(`${dayString} ${hourString} ${minString}`);
    } else {
      setValue(`${props.value} ${props.unit}`);
    }
  }, [props]);
  return (
    <Box
      sx={{
        width: "350px",
        height: "200px",
        display: "block",
        bgcolor: "#E2E5FA",
        borderRadius: "10px",
        padding: "15px",
      }}
    >
      <Typography variant="h6">{props.name}</Typography>
      <br />
      <Typography variant="h5">{value}</Typography>
    </Box>
  );
};

export const OverAllBenefits = (props: any): JSX.Element => {
  const [benefitsData, setBenefitsData] = useState([] as AdminBenefits[]);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  } as Range);

  useEffect(() => {
    setAllTimeDate();
  }, []);

  const loadData = async () => {
    let startDate = 0;
    let endDate = Math.floor(Date.now() / 1000);
    if (selectionRange.startDate) {
      startDate = Math.floor(selectionRange.startDate.getTime() / 1000);
    }
    if (selectionRange.endDate) {
      endDate = Math.floor(selectionRange.endDate.getTime() / 1000);
    }
    const benefits = await getAdminBenefits(startDate, endDate);
    setBenefitsData(benefits as AdminBenefits[]);
  };

  useEffect(() => {
    loadData();
  }, [selectionRange]);

  const handleSelect = (ranges: RangeKeyDict) => {
    setSelectionRange(ranges.selection);
  };

  const setAllTimeDate = () => {
    const newRange: Range = {
      startDate: new Date(0),
      endDate: new Date(),
      key: "selection",
    };
    setSelectionRange(newRange);
  };

  const handleDownloadAsCsv = async () => {
    let startDate = 0;
    let endDate = Math.floor(Date.now() / 1000);
    if (selectionRange.startDate) {
      startDate = Math.floor(selectionRange.startDate.getTime() / 1000);
    }
    if (selectionRange.endDate) {
      endDate = Math.floor(selectionRange.endDate.getTime() / 1000);
    }
    const benefits = await getAdminBenefitsCsv(startDate, endDate);
    const today = new Date();
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      title: "myTiro User Benefits",
      filename: `myTiro User Benefits (${today.toLocaleDateString("en-GB")})`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(benefits);
  };

  return (
    <Box sx={{ width: "100%", margin: "2%" }}>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Stack spacing={2}>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelect}
              showMonthAndYearPickers={false}
            />
            <Button variant="outlined" onClick={setAllTimeDate}>
              All Time
            </Button>
            <Button variant="outlined" onClick={handleDownloadAsCsv}>
              Download as CSV
            </Button>
          </Stack>
        </Grid>
        <Grid container item xs={7} spacing={3}>
          {benefitsData.map((benefit) => {
            return (
              <Grid item xs={6}>
                <BenefitCards {...benefit}></BenefitCards>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};
