import {
  Box,
  Drawer,
  IconButton,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import Calendar from "./calendar.component";
import CalendarHeader from "./calenderheader.component";
import { DateRange } from "./models/calendar_main.model";
import theme from "../../theme/theme";
import { LogoLoader } from "../../components/loader/loader.component";
import { useState } from "react";
import { SidePanel } from "../../components/sidepanel/sidepanel.component";
import LeftNavigationBar from "../../components/leftnavigation/leftnavigation.component";
import { checkIfAppInstalled } from "../../components/dashboard/utils/dashboard.utils";
import { NoDataOverlay } from "../../components/dashboard/nodataoverlay/nodataoverlay.component";
import { DemoCalendar } from "./democalendar/democalendar.component";
import { displayOverlay } from "../../overlay.service";
const gtag = require("ga-gtag");

interface CalendarContainerProps {
  matches: boolean;
}

interface CalendarContainerState {
  dateRange: DateRange;
  loading: boolean;
  disableZoomIn: boolean;
  disableZoomOut: boolean;
  heights: {
    cell: number;
    accuracyBox: number;
  };
  mergeResolution: number;
  currentZoomLevel: number;
  isToday: Boolean;
  currentLegend: string;
  showEvents: boolean;
  showOverlay: string | null;
}

class CalendarContainerClass extends React.Component<
  CalendarContainerProps,
  CalendarContainerState
> {
  zoomOffset: number;
  zoomLevels = [
    { mergeResolution: 15, heights: { cell: 60, accuracyBox: 60 } },
    { mergeResolution: 10, heights: { cell: 120, accuracyBox: 60 } },
    { mergeResolution: 5, heights: { cell: 180, accuracyBox: 60 } },
    { mergeResolution: 3, heights: { cell: 300, accuracyBox: 60 } },
    { mergeResolution: 1, heights: { cell: 900, accuracyBox: 60 } },
  ];
  constructor(props: CalendarContainerProps) {
    super(props);
    this.zoomOffset = 60;
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    startDate.setDate(now.getDate() - 3);
    let endDate = new Date();
    endDate.setHours(0, 0, 0, 0);
    endDate.setDate(now.getDate() + 3);
    let dateRange = { centerDate: now, startDate: startDate, endDate: endDate };
    this.state = {
      dateRange: dateRange,
      loading: false,
      disableZoomIn: false,
      disableZoomOut: false,
      heights: {
        cell: 180,
        accuracyBox: 60,
      },
      mergeResolution: 5,
      currentZoomLevel: 2,
      isToday: true,
      currentLegend: "categories",
      showEvents: true,
      showOverlay: null,
    };
    gtag.gtag("event", "view_calendar", {
      calendar_action: "open",
    });
    this.incrementDate = this.incrementDate.bind(this);
    this.decrementDate = this.decrementDate.bind(this);
    this.showLoader = this.showLoader.bind(this);
    this.hideLoader = this.hideLoader.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.goToToday = this.goToToday.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
  }

  componentDidMount() {
    if (!this.state.showOverlay) {
      displayOverlay("COMPONENT_CALENDAR").then((str: string | null) => {
        this.setState({
          showOverlay: str,
        });
      });
    }
  }

  checkIfTodaysDate(date: Date) {
    date.setHours(0, 0, 0, 0);
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    return now.getTime() === date.getTime();
  }

  incrementDate(): void {
    console.log("increment");
    const { dateRange } = this.state;
    let centerDate = dateRange.centerDate;
    centerDate.setDate(centerDate.getDate() + 1);

    let startDate = dateRange.startDate;
    startDate.setDate(startDate.getDate() + 1);
    let endDate = dateRange.endDate;
    endDate.setDate(endDate.getDate() + 1);
    this.setState({
      dateRange: {
        centerDate: centerDate,
        startDate: startDate,
        endDate: endDate,
      },
      isToday: this.checkIfTodaysDate(centerDate),
    });
  }

  decrementDate(): void {
    const { dateRange } = this.state;
    let centerDate = dateRange.centerDate;
    centerDate.setDate(centerDate.getDate() - 1);
    let startDate = dateRange.startDate;
    startDate.setDate(startDate.getDate() - 1);
    let endDate = dateRange.endDate;
    endDate.setDate(endDate.getDate() - 1);
    this.setState({
      dateRange: {
        centerDate: centerDate,
        startDate: startDate,
        endDate: endDate,
      },
      isToday: this.checkIfTodaysDate(centerDate),
    });
  }

  goToToday() {
    console.log("Function called");
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    startDate.setDate(now.getDate() - 3);
    let endDate = new Date();
    endDate.setHours(0, 0, 0, 0);
    endDate.setDate(now.getDate() + 3);
    let dateRange = { centerDate: now, startDate: startDate, endDate: endDate };
    this.setState({
      dateRange: dateRange,
      isToday: true,
    });
  }

  showLoader(): void {
    this.setState({ loading: true });
  }
  hideLoader(): void {
    this.setState({ loading: false });
  }

  zoomCalendar(zoomValue: number) {
    let cell = this.state.heights.cell;
    let accuracyBox = this.state.heights.accuracyBox;
    cell = Math.min(420, Math.max(60, cell + zoomValue));
    this.setState({
      heights: {
        cell: cell,
        accuracyBox: accuracyBox,
      },
      disableZoomIn: cell >= 420,
      disableZoomOut: cell <= 60,
    });
  }

  zoomIn() {
    let { currentZoomLevel, disableZoomIn, disableZoomOut } = this.state;
    if (currentZoomLevel < this.zoomLevels.length - 1) {
      currentZoomLevel += 1;
      if (currentZoomLevel === this.zoomLevels.length - 1) {
        disableZoomIn = true;
      }
      if (currentZoomLevel > 0) {
        disableZoomOut = false;
      }
      this.setState(
        {
          currentZoomLevel: currentZoomLevel,
          heights: this.zoomLevels[currentZoomLevel].heights,
          mergeResolution: this.zoomLevels[currentZoomLevel].mergeResolution,
          disableZoomIn: disableZoomIn,
          disableZoomOut: disableZoomOut,
        },
        () => {
          console.log("Zoomed In:", this.state.heights);
        }
      );
    }
  }

  zoomOut() {
    let { currentZoomLevel, disableZoomOut, disableZoomIn } = this.state;
    if (currentZoomLevel > 0) {
      currentZoomLevel -= 1;
      if (currentZoomLevel === 0) {
        disableZoomOut = true;
      }
      if (currentZoomLevel < this.zoomLevels.length - 1) {
        disableZoomIn = false;
      }
      this.setState(
        {
          currentZoomLevel: currentZoomLevel,
          heights: this.zoomLevels[currentZoomLevel].heights,
          mergeResolution: this.zoomLevels[currentZoomLevel].mergeResolution,
          disableZoomOut: disableZoomOut,
          disableZoomIn: disableZoomIn,
        },
        () => {
          console.log("Zoomed out:", this.state.heights);
        }
      );
    }
  }

  handleChange(currentLegend: string) {
    this.setState({
      currentLegend: currentLegend,
    });
  }

  handleSwitch(showEvents: boolean) {
    this.setState({
      showEvents: showEvents,
    });
  }

  render() {
    const { matches } = this.props;
    let now = new Date();
    return (
      <>
        <LogoLoader
          loading={
            matches
              ? false
              : this.state.showOverlay != null
              ? false
              : this.state.loading
          }
        ></LogoLoader>
        {!matches ? (
          <Box
            sx={{
              width: "100%",
              padding: "1%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <ThemeProvider theme={theme}>
              <CalendarHeader
                dateRange={this.state.dateRange}
                handleIncrement={this.incrementDate}
                handleDecrement={this.decrementDate}
                handleZoomIn={this.zoomIn}
                handleZoomOut={this.zoomOut}
                disableZoomIn={this.state.disableZoomIn}
                disableZoomOut={this.state.disableZoomOut}
                mergeResolution={this.state.mergeResolution}
                onReset={this.goToToday}
                isToday={this.state.isToday}
                handleSwitch={this.handleSwitch}
                handleChange={this.handleChange}
                currentLegend={this.state.currentLegend}
                showEvents={this.state.showEvents}
              />
              {!this.state.showOverlay ? (
                <Calendar
                  dateRange={this.state.dateRange}
                  showLoader={this.showLoader}
                  hideLoader={this.hideLoader}
                  heights={this.state.heights}
                  mergeResolution={this.state.mergeResolution}
                  showEvents={this.state.showEvents}
                  currentLegend={this.state.currentLegend}
                />
              ) : (
                <Box position={"relative"}>
                  <NoDataOverlay text={this.state.showOverlay} />
                  <DemoCalendar />
                </Box>
              )}
            </ThemeProvider>
          </Box>
        ) : (
          <ThemeProvider theme={theme}>
            <Typography
              variant="h5"
              sx={{ marginBottom: "2%", marginLeft: "2%" }}
            >
              Calendar
            </Typography>
            <Box
              width="100%"
              padding="4%"
              height="75%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ backgroundColor: "white" }}
            >
              <Typography variant="h6Light" textAlign={"center"}>
                The calendar is not available for mobile yet. Please use a
                bigger screen to view the calendar.
              </Typography>
            </Box>
          </ThemeProvider>
        )}
      </>
    );
  }
}

export const CalendarMobileHeader = () => {
  const [anchorEl, setAnchorEl] = useState(
    null as (EventTarget & HTMLElement) | null
  );
  const [navOpen, setnavOpen] = useState(false);
  const [insightsOpen, setinsightsOpen] = useState(false);
  return (
    <>
      <Box width="100%" display="block">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems="center"
          sx={{ backgroundColor: "white", padding: "4%" }}
        >
          <img src="/assets/Logo Symbol.svg" height="60px" />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems="center"
          sx={{ padding: "4% 0%" }}
        >
          <IconButton onClick={() => setnavOpen(true)}>
            <img src="/assets/Menu Hamburger.svg" height="30px" />
          </IconButton>

          <IconButton
            onClick={() => setinsightsOpen(true)}
            sx={{
              backgroundColor: "#FFFFFF",
              boxShadow: "-5px 0px 10px rgba(42, 46, 54, 0.06)",
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              marginRight: "0%",
            }}
          >
            <img src="/assets/insights_menu.svg" height="30px" />
          </IconButton>
        </Box>
      </Box>
      <Drawer anchor={"left"} open={navOpen} onClose={() => setnavOpen(false)}>
        <LeftNavigationBar />
      </Drawer>
      <Drawer
        anchor={"right"}
        open={insightsOpen}
        onClose={() => setinsightsOpen(false)}
        PaperProps={{ sx: { width: "60%" } }}
      >
        <SidePanel isMobile={true} />
      </Drawer>
    </>
  );
};

const CalendarContainer = (props: {}) => {
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box display="block" width={matches ? "100%" : "70%"}>
      {" "}
      {matches ? <CalendarMobileHeader /> : ""}
      <CalendarContainerClass matches={matches} {...props} />
    </Box>
  );
};

export default CalendarContainer;
