export interface ScoringValuesDto {
  id: number;
  accountId: string;
  dayTimestamp: number;
  startTime: number;
  endTime: number;
  scoringType: ScoringType;
  score: number;
  maxScore: number;
  minScore: number;
  referenceData: {
    data: {
      name: string;
      value: number;
    }[][];
  };
}

export enum ScoringType {
  FOCUS_TIME = "focus_time",
  GOALS = "goals",
  HABITS = "habits",
}
