import { withStyles, WithStyles } from "@mui/styles";
import "./styles/calendarday.styles.css";
import { calendarStyles } from "./styles/calendar.styles";
import {
  CalEvent,
  DayActivities,
  FocusSession,
} from "./models/calendar_main.model";
import { CalendarCell } from "./models/calendar_cell.model";
import CalendarColumn from "./calendarcolumn.component";

interface CalendarTodayProps extends WithStyles<typeof calendarStyles> {
  today: {
    past: DayActivities[];
    future: DayActivities[];
  };
  events?: Array<CalEvent>;
  focusSessions: FocusSession[] | undefined;
  showEvents: boolean | undefined;
  showDevices: boolean;
  heights: {
    cell: number;
    accuracyBox: number;
  };
}
class CalendarToday extends CalendarColumn {
  constructor(props: CalendarTodayProps) {
    super(props);
    let cells: Array<CalendarCell> = [];
    let events = [];
    if (props.events !== undefined) {
      for (const event of props.events) {
        events.push({ event: event, isOverlap: false });
      }
    }
    let activities = [];
    if (props.today.past.length !== 0) {
      for (const pastActivity of props.today.past[0]?.activities) {
        activities.push({
          activity: pastActivity,
          isOverlap: false,
          showText: true,
        });
      }
    } /// PUSH FUTURE AS WELL
    let focusSessions = [];
    if(props.focusSessions !== undefined)
    for(let i = 0;i < props.focusSessions.length ; i++){
      focusSessions.push({focusSession: props.focusSessions[i], isOverlap: false})
    }
    this.state = {
      cells: cells,
      events: events,
      activities: activities,
      isToday: true,
      isFuture: false,
      heights: props.heights,
      columnId: -1,
      focusSessions: focusSessions,
      showEvents: props.showEvents,
      showDevices: props.showDevices,
    };
  }

  // componentDidUpdate(prevProps: CalendarTodayProps, prevState: any) {
  //   if(this.props.showEvents !== prevProps.showEvents){
  //     console.log("show events:", prevProps.showEvents);
  //     this.setState ({
  //       showEvents: this.props.showEvents,
  //     })
  //   }
  // }

}

export default withStyles(calendarStyles)(CalendarToday);
