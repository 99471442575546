import * as React from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Add, Refresh } from "@mui/icons-material";
import {
  Calendar,
  getAllCalendars,
  getAllMailboxes,
  Mailbox,
  timeFromNow,
} from "./integration.service";
import { AccountContext } from "../../../accountcontext/account";
import { useContext } from "react";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import {
  getCurrentProvider,
  processIntegrationResponse,
  setCurrentProvider,
  startIntegration,
} from "../../../google_login.service";

import { toast, ToastContainer } from "react-toastify";
import theme from "../../../theme/theme";
import { SettingsPage } from "../settingspage.component";

const mailboxes: {
  name: string;
  type: string;
  last_updated: string;
}[] = [];

const calendars: {
  name: string;
  type: string;
  last_updated: string;
}[] = [];

interface IntegrationSettingsProps {}

const IntegrationSettings: React.FunctionComponent<
  IntegrationSettingsProps
> = () => {
  const { getSession } = useContext(AccountContext);
  const [emailServices, setemailServices] = useState(mailboxes);
  const [calendarServices, setcalendarServices] = useState(calendars);
  const [loading, setLoading] = React.useState(false);

  const loadMailboxes = async () => {
    getAllMailboxes()
      .then((mailboxes: Mailbox[]) => {
        const mbs = mailboxes.map((m: Mailbox) => {
          const last_updated = timeFromNow(m.last_updated);
          return {
            name: m.email_address,
            type: m.provider === 1 ? "Google" : "Unknown",
            last_updated: last_updated,
          };
        });
        setemailServices(mbs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadCalendars = async () => {
    getAllCalendars()
      .then((calendars: Calendar[]) => {
        const cals = calendars.map((c: Calendar) => {
          const last_updated = timeFromNow(c.last_updated);
          return {
            name: c.email_address,
            type: c.provider === 1 ? "Google" : "Unknown",
            last_updated: last_updated,
          };
        });
        setcalendarServices(cals);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadMailboxes();
    loadCalendars();
    window.addEventListener("message", compeleteIntegration);
    return () => {
      window.removeEventListener("message", compeleteIntegration);
    };
  }, []);

  const compeleteIntegration = (event: MessageEvent) => {
    if (!event || !("code" in event.data)) {
      return;
    }
    getSession().then((cognitoUser: CognitoUser) => {
      if (cognitoUser) {
        cognitoUser.getSession((err: any, session: CognitoUserSession) => {
          if (err) {
            console.log(err);
          } else {
            const idToken = session.getIdToken().getJwtToken();
            const username = session.getIdToken().payload["cognito:username"];
            processIntegrationResponse(
              event,
              username,
              idToken,
              getCurrentProvider()
            )
              .then(() => {
                setLoading(false);
                loadMailboxes();
                loadCalendars();
              })
              .catch((err: any) => {
                console.log(err);
                // toast.error("Something went wrong. please try again later", {
                //   position: "bottom-left",
                //   autoClose: 5000,
                //   closeOnClick: true,
                //   pauseOnHover: true,
                //   draggable: true,
                //   progress: undefined,
                // });
              })
              .finally(() => {
                // loadMailboxes();
                // loadCalendars();
                setLoading(false);
              });
          }
        });
      }
    });
  };

  const onComplete = (status: boolean | undefined, message: string) => {
    if (status === undefined && message != "") {
      //check if login is completed, if not show error
      toast.error(message, {
        position: "bottom-left",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    loadMailboxes();
    loadCalendars();
    setLoading(false);
  };

  const onSubmit = (
    provider: string,
    emailAddress: string | undefined = undefined
  ) => {
    setCurrentProvider(provider);
    getSession()
      .then((cognitoUser: CognitoUser) => {
        cognitoUser.getSession((err: any, session: CognitoUserSession) => {
          if (err) {
            console.log(err);
            return;
          }
          const idToken = session.getIdToken().getJwtToken();
          const username = session.getIdToken().payload["cognito:username"];
          setLoading(true);
          startIntegration(
            provider,
            idToken,
            username,
            onComplete,
            emailAddress
          );
        });
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getMailboxIcon = (type: string) => {
    if (type === "Google") {
      return "/assets/logos/Gmail_Logo.svg";
    } else if (type === "Outlook") {
      return "/assets/logos/outlook.svg";
    } else {
      return "/assets/logo.svg";
    }
  };

  const getCalendarIcon = (type: string) => {
    if (type === "Google") {
      return "/assets/logos/google-calendar.svg";
    } else if (type === "Microsoft") {
      return "/assets/logos/outlook-calendar.svg";
    } else {
      return "/assets/logo.svg";
    }
  };

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <SettingsPage
      title="Integration Management"
      subtitle="Manage your integrations"
      loading={loading}
    >
      <ToastContainer
        autoClose={3000}
        theme="colored"
        hideProgressBar
        closeOnClick
      />
      <Box display="block">
        <Typography variant="h6">Mailboxes</Typography>
        <Button
          variant="onlytextpurple"
          startIcon={<Add />}
          color="primary"
          onClick={() => {
            onSubmit("google_gmail");
          }}
        >
          Add mailbox{" "}
        </Button>
      </Box>

      <Box
        sx={{
          display: "block",
          margin: "2% 0",
          border: "1px solid lightgray",
          borderRadius: "8px",
          padding: "1% 4%",
          textAlign: matches ? "left" : "center",
        }}
      >
        {/* <Grid container>
          <Grid item xs={0} sm={6} display={{xs: "none", sm: 'inherit'}}>
            <Typography variant="body1Light">Account</Typography>
          </Grid>
          <Grid item xs={0} sm={3} display={{xs: "none", sm: 'inherit'}}>
            <Typography variant="body1Light">Last Update</Typography>
          </Grid>
          {/* <Grid item xs={0} sm={1}></Grid> 
          <Grid item xs={0} sm={3} display={{xs: "none"}}></Grid>
        </Grid> */}
        {emailServices.map((emailService) => {
          return (
            <Grid container margin="2% 0" alignItems={"center"} rowGap={1}>
              <Grid
                item
                xs={2}
                sm={1}
                textAlign="left"
                display="flex"
                alignItems="center"
                justifyContent={"flex-start"}
              >
                <img
                  src={getMailboxIcon(emailService.type)}
                  width="50%"
                  style={{ marginLeft: 0, marginRight: "auto" }}
                />
              </Grid>
              <Grid item xs={10} sm={5} textAlign="left">
                <Typography variant="body1Bold">{emailService.name}</Typography>
              </Grid>
              <Grid item xs={4} display={{ sm: "none" }}>
                <Typography
                  variant="body1Light"
                  textAlign={matches ? "left" : "inherit"}
                >
                  Last Synced:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={3}>
                <Typography
                  variant="body1"
                  textAlign={matches ? "left" : "inherit"}
                  // color={!emailService.expired ? "green" : "red"}
                >
                  Synced {emailService.last_updated} ago
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={1}>
                <IconButton>
                  <Delete />
                </IconButton>
              </Grid> */}
              <Grid item xs={12} sm={3} display="flex">
                <Button
                  variant="outlined"
                  startIcon={<Refresh />}
                  onClick={() => {
                    onSubmit("google_gmail", emailService.name);
                  }}
                  sx={{ marginRight: 0, marginLeft: "auto" }}
                >
                  Re-Sync
                </Button>
              </Grid>
            </Grid>
          );
        })}
      </Box>

      <div
        style={{
          height: "1px",
          borderTop: "1px dashed #D6DAE480",
          margin: "4% 0",
        }}
      />

      <Box display="block">
        <Typography variant="h6">Calendars</Typography>
        <Button
          variant="onlytextpurple"
          startIcon={<Add />}
          color="primary"
          onClick={() => {
            onSubmit("google_calendar");
          }}
        >
          Add Calendar{" "}
        </Button>
      </Box>

      <Box
        sx={{
          display: "block",
          margin: "2% 0",
          border: "1px solid lightgray",
          borderRadius: "8px",
          padding: "1% 4%",
          textAlign: matches ? "left" : "center",
        }}
      >
        {/*<Grid container>
        <Grid item xs={0} sm={1} textAlign="left" display={{xs: "none", sm: 'inherit'}}>
          </Grid>
          <Grid item xs={0} sm={5} textAlign="left" display={{xs: "none", sm: 'inherit'}}>
            <Typography variant="body1Light">Account</Typography>
          </Grid>
          <Grid item xs={0} sm={3} display={{xs: "none", sm: 'inherit'}}>
            <Typography variant="body1Light">Last Updated</Typography>
          </Grid>
          {/* <Grid item xs={0} sm={1}></Grid> 
          <Grid item xs={0} sm={1}></Grid>
        </Grid> */}
        {calendarServices.map((calendarService, index) => {
          return (
            <Grid
              container
              margin="2% 0"
              alignItems={"center"}
              key={index}
              rowGap={1}
            >
              <Grid
                item
                xs={2}
                sm={1}
                textAlign="left"
                display="flex"
                alignItems="center"
                justifyContent={"flex-start"}
              >
                <img
                  src={getCalendarIcon(calendarService.type)}
                  width="50%"
                  style={{ marginLeft: 0, marginRight: "auto" }}
                />
              </Grid>
              <Grid item xs={10} sm={5} textAlign="left">
                <Typography variant="body1Bold">
                  {calendarService.name}
                </Typography>
              </Grid>
              <Grid item xs={4} display={{ sm: "none" }}>
                <Typography variant="body1Light">Last Updated:</Typography>
              </Grid>
              <Grid item xs={8} sm={3}>
                <Typography
                  variant="body1"
                  // color={!calendarService.expired ? "green" : "red"}
                >
                  Synced {calendarService.last_updated} ago
                </Typography>
              </Grid>
              {/* <Grid item xs={4} display={{ sm: "none" }}>
                <Typography variant="body1Light">Date added:</Typography>
              </Grid>
              <Grid item xs={8} sm={1}>
                <IconButton>
                  <Delete />
                </IconButton>
              </Grid> */}
              <Grid item xs={12} sm={3} display="flex">
                <Button
                  variant="outlined"
                  startIcon={<Refresh />}
                  onClick={() => {
                    onSubmit("google_calendar", calendarService.name);
                  }}
                  sx={{ marginRight: 0, marginLeft: "auto" }}
                >
                  Re-Sync
                </Button>
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </SettingsPage>
  );
};

export default IntegrationSettings;
