import { Box, Typography, Switch } from "@mui/material";
import { withStyles, WithStyles } from "@mui/styles";
import * as React from "react";
import { useState, useEffect } from "react";
import {
  getColor,
  getNewCategoryColor,
} from "../../../components/dashboard/utils/dashboard.utils";
import { calendarStyles } from "../styles/calendar.styles";

interface LegendBoxProps extends WithStyles<typeof calendarStyles> {
  showDevices: boolean;
}

const LegendBox = (props: LegendBoxProps) => {
  const categories = [
    "Email",
    "Browsing",
    "Desktop Apps",
    "Meetings",
    "Office Apps",
    "Mobile Apps",
  ];

  const devices = ["Mobile", "Tablet", "Computer"];

  const { classes } = props;
  const [showDevices, setShowDevices] = useState(props.showDevices);
  const [currentLegend, setcurrentLegend] = useState(
    props.showDevices ? devices : categories
  );

  useEffect(() => {
    setShowDevices(props.showDevices);
  }, [props.showDevices]);

  useEffect(() => {
    setcurrentLegend(showDevices ? devices : categories);
  }, [showDevices]);

  return (
    <Box
      className={classes.categorybox}
      sx={{ width: "110%", position: "relative", left: "-10%" }}
    >
      {currentLegend.map((item, index) => (
        <Box className="category-bar-item" key={index}>
          <div
            className="category-color-box"
            style={{ backgroundColor: getNewCategoryColor(item) }}
          ></div>
          <Typography variant="body2Black">{item}</Typography>
        </Box>
      ))}
      <Box className="category-bar-item" key={8}>
        <div
          className="category-color-box"
          style={{ border: "1px #050505 solid" }}
        ></div>
        <Typography variant="body2Black">Focus Session</Typography>
      </Box>
    </Box>
  );
};

export default withStyles(calendarStyles)(LegendBox);
