import {
  Box,
  Container,
  Divider,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import theme from "../../theme/theme";
import {
  TOSFeedback,
  TOSIndemnification,
  TOSIntellectualProperty,
  TOSIntro,
  TOSLiability,
  TOSMinAgeReq,
  TOSMisc,
  TOSmyTiroAccount,
  TOSOfferings,
  TOSPayment,
  TOSResponsibilities,
  TOSSanctions,
  TOSTermination,
  TOSWarranties,
} from "./tos-sections.component";
const yellowpattern = "/assets/forms/Sign_in_Sign_up_Pattern_3.svg";
const greenpattern = "/assets/forms/Sign_in_Sign_up_Pattern_2.svg";
const graypattern = "/assets/forms/Sign_in_Sign_up_Pattern_1.svg";

export const TOSPage = (): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        bgcolor={theme.palette.secondary.main}
        display="block"
        height="100vh"
        width="100vw"
        position="relative"
        padding="2%"
        overflow="hidden"
      >
        <Box
          sx={{
            position: "absolute",
            top: "74vh",
            left: "-200px",
            zIndex: 0,
          }}
        >
          <img src={yellowpattern} />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "25vh",
            right: "-200px",
            // zIndex: 0,
          }}
        >
          <img src={greenpattern} />
        </Box>
        {/* <Box sx={{ position: "absolute", top: "20vh", left: "30vw" }}>
            <img src={graypattern} style={{ zIndex: 0 }} />
          </Box> */}
        <Container>
          <Box
            sx={{
              overflow: "auto",
              "::-webkit-scrollbar": {
                width: "0px",
                background: "transparent",
              },
            }}
          >
            <Typography variant="h4">Terms of Service</Typography>
            <Box height="85vh">{<TOSText></TOSText>}</Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

const TOSText = () => {
  return (
    <Stack>
      <TOSIntro />
      <Divider sx={{ width: "100%", margin: "50px" }} />
      <TOSOfferings />
      <Divider sx={{ width: "100%", margin: "50px" }} />
      <TOSmyTiroAccount />
      <Divider sx={{ width: "100%", margin: "50px" }} />
      <TOSMinAgeReq />
      <Divider sx={{ width: "100%", margin: "50px" }} />
      <TOSIntellectualProperty />
      <Divider sx={{ width: "100%", margin: "50px" }} />
      <TOSResponsibilities />
      <Divider sx={{ width: "100%", margin: "50px" }} />
      <TOSWarranties />
      <Divider sx={{ width: "100%", margin: "50px" }} />
      <TOSLiability />
      <Divider sx={{ width: "100%", margin: "50px" }} />
      <TOSIndemnification />
      <Divider sx={{ width: "100%", margin: "50px" }} />
      <TOSTermination />
      <Divider sx={{ width: "100%", margin: "50px" }} />
      <TOSPayment />
      <Divider sx={{ width: "100%", margin: "50px" }} />
      <TOSFeedback />
      <Divider sx={{ width: "100%", margin: "50px" }} />
      <TOSSanctions />
      <Divider sx={{ width: "100%", margin: "50px" }} />
      <TOSMisc />
    </Stack>
  );
};
