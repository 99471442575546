import { CognitoUserSession } from "amazon-cognito-identity-js";
import { DateTime } from "luxon";
import {
  getSession,
  handleSessionNotFound,
} from "../../accountcontext/account";
import {
  DaySummaryDto,
  DetailedTimelineDto,
  TimelineDto,
} from "./timeline.model";

export {};

const BASE_USAGE_URL = `/api/usage`;

export const getDetailedTimelineData = async (
  startTime: number,
  endTime: number
): Promise<DetailedTimelineDto[]> => {
  // console.log(`Fetching Data for: ${startTime} ${endTime}`);
  // console.log(
  //   `Fetching Data for: ${DateTime.fromSeconds(
  //     startTime
  //   ).toISO()} ${DateTime.fromSeconds(endTime).toISO()}`
  // );
  var params = [
    ["startTime", startTime.toString()],
    ["endTime", endTime.toString()],
  ];
  const searchParams = new URLSearchParams(params).toString();
  const url = `/api/home/timeline/hourly?${searchParams}`;
  try {
    const ret = (await callApi(url, {
      method: "GET",
    })) as DetailedTimelineDto[];
    console.log(ret);
    return ret;
  } catch {
    return [];
  }
};

export const getAppTimelineData = async (
  appName: string,
  startTime: number,
  endTime: number
): Promise<TimelineDto[]> => {
  var params = [
    ["appName", appName],
    ["startTime", startTime.toString()],
    ["endTime", endTime.toString()],
  ];
  const searchParams = new URLSearchParams(params).toString();
  const url = `/api/dashboard/timeline/app?${searchParams}`;
  try {
    const ret = (await callApi(url, {
      method: "GET",
    })) as TimelineDto[];
    // console.log(JSON.stringify(ret));
    return ret;
  } catch {
    return [];
  }
};

export const getAppSummary = async (
  appName: string,
  category: string,
  startTime: number,
  endTime: number
): Promise<DaySummaryDto> => {
  var params = [
    ["appName", appName],
    ["category", category],
    ["dayTs", startTime.toString()],
  ];
  const searchParams = new URLSearchParams(params).toString();
  const url = `/api/dashboard/app-summary?${searchParams}`;
  try {
    const ret = (await callApi(url, {
      method: "GET",
    })) as DaySummaryDto;
    //TODO remove log
    // console.log(ret);
    return ret;
  } catch {
    return { time: undefined, focus: undefined, activities: undefined };
  }
};

export const getWeeklyAppSummary = async (
  appName: string,
  category: string,
  startTime: number,
  noOfDays: number
): Promise<DaySummaryDto[]> => {
  var params = [
    ["appName", appName],
    ["category", category],
    ["dayTs", startTime.toString()],
    ["noOfDays", noOfDays.toString()],
  ];
  const searchParams = new URLSearchParams(params).toString();
  const url = `/api/dashboard/weekly-app-summary?${searchParams}`;
  try {
    const ret = (await callApi(url, {
      method: "GET",
    })) as DaySummaryDto[];
    //TODO remove log
    // console.log(ret);
    return ret;
  } catch {
    return [];
  }
};

export const getMonthlyAppSummary = async (
  appName: string,
  category: string,
  startTime: number
): Promise<DaySummaryDto[]> => {
  var params = [
    ["appName", appName],
    ["category", category],
    ["dayTs", startTime.toString()],
    ["includeWeekend", "true"],
  ];
  const searchParams = new URLSearchParams(params).toString();
  const url = `/api/dashboard/monthly-app-summary?${searchParams}`;
  try {
    const ret = (await callApi(url, {
      method: "GET",
    })) as DaySummaryDto[];
    //TODO remove log
    // console.log(ret);
    return ret;
  } catch {
    return [];
  }
};

export const getDaySummary = async (
  startTime: number,
  endTime: number
): Promise<DaySummaryDto> => {
  var params = [["dayTs", startTime.toString()]];
  const searchParams = new URLSearchParams(params).toString();
  const url = `/api/home/summary?${searchParams}`;
  try {
    const ret = (await callApi(url, {
      method: "GET",
    })) as DaySummaryDto;
    // console.log(ret);
    return ret;
  } catch {
    return { time: undefined, focus: undefined, activities: undefined };
  }
};

export const getTimelineData = async (
  startTime: number,
  endTime: number
): Promise<TimelineDto[]> => {
  //   console.log("here");
  // startTime = 1661970600;
  // endTime = 1662056999;
  var params = [
    ["startTime", startTime.toString()],
    ["endTime", endTime.toString()],
  ];
  const searchParams = new URLSearchParams(params).toString();
  const url = `/api/home/timeline/all-day?${searchParams}`;
  try {
    const ret = (await callApi(url, {
      method: "GET",
    })) as TimelineDto[];
    // console.log(JSON.stringify(ret));
    return ret;
  } catch {
    return [];
  }
};

const session = async (): Promise<{ token: string; username: string }> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        resolve({ token: idToken, username: username });
      })
      .catch((err) => {
        console.log(err);
        handleSessionNotFound(err);
      });
  });
};

const callApi = async (api: string, reqOpts: RequestInit) => {
  const { token, username } = await session();
  reqOpts.headers = { ...reqOpts.headers, Authorization: `Bearer ${token}` };
  return new Promise((resolve, reject) => {
    const url = `${BASE_USAGE_URL}${api}`;
    fetch(url, reqOpts)
      .then((response) => response.text())
      .then((result) => JSON.parse(result))
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};
