import { Box } from "@mui/material";

export const BoxLoader = (props: { loading: boolean }) => {
  return props.loading ? (
    <>
      <div className="loader-box-container"></div>
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "row",
          height: "150px",
          width: "150px",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#643992",
          borderRadius: "25%",
          position: "fixed",
          top: "50%",
          left: "50%",
          zIndex: "9999",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img src="/assets/logo_symbol.png" className="loader-logo" alt="logo" />
      </Box>
    </>
  ) : (
    <></>
  );
};
