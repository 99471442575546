import { CognitoUserSession } from "amazon-cognito-identity-js";
import { getSession, handleSessionNotFound } from "./accountcontext/account";
import errorMessages from "./components/dashboard/nodataoverlay/errormessages.json";
import { appState, getAppState } from "./services/app.service";

export type ComponentType =
  | "COMPONENT_DASHBOARD_TOTAL_TIME"
  | "COMPONENT_DASHBOARD_FOCUS_TIME"
  | "COMPONENT_DASHBOARD_USAGE_BY_CATEGORY"
  | "COMPONENT_DASHBOARD_USAGE_BY_DEVICE"
  | "COMPONENT_DASHBOARD_USAGE_BY_APPLICATION"
  | "COMPONENT_DASHBOARD_INFORMATION_CHANNELS_EMAILS"
  | "COMPONENT_DASHBOARD_INFORMATION_CHANNELS_TABS"
  | "COMPONENT_DASHBOARD_GOALS"
  | "COMPONENT_DASHBOARD_BENEFITS_REPORT"
  | "COMPONENT_DASHBOARD_BENEFITS_CARDS"
  | "COMPONENT_CALENDAR"
  | "COMPONENT_CALENDAR_EVENTS"
  | "COMPONENT_SIDEPANEL_PATTERNS"
  | "COMPONENT_SIDEPANEL_TWEAKS";

interface ProfileInfo {
  id?: string;
  firstName: string;
  lastName: string;
  timezone: string;
  firstLogin?: boolean;
  nativeAgentConnected: boolean;
  browserAgentConnected: boolean;
  googleMailConnected: boolean;
  googleCalendarConnected: boolean;
  onboardingComplete: boolean;
  customizations: {};
}

const BASE_URL = "api/user";

const getOverlayMessage = (componentType: ComponentType) => {
  return new Promise<any>((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];
        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
            accountId: username,
          },
        };
        const url = `${BASE_URL}/account/${username}/profile/overlay_message?component=${componentType}`;
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      })
      .catch(handleSessionNotFound);
  });
};

export const getProfileInfo = () => {
  return appState.get()?.account.profile;
};

const getResult = (res: any) => {
  console.log(res);
  if (res.statusCode !== undefined) {
    return "An error occured";
  } else {
    return res.message;
  }
};

export const displayOverlay = async (
  componentType: ComponentType
): Promise<string | null> => {
  const profileInfo = getProfileInfo();
  if (!profileInfo) {
    return "Something Went Wrong";
  }
  let result = null;
  const {
    nativeAgentConnected,
    browserAgentConnected,
    googleCalendarConnected,
    googleMailConnected,
  } = profileInfo;
  if (componentType === "COMPONENT_DASHBOARD_INFORMATION_CHANNELS_EMAILS") {
    if (!googleMailConnected) {
      try {
        let res = await getOverlayMessage(componentType);
        result = getResult(res);
      } catch {
        result = null;
      }
    }
  } else if (
    componentType === "COMPONENT_DASHBOARD_INFORMATION_CHANNELS_TABS"
  ) {
    if (!browserAgentConnected) {
      try {
        let res = await getOverlayMessage(componentType);
        result = getResult(res);
      } catch {
        result = null;
      }
    }
  } else if (componentType === "COMPONENT_CALENDAR_EVENTS") {
    if (!googleCalendarConnected) {
      try {
        let res = await getOverlayMessage(componentType);
        result = getResult(res);
      } catch {
        result = null;
      }
    }
  } else {
    if (!nativeAgentConnected) {
      try {
        let res = await getOverlayMessage(componentType);
        result = getResult(res);
      } catch {
        result = null;
      }
    } else {
      // if (componentType === "COMPONENT_DASHBOARD_GOALS") {
      //   let res = await getOverlayMessage(componentType);
      //   result = getResult(res);
      // } else {
      return null;
      // }
    }
  }

  return result;
};
