import * as React from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import "./styles/calendarday.styles.css";
import { Activity, CalEvent } from "./models/calendar_main.model";

import * as utils from "./utils/calendar.utils";

import { CalendarCell } from "./models/calendar_cell.model";
import CalendarEvent from "./calendarevent/calendarevent.component";
import ActivityItem from "./activityitem/activityitem.component";
import {
  CalendarColumnState,
  FocusSessionCalendarItem,
  EventCalendarItem,
  ActivityCalendarItem,
} from "./models/calendar_column.model";
import FocusIndicator from "./focusIndicator/focusindicator.component";

class CalendarColumn extends React.Component<any, CalendarColumnState> {
  constructor(props: any) {
    super(props);
  }

  static getDerivedStateFromProps(
    nextProps: any,
    prevState: CalendarColumnState
  ) {
    if (nextProps.heights !== prevState.heights) {
      return {
        heights: nextProps.heights,
      };
    }
    if (nextProps.showEvents !== prevState.showEvents) {
      return {
        showEvents: nextProps.showEvents,
      };
    }
    return null;
  }

  componentDidMount() {
    let cells: CalendarCell[] = [];
    for (const i of Array(24).keys()) {
      cells.push({
        isEmpty: true,
        activities: [],
        events: [],
        window: { start: i, end: i === 23 ? 0 : i + 1 },
      });
    }
    this.setState({
      cells: cells,
    });
    if (this.props.columnId !== undefined) {
      this.setState({
        columnId: this.props.columnId,
      });
    }
  }

  public static defaultProps = {
    isFuture: false,
  };

  populateCellArray(
    cellContent: any,
    sortedActivities: ActivityCalendarItem[],
    sortedEvents: EventCalendarItem[],
    sortedFocus: FocusSessionCalendarItem[] | undefined
  ) {
    for (let i = 0; i < 24; i++) {
      cellContent.push({
        activities: [],
        events: [],
        focusSessions: [],
        isEmpty: true,
      });
    }

    for (let i = 0; i < sortedActivities.length; i++) {
      const activity = sortedActivities[i];
      let starttime = new Date(activity.activity.time.start * 1000);
      let index = starttime.getHours();
      cellContent[index].isEmpty = false;
      cellContent[index].activities.push(activity);
    }

    if (this.state.showEvents) {
      for (const event of sortedEvents) {
        let starttime = new Date(event.event.time.start * 1000);
        let index = starttime.getHours();
        cellContent[index].isEmpty = false;
        cellContent[index].events.push(event);
      }
    }

    if (sortedFocus !== undefined)
      for (let i = 0; i < sortedFocus.length; i++) {
        const focusSession = sortedFocus[i];
        let startTime = new Date(focusSession.focusSession.time.start * 1000);
        let index = startTime.getHours();
        cellContent[index].isEmpty = false;
        cellContent[index].focusSessions.push(focusSession);
      }
  }

  populateCells() {
    const { classes, date } = this.props;
    const {
      activities,
      isFuture,
      events,
      heights,
      isToday,
      focusSessions,
      showEvents,
    } = this.state;
    let result = [];
    result.push(
      <Box
        key={-1}
        className={classes.accuaracycell}
        sx={{
          height: heights.accuracyBox + "px",
          flexBasis: heights.accuracyBox + "px!important",
          position: "relative",
        }}
      >
        {/* {!isToday ? (
          <Box className={classes.accuracybox}>
            <Typography
              variant="body2"
              align="center"
              sx={{ color: "#5D626F", fontWeight: "500", margin: "auto" }}
            >
              Accuracy: {date?.accuracy}
            </Typography>
          </Box>
        ) : (
          ""
        )} */}
      </Box>
    );

    let sortedActivities: {
      activity: Activity;
      isOverlap: Boolean;
      showText: Boolean;
    }[] = activities;
    sortedActivities = sortedActivities?.sort((a, b) => {
      return a.activity.time.start - b.activity.time.start;
    });
    let sortedEvents = events;
    sortedEvents = sortedEvents?.sort((a, b) => {
      return a.event.time.start - b.event.time.start;
    });

    let sortedFocus = focusSessions;
    sortedFocus = sortedFocus?.sort((a, b) => {
      return a.focusSession.time.start - b.focusSession.time.start;
    });
    let focusIndex = 0;
    let activityIndex = 0;
    if (showEvents === true) {
      // for (const event of sortedEvents) {
      //   while (activityIndex < sortedActivities.length) {
      //     const activity = sortedActivities[activityIndex];
      //     if (activity.activity.time.start > event.event.time.end) {
      //       break;
      //     }
      //     if (activity.activity.time.end < event.event.time.start) {
      //       activityIndex += 1;
      //       continue;
      //     }
      //     if (
      //       activity.activity.name !== null &&
      //       utils.doesOverlap(activity.activity, event.event)
      //     ) {
      //       while (focusIndex < sortedFocus.length) {
      //         const focusSession = sortedFocus[focusIndex];
      //         if (
      //           focusSession.focusSession.time.start >
      //           activity.activity.time.end
      //         ) {
      //           break;
      //         }
      //         if (
      //           focusSession.focusSession.time.end <
      //           activity.activity.time.start
      //         ) {
      //           focusIndex += 1;
      //           continue;
      //         }
      //         if (
      //           utils.doesOverlap(focusSession.focusSession, activity.activity)
      //         ) {
      //           focusSession.isOverlap = true;
      //         }
      //         focusIndex += 1;
      //       }
      //       activity.isOverlap = true;
      //       event.isOverlap = true;
      //     }
      //     activityIndex += 1;
      //   }
      for (const i in sortedEvents) {
        for (const j in sortedActivities) {
          if (
            !utils.isActivityOffline(sortedActivities[j].activity) &&
            utils.doesOverlap(
              sortedActivities[j].activity,
              sortedEvents[i].event
            )
          ) {
            sortedActivities[j].isOverlap = true;
            sortedEvents[i].isOverlap = true;
            for (const k in sortedFocus) {
              if (
                utils.doesOverlap(
                  sortedFocus[k].focusSession,
                  sortedActivities[j].activity
                )
              ) {
                sortedFocus[k].isOverlap = true;
                // if(sortedFocus[k].focusSession.time.start > 1649443320 && sortedFocus[k].focusSession.time.start<1649446200){
                //   console.log("???", sortedFocus[k].focusSession, sortedActivities[j].activity, sortedEvents[i].event, (utils.doesOverlap(sortedActivities[j].activity, sortedEvents[i].event)))
                // }
              }
            }
          }
        }
      }

      for (let i = 0; i < sortedEvents.length; i++) {
        for (let j = 0; j < sortedEvents.length; j++) {
          if (utils.doesOverlap(sortedEvents[i].event, sortedEvents[j].event)) {
            let val = sortedEvents[i].isOverlap || sortedEvents[j].isOverlap;
            sortedEvents[i].isOverlap = val;
            sortedEvents[j].isOverlap = val;
          }
        }
      }
    } else {
      for (const activity of sortedActivities) {
        activity.isOverlap = false;
      }
      for (const focus of sortedFocus) {
        focus.isOverlap = false;
      }
    }

    let currActivity: { activity: Activity; isOverlap: Boolean } = {
      activity: {
        name: "",
        time: { start: 0, end: 0, duration: 0 },
        icon: "",
        device: { type: "", name: "", id: "" },
        category: "",
        focus: "",
        offline: false,
      },
      isOverlap: false,
    };
    let isEmpty: Boolean = false;
    if (sortedActivities.length > 0) currActivity = sortedActivities[0];
    else {
      isEmpty = true;
    }
    let cellContent: Array<{
      activities: {
        activity: Activity;
        isOverlap: Boolean;
        showText: Boolean;
      }[];
      events: Array<{ event: CalEvent; isOverlap: Boolean }>;
      focusSessions: FocusSessionCalendarItem[];
      isEmpty: Boolean;
    }> = [];

    this.populateCellArray(
      cellContent,
      sortedActivities,
      sortedEvents,
      sortedFocus
    );

    for (const [index, cell] of cellContent.entries()) {
      let oneMinute = heights.cell / 60;
      let acts = [];
      if (cell.isEmpty) {
        result.push(
          <Box
            key={index}
            className={classes.gridcell}
            sx={{
              height: heights.cell + "px",
              flexBasis: heights.cell + "px!important",
              position: "relative",
              borderBottom:
                isFuture || isToday ? "1px solid #E6E7EB" : "1px transparent",
              borderTop:
                isFuture || isToday ? "1px solid #E6E7EB" : "1px transparent",
            }}
          >
            {this.state.columnId === 0 ? (
              <div
                style={{
                  position: "absolute",
                  top: -1,
                  left: -20,
                  height: "1px",
                  width: "20px",
                  backgroundColor: "#E6E7EB",
                  zIndex: 5,
                }}
              ></div>
            ) : (
              ""
            )}
          </Box>
        );
        continue;
      }
      for (const activity of cell.activities) {
        let starttime = new Date(activity.activity.time.start * 1000);
        let endtime = new Date(activity.activity.time.end * 1000);
        let mins = Math.abs(
          (endtime.getTime() - starttime.getTime()) / (1000 * 60)
        );
        mins = mins * oneMinute;
        //OFFLINE
        if (utils.isActivityOffline(activity.activity)) {
          acts.push(
            <Tooltip
              key={starttime.getTime()}
              placement="right"
              title={
                <div style={{ whiteSpace: "pre-line" }}>
                  {utils.getCellText(activity.activity)}
                </div>
              }
              arrow
              followCursor
            >
              <Box
                className={classes.offline}
                sx={{
                  height: mins + "px",
                  position: "absolute",
                  top: starttime.getMinutes() * oneMinute + "px",
                }}
              ></Box>
            </Tooltip>
          );
        } else {
          // ACTIVITY
          acts.push(
            <ActivityItem
              key={starttime.getTime()}
              activity={activity.activity}
              minutes={mins}
              startTime={starttime}
              isOverlap={activity.isOverlap}
              showIcon={mins >= 15}
              showText={activity.showText && mins >= 15}
              isToday={this.state.isToday}
              oneMinute={oneMinute}
              showDevice={this.props.showDevices}
            />
          );
        }
      }
      //Events
      if (showEvents) {
        for (const [i, event] of cell.events.entries()) {
          acts.push(
            <CalendarEvent
              key={i}
              event={event.event}
              isOverlap={event.isOverlap}
              oneMinute={oneMinute}
            />
          );
        }
      }
      //Focus Sessions
      if (cell.focusSessions !== undefined)
        for (let i = 0; i < cell.focusSessions.length; i++) {
          acts.push(
            <FocusIndicator
              key={cell.events.length + i}
              focusSession={cell.focusSessions[i].focusSession}
              oneMinute={oneMinute}
              isOverlap={cell.focusSessions[i].isOverlap}
            />
          );
        }
      result.push(
        <Box key={index} sx={{ position: "relative" }}>
          {this.state.columnId === 0 ? (
            <div
              style={{
                position: "absolute",
                top: -1,
                left: -20,
                height: "1px",
                width: "20px",
                backgroundColor: "#E6E7EB",
                zIndex: 5,
              }}
            ></div>
          ) : (
            ""
          )}
          <Box
            className={classes.gridcell}
            sx={{
              height: heights.cell + "px",
              flexBasis: heights.cell + "px!important",
              position: "relative",
              borderBottom:
                isFuture || isToday ? "1px solid #E6E7EB" : "1px transparent",
              borderTop:
                isFuture || isToday || index === 0
                  ? "1px solid #E6E7EB"
                  : "1px transparent",
              overflow: index === 23 ? "hidden" : "visible",
            }}
          >
            {acts}
          </Box>
        </Box>
      );
    }

    return result;
  }

  render() {
    const { classes } = this.props;
    return <Box className={classes.columnBox}>{this.populateCells()}</Box>;
  }
}

export default CalendarColumn;
