import { Link, Stack, Typography } from "@mui/material";
import { SupportsPage } from "../../supportpage.component";
import { SupportTabContent } from "../how-to-new.component";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import PushPinIcon from "@mui/icons-material/PushPin";

interface PinGoalsBlogProps {}

export const PinGoalsBlog = (props: PinGoalsBlogProps) => {
  return (
    <>
      <Typography
        variant="h6"
        display="block"
        id={SupportTabContent.pinGoals.id}
      ></Typography>
      <SupportsPage
        title={SupportTabContent.pinGoals.title}
        subtitle={SupportTabContent.pinGoals.description}
        loading={false}
      >
        <PinGoalsPage></PinGoalsPage>
      </SupportsPage>
    </>
  );
};

interface PinGoalsProps {}

const PinGoalsPage = (props: PinGoalsProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h6" display="block"></Typography>
      <Typography variant="body1" display="block" fontWeight={"bold"}>
        How to use the pin goals feature
      </Typography>
      <Typography variant="body1" display="block">
        We introduced the pin goal feature to let you create a goal that you
        want to pursue every day.
      </Typography>
      <Typography variant="body1" display="block">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {"You can pin a goal by pressing"}
          &nbsp;
          <PushPinOutlinedIcon />
          &nbsp;
          {"and you can unpin a pinned goal by pressing "}
          &nbsp;
          <PushPinIcon />.
        </div>
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/goals-new/monitor-goal-pin.png" width={"700"} />
      </Typography>
      <Typography variant="body1" display="block">
        When a goal is unpinned, it will stop appearing the next day.
      </Typography>
      <Typography variant="body1" display="block">
        Values within a pinned goal will stay the same.
      </Typography>
      <Typography variant="body1" display="block">
        Pinned goals have to be unpinned before they can be deleted.
      </Typography>
      <Typography variant="body1" display="block">
        Pinned goals can’t be edited. If you want to change any value, then
        unpin and delete the existing pinned goal and create another goal with
        desired values that you wish to pin.
      </Typography>
      <Typography variant="body1" display="block">
        Weekly emails will show your pinned goal performance for the past week.
      </Typography>
      <Typography variant="body1" display="block">
        Daily emails will show your pinned goal performance over the last two
        working days.
      </Typography>
      <Typography variant="body1" display="block"></Typography>
    </Stack>
  );
};
