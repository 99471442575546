import { Box, Typography } from "@mui/material";
import Highcharts, { color, Options, SeriesOptionsType } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import ErrorBoundary from "../../../../errorboundary.component";
const borderRadius = require("highcharts-rounded-corners");

borderRadius(Highcharts);

interface UsageCategoryChartProps {
  chartData: ChartDataEntry[];
}

interface ChartDataEntry {
  value: number;
  name: string;
  color: string;
}

export const UsageCategoryChart = (
  props: UsageCategoryChartProps
): JSX.Element => {
  const [chartOptions, setChartOptions] = useState<Options>({
    chart: {
      renderTo: "container",
      type: "bar",
      height: "70px",
      marginTop: 0,
      animation: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: undefined,
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },

    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            opacity: 0.9,
          },
        },
        events: {
          //   click: this.handleClick,
        },
        stacking: "percent",
        dataLabels: {
          enabled: true,
          align: "right",
          y: -2,
          style: {
            color: "#050505",
            fontFamily: "Roboto, sans-serif",
            fontSize: "max(0.8vw,12px)",
            fontWeight: "light",
            boxShadow: "none",
            textOutline: "none",
            overflow: "ellipsis",
            position: "relative",
            top: "-6px",
          },
          formatter: function () {
            const self = this as any;
            const percent = self.percentage as number;
            if (percent < 15) {
              return "";
            }
            let minutes = this.y as number;
            const hours = Math.floor(minutes / 60);
            minutes = minutes % 60;
            const timeString =
              (hours != 0 ? hours + "h " : "") +
              (minutes != 0 ? minutes + "m " : "");
            return timeString;
          },
        },
      },
      bar: {
        grouping: false,
        pointWidth: 24,
        groupPadding: 0,
      },
    },
  });

  const [noDataOverlay, setNoDataOverlay] = useState<boolean>(false);

  useEffect(() => {
    if (props.chartData) {
      const series = props.chartData.map((data) => {
        return {
          data: [data.value],
          name: data.name,
          color: data.color,
          borderRadiusTopLeft: "",
          borderRadiusTopRight: "",
          borderRadiusBottomLeft: "",
          borderRadiusBottomRight: "",
        };
      });
      if (series.length > 0) {
        series[0].borderRadiusTopLeft = "50%";
        series[0].borderRadiusTopRight = "50%";
        series[series.length - 1].borderRadiusBottomLeft = "50%";
        series[series.length - 1].borderRadiusBottomRight = "50%";
      }
      chartOptions.series = series as unknown as SeriesOptionsType[];

      setChartOptions({ ...chartOptions });
    }
  }, [props.chartData]);

  useEffect(() => {
    setNoDataOverlay(!props.chartData || props.chartData.length == 0);
  }, [props.chartData]);

  return (
    <>
      {noDataOverlay ? (
        <Box
          margin="2% auto"
          borderRadius="8px"
          height="70px"
          textAlign="center"
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          border="1px dashed #AAAFBB"
        >
          <Typography variant="h6Light" margin="auto" width="100%">
            No data available
          </Typography>
        </Box>
      ) : (
        <ErrorBoundary>
          <Box sx={{ marginTop: 2 }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
              allowChartUpdate={true}
              updateArgs={[true, true, true]}
            />
          </Box>
        </ErrorBoundary>
      )}
    </>
  );
};
