import { CognitoUserSession } from "amazon-cognito-identity-js";
import Bowser from "bowser";
import {
  getSession,
  handleSessionNotFound,
} from "../../../accountcontext/account";
const browser = Bowser.getParser(window.navigator.userAgent);

export enum DeviceType {
  COMPUTER = "Computer",
  MOBILE = "Mobile",
  TABLET = "Tablet",
}
const BASE_URL = "/api/user";
export interface Device {
  id?: string;
  accountId?: string;
  name: string;
  type: DeviceType;
  os: string;
  cpuDetails?: string | null;
  created?: string;
  updated?: string;
  deletedAt?: string | null;
  agents?: Agent[];
}

export interface Agent {
  id?: string;
  accountId?: string;
  name: string;
  lastSyncTime?: number;
  agentType?: string;
  created?: string;
  updated?: string;
  deletedAt?: string | null;
}

export interface BrowserAgent extends Agent {
  browser: string;
}

export interface NativeAgent extends Agent {}

export const getCurrentOs = () => {
  return browser.getOSName();
};

export const getCurrentBrowser = () => {
  return browser.getBrowserName();
};

export const getDevices = (accountId: string): Promise<Device[]> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };
        const url = `${BASE_URL}/onboarding/account/${accountId}/device`;
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((response) => JSON.parse(response))
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

export const createDevice = async (
  accountId: string,
  device: Device
): Promise<Device> => {
  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const requestOptions: RequestInit = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify(device),
        };
        const url = `${BASE_URL}/onboarding/account/${accountId}/device`;
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((response) => JSON.parse(response))
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

export const createAgent = async (
  accountId: string,
  deviceId: string,
  agent: BrowserAgent | NativeAgent
): Promise<BrowserAgent | NativeAgent> => {
  let url = `${BASE_URL}/onboarding/account/${accountId}/device/${deviceId}/agent`;
  if ("browser" in agent) {
    url += `/browser`;
  } else {
    url += `/native`;
  }

  return new Promise((resolve, reject) => {
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const requestOptions: RequestInit = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify(agent),
        };
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((response) => JSON.parse(response))
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

export const marshallDefaultDeviceAndAgent = (
  deviceName: string
): {
  device: Device;
  browserAgent: BrowserAgent;
  nativeAgent: NativeAgent;
} => {
  const device: Device = {
    name: deviceName,
    type: DeviceType.COMPUTER,
    os: getCurrentOs(),
  };
  const browserAgent: BrowserAgent = {
    name: `${getCurrentBrowser()}@${deviceName}`,
    browser: getCurrentBrowser(),
  };
  const nativeAgent: NativeAgent = {
    name: `${deviceName}@${getCurrentOs()}`,
  };
  return {
    device,
    browserAgent,
    nativeAgent,
  };
};

export const createDefaultDeviceAndAgent = async (
  accountId: string,
  deviceName: string
): Promise<Device[]> => {
  const { device, browserAgent, nativeAgent } =
    marshallDefaultDeviceAndAgent(deviceName);
  const createdDevice = await createDevice(accountId, device);
  console.log("Create device", createdDevice);
  if (createdDevice.id) {
    const createdBrowserAgent = await createAgent(
      accountId,
      createdDevice.id,
      browserAgent
    );
    const createdNativeAgent = await createAgent(
      accountId,
      createdDevice.id,
      nativeAgent
    );
    return await getDevices(accountId);
  } else {
    throw new Error("Failed to create default device and agent");
  }
};
