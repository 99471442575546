import {
  Activity,
  CalEvent,
  FocusSession,
} from "../models/calendar_main.model";

// const NO_DATA_AVAILABLE_KEY = "No Data Available";
const OFFLINE_KEY = "Offline";

export const isActivityOffline = (activity: Activity): boolean => {
  return activity.offline;
};

export const getCellText = (activity: Activity): string => {
  let start = new Date(activity.time.start * 1000);
  let end = new Date(activity.time.end * 1000);
  const name = isActivityOffline(activity) ? OFFLINE_KEY : activity.name;
  const deviceName = isActivityOffline(activity) ? "" : activity.device.name;
  return (
    name +
    ": " +
    start.getHours().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) +
    ":" +
    start.getMinutes().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) +
    " to " +
    end.getHours().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) +
    ":" +
    end.getMinutes().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) +
    "\n" +
    deviceName
  );
};

export const doesOverlap = (
  activity: Activity | FocusSession | CalEvent,
  calendarEvent: Activity | CalEvent | FocusSession
) => {
  let activityStart = activity.time.start;
  let activityEnd = activity.time.end;
  let eventStart = calendarEvent.time.start;
  let eventEnd = calendarEvent.time.end;
  return (
    (activityStart >= eventStart && activityStart <= eventEnd) ||
    (eventStart >= activityStart && eventStart <= activityEnd) ||
    (activityStart >= eventStart && activityEnd <= eventEnd) ||
    (eventStart >= activityStart && eventEnd <= activityEnd)
  );
};

export const getTodaysEvents = (data: CalEvent[] | undefined, date: Date) => {
  if (data === undefined) return undefined;
  return data.filter((item: FocusSession | CalEvent) => {
    let start = new Date(item.time.start * 1000);
    start.setHours(0, 0, 0, 0);
    return date.getTime() === start.getTime();
  });
};

export const getTodaysFocusSessions = (
  data: FocusSession[] | undefined,
  date: Date
) => {
  if (data === undefined) return undefined;
  return data.filter((item: FocusSession | CalEvent) => {
    let start = new Date(item.time.start * 1000);
    start.setHours(0, 0, 0, 0);
    return date.getTime() === start.getTime();
  });
};
