import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem, IconButton, Typography, Drawer } from "@mui/material";
import { useState, useEffect } from "react";
import { SidePanel } from "../sidepanel/sidepanel.component";
import LeftNavigationBar from '../leftnavigation/leftnavigation.component';

export const MobileHeader = (props: {
    value: string;
    onChange: (dateValue: "today" | "yesterday" | "week" | "month") => void;
  }) => {
    const getTodayRange = () => {
      const now = new Date();
      const yesterday = new Date();
      yesterday.setDate(now.getDate()); // today
      yesterday.setHours(0, 0, 0, 0);
      const endOfYesterday = new Date(yesterday);
      endOfYesterday.setHours(23, 59, 59, 999);
      let dateRange: { start: number; end: number }[] = [];
      dateRange.push({
        start: yesterday.getTime(),
        end: endOfYesterday.getTime(),
      });
      return dateRange;
    };
  
    const menuItems: Array<{
      value: "today" | "yesterday" | "week" | "month";
      text: string;
    }> = [
      {
        value: "today",
        text: "Today",
      },
      {
        value: "yesterday",
        text: "Yesterday",
      },
      {
        value: "week",
        text: "Last 7 days",
      },
      {
        value: "month",
        text: "Last Month",
      },
    ];
  
    const [anchorEl, setAnchorEl] = useState(
      null as (EventTarget & HTMLElement) | null
    );
    const [value, setvalue] = useState(props.value);
    const [dateRange, setdateRange] = useState(getTodayRange());
    const open = Boolean(anchorEl);
    const [navOpen, setnavOpen] = useState(false);
    const [insightsOpen, setinsightsOpen] = useState(false);
  
    const getDates = () => {
      const week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let datewindow = dateRange[dateRange.length - 1];
      let startdate = new Date(datewindow.start);
      let enddate = new Date(datewindow.end);
      if (value === "yesterday" || value === "today") {
        return startdate.toLocaleString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      } else {
        return (
          startdate.toLocaleString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          }) +
          "  -  " +
          enddate.toLocaleString("en-US", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        );
      }
    };
  
    const getYesterdayRange = () => {
      const now = new Date();
      const yesterday = new Date();
      yesterday.setDate(now.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);
      const endOfYesterday = new Date(yesterday);
      endOfYesterday.setHours(23, 59, 59, 999);
      let dateRange: { start: number; end: number }[] = [];
      dateRange.push({
        start: yesterday.getTime(),
        end: endOfYesterday.getTime(),
      });
      return dateRange;
    };
  
    const getWeekRange = () => {
      const now = new Date();
      const week = new Date();
      week.setDate(now.getDate() - 7);
      week.setHours(0, 0, 0, 0);
      const endOfWeek = new Date();
      endOfWeek.setDate(endOfWeek.getDate() - 1);
      endOfWeek.setHours(23, 59, 59, 999);
      let dateRange: { start: number; end: number }[] = [];
      dateRange.push({ start: week.getTime(), end: endOfWeek.getTime() });
      return dateRange;
    };
  
    const getMonthRange = () => {
      const now = new Date();
      const week = new Date();
      week.setDate(now.getDate() - 30);
      week.setHours(0, 0, 0, 0);
      const endOfWeek = new Date();
      endOfWeek.setDate(endOfWeek.getDate() - 1);
      endOfWeek.setHours(23, 59, 59, 999);
      let dateRange: { start: number; end: number }[] = [];
      dateRange.push({ start: week.getTime(), end: endOfWeek.getTime() });
      return dateRange;
    };
  
    useEffect(() => {
      if (value === "today") setdateRange(getTodayRange());
      else if (value === "yesterday") setdateRange(getYesterdayRange());
      else if (value === "week") setdateRange(getWeekRange());
      else if (value === "month") setdateRange(getMonthRange());
      else setdateRange(getTodayRange());
    }, [value]);
  
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleSelect = (val: "today" | "yesterday" | "week" | "month") => {
      setvalue(val);
      setAnchorEl(null);
      props.onChange(val);
    };
  
    const getText = () => {
      if (value === "today") return "TODAY";
      else if (value === "yesterday") return "YESTERDAY";
      else if (value === "week") return "LAST 7 DAYS";
      else if (value === "month") return "LAST MONTH";
      return " ";
    };
  
    return (
      <>
        <Box width="100%" display="block">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent={"center"}
            alignItems="center"
            position="relative"
            sx={{ backgroundColor: "white", padding: "6% 4%" }}
          >
            <img
              src="/assets/Logo Symbol.svg"
              height="60px"
              style={{ position: "absolute", left: "4%" }}
            />
            <Box
              position="relative"
              display="flex"
              flexDirection={"row"}
              alignItems="center"
              justifyContent={"center"}
              marginLeft="4%"
            >
              <Button
                variant="onlytext"
                endIcon={<KeyboardArrowDown />}
                onClick={handleClick}
              >
                {getText()}
              </Button>
              <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{ margin: "0 auto" }}
              >
                {menuItems.map((menuItem, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleSelect(menuItem.value)}
                  >
                    {menuItem.text}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            {/* <Typography variant="body1">Pranav Iyer</Typography> */}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent={"space-between"}
            alignItems="center"
            sx={{ padding: "4% 0%" }}
          >
            <IconButton onClick={() => setnavOpen(true)}>
              <img src="/assets/Menu Hamburger.svg" height="30px" />
            </IconButton>
  
            <Box display="flex" flexDirection="row">
              <img src="/assets/Calendar_date_rang.svg" height={"100%"} />
              <Typography variant="body1" marginLeft="2%">
                {getDates()}
              </Typography>
            </Box>
  
            <IconButton
              onClick={() => setinsightsOpen(true)}
              sx={{
                backgroundColor: "#FFFFFF",
                boxShadow: "-5px 0px 10px rgba(42, 46, 54, 0.06)",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                marginRight: "0%",
              }}
            >
              <img src="/assets/insights_menu.svg" height="30px" />
            </IconButton>
          </Box>
        </Box>
        <Drawer anchor={"left"} open={navOpen} onClose={() => setnavOpen(false)}>
          <LeftNavigationBar />
        </Drawer>
        <Drawer
          anchor={"right"}
          open={insightsOpen}
          onClose={() => setinsightsOpen(false)}
          PaperProps={{ sx: { width: "60%" } }}
        >
          <SidePanel isMobile={true} />
        </Drawer>
      </>
    );
  };