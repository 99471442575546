import * as React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { validatePassword } from "../../onboarding/Authentication/Regex";
import theme from "../../../theme/theme";
import { Close } from "@mui/icons-material";

interface ConfirmPasswordProps {
  onSubmit: (verificationCode: string, newPassword: string) => void;
  errorMessage: string;
  showErrorMessage: Boolean;
  onClose: () => void;
}

export const ConfirmPassword = (props: ConfirmPasswordProps) => {
  const [newPassword, setnewPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [verificationCode, setverificationCode] = useState("");
  const [isEqual, setisEqual] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    message: props.errorMessage,
    showError: props.showErrorMessage,
  });

  const checkPasswordValidity = (pass: string) => {
    let isValid = validatePassword(pass);
    if (isValid !== null) {
      setErrorMessage(isValid);
      return false;
    } else {
      setErrorMessage({
        message: "",
        showError: false,
      });
      return true;
    }
  };

  useEffect(() => {
    setErrorMessage({
      message: props.errorMessage,
      showError: props.showErrorMessage,
    });
  }, [props.errorMessage, props.showErrorMessage]);

  useEffect(() => {
    if (confirmPassword.length === 0) {
      setErrorMessage({ message: "", showError: false });
    } else if (confirmPassword !== newPassword) {
      setErrorMessage({ message: "The fields do not match", showError: true });
    } else {
      if (newPassword.length !== 0) checkPasswordValidity(newPassword);
    }
    setisEqual(newPassword === confirmPassword);
  }, [confirmPassword]);

  useEffect(() => {
    setisEqual(newPassword === confirmPassword);
    if (newPassword.length !== 0) checkPasswordValidity(newPassword);
  }, [newPassword]);

  const handlePassword = (event: React.FormEvent<HTMLInputElement>) => {
    setnewPassword(event.currentTarget.value);
  };

  const handleConfirmPassword = (event: React.FormEvent<HTMLInputElement>) => {
    setconfirmPassword(event.currentTarget.value);
  };

  const handleVerificationCode = (event: React.FormEvent<HTMLInputElement>) => {
    setverificationCode(event.currentTarget.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (verificationCode.length === 0) {
      setErrorMessage({
        message: "Please enter the verification code",
        showError: true,
      });
    } else if (isEqual === false) {
      setErrorMessage({ message: "The fields do not match", showError: true });
    } else if (newPassword.length === 0) {
      setErrorMessage({
        message: "Please enter your password",
        showError: true,
      });
    } else if (confirmPassword.length === 0) {
      setErrorMessage({
        message: "Please confirm your password",
        showError: true,
      });
    } else {
      if (checkPasswordValidity(newPassword)) {
        props.onSubmit(verificationCode, newPassword);
      } else {
        setErrorMessage({
          message: props.errorMessage,
          showError: props.showErrorMessage,
        });
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <IconButton className="close-button" onClick={props.onClose}>
        <Close />
      </IconButton>
      <Box
        sx={{
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignSelf: "center",
          backgroundColor: "white",
          padding: "0% 10%",
          borderRadius: "20px",
          width: "100%",
        }}
      >
        <h1>Reset password</h1>
        <FormControl
          onSubmit={handleSubmit}
          component={"form"}
          variant="standard"
          sx={{ width: "100%", padding: 0, justifyContent: "center" }}
        >
          <label>
            Enter the verification code sent to your registered email ID
            <br />
            <input
              type="text"
              placeholder="Enter Verification Code"
              value={verificationCode}
              onChange={handleVerificationCode}
            />
          </label>
          <label style={{ marginTop: "8%" }}>
            Password
            <br />
            <input
              type="password"
              className="password"
              placeholder="Enter password"
              value={newPassword}
              onChange={handlePassword}
            />
          </label>
          <label style={{ marginTop: "8%" }}>
            Confirm Password
            <br />
            <input
              type="password"
              className="password"
              placeholder="Enter password"
              value={confirmPassword}
              onChange={handleConfirmPassword}
            />
          </label>
          {/* <Box sx={{ display: isEqual ? "none" : "block" }}>
                <Typography color="red" variant="body2" sx={{ margin: "auto" }}>
                  The fields are not equal
                </Typography>
              </Box> */}
          <Box
            sx={{
              display: errorMessage.showError ? "block" : "none",
              height: "1.6em",
              lineHeight: "1.6em",
            }}
          >
            <Typography
              variant="body2"
              sx={{ color: "red", textAlign: "center", marginTop: "6%" }}
            >
              {errorMessage.message}
            </Typography>
          </Box>
          <Button
            variant="contained"
            className="myButton"
            type="submit"
            sx={{
              margin: "15px 0",
              marginTop: "8%",
              backgroundColor: "#643992",
            }}
          >
            Change password
          </Button>
        </FormControl>
      </Box>
    </ThemeProvider>
  );
};
