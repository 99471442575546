import { Box, Stack, Typography } from "@mui/material";
import Highcharts from "highcharts";
import { Options, SeriesOptionsType } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { json } from "stream/consumers";
import ErrorBoundary from "../../../../../errorboundary.component";
import { DetailedTimelineDto } from "../../../../../services/timeline/timeline.model";
import { convertSecondsToHHMM } from "../../../../admin/dailyemail/dailyemail.service";
import { timelineChartColors } from "./timeline-chart.component";

interface GoalProgressChartProps {
  //   chartData: ChartDataEntry[];
  //   title: string;
  timelineData: DetailedTimelineDto[];
  timelineHour: number;
}

export interface ChartDataEntry {
  value: number; //mins
  name: string;
  color: string | { pattern: any };
  appName: string;
  focusId: number | undefined;
}

export const GoalProgressChart = (
  props: GoalProgressChartProps
): JSX.Element => {
  const [time, setTime] = useState<number>(props.timelineHour - 1);
  const [chartOptions, setChartOptions] = useState<Options>({
    chart: {
      renderTo: "container",
      type: "bar",
      height: "70px",
      marginTop: 0,
      animation: false,
      events: {
        click: function (e) {
          console.log(e);
        },
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: undefined,
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        if (this.series.name === "Not Goal") {
          return false;
        }
        return `${this.series.name}: ${convertSecondsToHHMM(this.y * 60)}`;
      },
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: true,
      //   endOnTick: false,
      max: 60,
      tickInterval: 30,
      title: {
        text: "",
      },
      labels: {
        enabled: true,
        formatter: function () {
          let tempTime = this.tick.isLast ? time + 1 : time;
          let t = tempTime > 12 ? tempTime - 12 : tempTime;
          let tod = tempTime >= 12 && this.pos !== 24 ? "pm" : "am";
          if (tempTime === 0 || time === 24) {
            t = 12;
            tod = "am";
          }
          return `${t}:${this.pos % 60 === 0 ? `00` : this.pos}${tod}`;
        },
      },
    },

    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            opacity: 0.9,
          },
        },
        events: {
          //   click: this.handleClick,
        },
        stacking: "normal",
        dataLabels: {
          enabled: false,
          align: "right",
          y: -2,
          style: {
            color: "#050505",
            fontFamily: "Roboto, sans-serif",
            fontSize: "max(0.8vw,12px)",
            fontWeight: "light",
            boxShadow: "none",
            textOutline: "none",
            overflow: "ellipsis",
            position: "relative",
            top: "-6px",
          },
          //   formatter: function () {
          //     const self = this as any;
          //     const percent = self.percentage as number;
          //     if (percent < 15) {
          //       return "";
          //     }
          //     let minutes = this.y as number;
          //     const hours = Math.floor(minutes / 60);
          //     minutes = minutes % 60;
          //     const timeString =
          //       (hours != 0 ? hours + "h " : "") +
          //       (minutes != 0 ? minutes + "m " : "");
          //     return timeString;
          //   },
        },
      },
      bar: {
        grouping: false,
        pointWidth: 24,
        groupPadding: 0,
      },
    },
  });

  const [noDataOverlay, setNoDataOverlay] = useState<boolean>(false);

  useEffect(() => {
    if (props.timelineData.length > 0) {
      const cdata: DetailedTimelineDto[] = JSON.parse(
        JSON.stringify(props.timelineData)
      );
      const chartData = getGoalData(cdata);
      const series = chartData.reverse().map((data) => {
        return {
          data: [data.value],
          name: data.name,
          color: data.color,
          borderRadiusTopLeft: "",
          borderRadiusTopRight: "",
          borderRadiusBottomLeft: "",
          borderRadiusBottomRight: "",
        };
      });
      // if (series.length > 0) {
      //   series[0].borderRadiusTopLeft = "50%";
      //   series[0].borderRadiusTopRight = "50%";
      //   series[series.length - 1].borderRadiusBottomLeft = "50%";
      //   series[series.length - 1].borderRadiusBottomRight = "50%";
      // }
      chartOptions.series = series as unknown as SeriesOptionsType[];

      setChartOptions({ ...chartOptions });
    }
  }, [props.timelineData]);

  useEffect(() => {
    setNoDataOverlay(!props.timelineData || props.timelineData.length == 0);
  }, [props.timelineData]);

  return (
    <>
      {noDataOverlay ? (
        <Box
          margin="2% auto"
          borderRadius="8px"
          height="70px"
          textAlign="center"
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          border="1px dashed #AAAFBB"
        >
          <Typography variant="h6Light" margin="auto" width="100%">
            No data available
          </Typography>
        </Box>
      ) : (
        <ErrorBoundary>
          <Box
            borderRadius="8px"
            justifyContent="center"
            bgcolor="#FFFFFF"
            padding={"5px"}
            sx={{ marginTop: 2 }}
          >
            {/* <Stack direction={"row"}> */}
            <Typography variant="body1Bold">{"Goal View"}</Typography>
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
              allowChartUpdate={true}
              updateArgs={[true, true, true]}
            />
            {/* </Stack> */}
          </Box>
        </ErrorBoundary>
      )}
    </>
  );
};

const getGoalData = (data: DetailedTimelineDto[]) => {
  // console.log(data);
  const newData: ChartDataEntry[] = [];
  for (const entry of data) {
    let temp: ChartDataEntry = {
      value: entry.duration / 60,
      name: "",
      color: "",
      focusId: entry.focusSession?.id,
      appName: entry.subapplication ?? entry.application ?? "",
    };
    if (entry.offline) {
      temp.name = "Offline";
      temp.color = timelineChartColors.Offline.color;
      // temp.appName = entry.subapplication ?? entry.application ?? ""
    } else {
      if (entry.goals.length > 0) {
        temp.name = entry.goals.map((g) => `Goal - ${g.name}`).join(", ");
        temp.color = timelineChartColors.Goal.color;
      } else {
        temp.name = "Not Goal";
        temp.color = timelineChartColors.NoGoal.color;
      }
    }
    newData.push(temp);
  }

  let mergeTemp: ChartDataEntry = newData[0];
  const mergedData: ChartDataEntry[] = [];
  for (let i = 1; i < newData.length; i++) {
    const timelineData = newData[i];
    const entryTooSmall = timelineData.value < 0.1667; // value is in minutes
    if (
      mergeTemp.name === timelineData.name ||
      (timelineData.appName === "" && timelineData.name !== "Offline") ||
      entryTooSmall
    ) {
      mergeTemp.value += timelineData.value;
    } else {
      mergedData.push(mergeTemp);
      mergeTemp = newData[i];
    }
  }
  if (mergeTemp) {
    mergedData.push(mergeTemp);
  }

  // return newData;
  return mergedData;
};

// const dummyData: ChartDataEntry[] = [
//   {
//     value: 25,
//     name: "Goal",
//     color: "#29b6f6",
//   },
//   {
//     value: 8,
//     name: "Not Goal",
//     color: "#29b6f633",
//   },
//   {
//     value: 27,
//     name: "Offline",
//     color: "#00000033",
//   },
//   // {
//   //   value: 20,
//   //   name: "Not Goal",
//   //   color: "#29b6f633",
//   // },
// ];
