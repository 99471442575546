export interface UserItemDto {
  id: number;
  itemIdentifier: string;
  itemType: ItemType;
  categories: any;
}

export interface SaveUpdateCategoryDto {
  id?: number;
  accountId?: string;
  categoryName: string;
  categoryDescription?: string;
  categoryType: CategoryType;
  items: UserItemDto[];
}

export enum ItemType {
  DESKTOP_APP = "desktop_app",
  MOBILE_APP = "mobile_app",
  DOMAIN = "domain",
}

export enum CategoryType {
  USER_CATEGORY = "user_category",
  MYTIRO_CATEGORY = "mytiro_category",
}
