import { Link, Stack, Typography } from "@mui/material";
import { SupportTabContent } from "../../support-tab.component";
import { SupportsPage } from "../../supportpage.component";

interface InisghtsBlogProps {}

export const InsightsBlog = (props: InisghtsBlogProps) => {
  return (
    <>
      <Typography
        variant="h6"
        display="block"
        id={SupportTabContent.insights.id}
      ></Typography>
      <SupportsPage
        title={SupportTabContent.insights.title}
        subtitle={SupportTabContent.insights.description}
        loading={false}
      >
        <Insights></Insights>
      </SupportsPage>
    </>
  );
};

interface InsightsProps {}

const Insights = (props: InsightsProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h6" display="block">
        Increase awareness of your digital behavior
      </Typography>
      <Typography variant="body1" display="block">
        Increasing awareness of your digital behavior makes it easier to build
        healthy digital habits faster. Healthy time habits lead to higher
        productivity, increased efficiency, more deep work, and improved mental
        well-being.
      </Typography>
      <Typography variant="body1" display="block">
        myTiro Insights will ask you one question every day:
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/insights/question.png" width="600"></img>
      </Typography>
      <Typography variant="body1" display="block">
        If you respond correctly:
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/insights/correct-answer.png" width="600"></img>
      </Typography>
      <Typography variant="body1" display="block">
        If you respond incorrectly:
      </Typography>
      <Typography variant="body1" display="block">
        <img
          src="assets/how-to/insights/incorrect-answer.png"
          width="600"
        ></img>
      </Typography>
      <Typography variant="body1" display="block">
        If you choose to skip response:
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/insights/skip-answer.png" width="600"></img>
      </Typography>
      <Typography variant="body1" display="block">
        If the insight was not applicable to you:
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/insights/na-answer.png" width="600"></img>
      </Typography>
      <Typography variant="body1" display="block">
        All the information is from the previous working day.
      </Typography>
      <Typography variant="body1" display="block">
        myTiro does not compute data for activity on the weekends and the
        questions you see on Mondays will reflect data from Friday.
      </Typography>
      <Typography variant="body1" display="block">
        We have a bank of Insights from which to ask a question a day. We will
        keep on adding to questions on a regular basis. Previous myTiro Insights
        will repeat after all questions in the bank have been exhausted.
      </Typography>
      <Typography variant="body1" display="block">
        Every user is asked the same question.
      </Typography>
      <Typography variant="body1" display="block">
        myTiro Insight for the day will always be available on the{" "}
        <Link href={"home"}>Landing Page</Link>. All current and past insights
        will be available on <Link href={"dashboard"}> Dashboard</Link>{" "}
        and&nbsp;
        <Link href={"calendar"}>Calendar Page</Link>.
      </Typography>
      <Typography variant="body1" display="block">
        <img src="assets/how-to/insights/insights.png" width="400"></img>
      </Typography>
      <Typography variant="body1" display="block">
        We are sure you will find this feature Insightful!
      </Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
    </Stack>
  );
};
