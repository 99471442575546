import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Typography,
  Box,
  AccordionDetails,
} from "@mui/material";
import { useState } from "react";

export const AgentAccordion = (props: {
  icon: string;
  title: string;
  index: number;
  //onCheck: (index: number, checked: boolean) => void;
  details: JSX.Element;
}) => {
  const { icon, title, index, details } = props;
  const [expanded, setExpanded] = useState<string | boolean>("panel1");
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{ backgroundColor: "#eceff4" }}
      >
        <img src={icon} style={{ marginRight: "4%" }} />
        <Typography
          variant="body1Bold"
          sx={{ textDecoration: "underline", whitespace: "nowrap" }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {expanded === "panel1" ? (
            <KeyboardArrowDown />
          ) : (
            <KeyboardArrowRight />
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: "#eceff4", paddingTop: 0 }}>
        {details}
      </AccordionDetails>
    </Accordion>
  );
};
