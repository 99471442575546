import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid/Grid";
import React, { useEffect } from "react";
import {
  generateEmail,
  getAccountList,
  getUserData,
  generateEmailData,
  getEmailSubject,
} from "./dailyemail.service";
import { Box } from "@mui/system";
import { DateTime, FixedOffsetZone } from "luxon";
import mailtoLink from "mailto-link";
import {
  Account,
  AccountData,
  Device,
  ExtendedAccountData,
} from "./dailyemail.model";

export const DailyEmail = () => {
  const [selectedUser, setSelectedUser] = React.useState<
    ExtendedAccountData | undefined
  >(undefined);
  const [userMeta, setUserMeta] = React.useState<
    | {
        accountId: string;
        start: Date;
        end: Date;
        startPrev: Date;
        endPrev: Date;
      }
    | undefined
  >(undefined);

  const [rows, setRows] = React.useState<Account[]>([]);

  const [templateString, setTemplateString] = React.useState<string>("");

  const [mailToLink, setMailToLink] = React.useState<string | undefined>(
    undefined
  );

  React.useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    let response = await getAccountList();
    setRows(response.users);
  };

  const handleSelectUser = async (user: Account) => {
    let accountData: AccountData = await getUserData(user.accountId);
    const userData: ExtendedAccountData = {
      ...user,
      ...accountData,
    };
    console.log(userData);
    setSelectedUser(userData);
  };

  useEffect(() => {
    //create meta tag
    if (selectedUser) {
      const meta = {
        accountId: selectedUser.accountId,
        start: new Date(selectedUser.focus.startOfDay * 1000),
        end: new Date(selectedUser.focus.endOfDay * 1000),
        startPrev: new Date(selectedUser.focus.startOfPrevDay * 1000),
        endPrev: new Date(selectedUser.focus.endOfPrevDay * 1000),
      };
      setUserMeta(meta);
      const emailData = generateEmailData(selectedUser);
      const emailTemplate = generateEmail(emailData);
      setTemplateString(emailTemplate);
      const subject = getEmailSubject();
      const link = mailtoLink({
        to: selectedUser?.email,
        subject: subject,
        body: emailTemplate,
      });
      setMailToLink(link);
    }
  }, [selectedUser]);

  useEffect(() => {}, [templateString, userMeta]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <AdminUserTable rows={rows} handleRowClick={handleSelectUser} />
      </Grid>
      <Grid item xs={2}>
        {userMeta ? (
          <>
            <p>Account Id: {userMeta?.accountId}</p>
            <p>start: {userMeta?.start.toLocaleString()}</p>
            <p>end: {userMeta?.end.toLocaleString()}</p>
            <p>start of prev day: {userMeta?.startPrev.toLocaleString()}</p>
            <p>end of prev day: {userMeta?.endPrev.toLocaleString()}</p>
            <p>
              Devices:
              {selectedUser?.devices?.map((d: Device) => {
                return <li>{d.name}</li>;
              })}
            </p>
          </>
        ) : (
          ""
        )}
        <a href={mailToLink} target="_blank">
          Send Email
        </a>
      </Grid>
      <Grid item xs={6}>
        {/* <textarea value={templateString} onChange={e => setTemplateString(e.target.value)}></textarea> */}
        <Box sx={{ width: "100%", height: 500 }}>
          {/* <div style="width:300px;overflow:auto"> */}
          <pre style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
            {templateString}
          </pre>
          {/* </div> */}
        </Box>
      </Grid>
    </Grid>
  );
};

interface AdminUserTableProps {
  rows: any;
  handleRowClick: (row: Account) => void;
}

const AdminUserTable = (props: AdminUserTableProps) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <React.Fragment>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>TimeZone</TableCell>
              <TableCell>User Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: Account) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    onClick={() => props.handleRowClick(row)}
                    tabIndex={-1}
                    key={row.accountId}
                  >
                    <TableCell>{row.profile?.firstName}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.profile?.timezone}</TableCell>
                    <TableCell>
                      {DateTime.local()
                        .setZone(
                          FixedOffsetZone.instance(
                            parseInt(row.profile?.timezone ?? "0")
                          )
                        )
                        .toString()}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
};
