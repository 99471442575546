import { Box, Grid, TablePagination, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { SupportTicket } from "./supportticket.model";
import "../habits/habitstable.styles.css";
import theme from "../../../theme/theme";

const columnSize = {
    accountId: 2,
    fullName: 1,
    email: 2,
    description: 4,
    status: 1,
    created: 2
}

interface TicketsTableRowProps {
    ticket: SupportTicket,
}

const TicketsTableRow = (props: TicketsTableRowProps) => {
    return (
        <Grid container className="admin-tablerow">
            <Grid item xs={columnSize.accountId} className="admin-tablecell">
                <Typography variant="body1">{props.ticket.accountId}</Typography>
            </Grid>
            <Grid item xs={columnSize.fullName} className="admin-tablecell">
                <Typography variant="body1">{props.ticket.fullName}</Typography>
            </Grid>
            <Grid item xs={columnSize.email} className="admin-tablecell">
                <Typography variant="body1">{props.ticket.email}</Typography>
            </Grid>
            <Grid item xs={columnSize.description} sx={{ wordWrap: "break-word", whiteSpace: 'pre-wrap' }}>
                <Typography variant="body1">{props.ticket.description}</Typography>
            </Grid>
            <Grid item xs={columnSize.status} className="admin-tablecell">
                <Typography variant="body1">{props.ticket.status}</Typography>
            </Grid>
            <Grid item xs={columnSize.created} className="admin-tablecell">
                <Typography variant="body1">{props.ticket.created}</Typography>
            </Grid>
        </Grid>
    );
};

const TicketsTableHeader = () => {
    return (
        <Grid container className="admin-tableheader">
            <Grid item xs={columnSize.accountId} className="admin-tableheadercell">
                <Typography variant="body1Bold">Account ID</Typography>
            </Grid>
            <Grid item xs={columnSize.fullName} className="admin-tableheadercell">
                <Typography variant="body1Bold">Full Name</Typography>
            </Grid>
            <Grid item xs={columnSize.email} className="admin-tableheadercell">
                <Typography variant="body1Bold">Email</Typography>
            </Grid>
            <Grid item xs={columnSize.description} overflow="scroll">
                <Typography variant="body1Bold">Description</Typography>
            </Grid>
            <Grid item xs={columnSize.status} className="admin-tableheadercell">
                <Typography variant="body1Bold">Status</Typography>
            </Grid>
            <Grid item xs={columnSize.created} className="admin-tableheadercell">
                <Typography variant="body1Bold">Created</Typography>
            </Grid>
        </Grid>
    );
};

interface SupportTicketTableProps {
    tickets: SupportTicket[],
}

export const TicketsTable = (props: SupportTicketTableProps) => {
    const [tickets, setTickets] = useState(props.tickets);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        if (tickets !== props.tickets) {
            setTickets(props.tickets);
        }
    }, [props.tickets]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Box
            className="admin-table"
            marginTop="1%"
            sx={{ backgroundColor: theme.palette.secondary.main }}
        >
            <TicketsTableHeader />
            {tickets
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((ticket, index) => (
                <TicketsTableRow key={index} ticket={ticket} />
            ))}
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={props.tickets.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage} />
        </Box>
    );
};