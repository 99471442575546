import { Box, Grid } from "@mui/material";
import { Outlet } from 'react-router-dom';

const yellowpattern = "/assets/forms/Sign_in_Sign_up_Pattern_3.svg";
const greenpattern = "/assets/forms/Sign_in_Sign_up_Pattern_2.svg";
const graypattern = "/assets/forms/Sign_in_Sign_up_Pattern_1.svg";


export const FormLayout = () => {
  return (
    <div
      style={{
        backgroundColor: "#fff",
        width: "100vw",
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box sx={{height: '10vh', width: '100%', padding: '24px 60px'}}>
          <img src="/assets/Logo_login.svg" width="20%" style={{minWidth: '200px'}}/>
      </Box>
      <Box sx={{position: 'absolute', top: '74vh', left: '-200px', zIndex: 0}}>
          <img src={yellowpattern}/>
      </Box>
      <Box sx={{position: 'absolute', top: '25vh', right: '-200px', zIndex: 0}}>
          <img src={greenpattern}/>
      </Box>
      <Box sx={{position: 'absolute', top: '20vh', left: '30vw', zIndex: 0}}>
          <img src={graypattern} style={{zIndex: 0}}/>
      </Box>
      <Grid container alignItems="center" justifyContent="center" sx={{width: '100vw', position: 'relative', zIndex: 1, height: '90vh', overflow: 'scroll'}}>
        <Grid item xs={12} md={6} xl={4} sx={{backgroundColor: "white", padding: 0, boxShadow:" 0px 10px 80px 0px #282C320F", borderRadius: '8px'}}>
          <Outlet />
        </Grid>
      </Grid>
          
    </div>
  );
};
