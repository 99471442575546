import { Delete } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { AgentAccordion } from "../../../pages/onboarding/components/downloadagent/agentAccordion.component";
import { allAgentItems } from "../../../pages/onboarding/components/downloadagent/utils/downloadagent.utils";
import { SettingsPage } from "../settingspage.component";
import { formatDate, timeFromNow } from "../utils/settings.utils";
import { Agent, Device, getDevices } from "./device.service";

const AgentEntry = (props: { agent: Agent }) => {
  const { agent } = props;

  return (
    <Grid
      container
      direction="row"
      sx={{
        backgroundColor: "white",
        borderRadius: "8px",
        padding: "3% 2%",
        width: "100%",
        margin: "1% 0",
      }}
    >
      <Grid item sm={2}>
        <Box sx={{ display: "block" }}>
          <Typography variant="body1" sx={{ color: "#7F8493" }}>
            Type
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={6}>
        <Box sx={{ display: "block" }}>
          <Typography variant="body1" sx={{ color: "#7F8493" }}>
            Name
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={2}>
        <Box sx={{ display: "block" }}>
          <Typography variant="body1" sx={{ color: "#7F8493" }}>
            Last Synced
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={2}>
        <Box sx={{ display: "block" }}>
          <Typography variant="body1" sx={{ color: "#7F8493" }}>
            Date added
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={2}>
        <Typography variant="body1Bold">{agent.agentType}</Typography>
      </Grid>
      <Grid item sm={6}>
        <Typography variant="body1Bold">{agent.name}</Typography>
      </Grid>
      <Grid item sm={2}>
        <Typography variant="body1Bold">
          {agent.lastSyncTime
            ? timeFromNow(agent.lastSyncTime) + " ago"
            : " - "}
        </Typography>
      </Grid>
      <Grid item sm={2}>
        <Typography variant="body1Bold">{formatDate(agent.created)}</Typography>
      </Grid>
    </Grid>
  );
};

const DeviceEntry = (props: { device: Device; index: number }) => {
  const { device, index } = props;

  return (
    <Box
      sx={{
        display: "block",
        backgroundColor: "#F3F5F6",
        borderRadius: "8px",
        padding: "1% 1%",
        marginBottom: "1%",
      }}
    >
      <Grid container rowSpacing={2} sx={{ paddingTop: "2%" }}>
        <Grid item md={5} sx={{ paddingLeft: "2%" }}>
          <Box
            height={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignContent={"center"}
            textAlign="left"
          >
            <Typography variant="body1Bold" sx={{ m: "auto 0" }}>
              {device.name} ({device.type})
            </Typography>
          </Box>
        </Grid>
        <Grid item md={7}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              alignContent: "center",
              padding: "0 4%",
            }}
          >
            {/* <IconButton sx={{ padding: 0 }}>
              <Add />
            </IconButton> */}
            {/* <Delete fontSize="medium" sx={{ margin: "auto 4%" }} />
            <img
              src="/assets/dashboardcards/Edit.svg"
              height={"100%"}
              style={{ margin: "auto 0" }}
            /> */}
          </Box>
        </Grid>
        <Grid item xs={12}>
          {device.agents ? (
            device.agents.map((agent, index) => (
              <AgentEntry agent={agent} key={index} />
            ))
          ) : (
            <Typography variant="h5">No agents found</Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

interface DeviceManagementProps {}

const getDevicesFromService = async () => {
  const id = localStorage.getItem("id");
  if (id) {
    try {
      let devices = await getDevices(id);
      return devices;
    } catch (err) {
      console.log("devices could not be fetched", err);
      return undefined;
    }
    // getDevices(id)
    //   .then((devices)=> {return devices})
    //   .catch(err => {console.log("devices could not be fetched", err); return undefined})
  } else {
    console.log("ID not found please login again");
    return undefined;
  }
};

const DeviceManagement: React.FunctionComponent<DeviceManagementProps> = () => {
  const [deviceList, setdeviceList] = useState([] as Device[]);
  useEffect(() => {
    const loadData = async () => {
      const devices = await getDevicesFromService();
      if (devices) {
        setdeviceList(devices);
      }
    };
    loadData();
  }, []);
  return (
    <SettingsPage
      title="Devices & Browsers"
      subtitle="Modify your devices and browsers"
    >
      {/* <Box>
        <Typography variant="h6">Device Management</Typography>
        <Typography variant="body2">Modify your device settings</Typography>
      </Box> */}
      {/* <div
        style={{
          height: "1px",
          borderTop: "1px dashed #D6DAE480",
          margin: "4% 0",
        }}
      /> */}
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          marginBottom: "4%",
        }}
      > */}
      {/* <Box display="block" width="100%">
          <Typography variant="h6" sx={{ margin: "auto 0" }}>
            Your devices
          </Typography>
          <Typography variant="body2">
            List of devices connected to your account
          </Typography>
        </Box> */}
      {/* <Button
          variant="rounded_purple"
          sx={{ fontSize: "max(12px,0.83vw)", padding: "2% 6%" }}
        >
          Add Device
        </Button> */}
      {/* </Box> */}
      <Box
        sx={{
          overflow: "auto",
          width: "102%",
          paddingLeft: "1%",
          paddingRight: "1%",
          marginLeft: "-1%",
        }}
      >
        {deviceList.length > 0 ? (
          deviceList.map((device: Device, index: number) => (
            <DeviceEntry device={device} index={index} />
          ))
        ) : (
          <Typography variant="h6" color="lightgray">
            No devices found
          </Typography>
        )}
      </Box>
      <Box display="block" width="100%" marginTop="4%">
        <Typography variant="h6">Downloads</Typography>
        <Typography variant="body2">
          Download the myTiro apps and extension to view all your data
        </Typography>
        <Box display="block" width="100%" marginTop="4%">
          <Box></Box>
          {allAgentItems.map((agent, index) => (
            <AgentAccordion index={index} key={index} {...agent} />
          ))}
          <Box></Box>
        </Box>
      </Box>
    </SettingsPage>
  );
};

export default DeviceManagement;
