import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FragmentationData } from "../../dashboard.models";
import { APP_DUMMY_COLOR } from "./charts/app-timeline-chart.component";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

interface AppFragmentationProps {
  fragmentationData: FragmentationData;
  appName: string;
}

export const AppFragmentation = (props: AppFragmentationProps) => {
  const [fragData, setFragData] = useState<FragmentationData>({});
  const [appName, setAppName] = useState<string>("App");

  useEffect(() => {
    setFragData(props.fragmentationData);
    getTopApps(props.fragmentationData);
  }, [props.fragmentationData]);

  useEffect(() => {
    setAppName(props.appName);
  }, [props.appName]);

  // useEffect(() => {
  //   getTopApps(fragData);
  // }, [fragData]);

  const getTopApps = (fragData: FragmentationData) => {
    let entryTo;
    let exitFrom;
    if (fragData?.entryTo) {
      entryTo = Object.entries(fragData.entryTo)
        .sort(([, a], [, b]) => b - a)
        .slice(0, 2)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    }
    if (fragData?.exitFrom) {
      exitFrom = Object.entries(fragData.exitFrom)
        .sort(([, a], [, b]) => b - a)
        .slice(0, 2)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    }
    const data: FragmentationData = {
      appName: fragData?.appName ?? "",
      entryTo,
      exitFrom,
    };
    // console.log("at func appfrag: ", fragData, data);
    setFragData(data);
  };

  return (
    <Box
      sx={{
        bgcolor: "white",
        width: "100%",
        padding: "1.5%",
        borderRadius: "12px",
        paddingBottom: "2%",
        height: "100%",
      }}
    >
      <Typography variant="h6">
        <span style={{ fontWeight: 400 }}>
          What precedes and follows{" "}
          <span style={{ color: "#7E4BB7", fontWeight: 700 }}>{appName}</span>?
        </span>
      </Typography>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Stack spacing={2} sx={{ mt: 2 }}>
            {Object.keys(fragData?.exitFrom ?? []).map((key, index) => {
              return (
                <FragmentationLine
                  key={index}
                  to={fragData?.appName ?? ""}
                  from={key}
                  appName={fragData?.appName ?? ""}
                  count={fragData?.exitFrom?.[key] ?? 0}
                ></FragmentationLine>
              );
            })}
          </Stack>
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack spacing={2} sx={{ mt: 2 }}>
            {Object.keys(fragData?.entryTo ?? []).map((key, index) => {
              return (
                <FragmentationLine
                  key={index}
                  from={fragData?.appName ?? ""}
                  to={key}
                  appName={fragData?.appName ?? ""}
                  count={fragData?.entryTo?.[key] ?? 0}
                ></FragmentationLine>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

interface FragmentationLineProps {
  from: string;
  to: string;
  appName: string;
  count: number;
}

const FragmentationLine = (props: FragmentationLineProps) => {
  return (
    <Stack direction={"row"} justifyContent="space-evenly">
      {/* <Grid container spacing={1}> */}
      {/* <Grid item xs={4} md={4}> */}
      {/* <Typography
          variant="body1"
          color={props.from === props.appName ? APP_DUMMY_COLOR : "black"}
          fontWeight={props.from === props.appName ? "bold" : ""}
        >
          {props.from}
        </Typography> */}
      <Chip
        label={props.from}
        color={props.from === props.appName ? "primary" : "default"}
        sx={{ width: "15em" }}
      ></Chip>
      {/* </Grid> */}
      {/* <Grid item xs={4} md={3}> */}
      {/* <Typography variant="body1">{"->"}</Typography> */}

      <Box sx={{ display: "flex", position: "relative" }}>
        {/* <ArrowRightAltIcon
            fontSize="medium"
            sx={{
              // positon: "absolute",
              // right: "50%",
              // width: "130px",
              ".MuiIcon-root": { width: "130px" },
            }}
          ></ArrowRightAltIcon> */}
        {`---- ${props.count} --->`}
      </Box>
      {/* </Grid> */}
      {/* <Grid item xs={4} md={4}> */}
      {/* <Typography
          variant="body1"
          color={props.to === props.appName ? APP_DUMMY_COLOR : "black"}
          fontWeight={props.to === props.appName ? "bold" : ""}
        >
          {props.to}
        </Typography> */}
      <Chip
        label={props.to}
        color={props.to === props.appName ? "primary" : "default"}
        sx={{ width: "15em" }}
      ></Chip>
      {/* </Grid> */}
      {/* <Grid item xs={3} md={2}>
        <Typography variant="body1">{props.count} times</Typography>
      </Grid> */}
      {/* <Grid item md={1}></Grid> */}
      {/* </Grid> */}
    </Stack>
  );
};
