import { Activity } from "../models/calendar_main.model";

const UNKNOWN_CATEGORY = "Unknown"

// merge activities if they belong to same category, activity name is the one with largest cumulative duration
const mergeConsecutiveData = (
    activities: Activity[],
    mergeThreshold: number,
    resolutionInMinutes: number
): Activity[] => {
    const ret = [];
    let categoricalActivities = [];
    const actMap: any = {};
    let lastActivity: Activity | undefined;
    let maxDuration = 0;
    let startTime = 0;
    let endTime = 0;
    let duration = 0;
    for (const activity of activities) {
        if (
            lastActivity &&
            ((lastActivity.category === activity.category && lastActivity.category !== UNKNOWN_CATEGORY) || (lastActivity.category === UNKNOWN_CATEGORY && lastActivity.name === activity.name)) &&
            !activity.focus &&
            !lastActivity.focus &&
            endTime + mergeThreshold >= activity.time.start
            //   activity.time.duration < resolutionInMinutes * 60
        ) {
            categoricalActivities.push(activity);
            endTime = activity.time.end;
            duration += activity.time.duration;
            // lastActivity.time.end = activity.time.end;
            // lastActivity.time.duration += activity.time.duration;
        } else {
            if(lastActivity) {
                const longestActivity = sumActivitiesByName(categoricalActivities);
                // console.log(longestActivity);
                // console.log(categoricalActivities);
                if(longestActivity) {
                    lastActivity.name = longestActivity.name
                }
                lastActivity.time.end = endTime
                lastActivity.time.duration = duration
            }
            categoricalActivities = [];
            categoricalActivities.push(activity);
            ret.push(activity);
            lastActivity = activity;
            startTime = activity.time.start;
            endTime = activity.time.end;
            duration = activity.time.duration;
            maxDuration = activity.time.duration;
        }
    }
    if(lastActivity) {
        const longestActivity = sumActivitiesByName(categoricalActivities);
        // console.log(longestActivity);
        // console.log(categoricalActivities);
        if(longestActivity) {
            lastActivity.name = longestActivity.name
        }
        lastActivity.time.end = endTime
        lastActivity.time.duration = duration
    }
    // console.log(ret);
    return ret;
}


// merge activities whose duration < resolution
const mergeSmallIntervals = (
    activities: Activity[],
    resolutionInMinutes: number
): Activity[] => {
    const ret = [];
    let lastActivity: Activity | undefined;
    for (const activity of activities) {
        if (
            lastActivity &&
            activity.time.duration < resolutionInMinutes * 60 &&
            !activity.focus
        ) {
            lastActivity.time.end = activity.time.end;
            lastActivity.time.duration += activity.time.duration;
        } else {
            ret.push(activity);
            lastActivity = activity;
        }
    }
    return ret;
}


//sum up activities of same name and return max duration
const sumActivitiesByName = (activities: Activity[]): Activity | undefined => {
    const actMap: any = {};
    activities.forEach((activity) => {
        if (actMap[activity.name]) {
            actMap[activity.name].time.duration += activity.time.duration;
        } else {
            actMap[activity.name] = JSON.parse(JSON.stringify(activity));
        }
    });

    const acts = Object.values(actMap);
    acts.sort((a: any, b: any) => b.time.duration - a.time.duration);
    const a: any = acts.shift();
    return a as Activity;
};

// function to create dummy offline activity in place of missing data
// const handleMissingData = (
//     activities: Activity[],
// ): Activity[] => {
//     let startTime = activities[0]?.time.start || 0;
//     let prevTime = startTime;
//     const ret = [];
//     for (let i = 0; i < activities.length; i++) {
//         if(prevTime != activities[i].time.start) {
//             let dummyActivity: Activity = {
//                 time: {
//                     start: prevTime,
//                     end: activities[i].time.start,
//                     duration: activities[i].time.start - prevTime,
//                 },
//                 icon: "",
//                 category: UNKNOWN_CATEGORY,
//                 device: {
//                     type: null,
//                     name: "No Data Available",
//                 },
//                 offline: true,
//                 name: "",
//                 focus: ""
//             }
//             ret.push(dummyActivity as Activity);
//         }
//         ret.push(activities[i]);
//         prevTime = activities[i].time.end;
//     }
//     return ret;
    // if(acts) {
    //     const longestActivity: any = acts.reduce((a: any, b: any) => a.time.duration > b.time.duration ? a : b);
    //     console.log(longestActivity);
    //     return longestActivity as Activity;
    // } else {
    //     return undefined;
// }
// }

// spilt activties to list of lists of (intervals of size resolution/focus sessions)
const splitDataIntervalAndFocusWise = (
    activities: Activity[],
    resolutionInMinutes: number
): Activity[][] => {
    const intervalActivities = []
    let ret = [];
    let focusSes = [];
    let startTime = activities[0]?.time.start || 0;
    let endOfIntervalTime = startTime + resolutionInMinutes * 60
    let focusId = -1;
    for (let i = 0; i < activities.length; i++) {
        if (activities[i].focus) {
            if (ret.length != 0) {
                intervalActivities.push(ret);
                ret = [];
            }
            if (Number(activities[i].focus) != focusId && focusSes.length != 0) {
                intervalActivities.push(focusSes);
                focusSes = [];
            }
            focusSes.push(activities[i]);
            focusId = Number(activities[i].focus);
            endOfIntervalTime = activities[i].time.end + resolutionInMinutes * 60
        } else {
            if (focusSes.length != 0) {
                intervalActivities.push(focusSes);
                focusSes = [];
            }
            if (
                // activities[i].time.start <= startTime && 
                activities[i].time.end <= endOfIntervalTime
            ) {
                ret.push(activities[i]);
            } else {
                if (activities[i].time.start > endOfIntervalTime) {
                    if (ret.length != 0) {
                        intervalActivities.push(ret);
                        ret = [];
                    }
                    endOfIntervalTime = activities[i].time.start + resolutionInMinutes * 60;
                }
                while (activities[i].time.end >= endOfIntervalTime && endOfIntervalTime - activities[i].time.start > 0) {
                    const activity = {
                        ...activities[i],
                        time: {
                            start: activities[i].time.start,
                            end: endOfIntervalTime,
                            duration: endOfIntervalTime - activities[i].time.start
                        },
                        // toMerge: activities[i].focus === undefined ? true : false,
                    }
                    ret.push(activity);
                    intervalActivities.push(ret);
                    ret = [];
                    activities[i].time.start = endOfIntervalTime;
                    endOfIntervalTime += resolutionInMinutes * 60;
                    // console.log("here");
                }
                ret.push(activities[i]);
            }
        }
    }
    if (ret.length != 0) {
        intervalActivities.push(ret);
    }
    if (focusSes.length != 0) {
        intervalActivities.push(focusSes);
    }
    return intervalActivities;
}


//merge interval/focus session data to display the longest activity
const mergeCategoricalData = (
    intervalData: Activity[][]
): Activity[] => {
    const ret = [];
    for (let i = 0; i < intervalData.length; i++) {
        const longestActivity: Activity | undefined = sumActivitiesByName(intervalData[i]);
        if (
            longestActivity &&
            longestActivity.time.duration >= 1 //0.5 * (duration * 60) // if you need to filter out activities with duration < 0.5 * (duration * 60)
        ) {
            const actStart = intervalData[i][0].time.start;
            const actEnd = intervalData[i][intervalData[i].length - 1].time.end;
            const actDuration = actEnd - actStart;
            longestActivity.time = {
                start: actStart,
                end: actEnd,
                duration: actDuration,
            };
            ret.push(longestActivity);
        } else {
            intervalData[i].forEach((a) => ret.push(a));
        }
    }
    // console.log(ret);
    return ret;
}

export const masterMergeLogic = (
    activities: Activity[],
    resolutionInMinutes: number = 1
): Activity[] => {
    // const time = Date.now();
    const splitActivities = splitDataIntervalAndFocusWise(activities, resolutionInMinutes);
    //focus sessions are not to be merged 
    const mergedCategoricalData = mergeCategoricalData(splitActivities);
    const mergedConsecutiveData = mergeConsecutiveData(mergedCategoricalData, 10, resolutionInMinutes);
    const mergedData = mergeSmallIntervals(mergedConsecutiveData, resolutionInMinutes);
    // console.log("Duration: ", Date.now() - time);
    return mergedData;
    // return mergedConsecutiveData;
}

// export const testCalendarMerger = () => {
//     console.log("at test");
//     console.log(masterMergeLogic(dummyActs));
//   }

