import { Box, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { CalEvent } from "../models/calendar_main.model";

interface CalendarEventProps {
  event: CalEvent;
  isOverlap: Boolean;
  oneMinute: number;
}

interface CalendarEventState {
  name: string;
  start: number;
  duration: number;
  oneMinute: number
}

class CalendarEvent extends React.Component<
  CalendarEventProps,
  CalendarEventState
> {
  constructor(props: CalendarEventProps) {
    super(props);
    let start = new Date(props.event.time.start * 1000);
    let duration = Math.abs(props.event.time.duration / 60);
    this.state = {
      name: props.event.name,
      duration: duration,
      start: start.getMinutes(),
      oneMinute: props.oneMinute,
    };
  }

  static getDerivedStateFromProps(
    nextProps: CalendarEventProps,
    prevState: CalendarEventState
  ) {
    if(nextProps.oneMinute !== prevState.oneMinute){
      return{
        oneMinute: nextProps.oneMinute,
      }
    }
    return null;
  }

  render() {
    const { name, duration, start, oneMinute } = this.state;
    const { isOverlap} = this.props;
    let starttime = new Date(this.props.event.time.start * 1000);
    let end = new Date(this.props.event.time.end * 1000);
    return (
      <Tooltip
        key={start}
        placement="right"
        title={(
          name +
          ":\n" +
          starttime.getHours().toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          ":" +
          starttime.getMinutes().toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          " to " +
          end.getHours().toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          ":" +
          end.getMinutes().toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })
        )}
        arrow
        followCursor
      >
        <Box
          sx={{
            position: "absolute",
            top: start*oneMinute + "px",
            display: "flex",
            flexDirection: "column",
            width: isOverlap ? "50%" : "100%",
            boxSizing: "border-box",
            backgroundColor: "#598DF1",
            borderRadius: "5px",
            border: "1px solid white",
            justifyContent: "flex-start",
            marginLeft: isOverlap ? "50%" : 0,
            height: duration * oneMinute + "px",
            zIndex: 5,
            padding: "2% 4%",
            overflow: "hidden",
            "&:hover": {
              opacity: '0.9',
            },
          }}
        >
          {(duration * oneMinute) >= 20 ? <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              height: "100%",
              maxHeight: duration * oneMinute + "px",
              width: "100%",
              lineHeight: "1.2em",
            }}
          >
            {/* <img
              src="/assets/calendar/Calendar_Events.svg"
              height="75%"
              style={{ maxHeight: "18px", marginRight: "4%" }}
            /> */}
            <Typography
              variant="body1"
              sx={{
                color: "white",
                fontSize: "12px",
                wordWrap: "break-word",
                width: '100%',
                "&::before": {
                  content: "url('/assets/calendar/Calendar_Events.svg')",
                  display: "block",
                  height: duration * oneMinute,
                  float: "left",
                  marginRight: "10%",
                },
              }}
            >
              {name}
            </Typography>
          </Box> : ""}
        </Box>
      </Tooltip>
    );
  }
}

export default CalendarEvent;
