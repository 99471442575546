import * as React from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import "../../onboarding/components/onboarding.styles.css";
import theme from "../../../theme/theme";
import { Close } from "@mui/icons-material";

interface ForgotPasswordProps {
  credentials: { email: string | null; password: string | null };
  onSubmit: () => void;
  onClose: () => void;
  onEmailChange: (email: string | null) => void;
  errorMessage: string;
  showErrorMessage: Boolean;
}

export const ForgotPassword: React.FunctionComponent<ForgotPasswordProps> = (
  props: ForgotPasswordProps
) => {
  const handleEmail = (event: React.FormEvent<HTMLInputElement>) => {
    props.onEmailChange(event.currentTarget.value);
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    props.onSubmit();
  };
  return (
    <ThemeProvider theme={theme}>
      <IconButton className="close-button" onClick={props.onClose}>
        <Close />
      </IconButton>
      <Box
        sx={{
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignSelf: "center",
          backgroundColor: "white",
          padding: "0% 10%",
          borderRadius: "20px",
          width: "100%",
        }}
      >
        <h1>
          <span style={{ color: "#7E4BB7" }}>Reset your password</span>
        </h1>
        <FormControl
          onSubmit={onSubmit}
          component={"form"}
          variant="standard"
          sx={{
            width: "100%",
            padding: 0,
            justifyContent: "center",
            marginTop: "4%",
          }}
        >
          <label>
            Email
            <br />
            <input
              type="text"
              placeholder="Enter email"
              value={props.credentials.email ?? ""}
              onChange={handleEmail}
            />
          </label>

          <Box
            sx={{
              display: props.showErrorMessage ? "block" : "none",
              height: "1.6em",
              lineHeight: "1.6em",
            }}
          >
            <Typography
              variant="body2"
              sx={{ color: "red", textAlign: "center", marginTop: "6%" }}
            >
              {props.errorMessage}
            </Typography>
          </Box>
          <Button
            variant="contained"
            className="myButton"
            type="submit"
            onClick={onSubmit}
            sx={{
              margin: "15px 0",
              marginTop: "45px",
              backgroundColor: "#643992",
            }}
          >
            Send Verification Code
          </Button>
        </FormControl>
      </Box>
    </ThemeProvider>
  );
};
