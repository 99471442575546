import * as React from "react";
import {
  Add,
  ArrowBackIos,
  ArrowForwardIos,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { calendarStyles } from "./styles/calendar.styles";
import { WithStyles, withStyles } from "@mui/styles";
import { DateRange } from "./models/calendar_main.model";
import "./styles/calendarday.styles.css";
import { displayOverlay } from "../../overlay.service";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import {
  processIntegrationResponse,
  startIntegration,
} from "../../google_login.service";
import { getSession } from "../../accountcontext/account";
import { Navigate } from "react-router";
import { previousAppUrl } from "../../services/app.service";

interface CalendarHeaderProps extends WithStyles<typeof calendarStyles> {
  dateRange: DateRange;
  handleIncrement: () => void;
  handleDecrement: () => void;
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  onReset: () => void;
  handleSwitch: (showEvents: boolean) => void;
  handleChange: (currentLegend: string) => void;
  currentLegend: string;
  showEvents: boolean;
  disableZoomOut: boolean;
  disableZoomIn: boolean;
  mergeResolution: number;
  isToday: Boolean;
}

interface CalendarHeaderState {
  dateRange: {
    centerDate: Date;
    startDate: Date;
    endDate: Date;
  };
  disableZoomOut: boolean;
  disableZoomIn: boolean;
  isToday: Boolean;
  currentLegend: string;
  showEvents: boolean;
  showOverlay: string | null;
  eventsConnected: string | null;
  reload: boolean;
}

const legends = ["categories", "devices"];
const showOverlay = displayOverlay("COMPONENT_CALENDAR_EVENTS");

class CalendarHeader extends React.Component<
  CalendarHeaderProps,
  CalendarHeaderState
> {
  constructor(props: CalendarHeaderProps) {
    super(props);
    this.state = {
      dateRange: props.dateRange,
      disableZoomIn: props.disableZoomIn,
      disableZoomOut: props.disableZoomOut,
      isToday: props.isToday,
      showEvents: props.showEvents,
      currentLegend: props.currentLegend,
      showOverlay: null,
      eventsConnected: null,
      reload: false,
    };
    this.showDate = this.showDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.compeleteIntegration = this.compeleteIntegration.bind(this);
  }

  static getDerivedStateFromProps(
    newProps: CalendarHeaderProps,
    prevState: CalendarHeaderState
  ) {
    return {
      disableZoomOut: newProps.disableZoomOut,
      disableZoomIn: newProps.disableZoomIn,
      isToday: newProps.isToday,
      currentLegend: newProps.currentLegend,
      showEvents: newProps.showEvents,
      dateRange: newProps.dateRange,
    };
  }

  componentDidMount() {
    if (this.state.showOverlay === null) {
      displayOverlay("COMPONENT_CALENDAR").then((res) =>
        this.setState({
          showOverlay: res,
        })
      );
      displayOverlay("COMPONENT_CALENDAR_EVENTS").then((res) =>
        this.setState({
          eventsConnected: res,
        })
      );
    }
    window.addEventListener("message", this.compeleteIntegration);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.compeleteIntegration);
  }
  componentDidUpdate(
    prevProps: CalendarHeaderProps,
    prevState: CalendarHeaderState
  ) {
    if (prevState.currentLegend !== this.state.currentLegend) {
    }
    if (prevState.showEvents !== this.state.showEvents) {
    }
  }

  showDate() {
    const { dateRange } = this.state;
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    if (now.getTime() === dateRange.centerDate.getTime()) {
      return "Today";
    } else {
      return dateRange.centerDate.toLocaleString("en-us", {
        month: "long",
        day: "numeric",
      });
    }
  }
  compeleteIntegration(event: MessageEvent) {
    if (!event || !("code" in event.data)) {
      return;
    }
    const provider = "google_calendar";
    getSession().then((session: CognitoUserSession) => {
      const idToken = session.getIdToken().getJwtToken();
      const username = session.getIdToken().payload["cognito:username"];
      processIntegrationResponse(event, username, idToken, provider)
        .then(() => {
          console.log("Integration Compelted!!!");
          const location = window.location;
          previousAppUrl.set(location.pathname + location.search);
          setTimeout(() => {
            this.setState({
              eventsConnected: null,
              reload: true,
            });
          }, 1000);
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {});
    });
  }

  handleClick() {
    const provider = "google_calendar";
    getSession().then((session: CognitoUserSession) => {
      const idToken = session.getIdToken().getJwtToken();
      const username = session.getIdToken().payload["cognito:username"];
      startIntegration(provider, idToken, username, () => {});
    });
    // props.onConnect();
  }

  handleChange(event: any) {
    this.props.handleChange(event.target.value);
  }

  handleSwitch(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.handleSwitch(event.target.checked);
  }

  render() {
    const {
      classes,
      handleIncrement,
      handleDecrement,
      handleZoomIn,
      handleZoomOut,
      handleSwitch,
      handleChange,
    } = this.props;
    const {
      disableZoomIn,
      disableZoomOut,
      isToday,
      currentLegend,
      showEvents,
    } = this.state;
    const boxShadow = "0px 1px 8px 0px #D1D7E3 !important";
    const { mergeResolution } = this.props;
    let now = new Date();
    // console.log(this.state.showOverlay, "AAAAAAAAAA");
    console.log("Show Calendar Reload: ", this.state.reload);
    return (
      <>
        {this.state.reload && <Navigate to="/" replace={true} />}
        <Grid container alignItems={"self-start"} marginBottom="1%" rowGap={2}>
          <Grid item sm={12} md={3} display="block">
            <Typography variant="h4" color="primary">
              <span style={{ color: "#050505" }}>
                {this.state.dateRange.centerDate.toLocaleString("en-us", {
                  month: "long",
                })}
              </span>{" "}
              {this.state.dateRange.centerDate.toLocaleString("en-us", {
                year: "numeric",
              })}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: "1%",
                alignItems: "center",
              }}
            >
              <img
                src={
                  currentLegend === "devices"
                    ? "/assets/calendar/by_device.svg"
                    : "/assets/calendar/Calendar_by_activity.svg"
                }
                style={{
                  height: "1.4vw",
                  minHeight: "16px",
                  marginRight: "2%",
                }}
              />
              {/* <Typography variant="body1" sx={{ whiteSpace: "nowrap", textTransform: 'capitalize' }}>
              By {currentLegend}
            </Typography>
            <IconButton sx={{padding: 0, marginLeft: '2%'}}>
              <KeyboardArrowDown fontSize="small" />
            </IconButton> */}
              <select
                value={currentLegend}
                onChange={this.handleChange}
                className="calendar-heading-select"
                style={{ boxShadow: boxShadow }}
              >
                {legends.map((legend, index) => (
                  <option
                    className="calendar-heading-options"
                    key={index}
                    value={legend}
                    style={{ textTransform: "capitalize" }}
                  >
                    By {legend}
                  </option>
                ))}
              </select>
            </Box>
          </Grid>
          <Grid item height="100%" sm={3} md={2}>
            <ButtonGroup
              variant="contained"
              sx={{
                width: "100%",
                backgroundColor: "white",
                boxShadow: boxShadow,
                borderRadius: "12px",
              }}
            >
              <IconButton
                onClick={handleDecrement}
                sx={{ backgroundColor: "white", width: "20%" }}
                disabled={this.state.showOverlay ? true : false}
              >
                <KeyboardArrowLeft />
              </IconButton>
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: "4%",
                  width: "60%",
                  textAlign: "center",
                  borderLeft: "1px solid #D6DAE4",
                  borderRight: "1px solid #D6DAE4",
                }}
              >
                <Typography variant="body1" margin={"auto"} marginTop="2%">
                  {this.showDate()}
                </Typography>
              </Box>
              <IconButton
                onClick={handleIncrement}
                disabled={this.state.showOverlay ? true : false}
                sx={{ backgroundColor: "white", width: "20%" }}
              >
                <KeyboardArrowRight />
              </IconButton>
            </ButtonGroup>
          </Grid>
          <Grid item sm={2} md={2}>
            {!isToday ? (
              <Button
                variant="whiteButton"
                onClick={this.props.onReset}
                disabled={this.state.showOverlay ? true : false}
                sx={{
                  fontWeight: "regular",
                  boxShadow: boxShadow,
                  borderRadius: "12px",
                  marginLeft: "4%",
                }}
              >
                Today
              </Button>
            ) : (
              ""
            )}
          </Grid>
          {this.state.eventsConnected ? (
            <Grid
              item
              sm={3}
              md={0}
              display={{ md: "none" }}
              height="100%"
              alignSelf="center"
            ></Grid>
          ) : (
            <Grid item sm={4} md={2} height="100%" alignSelf="center"></Grid>
          )}
          {this.state.eventsConnected ? (
            <Grid
              item
              sm={4}
              md={4}
              display="flex"
              flexDirection="row"
              alignItems="center"
              height="100%"
            >
              <Button
                variant="onlytextpurple"
                onClick={this.handleClick}
                startIcon={<Add />}
                sx={{ marginTop: "2%", whiteSpace: "normal" }}
              >
                Connect Google calendar
              </Button>
            </Grid>
          ) : (
            <Grid
              item
              sm={3}
              md={2}
              display="flex"
              flexDirection="row"
              alignItems="center"
              height="100%"
            >
              <Typography variant="body1">Events</Typography>
              <Switch
                color="event"
                checked={showEvents}
                onChange={this.handleSwitch}
              />
            </Grid>
          )}

          <Grid item md={1} height="100%" overflow="visible">
            <ButtonGroup
              variant="contained"
              sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                boxShadow: boxShadow,
                width: "100%",
                padding: "4%",
              }}
            >
              <IconButton
                onClick={handleZoomOut}
                disabled={
                  disableZoomOut || (this.state.showOverlay ? true : false)
                }
                sx={{ width: "50%" }}
              >
                <img src="/assets/calendar/zoom_out.svg" />
              </IconButton>
              <IconButton
                onClick={handleZoomIn}
                disabled={
                  disableZoomIn || (this.state.showOverlay ? true : false)
                }
                sx={{ width: "50%" }}
              >
                <img src="/assets/calendar/zoom_in.svg" />
              </IconButton>
            </ButtonGroup>
            <Typography
              variant="body2"
              sx={{ marginTop: "2%", marginBottom: 0, textAlign: "center" }}
            >
              {mergeResolution} min
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(calendarStyles)(CalendarHeader);
