import { Link, Stack, Typography } from "@mui/material";
import { SupportTabContent } from "../../support-tab.component";
import { SupportsPage } from "../../supportpage.component";
// import { SupportTabContent } from "../understanding-tab.component";

interface ResultsBlogProps {}

export const ResultsBlog = (props: ResultsBlogProps) => {
  return (
    <>
      <Typography
        variant="h6"
        display="block"
        id={SupportTabContent.results.id}
      ></Typography>
      <SupportsPage
        title={SupportTabContent.results.title}
        subtitle={SupportTabContent.results.description}
        loading={false}
      >
        <ResultsPage></ResultsPage>
      </SupportsPage>
    </>
  );
};

interface ResultsProps {}

const ResultsPage = (props: ResultsProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="body1" display="block">
        To increase your awareness of your digital habits, myTiro helps you to
        understand where, when and how you spend your time by giving you
        continuous insights into your previous workday habits. Additionally,
        myTiro allows you to set personalized goals each day based on the
        previous day's data that give you the opportunity to build healthier
        digital habits.
      </Typography>
      <Typography variant="body1" display="block">
        For recent myTiro users, we suggest:
      </Typography>
      <Typography variant="body1" display="block">
        <ol>
          <li>
            Checking out <Link href="/calendar">calendar</Link> view if you want
            to know what you did during the day; where you spent your time, when
            you were focused, when you took breaks
          </li>
          <li>
            - Head to <Link href="/dashboard">dashboard</Link> when you want to
            know which applications/categories take up the most of your time,
            where you are focused, and what your information flows look like.
          </li>
          <li>
            Answer the daily myTiro insight <Link href="/home">question</Link>{" "}
            that helps you build continuous self-awareness. With this
            functionality, you can see where you are right and how you compare
            with other myTiro users.
          </li>
          <li>
            Start building healthy habits by setting your own personalized daily
            goals with myTiro Goals. There are three pre-built templates you can
            use or you can set custom goals that will populate previous days
            data so that you can set goals that evolve as you do.
          </li>
          <li>
            Visit your personalized dashboard a few times a day to review how
            you spend your time.{" "}
          </li>
          <li>
            Every morning before you start your day, review your daily recap
            email to take stock of the previous day and plan your current day
          </li>
        </ol>
      </Typography>
      <Typography variant="body1" display="block">
        <Link href="/support?option=howTomyTiro">
          Next: Detailed explanation of how to use myTiro
        </Link>
      </Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
    </Stack>
  );
};
