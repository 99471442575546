import { Box, ThemeProvider, Typography } from "@mui/material";
import * as React from "react";
import { useState, useEffect } from "react";
import theme from "../../../theme/theme";
import { getUserData } from "./userinfo.service";
import UserInfoTable from "./userinfotable.component";

export interface AgentInfo {
  agentId: string;
  type: string;
  name: string;
  lastSyncTime: number;
}

export interface DeviceInfo {
  deviceId: string;
  type: string;
  name: string;
  os: string;
  agents: Array<AgentInfo>;
}

export interface UserData {
  accountId: string;
  name: string;
  devices: Array<DeviceInfo>;
  lastRunTask?: {
    timestamp: number;
    dataProcessed: string;
  };
}

const dummyData: UserData[] = [
  {
    accountId: "c74ee3b7-ca8c-4a9b-b8ad-c63bb1aac287",
    name: "aditya@yappxlabs.com",
    devices: [
      {
        deviceId: "a57c553c-5fda-4448-8c37-ee975e901b28",
        name: "Aditya's Lenovo Laptop",
        type: "Computer",
        os: "Windows",
        agents: [
          {
            agentId: "8a01363c-d4c1-4e54-9bf6-f7630ad7127d",
            type: "native",
            name: "Windows @Aditya's Lenovo Laptop",
            lastSyncTime: 1650269641,
          },
          {
            agentId: "ce22c5c3-dbe9-4e09-9aad-fa7300031f61",
            type: "browser",
            name: "Google Chrome @Aditya's Lenovo Laptop",
            lastSyncTime: 1651034419,
          },
          {
            agentId: "5e5184d2-0ed3-4820-99c3-b0e77b178700",
            type: "browser",
            name: "Microsoft Edge @Aditya's Lenovo Laptop",
            lastSyncTime: 1651033879,
          },
        ],
      },
      {
        deviceId: "6baca1d6-e4d0-4377-8e34-f5698d757da5",
        name: "Aditya's 80X7",
        type: "Computer",
        os: "Windows",
        agents: [
          {
            agentId: "683221fa-7779-4e50-91af-25d8cabca7d1",
            type: "mobile",
            name: "Android @Aditya's Samsung Phone",
            lastSyncTime: 1651571815,
          },
          {
            agentId: "9af00c28-5075-4319-9d74-d6db5b4f9f96",
            type: "browser",
            name: "Google Chrome @Aditya's 80X7",
            lastSyncTime: 1652854819,
          },
          {
            agentId: "9b39b2c4-39cb-42dd-a591-14cec69b454e",
            type: "browser",
            name: "Microsoft Edge @Aditya's 80X7",
            lastSyncTime: 1652854826,
          },
          {
            agentId: "6fe9c9a3-82fb-482a-bedb-b9e8ecea98da",
            type: "native",
            name: "Windows @Aditya's 80X7",
            lastSyncTime: 1652854855,
          },
        ],
      },
      {
        deviceId: "1a1db3d6-8a4e-4205-a7a7-ce0594ae1930",
        name: "Aditya's Samsung Phone",
        type: "Mobile",
        os: "Android",
        agents: [],
      },
    ],
    lastRunTask: {
      timestamp: 1652852700,
      dataProcessed: "1652848200 to 1652851800",
    },
  },
];

const UserInfo = () => {
  const [userData, setuserData] = useState([] as UserData[]);

  const fetchUserData = () => {
    getUserData().then((res) => {
      setuserData(res.users);
    });
    //setuserData(dummyData);
  };

  useEffect(() => {
    fetchUserData();
  }, []);
  useEffect(() => {
    console.log(userData);
  }, [userData]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="block"
        width="100%"
        padding="2%"
        bgcolor={theme.palette.secondary.main}
      >
        <Typography variant="h6" marginBottom={"2%"}>
          Admin Console
        </Typography>
        <UserInfoTable userData={userData} />
      </Box>
    </ThemeProvider>
  );
};

export default UserInfo;
