import { Insight } from './insights.model';
import InfoCard from "../InfoCard/infocard.component";

let insightsicon = require('./assets/Insights-1.png');

interface InsightCardProps {
  insight: Insight;
  isExpandable: Boolean;
}


class InsightCard extends InfoCard {
  constructor(props: InsightCardProps) {
    super(props);
    this.state = {
      anchorEl: null,
      isExpandable: props.isExpandable,
      isOpen: false,
      description: props.insight.description,
      title: props.insight.title,
      actions: props.insight.actions,
      icon: insightsicon,
      color: '#F3F5F6',
    };
  }
}

export default InsightCard;
