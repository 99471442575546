import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const LimitsErrorDialog = (props: {
  open: boolean;
  closeDialog: () => void;
  dialogType: "focus" | "time";
}) => {
  const focusDurationError = "Please set at least 20 mins of focus time";
  const timeDurationErrro = "Please set focus less than or equal to total time";
  return (
    <Dialog onClose={props.closeDialog} open={props.open}>
      <DialogTitle>Focus Time Invalid</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.dialogType === "focus"
            ? focusDurationError
            : timeDurationErrro}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeDialog}>Okay</Button>
      </DialogActions>
    </Dialog>
  );
};
