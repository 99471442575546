import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";

interface AdminProps {}

const tabs = [
  {
    id: 0,
    label: "User Overview",
    path: "/administration/useroverview",
  },
  {
    id: 1,
    label: "Benefits",
    path: "/administration/benefits",
  },
  {
    id: 2,
    label: "User Info",
    path: "/administration/userinfo",
  },{
    id: 3,
    label: "Daily Email",
    path: "/administration/dailyemail",
  },{
    id: 4,
    label: "Error Logs",
    path: "/administration/errorlogs",
  }, {
    id: 5,
    label: "Support Tickets",
    path: "/administration/support-tickets",
  },
  
];

export const AdminPage = (props: AdminProps) => {
  const [tabSelectedId, setTabSelectedId] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelectedId(newValue);
  };

  useEffect(() => {
    const selectedTab = tabs.find((tab) => tab.id === tabSelectedId);
    if (selectedTab) {
      if (selectedTab.path !== location.pathname) {
        navigate(selectedTab.path);
      }
    }
  }, [tabSelectedId]);

  useEffect(() => {
    const currentPath = location.pathname;
    const selectedTab = tabs.find((tab) => tab.path === currentPath);
    if (selectedTab) {
      setTabSelectedId(selectedTab.id);
    }
  }, []);

  return (
    <Box sx={{ width: "100%", height: "100vh" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabSelectedId} onChange={handleChange}>
          {tabs.map((tab) => (
            <Tab key={tab.id} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ width: "100%", height: "95vh", overflow: "auto" }}>
        <Outlet />
      </Box>
    </Box>
  );
};
