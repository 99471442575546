import { CognitoUserSession } from "amazon-cognito-identity-js";
import {
  getSession,
  handleSessionNotFound,
} from "../../../accountcontext/account";
import { getCalendarData } from "../../../data/dummy_data.service";

const BASE_URL = "/api/user";

export type CalendarActivity = {
  name: string;
  time: {
    start: number;
    end: number;
    duration: number;
  };
  icon: string;
  category: string;
  device: {
    type: string;
    name: string;
  };
  focus: string;
  offline: boolean;
};

export type CalendarDay = {
  date: string;
  accuracy: number;
  activities: CalendarActivity[];
};

export type CalendarEvent = {
  time: {
    start: number;
    end: number;
    duration: number;
  };
  name: string;
  source: string;
  permalink: string;
};

export type CalendarMain = {
  timezone: {
    gmtOffset: number;
    name: string;
  };
  duration: {
    start: number;
    end: number;
  };
  currentTime: string;
  syncTime: string;
  data: {
    activities: {
      past: CalendarDay[];
      today: {
        past: CalendarDay[];
        future: CalendarDay[];
      };
      future: CalendarDay[];
    };
    events: CalendarEvent[];
    cummulative: {
      categories: {
        [category: string]: number;
      };
      devices: {
        [deviceType: string]: {
          total: number;
          devices: {
            [deviceName: string]: number;
          };
        };
      };
    };
  };
};

const fetchCalendarMainData = (
  startTime: number,
  endTime: number
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const startTimeStr = startTime.toString();
    const endTimeStr = endTime.toString();
    var params = [
      ["startTime", startTimeStr],
      ["endTime", endTimeStr],
    ];
    getSession()
      .then((session: CognitoUserSession) => {
        const idToken = session.getIdToken().getJwtToken();
        const username = session.getIdToken().payload["cognito:username"];

        const searchParams = new URLSearchParams(params).toString();

        const requestOptions: RequestInit = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        };

        const url = `${BASE_URL}/calendar/main/${username}?${searchParams}`;
        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((response) => JSON.parse(response))
          // .then((response: CalendarMain) => {
          //   for (const day of [
          //     ...response.data.activities.past,
          //     ...response.data.activities.today.past,
          //   ]) {
          //     console.log("______________________________");
          //     console.log(day.date);
          //     day.activities = mergineLogic1(day.activities);
          //   }
          //   return response;
          // })
          .then((result) => resolve(result))
          // .catch((error) => reject(error));
          .catch((error) => resolve(getCalendarData(startTime, endTime)));
      })
      .catch((err) => handleSessionNotFound(err));
  });
};

export const fetchCalendarData = (
  startTime: number,
  endTime: number
): Promise<any> => {
  const fetchList = [];
  fetchList.push(fetchCalendarMainData(startTime, endTime));
  return Promise.all(fetchList)
    .then((data) => {
      console.log("Calendar Data:", data);
      return data;
    })
    .catch((error) => {
      // console.log(error);
      return getCalendarData(startTime, endTime);
    });
};

// filter actvitiies less than 15 seconds duration and combine activites with similar name
const filterAndCombineCalendarData2 = (
  activities: CalendarActivity[],
  minDuration = 10,
  mergeThreshold = 5
): CalendarActivity[] => {
  let flag = true;
  let activitiesToProcess = activities;
  // console.log(activitiesToProcess.length);
  while (flag) {
    activitiesToProcess = mergeConsecutiveCalendarData(
      activitiesToProcess,
      mergeThreshold
    );
    const { filteredActivities, removed } = removeActivitiesLessThanThreshold(
      activitiesToProcess,
      minDuration
    );
    activitiesToProcess = filteredActivities;
    flag = removed;
  }

  return activitiesToProcess;
};

const filterAndCombineCalendarData = (
  activities: CalendarActivity[],
  minDuration = 10,
  mergeThreshold = 5
) => {
  const merged = [];
  // keep merging while focus is the same
  let current = activities[0];
  for (let i = 1; i < activities.length; i++) {
    const next = activities[i];
    if (
      (current.focus && next.focus && current.focus === next.focus) ||
      (current.offline == true && next.offline == true)
    ) {
      current = {
        ...current,
        time: {
          ...current.time,
          end: next.time.end,
          duration: current.time.duration + next.time.duration,
        },
      };
    } else {
      merged.push(current);
      current = next;
    }
  }
  merged.push(current);

  const mergedPass2 = [];

  current = merged[0];
  for (let i = 1; i < merged.length; i++) {
    const next = merged[i];
    if (
      !current.focus &&
      ((current.category &&
        current.category != "Unknown" &&
        current.category == next.category) ||
        current.name == next.name)
    ) {
      current.time.end = next.time.end;
      current.time.duration = current.time.duration + next.time.duration;
    } else {
      mergedPass2.push(current);
      current = next;
    }
  }

  mergedPass2.push(current);

  return mergedPass2;
};

const removeActivitiesLessThanThreshold = (
  activities: CalendarActivity[],
  minDuration: number
) => {
  const ret = [];
  let removed = false;
  // console.log("removing activities less than ", minDuration);
  for (const activity of activities) {
    if (activity.time.duration > minDuration) {
      ret.push(activity);
    } else {
      removed = true;
    }
  }
  return { filteredActivities: ret, removed: removed };
};
const mergeConsecutiveCalendarData = (
  activities: CalendarActivity[],
  mergeThreshold: number
): CalendarActivity[] => {
  const ret = [];
  let lastActivity: CalendarActivity | undefined;
  for (const activity of activities) {
    if (
      lastActivity &&
      lastActivity.name === activity.name &&
      lastActivity.time.end + mergeThreshold >= activity.time.start
    ) {
      lastActivity.time.end = activity.time.end;
      lastActivity.time.duration += activity.time.duration;
    } else {
      ret.push(activity);
      lastActivity = activity;
    }
  }
  return ret;
};

const mergeActivitiesByCategory = (
  activities: CalendarActivity[]
): CalendarActivity[] => {
  const ret = [];
  let lastActivity: CalendarActivity | undefined;
  for (const activity of activities) {
    if (
      lastActivity &&
      lastActivity.category != "Unknown" &&
      lastActivity.category === activity.category &&
      lastActivity.time.end + 500 >= activity.time.start
    ) {
      lastActivity.time.end = activity.time.end;
      lastActivity.time.duration += activity.time.duration;
    } else {
      ret.push(activity);
      lastActivity = activity;
    }
  }
  return ret;
};

// merge all no data available offline entries
// go 15 min at a time and create block of major contributor

// split all activitise into 1 second activities
const splitToSeconds = (activities: CalendarActivity[]) => {
  const ret = [];
  const perSecondMap: any = {};
  for (const activity of activities) {
    const start = activity.time.start;
    const end = activity.time.end;
    for (let i = start; i < end; i++) {
      const act = {
        ...activity,
        time: {
          start: i,
          end: i + 1,
          duration: 1,
        },
      };
      perSecondMap[i] = act;
      ret.push(act);
    }
  }
  console.log("set", Object.keys(perSecondMap).length);
  return Object.values(perSecondMap) as CalendarActivity[];
};

//filter activities by start and end
const filterActivitiesByStartEnd = (
  activities: CalendarActivity[],
  start: number,
  end: number
) => {
  const ret = [];
  for (const activity of activities) {
    if (activity.time.start >= start && activity.time.end < end) {
      ret.push(activity);
    }
  }
  return ret;
};

//sum up activities of same name and return max duration
const sumActivitiesByName = (
  activities: CalendarActivity[]
): CalendarActivity | undefined => {
  const actMap: any = {};
  activities.forEach((activity) => {
    if (actMap[activity.name]) {
      actMap[activity.name].time.duration += activity.time.duration;
    } else {
      actMap[activity.name] = JSON.parse(JSON.stringify(activity));
    }
  });

  const acts = Object.values(actMap);
  acts.sort((a: any, b: any) => b.time.duration - a.time.duration);
  const a: any = acts.shift();
  if (a.time.duration > 899) {
    console.log("ACT", actMap);
  }
  return a as CalendarActivity;
};

//loop over all activities 15 min at a time and find max duration
const findMaxDuration = (activities: CalendarActivity[], duration: number) => {
  const ret = [];
  const startTime = activities[0]?.time.start || 0;
  const endTime = activities[activities.length - 1]?.time.end || 0;
  for (let i = startTime; i < endTime; i += duration * 60) {
    const filteredActivities = filterActivitiesByStartEnd(
      activities,
      i,
      i + duration * 60
    );

    // console.log("filteredActivities", filteredActivities);
    const longestActivity: CalendarActivity | undefined =
      sumActivitiesByName(filteredActivities);
    // const tempAct = JSON.parse(JSON.stringify(longestActivity));
    // if (longestActivity && longestActivity?.time.duration > 899) {
    //   console.log("WHAT IS TIS", longestActivity);
    // }
    // console.log(
    //   longestActivity,
    //   longestActivity?.time.duration,
    //   longestActivity && longestActivity?.time.duration > 899
    // );
    // console.log(
    //   "Start:",
    //   new Date(i * 1000),
    //   new Date((i + duration * 60) * 1000),
    //   filteredActivities,
    //   longestActivity
    // );
    if (
      longestActivity &&
      longestActivity.time.duration >= 1 //0.5 * (duration * 60)
    ) {
      // console.log("longestActivity", longestActivity);
      const actStart = i;
      const actEnd = i + duration * 60 - 1;
      const actDuration = actEnd - actStart;
      longestActivity.time = {
        start: actStart,
        end: actEnd,
        duration: actDuration,
      };
      ret.push(longestActivity);
    } else {
      console.log("not big enough");
      filteredActivities.forEach((a) => ret.push(a));
    }
  }
  return ret;
};

const mergeByCategoryIfTogeather = (activities: CalendarActivity[]) => {
  const ret = [];
  let lastActivity: CalendarActivity | undefined;
  let catHolder: CalendarActivity[] = [];
  let category = undefined;
  for (const activity of activities) {
    if (category && category != "Unknown" && category === activity.category) {
      catHolder.push(activity);
    } else {
      if (catHolder.length > 1) {
        const longestActivity: CalendarActivity | undefined =
          sumActivitiesByName(catHolder);
        if (longestActivity) {
          ret.push(longestActivity);
        }
      }
      category = activity.category;
      catHolder = [activity];
      // ret.push(activity);
      lastActivity = activity;
    }
  }
  return ret;
};

const mergineLogic1 = (activities: CalendarActivity[]): CalendarActivity[] => {
  const totalDuration = activities.reduce((acc, cur) => {
    return acc + (cur.time.end - cur.time.start);
  }, 0);
  // console.log("Total duration in activities", totalDuration);
  const splitActitivities = splitToSeconds(activities);
  // console.log("splitActitivities", splitActitivities.length);
  const clusteredActivities = findMaxDuration(splitActitivities, 1);
  const mergedActivities = mergeConsecutiveCalendarData(
    clusteredActivities,
    10
  );
  const mergedActivitiesByCategory =
    mergeActivitiesByCategory(mergedActivities);
  return mergedActivitiesByCategory;
};

interface CalendarActivityMerge extends CalendarActivity {
  processed: boolean;
}

const mergeLogic2 = (activities: CalendarActivity[]): CalendarActivity[] => {
  const splitActivities = splitToSeconds(activities) as CalendarActivityMerge[];
  splitActivities.forEach((activity) => {
    activity.processed = false;
  });

  const mergedActivities: CalendarActivity[] = [];

  const mergeUpDurations = [21600, 10800, 3600, 1800, 900, 300];
  const startingTime = splitActivities[0]?.time.start || 0;
  const endingTime = splitActivities[splitActivities.length - 1]?.time.end || 0;

  [900].forEach((mergeUpDuration) => {
    for (let i = startingTime; i < endingTime; i += mergeUpDuration) {
      console.log(
        "Processing for: ",
        mergeUpDuration,
        new Date(i * 1000),
        new Date((i + mergeUpDuration) * 1000)
      );
      const filteredActivities = splitActivities
        .filter((activity) => activity.processed === false)
        .filter(
          (activity) =>
            activity.time.start >= i && activity.time.end < i + mergeUpDuration
        );
      console.log("Found activities: ", filteredActivities.length);
      const longestActivityByDuration = sumActivitiesByName(filteredActivities);
      console.log("LongestActivityByDuration: ", longestActivityByDuration);
      if (
        longestActivityByDuration //&&
        // longestActivityByDuration.time.duration >= 0.9 * mergeUpDuration
      ) {
        longestActivityByDuration.time = {
          start: startingTime,
          end: startingTime + mergeUpDuration,
          duration: mergeUpDuration,
        };
        console.log("Merged: ", longestActivityByDuration);
        filteredActivities.forEach((activity) => {
          activity.processed = true;
        });
        mergedActivities.push(longestActivityByDuration);
      }
    }
  });
  console.log("MergedActivities: ", mergedActivities);
  console.log(
    "Unprocessed: ",
    splitActivities.filter((activity) => activity.processed === false)
  );
  // const mergeUpDuration = 24 * 60 * 60;

  const tempMerge = mergedActivities.concat(
    splitActivities.filter((activity) => activity.processed === false)
  );

  tempMerge.sort((a, b) => a.time.start - b.time.start);
  console.log("mergedup:", tempMerge);
  const finalActivities = mergeConsecutiveCalendarData(tempMerge, 10);
  console.log("FinalActivities: ", finalActivities);
  return finalActivities;
};
