import { Link, Stack, Typography } from "@mui/material";
import { SupportTabContent } from "../../support-tab.component";
import { SupportsPage } from "../../supportpage.component";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

interface CustomCategoryBlogProps {}

export const CustomCategoryBlog = (props: CustomCategoryBlogProps) => {
  return (
    <>
      <Typography
        variant="h6"
        display="block"
        id={SupportTabContent.customCategory.id}
      ></Typography>
      <SupportsPage
        title={SupportTabContent.customCategory.title}
        subtitle={SupportTabContent.customCategory.description}
        loading={false}
      >
        <CustomCategory></CustomCategory>
      </SupportsPage>
    </>
  );
};

interface CustomCategoryProps {}

const CustomCategory = (props: CustomCategoryProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h6" display="block">
        Create Custom Categories within myTiro Goals
      </Typography>
      <Typography variant="body1" display="block">
        Create custom categories where you can club applications and websites
        that have meaning to you. And create goals around your custom categories
        to build healthy time habits.
      </Typography>
      <Typography variant="body1" display="block" fontWeight={"bold"}>
        You can create custom goals in two different ways
      </Typography>
      <Typography variant="body1" display="block">
        While you are setting custom goals on the home page:
      </Typography>
      <Typography variant="body1" display="block">
        <img
          src="assets/how-to/custom-category/create-from-goal.png"
          width="400"
        ></img>
      </Typography>
      <Typography variant="body1" display="block">
        {"And also, through"}{" "}
        <Link href="settings?option=custom-category">
          {" "}
          {"Settings > Custom Category"}
        </Link>{" "}
        {"> Add new custom category"}
      </Typography>
      <Typography variant="body1" display="block">
        <img
          src="assets/how-to/custom-category/settings-view.png"
          width="800"
        ></img>
      </Typography>
      <Typography variant="body1" display="block" fontWeight={"bold"}>
        To create a custom category, you need to name it and add applications
        and/or websites
      </Typography>
      <Typography variant="body1" display="block">
        <img
          src="assets/how-to/custom-category/create-category.png"
          width="600"
        ></img>
      </Typography>
      <Typography variant="body1" display="block">
        <img
          src="assets/how-to/custom-category/setting-goal.png"
          width="600"
        ></img>
      </Typography>
      <Typography variant="body1" display="block">
        To add applications and/or websites, select from the drop-down or start
        typing, and myTiro will show you the closest matches.
      </Typography>
      <Typography variant="body1" display="block">
        You can select as many applications or websites you like. However, each
        application/website can belong to only one goal. For example, LinkedIn
        cannot be a part of any other category if it is a part of the “Social
        Media” category.
      </Typography>
      <Typography variant="body1" display="block">
        Create custom categories by selecting applications or websites you have
        previously accessed and from a commonly maintained list from myTiro.
      </Typography>
      <Typography variant="body1" display="block">
        Send us an email if you want us to add an application or website.
      </Typography>
      <Typography variant="body1" display="block" fontWeight={"bold"}>
        Edit/Delete Custom Categories
      </Typography>
      <Typography variant="body1" display="block">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {"Your custom categories can be edited or deleted through"}&nbsp;
          <Link href="settings?option=custom-category">
            {" "}
            {"Settings > Custom Category"}
          </Link>{" "}
          &nbsp;
          {" > Click on"} &nbsp;
          <EditIcon />
          &nbsp; or&nbsp; <DeleteIcon /> button on an existing Custom Category
        </div>
      </Typography>
      <Typography variant="body1" display="block">
        <img
          src="assets/how-to/custom-category/settings-view.png"
          width="800"
        ></img>
      </Typography>
      <Typography variant="body1" display="block">
        <img
          src="assets/how-to/custom-category/edit-category.png"
          width="600"
        ></img>
      </Typography>
      <Typography variant="body1" display="block">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          Within a custom category, you can delete an existing application or
          website by clicking on &nbsp;
          <img src="assets/how-to/custom-category/cross.png" width="16"></img>,
          and add an application or website by typing on the{" "}
          <img src="assets/how-to/custom-category/cursor.png" width="16"></img>.
        </div>
      </Typography>
      <Typography variant="body1" display="block">
        That’s it! You are set to create your custom categories.
      </Typography>
      <Typography variant="body1" display="block">
        Here are some examples of how to use custom categories to develop
        healthy time habits:
      </Typography>
      <Typography variant="body1" display="block">
        {
          <ol>
            <li>
              I want to limit my news reading to 30 minutes
              <ol type="a">
                <li>
                  Set up a custom category, “News,” that includes
                  www.nytimes.com and www.wsj.com
                </li>
                <li>
                  Set a custom goal for “News” by setting Time to 30 min and
                  toggle to the max. Set Focus and Switch values to 0.
                </li>
              </ol>
            </li>
            <li>
              I want to focus at least 2 hours on Programming
              <ol type="a">
                <li>
                  Set up a custom category, “Programming,” that includes Visual
                  Studio Code, Terminal, Github, www.github.com
                </li>
                <li>
                  Set a custom goal for “Programming” by setting Focus to 120
                  min and toggle to the min. Set Time and Switch values to 0.
                </li>
              </ol>
            </li>
            <li>
              I want to access Social Media only four times
              <ol type="a">
                <li>
                  Set up a custom category, “Social Media,” that includes
                  Facebook, Instagram, Twitter
                </li>
                <li>
                  Set a custom “Social Media” goal by setting Switch to 4 and
                  toggle to the max. Set Time and Focus values to 0.
                </li>
              </ol>
            </li>
          </ol>
        }
      </Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
      <Typography variant="body1" display="block"></Typography>
    </Stack>
  );
};
