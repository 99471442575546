import { withStyles, WithStyles } from "@mui/styles";
import "./styles/calendarday.styles.css";
import { calendarStyles } from "./styles/calendar.styles";
import {
  CalEvent,
  DayActivities,
  FocusSession,
} from "./models/calendar_main.model";
import { CalendarCell } from "./models/calendar_cell.model";
import CalendarColumn from "./calendarcolumn.component";
import {
  CalendarColumnState,
  FocusSessionCalendarItem,
} from "./models/calendar_column.model";

interface CalendarDayProps extends WithStyles<typeof calendarStyles> {
  date: DayActivities;
  isFuture: boolean;
  events?: Array<CalEvent>;
  columnId: number;
  focusSessions: FocusSession[] | undefined;
  showEvents: boolean | undefined;
  showDevices: boolean;
  heights: {
    cell: number;
    accuracyBox: number;
  };
}

class CalendarDay extends CalendarColumn {
  constructor(props: CalendarDayProps) {
    super(props);
    let cells: CalendarCell[] = [];
    let events = [];
    if (props.events != undefined) {
      for (const event of props.events) {
        events.push({ event: event, isOverlap: false });
      }
    }
    let activities = [];
    if (props.date.activities !== undefined)
      for (const activity of props.date?.activities) {
        activities.push({
          activity: activity,
          isOverlap: false,
          showText: true,
        });
      }
    let focusSessions = [];
    if (props.focusSessions !== undefined)
      for (let i = 0; i < props.focusSessions?.length; i++) {
        focusSessions.push({
          focusSession: props.focusSessions[i],
          isOverlap: false,
        });
      }
    this.state = {
      cells: cells,
      events: events,
      activities: activities,
      isToday: false,
      isFuture: props.isFuture,
      heights: props.heights,
      columnId: props.columnId,
      focusSessions: focusSessions,
      showEvents: props.showEvents,
      showDevices: props.showDevices,
    };
  }

  componentDidUpdate(prevProps: CalendarDayProps, prevState: any) {
    if (this.props.date !== prevProps.date) {
      let activities = [];
      if (this.props.date.activities !== undefined){
        for (const activity of this.props.date?.activities) {
          activities.push({
            activity: activity,
            isOverlap: false,
            showText: true,
          });
        }
      }
      this.setState({
        activities: activities,
      })
    }
  }

  public static defaultProps = {
    isFuture: false,
  };
}

export default withStyles(calendarStyles)(CalendarDay);
