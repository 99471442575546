import { CategoryType } from "../../components/settings/custom-category/models/custom-category.model";
import { DeviceType } from "../../components/settings/devicemanagement/device.service";

export interface DetailedTimelineDto {
  device: string;
  deviceType: DeviceType;
  application: string;
  subapplication: string;
  source: NormalizedDataSource | undefined;
  focusSession: FocusSession;
  focusBreak: boolean;
  offline: boolean;
  // goal: GoalInstance[];
  goals: { id: number; name: string }[];
  category: string;
  userCategory: UserCategoryEntity[];
  startTime: number;
  endTime: number;
  duration: number;
}

interface UserCategoryEntity {
  id: number;
  accountId: string;
  categoryName: string;
  categoryDescription?: string;
  categoryType: CategoryType;
}

interface FocusSession {
  id: number;
  accountId: string;
  duration: number;
  startTime: number;
  endTime: number;
  breakTime: number;
  breakCount: number;
}

export enum NormalizedDataSource {
  BROWSER_EXTN = "browser_extension",
  DESKTOP_APP = "desktop_app",
  MOBILE_APP = "mobile_app",
}

export enum TimelineDataStatus {
  OFFLINE = "Offline",
  ONLINE = "Online",
  FOCUS = "Focus",
  APP = "App",
}

export interface TimelineDto {
  time: {
    startTime: number;
    endTime: number;
    duration: number;
  };
  hour: number;
  offline: boolean;
  focus: boolean;
  focusBreak: boolean;
  //TODO remove string
  status: TimelineDataStatus;
}

export interface DaySummaryDto {
  time: number | undefined;
  focus: number | undefined;
  activities: number | undefined;
  dayTs?: number;
  endWeekTs?: number;
}
