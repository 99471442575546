import { Box, Switch, Theme, Typography } from "@mui/material";
import * as React from "react";
import CalendarDay from "./calendarday.component";
import CalendarToday from "./calendartoday.component";
import { createStyles, WithStyles, withStyles } from "@mui/styles";

import { fetchCalendarData } from "./services/calendarday.service";
import ErrorBoundary from "../../errorboundary.component";
import {
  CalendarMain,
  DayActivities,
  DateRange,
} from "./models/calendar_main.model";
import { getColor } from "../../components/dashboard/utils/dashboard.utils";
import CurrentTimeIndicator from "./currenttimeindicator.component";
// import { masterMergeLogic } from "./services/calendar_merger.service";
// import { masterMergeLogic } from "./services/calendar_merger.service";
import { masterMergeLogic } from "./services/calendar_merger_new_2.service";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LegendBox from "./legendbox/legendbox.component";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      borderRadius: "12px",
      border: "1px solid white",
      backgroundColor: "white",
      padding: 0,
      height: "auto",
      width: "100%",
    },

    toptext: {
      width: "15%",
      minWidth: "121px",
      textAlign: "center",
    },
    flexbox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      width: "80%",
      position: "relative",
      left: "-4%",
    },
    datebox: {
      height: "7vh",
      margin: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "left",
      backgroundColor: "#F7F4FB",
      width: "100%",
      alignItems: "center",
      border: "1px solid white",
      borderLeft: 0,
      paddingRight: "50px",
    },
  });

interface CalendarProps extends WithStyles<typeof styles> {
  dateRange: DateRange;
  heights: {
    cell: number;
    accuracyBox: number;
  };
  showLoader: () => void;
  hideLoader: () => void;
  mergeResolution: number;
  showEvents: boolean;
  currentLegend: string;
}

interface CalendarState {
  calendarData: CalendarMain | undefined;
  rawCalendarData: CalendarMain | undefined;
  mergeResolution: number;
  dateRange: DateRange;
  dataLoaded: Boolean;
  columns: JSX.Element[];
  showEvents: boolean | undefined;
  currentLegend: string;
  heights: {
    cell: number;
    accuracyBox: number;
  };
}

class Calendar extends React.Component<CalendarProps, CalendarState> {
  timelineDiv: any;
  constructor(props: CalendarProps) {
    super(props);
    this.state = {
      calendarData: undefined,
      rawCalendarData: undefined,
      dateRange: props.dateRange,
      heights: props.heights,
      mergeResolution: props.mergeResolution,
      dataLoaded: false,
      columns: [] as JSX.Element[],
      showEvents: props.showEvents,
      currentLegend: props.currentLegend,
    };

    this.timelineDiv = React.createRef();
    this.loadCalendarData = this.loadCalendarData.bind(this);
  }

  static getDerivedStateFromProps(
    nextProps: CalendarProps,
    prevState: CalendarState
  ) {
    if (nextProps.dateRange !== prevState.dateRange) {
      return {
        calendarData: undefined,
        columns: [],
        dateRange: nextProps.dateRange,
      };
    }
    if (prevState.mergeResolution !== nextProps.mergeResolution) {
      return {
        columns: [],
      };
    }
    if (prevState.showEvents !== nextProps.showEvents) {
      return {
        showEvents: nextProps.showEvents,
      };
    }
    if (prevState.currentLegend !== nextProps.currentLegend) {
      return {
        currentLegend: nextProps.currentLegend,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps: CalendarProps, prevState: CalendarState) {
    if (prevProps.heights !== this.props.heights) {
      // Consistent scroll across zooom
      let currentScrollFraction =
        this.timelineDiv.current.scrollTop / (24 * prevProps.heights.cell);
      let newScroll =
        currentScrollFraction * (24 * this.props.heights.cell) -
        this.timelineDiv.current.scrollTop;
      this.timelineDiv.current.scrollTop += newScroll;
      this.setState({
        heights: this.props.heights,
        mergeResolution: this.props.mergeResolution,
        calendarData: undefined,
      });
    }
    if (prevProps.dateRange !== this.props.dateRange) {
      console.log("Dates Changed in Calendar");
      this.loadCalendarData(this.state.dateRange);
    }
    if (prevState.mergeResolution !== this.state.mergeResolution) {
      console.log(
        "Merge Resolution Changed in Calendar",
        this.state.calendarData
      );
      this.mergeCalendarActivities().then(() => {
        this.setState({ columns: this.displayDailyActivities() });
      });
    }
    if (prevState.showEvents !== this.state.showEvents) {
      this.setState({ columns: this.displayDailyActivities() });
    }
    if (prevState.currentLegend !== this.state.currentLegend) {
      this.setState({ columns: this.displayDailyActivities() });
    }
  }
  componentDidMount() {
    this.loadCalendarData(this.state.dateRange);
    let now = new Date();
    this.timelineDiv.current.scrollTop +=
      now.getHours() * this.state.heights.cell +
      now.getMinutes() * (this.state.heights.cell / 60) -
      180;
  }

  loadCalendarData = (dateRange: DateRange) => {
    const now = dateRange.centerDate;
    const start = new Date(dateRange.startDate);
    const end = dateRange.endDate;
    end.setHours(23, 59, 59, 999);

    const startTime = Math.floor(start.getTime() / 1000);
    const endTime = Math.floor(end.getTime() / 1000);
    console.log("fetching: ", start, end);
    this.props.showLoader();

    fetchCalendarData(startTime, endTime).then((data: any) => {
      //this.setState({ calendarData: dummyCalendarData });
      this.setState({ rawCalendarData: data[0] }, async () => {
        try {
          await this.mergeCalendarActivities();
          this.props.hideLoader();
          this.setState({ columns: this.displayDailyActivities() });
          this.setState({
            dataLoaded: true,
          });
        } catch (err) {
          this.props.hideLoader();
          console.log("Merge failed", err);
          toast.error(
            "Something went wrong. Please reload the page and try again.",
            {
              position: "bottom-left",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }); // Check warning
    });
  };

  mergeCalendarActivities = async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      let rawCalendarData = JSON.parse(
        JSON.stringify(this.state.rawCalendarData)
      );
      if (!rawCalendarData) {
        return;
      }
      for (const day of [
        ...rawCalendarData.data.activities.past,
        ...rawCalendarData.data.activities.today.past,
      ]) {
        try {
          day.activities = masterMergeLogic(
            day.activities,
            this.state.mergeResolution
          );
        } catch (error) {
          reject(error);
        }
      }
      this.setState({ calendarData: rawCalendarData }, () => {
        resolve();
      });
    });
  };

  splitLongEvents() {
    const { calendarData } = this.state;
    if (calendarData) {
      let events = [...calendarData.data.events];
      for (const event of calendarData.data.events) {
        let start = event.time.start;
        let startDay = new Date(start * 1000);
        startDay.setHours(0, 0, 0, 0);
        let eod = new Date(start * 1000);
        eod.setHours(23, 59, 59, 999);
        let endT = eod.getTime() / 1000;
        if (endT - start < event.time.duration) {
          let eventEnd = event.time.end;
          let endDayStart = new Date(eventEnd * 1000);
          endDayStart.setHours(0, 0, 0, 0);
          if (endDayStart.getTime() - startDay.getTime() > 172800) {
            // 2 DAYS
            let iter = new Date(startDay.getTime());
            iter.setDate(iter.getDate() + 1);
            for (
              ;
              iter.getTime() < endDayStart.getTime();
              iter.setDate(iter.getDate() + 1)
            ) {
              let iterEnd = new Date(iter.getTime());
              iterEnd.setHours(23, 59, 59, 999);
              events.push({
                ...event,
                time: {
                  start: iter.getTime() / 1000,
                  end: iterEnd.getTime() / 1000,
                  duration: 86400,
                },
              });
            }
          }
          let newStart = endDayStart.getTime() / 1000;
          event.time.end = endT;
          event.time.duration = endT - start;
          events.push({
            ...event,
            time: {
              start: newStart,
              end: eventEnd,
              duration: eventEnd - newStart,
            },
          });
        }
      }
      this.setState({
        calendarData: {
          ...calendarData,
          data: { ...calendarData.data, events: events },
        },
      });
    }
  }

  displayDailyActivities() {
    const { heights } = this.state;
    let result = [];
    if (this.state.calendarData) {
      this.splitLongEvents();
      const startdate: any = new Date(this.state.dateRange.startDate.getTime());
      //startdate.setHours(0, 0, 0, 0);
      const enddate: any = new Date(this.state.dateRange.endDate.getTime());
      enddate.setHours(0, 0, 0, 0);

      const td: any = new Date(this.state.dateRange.centerDate.getTime());
      td.setHours(0, 0, 0, 0);
      let dateObject: Date = startdate;
      //let diffdays: number = (enddate - startdate) / (1000 * 60 * 60 * 24);
      let diffdays: number = (enddate - startdate) / (1000 * 60 * 60 * 24) + 1;
      let diffArr = Array(diffdays).keys();
      let past = this.state.calendarData.data.activities.past;
      let future = this.state.calendarData.data.activities.future;
      past.sort((a: any, b: any) => {
        return a.dateEpoch - b.dateEpoch;
      });
      let currDate = new Date(startdate.getTime());
      currDate.setHours(0, 0, 0, 0);
      let dateArr: Array<Date>;
      dateArr = [];
      for (const i of diffArr) {
        dateArr.push(new Date(currDate));
        currDate.setDate(currDate.getDate() + 1);
      }
      let j = 0,
        k = 0;
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      for (const [index, date] of dateArr.entries()) {
        let dummyDate: DayActivities = {
          date: date.toDateString(),
          accuracy: "0%",
          activities: [],
        };
        let events = this.state.calendarData.data.events.filter((event) => {
          let start = new Date(event.time.start * 1000);
          start.setHours(0, 0, 0, 0);
          return date.getTime() === start.getTime();
        });

        let focusSessions = this.state.calendarData.data.focusSessions?.filter(
          (event) => {
            let start = new Date(event.time.start * 1000);
            start.setHours(0, 0, 0, 0);
            return date.getTime() === start.getTime();
          }
        );
        // let focusSessions = fs.filter(
        //   (focus) => {
        //     let start = new Date(focus.time.start * 1000);
        //     start.setHours(0, 0, 0, 0);
        //     return date.getTime() === start.getTime();
        //   }
        // );

        if (date.getTime() < today.getTime()) {
          if (j < past.length) {
            let pastDate = new Date(past[j].date);
            if (date.getTime() === pastDate.getTime()) {
              result.push(
                <CalendarDay
                  key={index}
                  columnId={index}
                  date={past[j]}
                  isFuture={false}
                  events={events}
                  heights={heights}
                  focusSessions={focusSessions}
                  showEvents={this.state.showEvents}
                  showDevices={
                    this.state.currentLegend === "devices" ? true : false
                  }
                />
              );
              j += 1;
            } else if (date.getTime() > pastDate.getTime()) {
              while (date.getTime() > pastDate.getTime() && j < past.length) {
                pastDate = new Date(past[j].date);
                pastDate.setHours(0, 0, 0, 0);
                pastDate.setDate(pastDate.getDate() + 1);
                j += 1;
              }
              if (date.getTime() === pastDate.getTime()) {
                result.push(
                  <CalendarDay
                    key={index}
                    columnId={index}
                    date={past[j]}
                    isFuture={false}
                    events={events}
                    heights={heights}
                    focusSessions={focusSessions}
                    showEvents={this.state.showEvents}
                    showDevices={
                      this.state.currentLegend === "devices" ? true : false
                    }
                  />
                );
              } else {
                result.push(
                  <CalendarDay
                    key={index}
                    columnId={index}
                    date={dummyDate}
                    isFuture={false}
                    events={events}
                    heights={heights}
                    focusSessions={focusSessions}
                    showEvents={this.state.showEvents}
                    showDevices={
                      this.state.currentLegend === "devices" ? true : false
                    }
                  />
                );
              }
            } else {
              result.push(
                <CalendarDay
                  key={index}
                  columnId={index}
                  date={dummyDate}
                  isFuture={false}
                  events={events}
                  heights={heights}
                  focusSessions={focusSessions}
                  showEvents={this.state.showEvents}
                  showDevices={
                    this.state.currentLegend === "devices" ? true : false
                  }
                />
              );
            }
          } else {
            result.push(
              <CalendarDay
                key={index}
                columnId={index}
                date={dummyDate}
                isFuture={false}
                events={events}
                heights={heights}
                focusSessions={focusSessions}
                showEvents={this.state.showEvents}
                showDevices={
                  this.state.currentLegend === "devices" ? true : false
                }
              />
            );
          }
        } else if (date.getTime() === today.getTime()) {
          result.push(
            <CalendarToday
              key={index}
              today={this.state.calendarData.data.activities.today}
              events={events}
              heights={heights}
              focusSessions={focusSessions}
              showEvents={this.state.showEvents}
              showDevices={
                this.state.currentLegend === "devices" ? true : false
              }
            />
          );
        } else {
          if (k < future.length) {
            let futureDate = new Date(future[k].date);
            if (index) {
              result.push(
                <CalendarDay
                  key={index}
                  columnId={index}
                  date={future[k]}
                  isFuture={true}
                  events={events}
                  heights={heights}
                  focusSessions={focusSessions}
                  showEvents={this.state.showEvents}
                  showDevices={
                    this.state.currentLegend === "devices" ? true : false
                  }
                />
              );
              k += 1;
            } else {
              result.push(
                <CalendarDay
                  key={index}
                  columnId={index}
                  date={dummyDate}
                  isFuture={true}
                  events={events}
                  heights={heights}
                  focusSessions={focusSessions}
                  showEvents={this.state.showEvents}
                  showDevices={
                    this.state.currentLegend === "devices" ? true : false
                  }
                />
              );
            }
          } else {
            result.push(
              <CalendarDay
                key={index}
                columnId={index}
                date={dummyDate}
                isFuture={true}
                events={events}
                heights={heights}
                focusSessions={focusSessions}
                showEvents={this.state.showEvents}
                showDevices={
                  this.state.currentLegend === "devices" ? true : false
                }
              />
            );
          }
        }
      }
    } else {
      let dummyDate: DayActivities = {
        date: new Date().toDateString(),
        accuracy: "0%",
        activities: [],
      };
      for (let i = 0; i < 7; i++) {
        result.push(
          <CalendarDay
            key={i}
            columnId={i}
            date={dummyDate}
            isFuture={true}
            events={[]}
            heights={heights}
            focusSessions={[]}
            showEvents={this.state.showEvents}
            showDevices={this.state.currentLegend === "devices" ? true : false}
          />
        );
      }
    }
    return result;
  }

  displayDates() {
    const { classes } = this.props;
    let week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let result = [];
    // let past = this.state.calendarData.data.activities.past;
    if (this.state.dateRange) {
      const startdate = this.state.dateRange.startDate;
      //startdate.setHours(0, 0, 0, 0);
      const enddate = this.state.dateRange.endDate;
      //enddate.setHours(0, 0, 0, 0);
      const center = this.state.dateRange.centerDate;
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let date: Date;
      for (
        date = new Date(startdate.getTime());
        date <= enddate;
        date.setDate(date.getDate() + 1)
      ) {
        if (date.getTime() !== today.getTime()) {
          result.push(
            <Typography
              variant="calendarHeading"
              key={date.getTime()}
              className={classes.toptext}
            >
              {week[date.getDay()]} {date.getDate()}
            </Typography>
          );
        } else {
          result.push(
            <Typography
              variant="calendarHeading"
              key={date.getTime()}
              className={classes.toptext}
              color="#000!important"
            >
              {week[date.getDay()]}{" "}
              <span
                style={{
                  backgroundColor: "#643992",
                  color: "white",
                  height: "26px",
                  padding: "2% 5%",
                  borderRadius: "3px",
                }}
              >
                {date.getDate()}
              </span>
            </Typography>
          );
        }
      }
    }
    return result;
  }

  displayTimeFrames() {
    const { heights } = this.state;
    let result = [];
    let curr: number = 12;
    let ampm: string = "AM";
    let arr = Array(24).keys();
    let initPos =
      heights.accuracyBox - ((8.5 / 12) * (0.83 * window.innerWidth)) / 100; // Subtract to align to grid lines
    for (const i of arr) {
      result.push(
        <Box
          key={i}
          sx={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: initPos + "px",
            right: "10px",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Typography
            variant="body2"
            sx={{ marginRight: "25%", whiteSpace: "nowrap" }}
          >
            {curr} {ampm}
          </Typography>
          {/* <div
            style={{
              height: "2px",
              width: "14px",
              borderTop: "2px solid #E0E4EF",
              alignSelf: "center",
            }}
          ></div> */}
        </Box>
      );
      initPos += heights.cell;
      if (curr === 12) {
        curr = 1;
      } else {
        if (curr === 11) ampm = ampm === "AM" ? "PM" : "AM";
        curr += 1;
      }
    }
    return result;
  }

  getTimeZoneOffset() {
    const x = new Date();
    const tzOff = x.getTimezoneOffset();
    let timezoneOffsetString = "UTC";
    if (tzOff <= 0) timezoneOffsetString += "+";
    else timezoneOffsetString += "-";
    const hours = Math.floor(Math.abs(tzOff) / 60)
      .toString()
      .padStart(2, "0");
    const minutes = (Math.abs(tzOff) % 60).toString().padStart(2, "0");
    timezoneOffsetString += hours + ":" + minutes;
    return timezoneOffsetString;
  }

  render() {
    const { heights } = this.state;
    const now = new Date();
    let categories = [
      "Applications",
      "Email",
      // "Calendar",
      "News",
      "Entertainment/Social Media",
      "Other",
      // "Prediction",
    ];
    const { classes } = this.props;
    return (
      <>
        <ToastContainer
          autoClose={3000}
          theme="colored"
          hideProgressBar
          closeOnClick
        />
        <Box
          className={classes.container}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            borderRadius: "12px",
            overflow: "hidden",
            margin: "0 auto",
            height: "auto",
            overflowX: "scroll",
          }}
        >
          <ErrorBoundary>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#F7F4FB",
                border: "1px white",
                width: "100%",
                position: "relative",
              }}
            >
              {
                <Box
                  sx={{
                    height: "7vh",
                    width: "10%",
                    minWidth: "100px",
                    borderTop: "1px solid white",
                    borderLeft: "1px solid white",
                    boxSizing: "border-box",
                    overflow: "visible",
                    whiteSpace: "nowrap",
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#7D708E",
                      width: "100%",
                    }}
                  >
                    {this.getTimeZoneOffset()}
                  </Typography>
                </Box>
              }
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "90%",
                  minWidth: "850px",
                }}
              >
                {this.state.calendarData ? (
                  <Box className={classes.datebox}>{this.displayDates()}</Box>
                ) : (
                  <Box className={classes.datebox}>{this.displayDates()}</Box>
                )}
                <LegendBox
                  showDevices={
                    this.state.currentLegend === "devices" ? true : false
                  }
                />
              </Box>
            </Box>
            <Box
              sx={{
                backgroundColor: "white",
                height: "75vh",
                width: "100%",
                minWidth: "951px",
                overflowY: "auto",
                overflowX: "hidden",
                position: "relative",
                paddingRight: "50px",
              }}
              ref={this.timelineDiv}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <CurrentTimeIndicator heights={heights} />
                <Box
                  sx={{
                    position: "absolute",
                    width: "10%",
                    minWidth: "100px",
                    backgroundColor: "white",
                  }}
                >
                  <Box sx={{ position: "relative", width: "100%" }}>
                    {this.displayTimeFrames()}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "90%",
                    minWidth: "850px",
                    marginLeft: "max(10%, 100px)",
                    flex: "0 0 90%",
                  }}
                >
                  {this.state.columns}
                </Box>
              </Box>
              <Box
                key={-2}
                sx={{
                  height: heights.accuracyBox,
                  width: "100%",
                  borderTop: "1px solid black",
                }}
              ></Box>
            </Box>
          </ErrorBoundary>
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(Calendar);
