import {
    Box,
    Typography,
    Button,
    TextField,
    ThemeProvider,
    Grid,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { BoxLoader } from "../../../components/loader/boxloader.component";
import { appState } from "../../../services/app.service";
import theme from "../../../theme/theme";
import { SupportTabContent } from "../support-tab.component";
import { SupportsPage } from "../supportpage.component";
import { raiseTicket } from "./support-ticket.service";
const yellowpattern = "/assets/forms/Sign_in_Sign_up_Pattern_3.svg";
const greenpattern = "/assets/forms/Sign_in_Sign_up_Pattern_2.svg";
const graypattern = "/assets/forms/Sign_in_Sign_up_Pattern_1.svg";

export const FormLayout = (props: any) => {
    return (
        <div
            style={{
                backgroundColor: "#fff",
                width: "100%",
                overflow: "hidden",
                position: "relative",
            }}
        >
            <Box sx={{ height: "20vh", width: "100%", padding: "24px 60px" }}></Box>
            <Box
                sx={{ position: "absolute", top: "74vh", left: "-200px", zIndex: 0 }}
            >
                <img src={yellowpattern} />
            </Box>
            <Box
                sx={{ position: "absolute", top: "25vh", right: "-200px", zIndex: 0 }}
            >
                <img src={greenpattern} />
            </Box>
            <Box sx={{ position: "absolute", top: "20vh", left: "20vw", zIndex: 0 }}>
                <img src={graypattern} style={{ zIndex: 0 }} />
            </Box>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                sx={{ width: "100%", position: "relative", zIndex: 1 }}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                    xl={4}
                    sx={{
                        backgroundColor: "white",
                        padding: 0,
                        boxShadow: " 0px 10px 80px 0px #282C320F",
                        borderRadius: "8px",
                    }}
                >
                    {props.children}
                </Grid>
            </Grid>
        </div>
    );
};

const SupportForm = () => {
    const [description, setdescription] = useState("");
    const [errorMessage, seterrorMessage] = useState({
        message: "",
        showError: false,
    });
    const [successMessage, setsuccessMessage] = useState({
        message: "",
        showSuccess: false,
    });
    const [loading, setloading] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [showDescriptionBox, setShowDescriptionBox] = useState(true);

    useEffect(() => {
        seterrorMessage({
            message: "",
            showError: false,
        });
        setsuccessMessage({
            message: "",
            showSuccess: false,
        });
        setUserEmail(appState.get()?.account.email ?? "");
    }, []);

    const onSubmit = async () => {
        if (description.length === 0) {
            seterrorMessage({
                message: "Please describe your problem",
                showError: true,
            });
        } else {
            seterrorMessage({
                message: "",
                showError: false,
            });
            setloading(true);
            raiseTicket(description)
                .then((res) => {
                    setloading(false);
                    setdescription("");
                    setsuccessMessage({
                        message: "Your ticket has been raised successfully",
                        showSuccess: true,
                    });
                    setShowDescriptionBox(false);
                })
                .catch((err) => {
                    console.log(err);
                    seterrorMessage({
                        message: "Something went wrong, Please try again",
                        showError: true,
                    });
                    setloading(false);
                });
        }
    };

    const onSubmitAnotherTicket = () => {
        setShowDescriptionBox(true);
    }

    const handeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        setdescription(event.currentTarget.value);
        if (successMessage.showSuccess) {
            setsuccessMessage({
                message: "",
                showSuccess: false,
            });
        }
        if (errorMessage.showError) {
            seterrorMessage({
                message: "",
                showError: false,
            });
        }
    };

    return (
        <Box
            sx={{
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
                backgroundColor: "white",
                padding: "8% 8%",
                borderRadius: "20px",
                width: "75%",
            }}
        >
            <BoxLoader loading={loading} />
            <Typography
                variant="body1"
                sx={{ color: "#7F8493", textAlign: "center" }}
            >
                Briefly describe your problem and we will get back to you via email on
                {" " + userEmail}
            </Typography>
            <Box sx={{ display: "block", marginTop: "8%" }}>
                {showDescriptionBox ? (<TextField
                    fullWidth
                    id="description"
                    onChange={handeDescription}
                    multiline
                    minRows={3}
                    value={description ? description : ""}
                    placeholder="Problem Description"
                />) : ("")}
            </Box>
            <Box
                sx={{
                    display: "block",
                    height: "1.2rem",
                    //   marginTop: "-8%",
                    width: "100%",
                    lineHeight: "1.2rem",
                    backgroundColor: "transparent",
                }}
            >
                <Typography
                    variant="body2"
                    sx={{ color: "red", textAlign: "center", marginTop: "6%" }}
                >
                    {errorMessage.showError ? errorMessage.message : ""}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{ color: "green", textAlign: "center", marginTop: "6%" }}
                >
                    {successMessage.showSuccess ? successMessage.message : ""}
                </Typography>
            </Box>
            <Button
                variant="contained"
                className="myButton"
                onClick={showDescriptionBox?(onSubmit):(onSubmitAnotherTicket)}
                sx={{
                    margin: "15px 0",
                    marginTop: "45px",
                    backgroundColor: "#643992",
                }}
            >
                {showDescriptionBox?("Submit"):("Submit Another Ticket")}
            </Button>
        </Box>
    );
};

export const SupportTicket = () => {
    return (
        <SupportsPage
            title={SupportTabContent.contactSupport.title}
            subtitle={SupportTabContent.contactSupport.description}
            loading={false}
        >
            <ThemeProvider theme={theme}>
                <SupportForm />
            </ThemeProvider>
        </SupportsPage>
    );
};
