import * as React from "react";
import { Component } from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "../dashboard.styles.css";
import { checkIfAppInstalled, getColor } from "../utils/dashboard.utils";
import InfoDialog from "../../infodialog/infodialog.component";
import ErrorBoundary from "../../../errorboundary.component";
import { NoDataOverlay } from "../nodataoverlay/nodataoverlay.component";
import { deviceOptions } from "./deviceDummyOptions";
import { displayOverlay } from "../../../overlay.service";
import InfoIcon from "@mui/icons-material/Info";
const borderRadius = require("highcharts-rounded-corners");
borderRadius(Highcharts);

const tooltipText = "Running total of time you spent across your devices";

interface DeviceUsageProps {
  usageItems: {
    value: Array<{
      name: string;
      time: { hours: number; minutes: number; seconds: number };
    }>;
  };
}

interface DeviceUsageState {
  options: any;
  dialogOpen: boolean;
  showOverlay: string | null;
}

const deviceTypes = ["Computer", "Mobile", "Tablet"];

class DeviceUsage extends React.Component<DeviceUsageProps, DeviceUsageState> {
  constructor(props: DeviceUsageProps) {
    super(props);
    this.state = {
      dialogOpen: false,
      showOverlay: null,
      options: {
        chart: {
          renderTo: "container",
          type: "bar",
          height: "74px",
          animation: false,
        },

        credits: {
          enabled: false,
        },

        legend: {
          enabled: false,
        },

        title: {
          text: undefined,
        },
        tooltip: {
          enabled: false,
        },

        xAxis: {
          visible: false,
        },
        yAxis: {
          visible: false,
          max: 100,
        },

        plotOptions: {
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
              hover: {
                opacity: 0.9,
              },
            },
            events: {
              click: this.handleClick,
            },
            stacking: "percent",
            pointWidth: 24,
            groupPadding: 0,
            dataLabels: {
              enabled: true,
              align: "right",
              // verticalAlign: "top",
              y: -2,
              style: {
                color: "#fffff",
                fontFamily: "Roboto, sans-serif",
                fontSize: "max(0.8vw, 12px)",
                fontWeight: "light",
                boxShadow: "none",
                textOutline: "none",
                overflow: "ellipsis",
                position: "relative",
                top: "-5px",
              },
              formatter: function () {
                const self = this as any;
                const percent = self.percentage as number;
                if (percent < 20) {
                  return "";
                }
                let minutes = this.y as number;
                const hours = Math.floor(minutes / 60);
                minutes = minutes % 60;
                const timeString =
                  (hours != 0 ? hours + "h " : "") +
                  (minutes != 0 ? minutes + "m " : "");
                return timeString;
              },
            },
          },
          column: {
            grouping: false,
          },
        },

        series: [
          /*
                    {data: [48], name: 'laptop'},
                    {data: [18], name: 'mobile'},
                    {data: [34], name: 'tablet'} */
        ],
      },
    };
  }

  static getDerivedStateFromProps(
    props: DeviceUsageProps,
    state: DeviceUsageState
  ) {
    const { usageItems } = props;
    let locoptions = state.options;
    let series = [];
    let i = 0;
    {
      if (usageItems) {
        let sortedItems = usageItems.value.sort((a, b) => {
          if (a.name > b.name) return -1;
          else if (a.name < b.name) return 1;
          else return 0;
        });
        for (const device of sortedItems) {
          let color = getColor(device.name) + "BB";
          let val = [];
          let perc = device.time.hours * 60 + device.time.minutes;
          val.push(perc);
          if (i === 0) {
            if (usageItems.value.length !== 1) {
              series.push({
                data: val,
                name: device.name,
                borderRadiusTopLeft: "50%",
                borderRadiusTopRight: "50%",
                color: color,
              });
            } else {
              series.push({
                data: val,
                name: device.name,
                borderRadiusTopLeft: "50%",
                borderRadiusTopRight: "50%",
                borderRadiusBottomLeft: "50%",
                borderRadiusBottomRight: "50%",
                color: color,
              });
            }
          } else if (i === usageItems.value.length - 1) {
            series.push({
              data: val,
              name: device.name,
              borderRadiusBottomLeft: "50%",
              borderRadiusBottomRight: "50%",
              color: color,
            });
          } else {
            series.push({ data: val, name: device.name, color: color });
          }
          i += 1;
        }
      }
      locoptions.series = series;
      locoptions.yAxis = {
        visible: false,
        max: 100,
      };
    }
    return {
      options: locoptions,
    };
  }

  componentDidMount() {
    if (this.state.showOverlay === null) {
      displayOverlay("COMPONENT_DASHBOARD_USAGE_BY_DEVICE").then(
        (str: string | null) =>
          this.setState({
            showOverlay: str,
          })
      );
    }
  }

  handleClick = (event: any) =>
    this.setState({
      dialogOpen: false,
    });

  handleClose = (event: any, reason: string) =>
    this.setState({
      dialogOpen: false,
    });

  render() {
    return (
      <Box
        className="masonry-item"
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          height: "11.25vw",
          width: "100%",
          alignContent: "center",
          padding: "2% 5%",
          borderRadius: "12px",
          minHeight: "174px",
        }}
      >
        <ErrorBoundary>
          <InfoDialog
            open={this.state.dialogOpen}
            insights={[]}
            rawdata={[]}
            onClose={this.handleClose}
          />
          <Stack direction={"row"} justifyContent="space-between">
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
                width: "100%",
                marginRight: "-24px",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Device Usage
              </Typography>
            </Box>
            <Box sx={{ marginRight: "-19px" }}>
              <Tooltip title={tooltipText}>
                <InfoIcon
                  sx={{
                    fontSize: "20px",
                    ":hover": { transform: "scale(1.1)" },
                  }}
                />
              </Tooltip>
            </Box>
          </Stack>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            position="relative"
            height="100%"
            overflow="visible"
          >
            <div
              style={{
                width: "100%",
                padding: "0px",
                height: "74px",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              {this.props.usageItems &&
              this.state.options.series.length > 0 &&
              !this.state.showOverlay ? (
                <Box margin="auto">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state.options}
                    allowChartUpdate={true}
                    updateArgs={[true]}
                  />
                </Box>
              ) : !this.state.showOverlay ? (
                <Box
                  borderRadius="8px"
                  height="90%"
                  textAlign="center"
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  border={"1px dashed #AAAFBB"}
                  position="relative"
                >
                  <Typography variant="h6Light" margin="auto" width="100%">
                    No data available
                  </Typography>
                  {/* <Typography variant="h6Light" margin="auto" width="100%">
                  No data available
                </Typography> */}
                </Box>
              ) : (
                <div style={{ width: "100%", padding: "0px", height: "70px" }}>
                  <NoDataOverlay text={this.state.showOverlay} />
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={deviceOptions}
                    allowChartUpdate={true}
                    updateArgs={[true]}
                  />
                </div>
              )}
            </div>
            <Box>
              <div
                style={{
                  width: "110%",
                  height: "2px",
                  backgroundColor: "#D6DAE4",
                  marginLeft: "-5%",
                  opacity: "0.5",
                  marginTop: "auto",
                }}
              ></div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: "3%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginRight: "5%",
                  }}
                >
                  {deviceTypes.sort().map((device) => (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      marginRight="12%"
                    >
                      <div
                        className="legendBox"
                        style={{ backgroundColor: getColor(device) + "BB" }}
                      ></div>
                      <Typography variant="body1">{device}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </ErrorBoundary>
      </Box>
    );
  }
}

export default DeviceUsage;
