import {
  getGroups,
  getUserEmail,
  getUsername,
} from "../../accountcontext/account";
import {
  Account,
  createNewAccount,
  getAccount,
  getProfile,
  Profile,
} from "../../components/profile.service";
import { appState, setAppInitialized } from "../../services/app.service";

// TEMPORARY STATIC CONSTANTS
const MAINTENANCE_STATUS = false;

export const initializeMyTiro = async (initComplete: any) => {
  return new Promise<void>(async (resolve) => {
    // check if under maintenance
    const maintenance = await getMaintenanceStatus();
    if (maintenance) {
      initComplete(true, null, null);
      return;
    }

    const account = await loadAccount(true);
    const createProfile =
      account.profile === undefined || account.profile === null;
    let profile = undefined;
    if (!createProfile) {
      profile = await loadProfile();
    }
    const cogGroups = await getGroups();
    setupAppState(account, profile, cogGroups);
    setAppInitialized();
    initComplete(false, createProfile, account);
    return;

    // Temporary to test loading time
    // setTimeout(() => {
    //   resolve();
    // }, 2000);
  });
};

const getMaintenanceStatus = async () => {
  return new Promise<boolean>((resolve) => {
    setTimeout(() => {
      resolve(MAINTENANCE_STATUS);
    }, 500);
  });
};

const loadProfile = async (): Promise<Profile> => {
  return new Promise((resolve, reject) => {
    getProfile()
      .then((profile) => {
        resolve(profile);
      })
      .catch((err) => {
        console.log("Could not get profile: ", err);
        reject(err);
      });
  });
};

const loadAccount = async (noCache: boolean) => {
  return new Promise<Account>(async (resolve, reject) => {
    const username = await getUsername();
    getAccount(username, noCache)
      .then((account) => {
        console.log("Account Loaded:", account);
        resolve(account);
      })
      .catch(async (err) => {
        if (err.message === "NOT_FOUND") {
          const email = await getUserEmail();
          const newAccount: Account = {
            id: username,
            email: email,
            name: email,
          };
          createNewAccount(newAccount)
            .then((account) => {
              console.log("New Account Created:", account);
              resolve(account);
            })
            .catch((err) => {
              console.log("Error Creating New Account:", err);
              reject(err);
            });
        } else {
          reject(err);
        }
      });
  });
};

const setupAppState = (
  account: Account,
  profile: Profile | undefined,
  cogGrouns: string[]
) => {
  account.profile = profile;
  appState.set({
    account: account,
    username: account.id,
    isOnboarded: account.profile?.onboardingComplete ?? false,
    cognitoGroups: cogGrouns,
  });
};
