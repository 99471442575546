import { Box, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import {
  DaySummaryDto,
  TimelineDto,
} from "../../../../services/timeline/timeline.model";
import { AppTimelineChart } from "./charts/app-timeline-chart.component";
import { appusagedialogdummyData } from "./dummydata";

interface AppTimelineProps {
  timelineData: TimelineDto[];
  prevTimelineData: TimelineDto[];
  summary: DaySummaryDto;
  prevSummary: DaySummaryDto;
  dateEpoch: number;
  appName: string;
}

export const AppTimeline = (props: AppTimelineProps) => {
  const [appTimelineData, setAppTimelineData] = useState<TimelineDto[]>(
    appusagedialogdummyData
  );
  const [dateEpoch, setDateEpoch] = useState<number>(0);
  const [minHour, setMinHour] = useState<number>(0);
  const [maxHour, setMaxHour] = useState<number>(24);
  const [appName, setAppName] = useState<string>("App's");
  const [timelineData, setTimelineData] = useState<TimelineDto[][]>([]);

  useEffect(() => {
    setAppName(props.appName);
  }, [props.appName]);

  const updateHour = () => {
    if (!props.timelineData || !props.prevTimelineData) {
      // console.log(props.timelineData);
      return;
    }
    let todaymin = 0;
    let todaymax = 24;
    let prevmin = 0;
    let prevmax = 24;

    todaymin = props.timelineData.find((td) => !td.offline)?.hour ?? 24;
    todaymax =
      props.timelineData
        .slice()
        .reverse()
        .find((td) => !td.offline)?.hour ?? 0;

    prevmin = props.prevTimelineData.find((td) => !td.offline)?.hour ?? 24;
    prevmax =
      props.prevTimelineData
        .slice()
        .reverse()
        .find((td) => !td.offline)?.hour ?? 0;

    let min = Math.min(todaymin, prevmin);
    let max = Math.max(todaymax, prevmax);

    setMinHour(min === 0 ? min : min - 1);
    setMaxHour(max === 24 ? max : max + 1);
    // console.log("min at component: ", min);
    // console.log("max at component: ", max);
    // setMinHour(min);
    // setMaxHour(max);
  };

  useEffect(() => {
    //TODO remove log
    // console.log("date epoch", props.dateEpoch);
    setDateEpoch(props.dateEpoch);
  }, [props.dateEpoch]);

  useEffect(() => {
    setTimelineData(getData(props.timelineData));
    updateHour();
  }, [props.timelineData, props.prevTimelineData]);

  return (
    <Box
      sx={{
        bgcolor: "white",
        width: "100%",
        padding: "1%",
        borderRadius: "12px",
        height: "100%",
      }}
    >
      <Typography variant="h6">
        <span style={{ fontWeight: 400 }}>
          When did you use{" "}
          <span style={{ color: "#7E4BB7", fontWeight: 700 }}>{appName}</span>?
        </span>
      </Typography>
      <AppTimelineChart
        chartData={props.timelineData}
        title={""}
        summary={props.summary}
        today={true}
        minHour={minHour}
        maxHour={maxHour}
        date={DateTime.fromSeconds(dateEpoch)}
        showLegend={true}
        appName={appName}
      ></AppTimelineChart>
      {/* <AppTimelineChart
        chartData={props.prevTimelineData}
        title={""}
        summary={props.prevSummary}
        today={false}
        minHour={minHour}
        maxHour={maxHour}
        date={DateTime.fromSeconds(dateEpoch).minus({ days: 1 })}
        showLegend={false}
        appName={appName}
      ></AppTimelineChart> */}
    </Box>
  );
};

const getData = (ddata: TimelineDto[]) => {
  //   console.log(ddata);
  const arry = [];
  const json: {
    [epoch: number]: TimelineDto[];
  } = {};
  for (const data of ddata) {
    const key = DateTime.fromSeconds(data.time.startTime)
      .startOf("day")
      .toUnixInteger();
    json[key] ??= [];
    json[key].push(data);
  }
  for (const key in json) {
    arry.push(json[key]);
  }
  return arry;
};
