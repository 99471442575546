export interface NotificationType {
  id: number;
  accountId: string;
  source: string;
  notificationMethod: NotificationMethod;
  title: string;
  message: string;
  action: NotificationAction;
  status: NotificationStatus;
  timeToShow: number;
  readTimestamp: number | null;
  dismissedTimestamp: number | null;
  createdAt: Date;
  updatedAt: Date;
}

export enum NotificationMethod {
  DASHBOARD = "DASHBOARD",
}

export interface NotificationAction {
  name: string;
  url: string;
}

export enum NotificationStatus {
  READ = "READ",
  UNREAD = "UNREAD",
  DISMISSED = "DISMISSED",
}
