import { Theme } from "@mui/material";
import { createStyles } from "@mui/styles";

export const calendarStyles = (theme: Theme) =>
  createStyles({
    gridcell: {
      border: "1px solid #E6E7EB",
      width: "100%",
      alignItems: "center",
      overflow: "visible",
      boxSizing: "border-box",
      flex: "0 0",
    },

    accuaracycell: {
      alignItems: "self-start",
      border: "1px solid #E6E7EB",
      backgroundColor: "transparent",
      width: "100%",
      flex: "0 0",
    },
    accuracybox: {
      backgroundColor: "#E7EBEE",
      padding: "2%",
      margin: "4% auto",
      borderRadius: "3px",
      width: "90%",
      height: "60%",
      textAlign: "center",
    },
    activityItem: {
      padding: "4% 10%",
      border: "1px solid white",
      margin: "0%",
      width: "100%",
      boxShadow: "none",
      textAlign: "center",
      overflow: "hidden",
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      zIndex: 1,
      msTextOverflow: "ellipsis",
      "&:hover": {
        transform: "scale3d(1, 1, 1)",
        opacity: "0.9",
      },
    },
    activityIcon: { fontSize: "11px", alignSelf: "baseline", marginTop: "2px" },
    activityDescription: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      height: "100%",
      width: "100%",
      lineHeight: "1.2em",
    },
    offline: {
      padding: "0 5%",
      border: "1px solid white",
      backgroundColor: "#F5F6F9",
      margin: "0%",
      width: "100%",
      boxShadow: "none",
      zIndex: 1,
      textAlign: "center",
      background:
        "repeating-linear-gradient(45deg,#F5F6F9,#F5F6F9 10px,#fff 10px,#fff 20px);",

      "&:hover": {
        background:
          "repeating-linear-gradient(45deg,#7882E4,#7882E4 10px,#fff 10px,#fff 20px);",
      },
    },
    columnBox: {
      display: "flex",
      flexDirection: "column",
      width: "15%",
      minWidth: "120px",
    },
    topbox: {
      justifyContent: "center",
      height: "10%",
      padding: "5%",
      backgroundColor: "#F7F4FB",
    },
    text: {
      fontSize: "12px !important",
      fontWeight: "500 !important",
    },
    description: {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "white",
    },
    selector: {
      backgroundColor: "white",
      borderRadius: 0,
      height: "42px",
      alignSelf: "center",
    },
    categorybox: {
      margin: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      backgroundColor: "#F7F4FB",
      width: "110%",
      alignItems: "center",
      border: "1px solid white",
      padding: '1% 0',
      borderLeft: 0,
      paddingRight: "1%",
    },
  });
