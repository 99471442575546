import { getColor } from "../utils/dashboard.utils";

export const focusOptions = {
    chart: {
      renderTo: "container",
      type: "bar",
      height: "70px",
      marginTop: 0,
      animation: false,
    },
    credits: {
      enabled: false,
    },

    legend: {
      enabled: false,
    },

    title: {
      text: undefined,
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },

    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            opacity: 0.9,
          },
        },
        stacking: "percent",
        // pointWidth: 24,
        // groupPadding: 0,
        dataLabels: {
          enabled: true,
          align: "right",
          verticalAlign: "top",
          y: -2,
          style: {
            color: "#fffff",
            fontFamily: "Roboto, sans-serif",
            fontSize: "max(12px, 0.8vw)",
            fontWeight: "400",
            boxShadow: "none",
            textOutline: "none",
            overflow: "ellipsis",
            position: "absolute",
          },
          formatter: function () {
            const self = this as any;
            const percent = self.percentage as number;
            if (percent < 15) {
              return "";
            }
            let minutes = this.y as number;
            const hours = Math.floor(minutes / 60);
            minutes = minutes % 60;
            const timeString =
              (hours != 0 ? hours + "h " : "") +
              (minutes != 0 ? minutes + "m " : "");
            return timeString;
          },
        },
      },
      bar: {
        grouping: false,
        pointWidth: 24,
        groupPadding: 0,
      },
    },
    series: [
        {
            data: [450],
            type: "bar",
            name: "Focused",
            legendIndex: 0,
            color: getColor("Fragmented"),
          },
          {
            data: [450],
            type: "bar",
            name: "Focused",
            legendIndex: 0,
            color: getColor("Focused"),
          }
    ],
}